import styled from 'styled-components'

export const ArtistCard = styled.div`
    transform: translateY(-4rem);
`

export const ArtistImage = styled.img`
    border-radius: 50%;
    height: 8rem;
    object-fit: cover;
    width: 8rem;
`

export const DivWithHeight = styled.div`
    height: 8rem;
`

export const IconImage = styled.img`
    width: 1.5rem;
`