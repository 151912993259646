import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import * as routes from 'constants/routes'
import { PointerDiv, OptionIconImg } from './styled'
import EditIcon from 'components/atoms/images/icono_editar_perfil.svg'
// import PaymentMethodIcon from 'components/atoms/images/icono_pagos.svg'
import HistoryIcon from 'components/atoms/images/icono_historial.svg'
import FavoritesIcon from 'components/atoms/images/icono_favoritos.svg'
import SavedIcon from 'components/atoms/images/icono_guardados.svg'
import Artistcon from 'components/atoms/images/icono_quiero_ser_artista.svg'

class SmallProfileList extends Component{
    constructor(props){
        super(props)
        this.onOptionClick = this.onOptionClick.bind(this)
    }

    onOptionClick(route){
        this.props.history.push(route)
    }

    render(){
        return(
            <section className='d-flex flex-column p-4 text-gray-muzig'>
                <PointerDiv 
                    className='d-flex my-2' 
                    onClick={() => this.onOptionClick(routes.CLIENT_PROFILE_EDIT)}
                >
                    <div className='mx-2'>
                        <OptionIconImg alt='' src={EditIcon}/>
                    </div>
                    <h5 className='mx-2'>Editar perfil</h5>
                </PointerDiv>
                {/* <PointerDiv 
                    className='d-flex my-2' 
                    onClick={() => this.onOptionClick(routes.CLIENT_PROFILE_PAYMENT_METHODS)}
                >
                    <div className='mx-2'>
                        <OptionIconImg alt='' src={PaymentMethodIcon}/>
                    </div>
                    <h5 className='mx-2'>Formas de pago</h5>
                </PointerDiv> */}
                <PointerDiv 
                    className='d-flex my-2' 
                    onClick={() => this.onOptionClick(routes.CLIENT_PROFILE_HISTORY)}
                >
                    <div className='mx-2'>
                        <OptionIconImg alt='' src={HistoryIcon}/>
                    </div>
                    <h5 className='mx-2'>Historial</h5>
                </PointerDiv>
                <PointerDiv 
                    className='d-flex my-2' 
                    onClick={() => this.onOptionClick(routes.CLIENT_PROFILE_FAVORITES)}
                >
                    <div className='mx-2'>
                        <OptionIconImg alt='' src={FavoritesIcon}/>
                    </div>
                    <h5 className='mx-2'>Favoritos</h5>
                </PointerDiv>
                <PointerDiv 
                    className='d-flex my-2' 
                    onClick={() => this.onOptionClick(routes.CLIENT_PROFILE_SAVED)}
                >
                    <div className='mx-2'>
                        <OptionIconImg alt='' src={SavedIcon}/>
                    </div>
                    <h5 className='mx-2'>Guardados</h5>
                </PointerDiv>
                <PointerDiv 
                    className='d-flex my-2' 
                    onClick={() => this.onOptionClick(routes.CLIENT_CONVERT_TO_ARTIST)}
                >
                    <div className='mx-2'>
                        <OptionIconImg alt='' src={Artistcon}/>
                    </div>
                    <h5 className='mx-2'>Quiero ser artista</h5>
                </PointerDiv>
            </section>
        )
    }
}

export default withRouter(SmallProfileList)