import React from 'react'

import {
    Label,
    FormGroup
} from 'reactstrap'

import { FileInput, FileDiv, FileThumb, ActiveFormFeedback, InputTitle } from './styled'
import { toFixed } from 'helpers/index'

export default ( field ) => {
    
    const { meta: {error, touched} } = field
    const isInvalid = touched && error ? true : false
    let errorMessage = touched ? error : null
    const { input: {onChange, onBlur, value: omitValue, ...inputProps} } = field
    const { ...props } = field

    let fileObject = {}

    if(field.input.value && typeof(field.input.value.file) != 'undefined'  ) {
        fileObject = field.input.value.file
    }

    const handleChange = (handler) => ({ target: {files} }) =>
                          handler(files.length ? { file: files[0] } : {} )

    const renderFileThumb = () => {
        return (
            <div
            className='d-flex flex-column my-4 text-center' 
            key={fileObject.name}>
                <div className='d-flex justify-content-center rounded'>
                    <FileThumb 
                        src={window.URL.createObjectURL(fileObject)}
                        className='mb-1'
                    />
                </div>
                <span className={`my-1 ${fileObject.size/1000000 <= 10 ? 'text-secondary' : 'text-danger'}`}>
                    {fileObject.name} - {toFixed(fileObject.size/1000000,2)} MB. <br/>
                    {fileObject.size/1000000 > 10 ? 'EL ARCHIVO SOBREPASA EL LÍMITE DE 10 MB' : ''}
                </span>
            </div>
        )
    }

    return(
        <FormGroup className="bg-white">
            <Label className='d-flex flex-column'>
                {fileObject instanceof File ? renderFileThumb() : ''}
            </Label>
            <FileDiv className='bg-gray-sidebar'>
                <FileInput
                    onChange={handleChange(onChange)}
                    onBlur={handleChange(onBlur)}
                    { ...inputProps } { ...props }
                />
                <InputTitle className='text-secondary text-center'>Presiona para seleccionar tu archivo</InputTitle>
            </FileDiv>
            <ActiveFormFeedback invalid={isInvalid.toString()}>{errorMessage}</ActiveFormFeedback>
        </FormGroup>
    )
}