import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { CardBrandLogo, CardCheck, CardDelete } from './styled'
import { selectDefaultCard, deleteCard } from 'actions/user'
import AcceptModal from 'components/organisms/modal_generic_accept'

class UserCardsList extends Component{
    constructor(props){
        super(props)
        this.state = {
            modalDeleteCard: false,
            cardIdToDelete: null
        }
        this.renderCardsItems = this.renderCardsItems.bind(this)
        this.onCardSelected = this.onCardSelected.bind(this)
        this.toogleDeleteCard = this.toogleDeleteCard.bind(this)
        this.onAcceptDeleteCard = this.onAcceptDeleteCard.bind(this)
    }

    onCardSelected(card_id){
        this.props.selectDefaultCard(this.props.user_id, card_id)
        if(this.props.redirectTo)
            this.props.history.push(this.props.redirectTo)
    }

    toogleDeleteCard(card_id = null) {
        this.setState({
            modalDeleteCard: !this.state.modalDeleteCard,
            cardIdToDelete : card_id
        })
    }

    onAcceptDeleteCard(){
        this.props.deleteCard(this.props.user_id, this.state.cardIdToDelete)
        this.toogleDeleteCard()
    }

    renderCardsItems(){
        return _.map(this.props.user.cards, (card, id) => {
            return (
                <div className='row no-gutters d-flex align-items-center my-2' key={id}>
                    <div 
                        className='col-1 d-flex justify-content-sm-center'
                        onClick={() => this.onCardSelected(id)}>
                        <CardCheck default={this.props.user.default_card === id}/>
                    </div>
                    <div className='col-2 col-sm-1'><CardBrandLogo brand={card.brand}/></div>
                    <div className='col-8 col-sm-4 col-md-3'>**** **** **** {card.last4}</div>
                    <div className='col-1'
                        onClick={() => this.toogleDeleteCard(id)}>
                        <CardDelete/>
                    </div>
                </div>
            )
        }) 
    }

    render(){
        return(
            <>
                {this.renderCardsItems()}
                <AcceptModal
                    isOpen={this.state.modalDeleteCard}
                    toogle={this.toogleDeleteCard}
                    onClickAcceptButton={this.onAcceptDeleteCard}
                    modalHeaderText='Eliminar tarjeta'
                    modalBodyText='¿Estás seguro de eliminar esta tarjeta?'
                    acceptButtonColor='danger'
                    acceptButtonText='Eliminar'
                    cancelButtonColor='secondary'
                    cancelButtonText='Cancelar'
                />
            </>
        )
    }
}

function mapStateToProps({ auth }){
    const { user } = auth
    const user_id = auth.meta.user.uid
    return ({ user, user_id })
}

export default connect(
    mapStateToProps,
    { selectDefaultCard, deleteCard }
)(
    withRouter(UserCardsList)
)