import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import FlashAlert from 'components/molecules/alert'
import HomeSearch from 'components/organisms/home_search_field'
import SearchResultCard from 'components/molecules/search_result_card'
import IconNoResults from 'components/atoms/images/vacios/sin_resultados.svg'

import { selectService } from 'actions/services'
import * as routes from 'constants/routes'
import { EmptyImg } from './styled'
import { Row } from 'reactstrap'
import { getCurrentCountry } from 'helpers/index'

class SearchResults extends Component{
    constructor(props){
        super(props)
        this.onServiceClick = this.onServiceClick.bind(this)
    }
    
    onServiceClick(service){
        this.props.selectService(service, () => {
            const URL_TOKENS = { ':id':service.id, ':slug':service.slug }
            const formated_route = routes.SHOW_SERVICE.replace(/:id|:slug/gi, matched => URL_TOKENS[matched])
            this.props.history.push(formated_route)
        })
    }

    renderListServices(services){
        return _.map( services, service => {
            return (
                <SearchResultCard 
                    key={service.id}
                    service={service}
                    onServiceClick={this.onServiceClick}
                />
            )
        })
    }

    renderServicesList(){
       return (
            <div className='d-flex flex-column'>
                <FlashAlert/>
                <section className='text-center mt-3 mt-md-5'>
                    <HomeSearch />
                    <h1 className='mt-4 mt-md-3'>Resultados de búsqueda</h1>
                    <h6>
                        { 
                            this.props.user ? 
                                `Se muestran servicios para ${this.props.user.country} , si deseas consultar servicios en otro país, puedes cambiar el país en tu Perfil -> Editar Perfil.` 
                            : 
                                '' 
                        }
                    </h6>
                    <Row noGutters className='p-2'>
                        {this.renderListServices(this.props.list)}
                    </Row>
                </section>
            </div>
        )
    }

    renderEmptyServices(){
        return (
            <div className='text-center mt-3 mt-md-5'>
                <HomeSearch />
                <EmptyImg src={IconNoResults} alt='' className='mt-4'/>
                <h2 className='text-gray-muzig my-4'>Sin resultados para tu búsqueda</h2>
            </div>
        )
    }

    render(){
        return(
            <>
                {
                    this.props.list.length > 0 ? 
                        this.renderServicesList()
                        :
                        this.renderEmptyServices()
                }
            </>
        )
    }
}

function mapStateToProps({ services, auth }){
    const { list } = services
    const { user } = auth
    const user_country = getCurrentCountry(user ? user.country : 'MÉXICO')
    
    return { list, user, user_country }
}

export default withRouter(
    connect(
        mapStateToProps, { selectService }
    )(SearchResults)
)