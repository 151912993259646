import styled from 'styled-components'

export const EmptyImg = styled.img`
    width: 45%;
`

export const FavoriteImg = styled.img`
    width: 3rem;
`

export const PointerDiv = styled.div`
    cursor: pointer;
`