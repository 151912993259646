import styled from 'styled-components'

export const EmptyImg = styled.img`
    width: 45%;
`

export const StyledBadge = styled.span`
    border-radius: 2rem!important;
`

export const BottomDiv = styled.div`
    background-color: ${
        props => {
            switch(props.status){
                case 0:
                    return '#FFB352'
                case 1:
                    return '#60A416'
                case 3:
                    return '#EB5353'
                default:
                    return '#B3B3B3'
            }
        }
    }
`