import _ from 'lodash'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'

import { selectNewServiceStep } from 'actions/shared'
import { createService } from 'actions/artists'
import { NEW_SERVICE_VIEWS } from 'constants/index'
import * as routes from 'constants/routes'
import { PointerDiv, OptionIconImg } from './styled'
import Button from 'components/atoms/buttons/squared_solid'

import IncompleteIcon from 'components/atoms/images/icono_incompleto.svg'
import CompleteIcon from 'components/atoms/images/icono_completo.svg'


class BigSidemenu extends Component{
    constructor(props){
        super(props)
        this.onStepSelected = this.onStepSelected.bind(this)
        this.shouldFormBeInvalid = this.shouldFormBeInvalid.bind(this)
    }

    onStepSelected(page){
        this.props.selectNewServiceStep(page)
    }

    onSubmit(values){
        values.artist_id = this.props.user.uid
        values.artist_name = this.props.user_info.artist_name
        values.category = this.props.user_info.artist_type
        this.props.createService(values, () => {
            this.props.reset('createNewServiceWizard')
            this.props.history.push(routes.ARTIST_JOB_SERVICES)
        })
    }

    shouldFormBeInvalid(){
        const incompleteViews = _.filter(NEW_SERVICE_VIEWS, view => {
            return !this.props.newServiceStepCompleted[view]
        })

        if(incompleteViews.length > 0)
            return true
        else{
            return false
        }
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <section className='d-flex flex-column text-gray-muzig'>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.name}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.name)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.name] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Título</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.description}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.description)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.description] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Descripción</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.cost}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.cost)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.cost] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Costo por hora</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.zones}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.zones)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.zones] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Zonas de servicio</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.availability}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.availability)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.availability] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Disponibilidad</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.photos}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.photos)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.photos] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Fotos</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.video}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.video)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.video] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Video</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.types}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.types)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.types] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Tipo de evento</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.includes}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.includes)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.includes] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>¿Qué incluye?</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.newServiceStep === NEW_SERVICE_VIEWS.category}
                    onClick={() => this.onStepSelected(NEW_SERVICE_VIEWS.category)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.category] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Categoría</div>
                </PointerDiv>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='my-5'>
                    <Button
                        is_disabled={this.shouldFormBeInvalid()}
                        type='submit' 
                        color='secondary' 
                        text='Finalizar'/>
                </form>
            </section>
        )
    }
}

function mapStateToProps({ shared, auth }){
    const { newServiceStep, newServiceStepCompleted } = shared
    const { meta: { user }} = auth
    const user_info = auth.user
    return { newServiceStep, newServiceStepCompleted, user, user_info }
}

BigSidemenu = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(BigSidemenu)
  
  BigSidemenu = connect(
    mapStateToProps,
    { selectNewServiceStep, createService, reset }
  )(withRouter(BigSidemenu))

export default BigSidemenu