import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import validate from './validate'
import Input from 'components/atoms/inputs/generic_input'
import FileInput from 'components/atoms/inputs/generic_file_input'
import NextButton from 'components/atoms/buttons/solid'
import { BackButton } from '../styled'

import iconCLABE from 'components/atoms/images/icono_clabe.svg'
import iconPromotor from 'components/atoms/images/icono_promotor.svg'

class WizardFormFourthPage extends Component {

  render(){
    const { handleSubmit, previousPage } = this.props

    return (
      <>
        <h2 className='text-primary mt-4'>Datos personales</h2>
        <p>Agrega la foto de tu identificación oficial vigente y tu correo de Paypal a donde realizaremos los depósitos.</p>

        <form onSubmit={handleSubmit} className='p-2'>
          <div className='bg-warning rounded'>
            <h3 className='text-white p-2'>Identificación*</h3>
          </div>
          
          <Field
            name="idCard"
            type='file'
            label="Identificación oficial"
            component={FileInput}
          />

          <div className='w-100 my-5'></div>

          <div className='bg-warning rounded'>
            <h3 className='text-white p-2'>Referidos</h3>
          </div>
          <p className='text-gray-muzig'>Proporciona el correo de quién te refirió en The Muzigzag®. De lo contrario omite esta información.</p>

          <Field
            name="promoter_email"
            type='email'
            placeholder="Correo Electrónico"
            icon={iconPromotor}
            component={Input}
          />

            <div className="py-1"/>
            <p style={{fontSize: "12px"}} className='text-gray'>*Campos obligatorios</p>

          <div className='d-flex flex-md-row flex-column justify-content-center mt-5'>
            <BackButton 
              className='mx-md-2 text-white' 
              type="button" 
              onClick={previousPage} 
              color='bg-warning'>Anterior</BackButton>
            <div className='d-block d-md-none w-100 my-2'></div>
            <NextButton 
              className='mx-md-2' 
              type="submit" 
              text='Siguiente'/>
          </div>

        </form>
      </>
    )
  }
}

export default connect(
  null,
  { }
)(
  reduxForm({
    form: 'signupArtistWizard',
    destroyOnUnmount: false,
    validate
  })(WizardFormFourthPage)
)