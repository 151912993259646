import styled from 'styled-components'
import 'react-chat-elements/dist/main.css'
import { MessageList } from 'react-chat-elements'
import { Form } from 'reactstrap'

export const StyledMessageList = styled(MessageList)`
    height: 68vh;
    overflow-y:scroll;
    overflow-x:hidden;
    position:fixed;
    width: 100%;

    .rce-mbox.rce-mbox--clear-notch{
        border-radius: 2rem!important;
        border: 1px solid #4E6395;
        margin: 0.5rem 1rem;
        padding:1rem 2rem 1rem 2rem!important;
    }

    .rce-mbox-time{
        position: inherit;
    }

    .rce-smsg{
        margin: 2rem;
        background-color: #4E6395;
        color: white;
        padding: 1rem;
    }  

`

export const StyledForm = styled(Form)`
    position: absolute;
    bottom: 5rem;
    width: 100%;
    padding-left: 1rem!important;
    padding-right: 1rem!important;
`

export const ConfirmDiv = styled.div`
    cursor: pointer;
    position: relative;
    overflow: hidden;

    span:after {
        animation: sheen 1s 1s forwards;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -100px;
        background: linear-gradient(to bottom, transparent, rgba(255,255,255,0.8) 50%, transparent);
        transform: rotateZ(60deg) translate(-1em, 4em);
    }

    @keyframes sheen {
        100% {
          transform: rotateZ(60deg) translate(0, -10em);
        }
    }
`

export const CancelDiv = styled.div`
    cursor: pointer;
`

export const IconImage = styled.img`
    width: 1.5rem;
`