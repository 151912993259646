import styled from 'styled-components'
import { Button } from 'reactstrap'

export const ImageLogo = styled.img`
    width: 6rem
`

export const RightDiv = styled.div`
    height: 92vh
`

export const RoundedButton = styled(Button)`
  border-radius: 2rem!important;
  padding: 0.2rem 0;
  min-width: 15rem;
  max-width: 15rem;
`

export const ClickableSpan = styled.span`
    cursor:pointer;
    font-size: 14px;
`