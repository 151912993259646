import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Input from 'components/atoms/inputs/simpliest_input'
import { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep } from 'actions/shared'
import { NEW_SERVICE_VIEWS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'
import * as routes from 'constants/routes'

class NewServiceVideo extends Component{
    constructor(props){
        super(props)
        this.validateVideo = this.validateVideo.bind(this)
    }

    validateVideo(video){
        const allowedPlatforms = /youtube\.com|youtu\.be/i
        if(video && !allowedPlatforms.exec(video)){
            this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.video)
            return 'Tu video tiene que ser de Youtube.'
        }
    }

    onSubmit(values){
        this.props.completeNewServiceStep(NEW_SERVICE_VIEWS.video)
        this.props.selectNewServiceStep(NEW_SERVICE_VIEWS.types)
        this.props.history.push(routes.ARTIST_JOB_SERVICES_NEW)
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <div className='container mt-5'>
                <h4 className='text-secondary'>Video</h4>
                <p>Agrega uno de tus mejores videos para mostrarlo a tus clientes. 
                    Sólo puedes agregar enlaces de Youtube.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="video"
                        type="text"
                        placeholder="Agrega un video"
                        validate={this.validateVideo}
                        component={Input}
                    />
                    <div className='my-5 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </div>
        )
    }
}

NewServiceVideo = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewServiceVideo)
  
  NewServiceVideo = connect(
    null,
    { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep }
  )(NewServiceVideo)
  
  export default withRouter(NewServiceVideo)