import styled from 'styled-components'
import { MdSearch, MdTune } from 'react-icons/md'
import { Form } from 'reactstrap'

export const InlineForm = styled(Form)`
    background-color: ${ props => props.small === 'true' ? '#F2F2F2' : 'white' }
    display: inline-block;
    transform: ${ props => props.small === 'true' ? 'none' : 'translateY(-1.5rem)' };
    width: ${ props => props.small === 'true' ? '90%' : '70%' };
`

export const SearchIcon = styled(MdSearch)`
    font-size: 2rem;
`

export const FilterIcon = styled(MdTune)`
    font-size: 2rem;
`