import styled from 'styled-components'
import PageLoaderGif from 'components/atoms/images/page-loader.gif'
import PageLoaderGifHD from 'components/atoms/images/page-loader-hd.gif'

export const PageLoaderDiv = styled.div`
    background: rgba(255,255,255,1) url(${ props => props.hd ? PageLoaderGifHD : PageLoaderGif }) no-repeat center center;
    background-size: contain;
    display: ${ props => props.active ? 'initial' : 'none' }
    height: 100%;
    left: 0;
    padding: 5rem 1rem;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100vw;
    z-index: 1000;
`