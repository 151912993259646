import styled from 'styled-components'
import cover1 from 'components/atoms/images/home/cover-home-1.jpg'
import cover2 from 'components/atoms/images/home/cover-home-2.jpg'
import cover3 from 'components/atoms/images/home/cover-home-3.jpg'
import cover4 from 'components/atoms/images/home/cover-home-4.jpg'
import cover5 from 'components/atoms/images/home/cover-home-5.jpg'
import cover6 from 'components/atoms/images/home/cover-home-6.jpg'

const covers = [cover1, cover2, cover3, cover4, cover5, cover6]

export const HomeBanner = styled.div`
    background-image: url(${covers[Math.floor(Math.random() * 6) + 0 ]});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: ${props => {
        switch(props.size){
            case 'xs':
                return '10vh'
            case 'sm':
                return '20vh'
            case 'md':
                return '30vh'
            case 'lg':
                return '40vh'
            case 'xl':
                return '50vh'
            default:
                break
        }
    }};
    width: 100%;
`