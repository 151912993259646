import styled from 'styled-components'
import { Button, FormGroup } from 'reactstrap'

import VisaLogo from 'components/atoms/images/tarjeta_visa.svg'
import MasterCardLogo from 'components/atoms/images/tarjeta_master.svg'
import AmexLogo from 'components/atoms/images/tarjeta_american.svg'

export const ConfirmIconImage = styled.img`
    height: 2rem;
    width: 2rem;
`

export const SpanChangePayment = styled.span`
    cursor: pointer;
`

export const ArtistImage = styled.img`
    border-radius: 50%;
    height: 6rem;
    object-fit: cover;
    width: 6rem;
`

export const RoundedButton = styled(Button)`
    border-radius: 2rem!important;
`

export const CardBrandLogo = styled.div`
    background-image: url(
        ${props => {
            switch(props.brand){
                case 'visa':
                    return VisaLogo
                case 'mastercard':
                    return MasterCardLogo
                case 'american_express':
                    return AmexLogo
                default:
                    break
            }
        }}
    );
    background-repeat: no-repeat;
    background-size: cover;
    height: 2rem;
    width: 2rem;
`

export const TranslatedFormGroup = styled(FormGroup)`
    transform: translateX(1.2rem)
`

export const PaypalButton = styled.div`
    display: ${props => props.payment === 'paypal' ? 'block' : 'none'}
`

export const CostInfo = styled.span`
    cursor: pointer;
    font-size: 1.3rem;
`