import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import Input from 'components/atoms/inputs/simpliest_text_area'
import { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep } from 'actions/shared'
import { NEW_SERVICE_VIEWS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'

class NewServiceDescription extends Component{
    constructor(props){
        super(props)
        this.validateDescription = this.validateDescription.bind(this)
    }

    validateDescription(description){
        if(!description){
            this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.description)
            return 'Debes definir una descripción para tu servicio'
        }
    }

    onSubmit(values){
        this.props.completeNewServiceStep(NEW_SERVICE_VIEWS.description)
        this.props.selectNewServiceStep(NEW_SERVICE_VIEWS.cost)
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>Descripción</h2>
                <p>Es este espacio describe a detalle tu servicio, trayectoria, lo que incluye, cuántos artistas son y duración del servicio. Recuerda que entre más específica sea la descripción de tu servicio más oportunidades de contratación tendrás.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="description"
                        type="textarea"
                        rows={10}
                        placeholder="Agrega una descripción"
                        validate={this.validateDescription}
                        component={Input}
                    />
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

NewServiceDescription = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewServiceDescription)
  
  NewServiceDescription = connect(
    null,
    { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep }
  )(NewServiceDescription)
  
  export default NewServiceDescription