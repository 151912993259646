import React, { Component } from 'react'
import { LeftCover } from './styled'

export default class LeftSide extends Component{

    render(){
        return(
            <LeftCover className='w-50 ro'>
                <div className=' px-4'>
                    <h1 className='text-white d-none d-lg-block'>{this.props.title}</h1>
                    <h2 className='text-white d-none d-md-block d-lg-none'>{this.props.title}</h2>
                </div>
            </LeftCover>
        )
    }
}