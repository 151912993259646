import map from 'lodash/map'
import React, { Component } from 'react'
import  { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { fetchArtistPayments, fetchArtistWeekHistory } from 'actions/artists'
import { EmptyImg, PointerDiv } from './styled'
import IconNoPayments from 'components/atoms/images/vacios/sin_pagos.svg'
import { MOMENT_IN_SPANISH, CURRENCY_FORMATTER } from 'constants/index'
import * as routes from 'constants/routes'

class ArtistPaymentList extends Component{
    constructor(props){
        super(props)
        this.renderPaymentsList = this.renderPaymentsList.bind(this)
        this.renderEmptyPayments = this.renderEmptyPayments.bind(this)
        this.onPaymentClick = this.onPaymentClick.bind(this)
    }

    componentDidMount(){
        this.props.fetchArtistPayments(this.props.user_id)
    }

    onPaymentClick(end_date){
        this.props.fetchArtistWeekHistory(this.props.user_id, end_date, () => {
            this.props.history.push(routes.ARTIST_MY_WEEK_HISTORY)
        })
    }

    renderPaymentsList(){
        moment.updateLocale('es', MOMENT_IN_SPANISH)
        return (
            map(this.props.payments, payment => {
                return (
                    <PointerDiv 
                        className='row no-gutters border-bottom border-gray-muzig py-4' 
                        key={payment.id}
                        onClick={() => this.onPaymentClick(payment.created_at)}>
                        <div className='col-6 d-flex align-items-center'>
                            <span className='text-gray-muzig'>
                                {moment(payment.created_at).format('DD MMM YYYY')}
                            </span>
                        </div>
                        <div className='col-6'>
                            <h4 className='text-secondary text-right'>{CURRENCY_FORMATTER.format(payment.amount)}</h4>
                        </div>
                        
                    </PointerDiv>
                )
            })
        )
    }

    renderEmptyPayments(){
        return (
            <div className='text-center mt-5'>
                <EmptyImg src={IconNoPayments} alt='' />
                <h2 className='text-gray-muzig my-4'>Sin pagos</h2>
            </div>
        )
    }

    render(){
        return(
            <>
                {
                    this.props.payments.length > 0 ? 
                        this.renderPaymentsList()
                        :
                        this.renderEmptyPayments()
                }
            </>
        )
    }
}

function mapStateToProps({ auth, artist }){
    const user_id = auth.meta.user.uid
    const { payments } = artist
    return { user_id, payments }
}

export default connect(
    mapStateToProps,
    { fetchArtistPayments, fetchArtistWeekHistory }
)(withRouter(ArtistPaymentList))