import _ from 'lodash'
import { ALERTS, SHARED } from 'constants/index'
import { firestore } from 'firebase/index'
import { localeSortArrayBy } from 'helpers/index'

export function closeAlert(){
    return dispatch => {
      dispatch({
        type: ALERTS.CLOSE_ALERT,
        payload: false
      });
    }
  }

export function tabBarOptionSelected(tab_bar_type, option_selected){
  //recibimos tipo de objecto (servicio, artista, cliente, etc) y su opción seleccionada
  return dispatch => {
    dispatch({
      type: tab_bar_type,
      payload: option_selected
    })
  }
}

export function getCountriesList(){
  let countriesArray = []

  return dispatch => {
    firestore.collection("countries").orderBy('name')
    .get().then( querySnapshot => {
      querySnapshot.forEach( doc => {
        let fid = { id:doc.id }
        let data = doc.data()
        countriesArray.push({ ...fid, ...data })
        countriesArray = localeSortArrayBy(countriesArray, 'name')
      })
      
      dispatch({
        type: SHARED.FETCH_COUNTRIES_SUCCESS,
        payload: countriesArray
      })
    })
    .catch( error => console.log(dispatch, error) )
  }
}

export function getZonesByCountry(country){
  return dispatch => {
    firestore.collection('zones').doc(country)
    .get().then( doc => {
      if (doc.exists){
        dispatch({
          type: SHARED.FETCH_ZONES_OF_COUNTRY,
          payload: {zones: doc.data(), country }
        })}
      else
        dispatch({
          type: ALERTS.SHOW_ERROR_MESSAGE,
          payload: 'Aun no hay zonas disponibles para este país, lamentamos los inconvenientes.'
        })
    })
    .catch( error => console.log(dispatch, error) )
  }
}

export function unselectCountry(country){
  return dispatch => {
    dispatch({
      type: SHARED.UNSELECT_ZONES_OF_LIST,
      payload: country
    })
  }
}

export function getEventTypesList(){
  let typesArray = []

  return dispatch => {
    firestore.collection("catalogs").doc('type-event')
    .get().then( querySnapshot => {
      _.map(querySnapshot.data(), (type, uid) => {
        typesArray.push({id: uid, name: uid})
      })
      
      dispatch({
        type: SHARED.FETCH_EVENT_TYPES,
        payload: typesArray
      })
    })
    .catch( error => console.log(dispatch, error) )
  }
}

export function selectSidemenuOption(page){
  return dispatch => {
    dispatch({
      type: SHARED.SELECT_SIDEMENU_OPTION,
      payload: page
    })
  }
}

export function selectNewServiceStep(step){
  return dispatch => {
    dispatch({
      type: SHARED.SELECT_NEW_SERVICE_STEP,
      payload: step
    })
  }
}

export function completeNewServiceStep(step){
  return dispatch => {
    dispatch({
      type: SHARED.COMPLETE_SERVICE_STEP,
      payload: step
    })
  }
}

export function uncheckNewServiceStep(step){
  return dispatch => {
    dispatch({
      type: SHARED.INCOMPLETE_SERVICE_STEP,
      payload: step
    })
  }
}

export function selectEditServiceStep(step){
  return dispatch => {
    dispatch({
      type: SHARED.SELECT_EDIT_SERVICE_STEP,
      payload: step
    })
  }
}

export function completeEditServiceStep(step){
  return dispatch => {
    dispatch({
      type: SHARED.COMPLETE_EDIT_SERVICE_STEP,
      payload: step
    })
  }
}

export function uncheckEditServiceStep(step){
  return dispatch => {
    dispatch({
      type: SHARED.INCOMPLETE_EDIT_SERVICE_STEP,
      payload: step
    })
  }
}

export function getIncludesList(){
  let includesArray = []

  return dispatch => {
    firestore.collection("includes")
    .get().then( querySnapshot => {
      querySnapshot.forEach( doc => {
        let fid = { id:doc.id }
        let data = doc.data()
        includesArray.push({ ...fid, ...data })
      })
      
      dispatch({
        type: SHARED.FETCH_INCLUDES,
        payload: includesArray
      })
    })
    .catch( error => console.log(dispatch, error) )
  }
}

export function getSubcategoriesList(category){
  let subcategoriesArray = []

  return dispatch => {
    firestore.collection("categories").where('parent', '==', category)
    .get().then( querySnapshot => {
      querySnapshot.forEach( doc => {
        let fid = { id:doc.id }
        let data = doc.data()
        subcategoriesArray.push({ ...fid, ...data })
      })
      console.log('subcategorias: ', subcategoriesArray)
      
      dispatch({
        type: SHARED.FETCH_SUBCATEGORIES,
        payload: subcategoriesArray
      })
    })
    .catch( error => console.log(dispatch, error) )
  }
}

export function showSuccessMessage(message){
  return dispatch => {
    dispatch({
      type: ALERTS.SHOW_SUCCESS_MESSAGE,
      payload: message
    })
  }
}

export function showErrorMessage(message){
  return dispatch => {
    dispatch({
      type: ALERTS.SHOW_ERROR_MESSAGE,
      payload: message
    })
  }
}