import styled from 'styled-components'
import { Button } from 'reactstrap'

export const RightDiv = styled.div`
    height: 94.5vh;
`

export const RoundedButton = styled(Button)`
  border-radius: 2rem!important;
  padding: 0.2rem 5rem;
  min-width: 15rem;
`