import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form
    } from 'reactstrap'

import TextAreaInput from 'components/atoms/inputs/generic_text_area'
import { StyledDiv } from './styled'

class RateServiceModal extends Component{
    
    render(){
        const { handleSubmit } = this.props
        
        return(
            <Modal 
                isOpen={this.props.isOpen} 
                toggle={() => this.props.toogle()}
            >
                <Form onSubmit={handleSubmit(this.props.onClickAcceptButton )}>
                    <ModalHeader toggle={ () => this.props.toogle()}>
                        {this.props.modalHeaderText}
                    </ModalHeader>
                    <ModalBody>
                        <StyledDiv className='d-flex justify-content-center mb-4'>
                            <div className='row no-gutters my-4 my-sm-2'>
                                <div className='col-12 text-center'>
                                    <Field id='like' name="rateValue" component='input' type="radio" value="like" />
                                    <label htmlFor='like' className='like p-2'></label>
                                </div>
                                <div className='col-12 px-xs-0 px-sm-5'>
                                    <h5 className='text-gray-muzig text-center'>Si</h5>
                                </div>
                            </div>
                            <div className='row no-gutters my-4 my-sm-2'>
                                <div className='col-12 text-center'>
                                    <Field id='dislike' name="rateValue" component='input' type="radio" value="dislike" />
                                    <label htmlFor='dislike' className='dislike p-2'></label>
                                </div>
                                <div className='col-12 px-xs-0 px-sm-5'>
                                    <h5 className='text-gray-muzig text-center'>No</h5>
                                </div>
                            </div>
                        </StyledDiv>
                        {this.props.modalBodyText}
                        <Field
                            name="comment"
                            type="textarea"
                            class="col-12 mt-4 border-0"
                            component={TextAreaInput}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="secondary">Enviar calificación</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

function validate(values){
    let errors = {}

    if(!values.rateValue)
        errors.rateValue = 'Selecciona una calificación'

    if(!values.comment)
        errors.comment = 'Por favor escribe tu reseña'

    return errors
}

function mapStateToProps({ auth }){
    const { user } = auth
    return { user }
}
  
export default reduxForm({
    validate,
    form: 'RateServiceForm'
  })(
    connect(mapStateToProps, {  })(RateServiceModal)
  )