import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Banner from 'components/molecules/my_services_banner'
import ServicesList from 'components/organisms/my_services_list'


class MyServices extends Component{

    render(){
        return(
            <>
                <Banner/>
                <ServicesList/>
            </>
        )
    }
}

// function mapStateToProps({  }){

// }


export default withRouter(
    connect(
        null,
        {  }
    )(MyServices)
)