import React from 'react'

import {
    InputGroup,
    InputGroupAddon,
    FormGroup
} from 'reactstrap'
import { StyledInput, InputIcon, InvalidFeedback } from './styled'

export default ( field ) => {
    const { meta: {error, touched} } = field
    const isInvalid = touched && error ? true : false
    const colorClass = touched && error ? 'gray' : 'gray'

    return(
        <FormGroup className={"w-100 ml-1 mr-1"}>
            <InputGroup className={`border border-${colorClass} border-2 rounded p-1 w-100`}>
                <StyledInput
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    invalid={isInvalid}
                    autoComplete={field.autocomplete}
                    maxLength={field.max}
                    disabled={field.disabled}
                    {...field.input}
                />
                
            </InputGroup>
            <InvalidFeedback className='mx-3 text-danger'>{ isInvalid ? error : ''}</InvalidFeedback>
        </FormGroup>
    )
}