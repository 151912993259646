import React, { Component } from 'react'
import MyPaymentsList from 'components/organisms/artist_payments_list'

class MyPayments extends Component{
    render(){
        return(
            <>
                <section className='p-4'>
                    <h4 className='text-secondary'>Mis pagos</h4>
                    <MyPaymentsList/>
                </section>
            </>
        )
    }
}

export default MyPayments