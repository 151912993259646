import _ from 'lodash'
import React from 'react'

import {
    InputGroup,
    InputGroupAddon,
    FormGroup
} from 'reactstrap'
import { StyledInput, InputIcon, InvalidFeedback } from './styled'

export default ( field ) => {
    const { meta: {error, touched} } = field
    const isInvalid = touched && error ? true : false
    const colorClass = touched && error ? 'danger' : 'primary'

    const renderOptions = _.map(field.options, option => {
        return(
            <option key={option.id} value={option.id}>{option.name}</option>
        )
    })

    return(
        <FormGroup>
            <InputGroup className={`border-bottom border-${colorClass}`}>
                <InputGroupAddon addonType="prepend" className='d-flex align-items-center'>
                    <InputIcon src={field.icon}/>
                </InputGroupAddon>
                <StyledInput
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    invalid={isInvalid}
                    autoComplete={field.autocomplete}
                    {...field.input}
                >
                    <option key="default" value='' disabled>Selecciona</option>
                    {renderOptions}
                </StyledInput>
            </InputGroup>
            <InvalidFeedback className='mx-3 text-danger'>{ isInvalid ? error : ''}</InvalidFeedback>
        </FormGroup>
    )
}