import styled from 'styled-components'
import ServicesBannerImg from 'components/atoms/images/my_services_cover.jpg'

export const Banner = styled.div`
    background-image: url(${ServicesBannerImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: ${props => {
        switch(props.size){
            case 'xs':
                return '10vh'
            case 'sm':
                return '15vh'
            case 'md':
                return '20vh'
            case 'lg':
                return '25vh'
            case 'xl':
                return '35vh'
            default:
                break
        }
    }};
    width: 100%;
`