import React, { Component } from 'react'
import WeeklyPaymentsList from 'components/organisms/artist_weekly_payments_list'

class MyPayments extends Component{
    render(){
        return(
            <>
                <section className='p-4'>
                    <h4 className='text-secondary'>Resumen semanal</h4>
                    <WeeklyPaymentsList/>
                </section>
            </>
        )
    }
}

export default MyPayments