import styled from 'styled-components'

export const ClientCard = styled.div`
    border-radius: 10px;
`

export const ClientCardRow = styled.div`
    height: 10em;
  border-radius: 10px;
`

export const ArtistImage = styled.img`
    border-radius: 50%;
    height: 10rem;
    object-fit: cover;
    width: 10rem;
`

export const Divisor = styled.div`
    height:0.15rem;
    width:80%;
`

export const VerticalDivisor = styled.span`
    height: 90%;
    width: 0.15rem;
`

export const ImageForm = styled.form`
    transform: translate(-3rem,3.5rem);
`

export const CostInfo = styled.span`
    cursor: pointer;
    font-size: 1.6rem;
`