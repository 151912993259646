import styled from 'styled-components'

export const PointerSpan = styled.span`
    cursor: pointer
`

export const HorizontalScroll = styled.div`
    overflow-y: hidden;
    overflow-x: auto;
    // -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
`