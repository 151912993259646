import React from 'react'
import Media from "react-media"

import { ClientCard, ClientImage, DivWithHeight, IconImage } from './styled'
import IconLike from 'components/atoms/images/icono_like_vacio.svg'
import { DEFAULT_PDF_PROFILE_IMG, DEFAULT_PROFILE_IMAGE_URL } from 'constants/index'

export default (props) => {
    return(
        <>
            
            <Media query={{ minWidth: 768}}>
                {matches => matches ? 
                    <ClientCard className='bg-primary p-4 d-flex align-items-center justify-content-around'>
                        <ClientImage src={
                                            props.service.client_image && props.service.client_image !== DEFAULT_PDF_PROFILE_IMG ?
                                                props.service.client_image
                                                :
                                                DEFAULT_PROFILE_IMAGE_URL
                                        }/>
                        <div>
                            <h2 className='text-white'>{props.service.client_name}</h2>
                            <h5 className='text-white'>{props.service.service_name}</h5>
                        </div>
                        <DivWithHeight className='d-flex align-items-end flex-column'>
                            <h5 className='text-white'>Servicio</h5>
                            <div className='text-white mt-auto d-flex align-items-center'>
                                <IconImage src={IconLike}/>
                                <span className='ml-2'>{props.service.rating}%</span>
                            </div>
                        </DivWithHeight>
                    </ClientCard> : ''}
            </Media>
        </>
    )
}