import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import validate from './validate'
import Input from 'components/atoms/inputs/generic_input'
import OptionSelect from 'components/atoms/inputs/generic_option_select'
import RoundedButton from 'components/atoms/buttons/solid'
import iconName from 'components/atoms/images/icono_nombre_apellido.svg'
import iconEmail from 'components/atoms/images/icono_correo.svg'
import iconTel from 'components/atoms/images/icono_telefono_formulario.svg'
import iconCountry from 'components/atoms/images/icono_pais_registro.svg'
import iconRFC from 'components/atoms/images/icono_rfc.svg'

import { getCountriesList } from 'actions/shared'

class WizardFormFirstPage extends Component {
  componentDidMount(){
    this.props.getCountriesList()
  }

  render(){
    const { handleSubmit } = this.props

    return (
      <>
        <h2 className='text-primary mt-4'>Bienvenido a Muzigzag</h2>
        <p>Por favor ingresa tus datos personales.</p>
        <form onSubmit={handleSubmit} className='p-2'>
          <Field
            name="name"
            type="text"
            placeholder="Nombre*"
            autocomplete='name'
            icon={iconName}
            component={Input}
          />
          <Field
            name="lastName"
            type="text"
            placeholder="Apellidos*"
            autocomplete='last-name'
            icon={iconName}
            component={Input}
          />
          
          <Field
            name="email"
            type="email"
            placeholder="Correo electrónico*"
            autocomplete='email'
            icon={iconEmail}
            component={Input}
            disabled={this.props.initialValues.email ? true : false}
          />
          <Field
            name="rfc"
            type="text"
            placeholder="RFC/ Número Fiscal o Tributario"
            autocomplete='artist-rfc'
            icon={iconRFC}
            component={Input}
          />

          <div className="py-3"/>

          <Field
            name="tel"
            type='number'
            placeholder="Teléfono principal*"
            autocomplete='main-tel'
            icon={iconTel}
            component={Input}
          />

          <Field
            name="country"
            type='select'
            placeholder="País*"
            icon={iconCountry}
            options={this.props.countries}
            component={OptionSelect}
          />

          <div className="py-1"/>
          <p className='text-gray'>*Campos obligatorios</p>
          <p className='text-gray'>Al registrarte y crear una cuenta confirmas que estás de acuerdo con nuestros Términos y Condiciones y nuestro Aviso de Privacidad.</p>
          <div className="py-3"/>
          
          <div className='d-flex justify-content-center mt-5'>
            <RoundedButton type="submit" text='Registrar'/>
          </div>
        </form>
      </>
    )
  }
}

function mapStateToProps({ auth, shared }){
  const { meta } = auth
  const { countries } = shared
  
  const initialValues = {
    'name' : meta.user.displayName,
    'email' : meta.user.email,
    'tel' : meta.user.phoneNumber || ''
  }
  
  return { initialValues, countries }
}

WizardFormFirstPage = reduxForm({
  form: 'completeClientWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(WizardFormFirstPage)

WizardFormFirstPage = connect(
  mapStateToProps,
  { getCountriesList }
)(WizardFormFirstPage)

export default WizardFormFirstPage