import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, initialize } from 'redux-form'
import { withRouter } from 'react-router-dom'

import Input from 'components/atoms/inputs/generic_input'
import RoundedButton from 'components/atoms/buttons/solid'
import iconPromotor from 'components/atoms/images/icono_promotor.svg'

import { updateArtistPromoter, getArtistPromoter } from 'actions/artists'
import validate from './validate'
import * as routes from 'constants/routes'

class ArtistProfileEditPromoter extends Component {
    constructor(props){
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount(){
        if(this.props.promoter_id)
            this.props.getArtistPromoter(this.props.promoter_id, () => {
                this.props.initialize(this.props.initialValues)
            })
    }

    onSubmit(values){
        const change = {
            user_id : this.props.user_id,
            promoter : values.promoter_email
        }
        this.props.updateArtistPromoter(change, () => {
            this.props.history.push(routes.ARTIST_PROFILE)
        })
    }

    render(){
        const { handleSubmit } = this.props

        return (
        <>
            <form onSubmit={handleSubmit(this.onSubmit)} className='p-2'>

                <h6 className='my-3'>Proporciona el correo de quién te refirió en The Muzigzag®. De lo contrario omite esta información.</h6>
                <Field
                    name="promoter_email"
                    type='email'
                    placeholder="Correo Electrónico"
                    icon={iconPromotor}
                    component={Input}
                />

                <div className="py-2"/>
                
                <div className='d-flex justify-content-center'>
                    <RoundedButton type="submit" text='Guardar'/>
                </div>
            </form>
        </>
        )
    }
}

function mapStateToProps({ auth, artist }){
  const { user } = auth
  const user_id = auth.meta.user.uid
  const promoter_id = user.promoter || ''
  const promoter_email = artist.promoter.email
  
  const initialValues = {
    'promoter_email' : promoter_email
  }
  
  return { initialValues, user_id, promoter_id }
}

ArtistProfileEditPromoter = reduxForm({
  form: 'ArtistProfileEditPromoter',
  validate,
})(ArtistProfileEditPromoter)

ArtistProfileEditPromoter = connect(
  mapStateToProps,
  { updateArtistPromoter, getArtistPromoter, initialize }
)(ArtistProfileEditPromoter)

export default withRouter(ArtistProfileEditPromoter)