import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/firestore'
import { ENVIRONMENTS } from 'constants/index'

let config = {}
if(process.env.REACT_APP_STAGE === ENVIRONMENTS.QAS)
  config = {
    apiKey: process.env.REACT_APP_QAS_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_QAS_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_QAS_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_QAS_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_QAS_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_QAS_FIREBASE_MESSAGING_SENDER_ID
  }

 else if(process.env.REACT_APP_STAGE === ENVIRONMENTS.PRD)
  config = {
    apiKey: process.env.REACT_APP_PRD_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PRD_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PRD_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_PRD_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PRD_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PRD_FIREBASE_MESSAGING_SENDER_ID
  }

if (!firebase.apps.length){
  firebase.initializeApp(config)
}

const auth = firebase.auth()
const database = firebase.database()
const storage = firebase.storage()
let firestore = firebase.firestore()
const firestoreFieldValue = firebase.firestore.FieldValue
const FacebookAuthProvider = new firebase.auth.FacebookAuthProvider()

export {
  auth,
  database,
  storage,
  firestore,
  firestoreFieldValue,
  FacebookAuthProvider
}
