import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'

import { fetchMessages, sendChatMessage, confirmRequest, cancelRequest } from 'actions/user'
import * as routes from 'constants/routes'
import { MIN_MESSAGES_TO_ACTIVATE_CHAT_OPTION, SERVICES } from 'constants/index'
import { StyledMessageList, StyledForm, ConfirmDiv, IconImage, CancelDiv } from './styled'
import { Navbar } from 'react-chat-elements'
import { Button } from 'reactstrap'
import ChatInput from 'components/atoms/inputs/chat_message_input'
import IconSend from 'components/atoms/images/icono_enviar.svg'
import ContinueModal from 'components/organisms/modal_generic_continue'
import ModalGenericAccept from 'components/organisms/modal_generic_accept'

class Chat extends Component{
    constructor(props){
        super(props)
        this.state = {
            modalSuccesfullClientPayment: false,
            first_time: true,
            modalCancelService: false
        }
        this.onAcceptCancelService = this.onAcceptCancelService.bind(this)
        this.onConfirmRequest = this.onConfirmRequest.bind(this)
        this.renderRightOption = this.renderRightOption.bind(this)
        this.isOptionAvailable = this.isOptionAvailable.bind(this)
        this.validateMessage = this.validateMessage.bind(this)
        this.toogleSuccesfullClientPayment = this.toogleSuccesfullClientPayment.bind(this)
        this.isThereAnyMessageFromClient = this.isThereAnyMessageFromClient.bind(this)
        this.renderSuccesfullPaymentClientModal = this.renderSuccesfullPaymentClientModal.bind(this)
        this.renderGenericAcceptModal = this.renderGenericAcceptModal.bind(this)
        this.toogleCancelServiceModal = this.toogleCancelServiceModal.bind(this)
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        if( _.isEmpty(this.props.my_current_service) )
            this.props.history.push(routes.CLIENT_MY_SERVICES)
        else
            this.props.fetchMessages(this.props.my_current_service.id, this.props.current_user_id)
    }

    onSubmit(reset, values){
        const message = values['chat-input']
        
        let fields = Object.assign({}, {
                request_id: this.props.my_current_service.id,
                created_at: new Date().getTime(),
                message,
                type: 0,
                user_id: this.props.current_user_id
            }
        )
        this.props.sendChatMessage(fields)
        reset('input-chat-form')
    }

    isOptionAvailable(){
        let client_counter= 0, artist_counter = 0
        const messages = this.props.messages

        for(let i=0; i<messages.length; i++){
            if(messages[i].type === 'text' && messages[i].position === 'left'){
                artist_counter++
                if (client_counter >= MIN_MESSAGES_TO_ACTIVATE_CHAT_OPTION && artist_counter >= MIN_MESSAGES_TO_ACTIVATE_CHAT_OPTION) break
            }
            if(messages[i].type === 'text' && messages[i].position === 'right'){
                client_counter++
                if (client_counter >= MIN_MESSAGES_TO_ACTIVATE_CHAT_OPTION && artist_counter >= MIN_MESSAGES_TO_ACTIVATE_CHAT_OPTION) break
            }
        }

        return (client_counter >= MIN_MESSAGES_TO_ACTIVATE_CHAT_OPTION && artist_counter >= MIN_MESSAGES_TO_ACTIVATE_CHAT_OPTION) ? true : false

    }

    onConfirmRequest(){
        this.props.confirmRequest( this.props.my_current_service, () => {
            this.props.history.push(routes.CLIENT_MY_SERVICES)
        })
    }

    onAcceptCancelService(){
        let request_id = this.props.my_current_service.id
        let request = Object.assign(
            {}, 
            this.props.my_current_service,
            {   status: 0,
                updated_at : new Date().getTime(),
                origin: navigator.appVersion
            }
        )
        delete request.accepted
        delete request.end_hour
        delete request.image
        delete request.start_hour
        delete request.id
        
        this.props.cancelRequest(request, request_id, () => {
            this.toogleCancelServiceModal()
            this.props.history.push(routes.CLIENT_MY_SERVICES)
        })
    }

    validateMessage(message){
        if(this.props.my_current_service.type === SERVICES.TYPE.REQUEST){
            const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
            //const telRegex = /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/gi
            const telRegex = /[-.0-9]+/
            
            if (emailRegex.test(message))
                return 'No puedes enviar correos en este chat.'
            if (telRegex.test(message))
                return 'No puedes enviar números en este chat.'
        }
    }

    isThereAnyMessageFromClient(){
        let client_counter = 0
        const messages = this.props.messages

        for(let i=0; i<messages.length; i++){
            if(messages[i].type === 'text' && messages[i].position === 'right'){
                client_counter++
            }
        }

        return client_counter > 0
    }

    toogleSuccesfullClientPayment(){
        this.setState({
            modalSuccesfullClientPayment: !this.state.modalSuccesfullClientPayment
        })

        if(this.state.first_time)
            this.setState({
                first_time: !this.state.first_time
            })
    }

    toogleCancelServiceModal() {
        this.setState({
            modalCancelService: !this.state.modalCancelService
        })
    }

    renderSuccesfullPaymentClientModal(){
        return(
            <ContinueModal
                isOpen={this.state.modalSuccesfullClientPayment}
                toogle={this.toogleSuccesfullClientPayment}
                onClickAcceptButton={this.toogleSuccesfullClientPayment}
                modalHeaderText='¡Felicidades!'
                modalBodyText={`Te invitamos a platicar con el artista para que finalices la contratación. No olvides presionar el botón “CONTRATAR”.`}
                acceptButtonText='OK'
                acceptButtonColor='primary'
            />
        )
    }

    renderRightOption(){
        if (this.props.my_current_service.type === 'request' 
            && this.props.my_current_service.accepted)
            if(this.props.my_current_service.cash)
                return (
                    <ConfirmDiv>
                        <span>PAGA EN TIENDA</span>
                    </ConfirmDiv>
                )
            else
                return (
                    <>
                        <CancelDiv onClick={this.toogleCancelServiceModal}>
                            <span>CANCELAR</span>
                        </CancelDiv>
                        {
                            this.isOptionAvailable() ? 
                                <ConfirmDiv 
                                    onClick={this.onConfirmRequest}
                                    className='ml-4'>
                                    <span>CONTRATAR</span>
                                </ConfirmDiv>
                            : ''
                        }
                    </>
                )
    }

    renderGenericAcceptModal(){
        return (
            <ModalGenericAccept
                isOpen={this.state.modalCancelService}
                toogle={this.toogleCancelServiceModal}
                onClickAcceptButton={this.onAcceptCancelService}
                modalHeaderText='Cancelar'
                modalBodyText='¿Quieres cancelar el servicio?'
                acceptButtonColor='danger'
                acceptButtonText='Si'
                cancelButtonColor='secondary'
                cancelButtonText='No'
            />
        )
    }

    render(){
        const { handleSubmit, reset } = this.props

        if(!_.isEmpty(this.props.messages) && !this.isThereAnyMessageFromClient() && this.state.first_time)
            this.toogleSuccesfullClientPayment()
  
        return(
            <>
                {this.renderSuccesfullPaymentClientModal()}
                {this.renderGenericAcceptModal()}
                <Navbar
                    left={
                        <div>
                            <span>{this.props.receiver}</span>
                        </div>
                    }
                    right={this.renderRightOption()}
                />
                <StyledMessageList
                    className=''
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={this.props.messages}
                />
                <StyledForm 
                    onSubmit={handleSubmit(this.onSubmit.bind(this, reset))} 
                    className='d-flex justify-content-between align-items-center bg-white'>
                        <Field
                            name='chat-input'
                            type='text'
                            rows={1}
                            className=''
                            component={ChatInput}
                            validate={this.validateMessage}
                        >
                        </Field>
                        <Button color='link'><IconImage src={IconSend} alt=''/></Button>
                </StyledForm>
            </>
        )
    }
}

function mapStateToProps({ user, auth }){
    const { my_current_service } = user
    const messages = user.my_current_service_messages
    const chat = user.my_current_chat_info
    const current_user_id = auth.meta.user.uid
    const receiver = current_user_id === chat.artist_id ? chat.client_name : chat.artist_name
    
    return { my_current_service, current_user_id, messages, chat, receiver }
}

export default reduxForm({
    form:'input-chat-form',
    enableReinitialize: true
    })(withRouter(
        connect(
            mapStateToProps, { fetchMessages, sendChatMessage, confirmRequest, cancelRequest } 
        )(Chat)
    )
)