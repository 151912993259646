import styled from 'styled-components'

export const ArtistImg = styled.img`
    border: white solid 2px;
    border-radius: 50%;
    height: 8rem;
    object-fit: cover;
    width: 8rem;
`

export const Divisor = styled.span`
    height: 80%;
    width: 0.1rem;
`

export const ImageForm = styled.form`
    cursor: pointer;
    transform: translate(2rem,-2rem);
`

export const CostInfo = styled.span`
    cursor: pointer;
    font-size: 1.2rem;
`