import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

import validate from './validate'
import { GOOGLE_PLACES_API } from 'constants/index'
import Input from 'components/atoms/inputs/generic_input'
import OptionSelect from 'components/atoms/inputs/generic_option_select'
import NextButton from 'components/atoms/buttons/solid'
import { BackButton } from '../styled'
import iconPlace from 'components/atoms/images/icono_direccion.svg'
import iconCountry from 'components/atoms/images/icono_pais_registro.svg'

import { getCountriesList } from 'actions/shared'

class WizardFormSecondPage extends Component {
  constructor(props){
      super(props)
      this.state = {
          search: '',
          value: '',
      }
      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleSelectSuggest = this.handleSelectSuggest.bind(this)
  }

  handleInputChange(e) {
    this.setState({search: e.target.value, value: e.target.value})
  }

  handleSelectSuggest(suggest) {
    this.setState({search: "", value: suggest.formatted_address})
    this.props.change('address',this.state.value)
  }

  componentDidMount(){
    this.props.getCountriesList()
  }

  render(){
    const { handleSubmit, previousPage } = this.props
    const { search } = this.state

    return (
      <>
        <h2 className='text-primary mt-4'>Dirección</h2>
        <p>Por favor define tu dirección y selecciona tu país de residencia.</p>

        <form onSubmit={handleSubmit} className='p-2'>
          <ReactGoogleMapLoader
            params={{
              key: GOOGLE_PLACES_API,
              libraries: "places,geocode",
            }}
            render={googleMaps =>
              googleMaps && (
                <div>
                  <ReactGooglePlacesSuggest
                    autocompletionRequest={{input: search}}
                    googleMaps={googleMaps}
                    onSelectSuggest={this.handleSelectSuggest}
                  >
                    <Field
                      name="address"
                      type='text'
                      placeholder="Dirección*"
                      autocomplete='address'
                      icon={iconPlace}
                      component={Input}
                      onChange={this.handleInputChange}
                    />
                  </ReactGooglePlacesSuggest>
                </div>
              )
            }
          />
          
          <Field
            name="country"
            type='select'
            placeholder="País*"
            icon={iconCountry}
            options={this.props.countries}
            component={OptionSelect}
          />

          <div className="py-1"/>
          <p className='text-gray'>*Campos obligatorios</p>
          
          <div className='d-flex flex-md-row flex-column justify-content-center mt-5'>
            <BackButton 
              className='mx-md-2 text-white' 
              type="button" 
              onClick={previousPage} 
              color='gray-muzig'>Anterior</BackButton>
            <div className='d-block d-md-none w-100 my-2'></div>
            <NextButton 
              className='mx-md-2' 
              type="submit" 
              text='Siguiente'/>
          </div>
        </form>
      </>
    )
  }
}

function mapStateToProps({ shared }){
  const { countries } = shared
  return { countries }
}

WizardFormSecondPage.propTypes = {
  googleMaps: PropTypes.object,
}

export default connect(
  mapStateToProps,
  { change, getCountriesList }
)(
  reduxForm({
    form: 'signupArtistWizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(WizardFormSecondPage)
)