import map from 'lodash/map'
import React, { Component } from 'react'
import  { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchUserFavorites, unLikeService } from 'actions/user'
import { EmptyImg, FavoriteImg, PointerDiv } from './styled'
import IconNoFavorites from 'components/atoms/images/vacios/sin_favoritos.svg'
import IconHeart from 'components/atoms/images/icono_corazon_lleno.svg'
import * as routes from 'constants/routes'

class UserHistoryList extends Component{
    constructor(props){
        super(props)
        this.renderFavoritesList = this.renderFavoritesList.bind(this)
        this.renderEmptyFavorites = this.renderEmptyFavorites.bind(this)
        this.onUnLikeService = this.onUnLikeService.bind(this)
        this.onClickService = this.onClickService.bind(this)
    }

    componentDidMount(){
        this.props.fetchUserFavorites(this.props.user_id)
    }

    onUnLikeService(service_id){
        this.props.unLikeService(this.props.user_id, service_id)
    }

    onClickService(service){
        const URL_TOKENS = { ':id':service.id, ':slug':service.slug }
        const formated_route = routes.SHOW_SERVICE.replace(/:id|:slug/gi, matched => URL_TOKENS[matched])
        this.props.history.push(formated_route)
    }

    renderFavoritesList(){
        return (
            map(this.props.favorites, service => {
                return (
                    <div className='row no-gutters py-4' key={service.id}>
                        <div 
                            className='col-9'
                            onClick={() => this.onClickService(service)}>
                            <h5>{service.service_name}</h5>
                            <span className='text-gray-muzig'>{service.artist_name}</span>
                        </div>
                        <PointerDiv 
                            className='col-3 d-flex align-items-center justify-content-center'
                            onClick={() => this.onUnLikeService(service.id)}>
                            <FavoriteImg src={IconHeart} alt=''/>
                        </PointerDiv>
                    </div>
                )
            })
        )
    }

    renderEmptyFavorites(){
        return (
            <div className='text-center mt-5'>
                <EmptyImg src={IconNoFavorites} alt='' />
                <h2 className='text-gray-muzig my-4'>Sin favoritos</h2>
            </div>
        )
    }

    render(){
        return(
            <>
                {
                    this.props.favorites.length > 0 ? 
                        this.renderFavoritesList()
                        :
                        this.renderEmptyFavorites()
                }
            </>
        )
    }
}

function mapStateToProps({ auth, user }){
    const user_id = auth.meta.user.uid
    const { favorites } = user
    return { user_id, favorites }
}

export default connect(
    mapStateToProps,
    { fetchUserFavorites, unLikeService }
)(withRouter(UserHistoryList))