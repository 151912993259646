import React from 'react'
import Media from "react-media"

import { HomeBanner } from './styled'

export default () => {
    return(
        <>
            <Media query={{ minWidth: 768, maxWidth: 991 }}>
                {matches => matches ? <HomeBanner size='md'/> : ''}
            </Media>
            <Media query={{ minWidth: 992, maxWidth: 1199 }}>
                {matches => matches ? <HomeBanner size='lg'/> : ''}
            </Media>
            <Media query={{ minWidth: 1200 }}>
                {matches => matches ? <HomeBanner size='xl'/> : ''}
            </Media>
        </>
    )
}