import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { reset } from 'redux-form'

import WizardFormFirstPage from './wizardFormFirstPage'
import { CLIENTS, SERVER_CODES } from 'constants/index'
import * as routes from 'constants/routes'
import { registerNewClient, validateEmail } from 'actions/authentication'
import { showSuccessMessage } from 'actions/shared'

/*Lo dejo en Wizard por si después se extiende el formulario de registro para cliente.*/

class WizardForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1
    }
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.callRegisterNewClient = this.callRegisterNewClient.bind(this)
  }
  
  nextPage() {
    this.setState({ page: this.state.page + 1 })
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 })
  }

  onSubmit(values) {
    this.props.validateEmail(values.email, response_code => {
      switch(response_code){
        case SERVER_CODES.VALIDATE_EMAIL.AVAILABLE:
          this.callRegisterNewClient(values)
          break
        case SERVER_CODES.VALIDATE_EMAIL.LIBERATED:
          this.callRegisterNewClient(values)
          break
        case SERVER_CODES.VALIDATE_EMAIL.PROVIDER_PASSWORD:
          this.props.showSuccessMessage('Este correo ya tiene una cuenta asociada. Favor de hacer login con este correo y su contraseña correspondiente.')
          break
        case SERVER_CODES.VALIDATE_EMAIL.PROVIDER_FACEBOOK:
          this.props.showSuccessMessage('Este correo ya tiene una cuenta asociada. Favor de hacer login con la cuenta de Facebook de este correo.')
          break
        case SERVER_CODES.VALIDATE_EMAIL.PROVIDER_UNKNOWN:
          this.props.showSuccessMessage('Este correo ya tiene una cuenta asociada.')
          break
        case SERVER_CODES.VALIDATE_EMAIL.ADMIN:
          this.props.showSuccessMessage('No puedes utilizar este correo.')
          break
        default: break

      }
    })
    

  }

  callRegisterNewClient(values){
    let fields = {}
    fields['country'] = values.country
    fields['email'] = values.email
    fields['last_name'] = values.lastName
    fields['name'] = values.name
    fields['password'] = values.password
    fields['phone'] = values.tel
    fields['rfc'] = values.rfc || null
    fields['status'] = CLIENTS.STATUS.ACTIVE
    fields['user_type'] = CLIENTS.TYPE_ID
    
    this.props.registerNewClient(fields, () => {
      this.props.reset('signupClientWizard')
      this.props.history.push(routes.ROOT)
    })
  }

  render() {
    const { page } = this.state

    return (
      <div className='py-0 px-5'>
        {page === 1 && (
          <WizardFormFirstPage onSubmit={this.onSubmit.bind(this)} />
        )}
      </div>
    )
  }
}

export default connect(
  null, 
  { registerNewClient, validateEmail, showSuccessMessage, reset }
)(withRouter(WizardForm))