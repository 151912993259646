import { 
    USER
} from 'constants/index';

const INITIAL_STATE = { 
            requested_services : [],
            booked_services : [],
            my_current_service : {},
            my_current_service_messages : [],
            my_current_chat_info: {},
            history : [],
            favorites : [],
            saved: [],
            my_pending_rates: []
        }

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case USER.FETCH_MY_REQUESTED_SERVICES:
            return { ...state, requested_services: action.payload }

        case USER.FETCH_MY_BOOKED_SERVICES:
            return { ...state, booked_services: action.payload }

        case USER.SELECT_MY_SERVICE:
            return { ...state, my_current_service: action.payload }

        case USER.FETCH_MY_CHAT:
            return { 
                ...state, 
                my_current_service_messages: action.payload.messages,
                my_current_chat_info: action.payload.chat_info 
            }
        
        case USER.FETCH_HISTORY:
            return { ...state, history: action.payload }

        case USER.FETCH_FAVORITES:
            return { ...state, favorites: action.payload }

        case USER.FETCH_SAVED:
            return { ...state, saved: action.payload }

        case USER.FETCH_PENDING_RATES:
            return { ...state, my_pending_rates: action.payload }
        
        case USER.CLEAN_CURRENT_DATA:
            state = INITIAL_STATE
            return { ...state }
        default:
            return { ...state }
    }
}
