import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import validate from './validate'
import Input from 'components/atoms/inputs/generic_input'
import OptionSelect from 'components/atoms/inputs/generic_option_select'
import RoundedButton from 'components/atoms/buttons/solid'
import iconName from 'components/atoms/images/icono_nombre_apellido.svg'
import iconEmail from 'components/atoms/images/icono_correo.svg'
import iconPassword from 'components/atoms/images/icono_contrasenia.svg'
import iconTel from 'components/atoms/images/icono_telefono_formulario.svg'
import iconCountry from 'components/atoms/images/icono_pais_registro.svg'
import iconRFC from 'components/atoms/images/icono_rfc.svg'

import { getCountriesList } from 'actions/shared'

class WizardFormFirstPage extends Component {
  componentDidMount(){
    this.props.getCountriesList()
  }

  render(){
    const { handleSubmit } = this.props

    return (
      <>
        <h2 className='text-info mt-4'>Bienvenido a Muzigzag</h2>
        <p>Por favor ingresa tus datos personales.</p>
        <form onSubmit={handleSubmit} className='p-2 ml-auto mr-auto w-100'>
          <div className={"d-flex flex-row align-items-center justify-content-between"}>
            <Field
                name="name"
                type="text"
                placeholder="Nombre*"
                autocomplete='name'
                icon={iconName}
                component={Input}
            />
            <Field
                name="lastName"
                type="text"
                placeholder="Apellidos*"
                autocomplete='last-name'
                icon={iconName}
                component={Input}
            />
          </div>
          
          <Field
            name="email"
            type="email"
            placeholder="Correo electrónico*"
            autocomplete='email'
            icon={iconEmail}
            component={Input}
          />

          <Field
            name="rfc"
            type="text"
            placeholder="RFC/ Número Fiscal o Tributario"
            autocomplete='artist-rfc'
            icon={iconRFC}
            component={Input}
          />
          <div className={"d-flex flex-row align-items-center justify-content-between"}>
            <Field
                name="password"
                type="password"
                placeholder="Contraseña*"
                autocomplete='password'
                icon={iconPassword}
                component={Input}
            />
            <Field
                name="confirmPassword"
                type="password"
                placeholder="Confirmar contraseña*"
                autocomplete='confirm-password'
                icon={iconPassword}
                component={Input}
            />
          </div>

          <Field
            name="tel"
            type='number'
            placeholder="Teléfono principal*"
            autocomplete='main-tel'
            icon={iconTel}
            component={Input}
          />

          <Field
            name="country"
            type='select'
            placeholder="País*"
            icon={iconCountry}
            options={this.props.countries}
            component={OptionSelect}
          />

          <div className="py-1"/>
          <p style={{fontSize: "12px"}} className='text-gray'>*Campos obligatorios</p>
          <p style={{fontSize: "12px"}} className='text-gray'>Al registrarte y crear una cuenta confirmas que estás de acuerdo con nuestros Términos y Condiciones y nuestro Aviso de Privacidad.</p>
          <div className="py-3"/>
          
          <div className='d-flex justify-content-center mt-5'>
            <RoundedButton type="submit" text='Registrar'/>
          </div>
        </form>
      </>
    )
  }
}

function mapStateToProps({ shared }){
  const { countries } = shared
  
  return { countries }
}

WizardFormFirstPage = reduxForm({
  form: 'signupClientWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(WizardFormFirstPage)

WizardFormFirstPage = connect(
  mapStateToProps,
  { getCountriesList }
)(WizardFormFirstPage)

export default WizardFormFirstPage