import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { reset } from 'redux-form'

import WizardFormFirstPage from './wizardFormFirstPage'
import WizardFormSecondPage from './wizardFormSecondPage'
import WizardFormThirdPage from './wizardFormThirdPage'
import WizardFormFourthPage from './wizardFormFourthPage'
import WizardFormFifthPage from './wizardFormFifthPage'

import step_one from 'components/atoms/images/linea_1.svg'
import step_two from 'components/atoms/images/linea_2.svg'
import step_three from 'components/atoms/images/linea_3.svg'
import step_four from 'components/atoms/images/linea_4.svg'
import step_five from 'components/atoms/images/linea_5.svg'

import { ARTISTS, SERVER_CODES } from 'constants/index'
import * as routes from 'constants/routes'
import { registerNewArtist, validateEmail } from 'actions/authentication'
import { showSuccessMessage } from 'actions/shared'

function getArtistTypeId(artist_name) {
  switch(artist_name){
    case ARTISTS.TYPES.MUSICAL.name:
      return ARTISTS.TYPES.MUSICAL.id

    case ARTISTS.TYPES.PERFORMANCE.name:
      return ARTISTS.TYPES.PERFORMANCE.id

    case ARTISTS.TYPES.PRODUCTION.name:
      return ARTISTS.TYPES.PRODUCTION.id

    default:
      return ARTISTS.TYPES.MUSICAL.id
  }
}

function getZonesPresence(values) {
  let zones = {}
  for (const country of values['countries-presence']) {
    zones[country.value] = []
    
    for (const zone of values[`presence-for-${country.value}`]) {
      zones[country.value].push(zone.value)
    }
  }
  return zones
}

class WizardForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1
    }
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    
  }
  
  nextPage(values) {
    if(this.state.page === 1){
      this.props.validateEmail(values.email, response_code => {
        switch(response_code){
          case SERVER_CODES.VALIDATE_EMAIL.AVAILABLE:
            this.setState({ page: this.state.page + 1 })
            break
          case SERVER_CODES.VALIDATE_EMAIL.LIBERATED:
            this.setState({ page: this.state.page + 1 })
            break
          case SERVER_CODES.VALIDATE_EMAIL.PROVIDER_PASSWORD:
            this.props.showSuccessMessage('Este correo ya tiene una cuenta asociada. Favor de hacer login con este correo y su contraseña correspondiente.')
            break
          case SERVER_CODES.VALIDATE_EMAIL.PROVIDER_FACEBOOK:
            this.props.showSuccessMessage('Este correo ya tiene una cuenta asociada. Favor de hacer login con la cuenta de Facebook de este correo.')
            break
          case SERVER_CODES.VALIDATE_EMAIL.PROVIDER_UNKNOWN:
            this.props.showSuccessMessage('Este correo ya tiene una cuenta asociada.')
            break
          case SERVER_CODES.VALIDATE_EMAIL.ADMIN:
            this.props.showSuccessMessage('No puedes utilizar este correo.')
            break
          default: break
  
        }
      })
    }
    else
      this.setState({ page: this.state.page + 1 })
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 })
  }

  onSubmit(values) {
    let fields = {}
    fields['address'] = values.address
    fields['artist_name'] = values.artistName
    fields['artist_type'] = getArtistTypeId(values.artistType)
    fields['card_id_file'] = values.idCard
    fields['country'] = values.country
    fields['zones'] = getZonesPresence(values)
    fields['email'] = values.email
    fields['last_name'] = values.lastName
    fields['name'] = values.name
    fields['password'] = values.password
    fields['paypal_email'] = values.paypal_email
    fields['phone'] = values.tel
    fields['phone2'] = values.telSecondary || null
    fields['promoter'] = values.promoter_email || null
    fields['rfc'] = values.rfc || null
    fields['status'] = ARTISTS.STATUS.ACTIVE
    fields['user_type'] = ARTISTS.TYPE_ID
    fields['verified'] = false
    
    this.props.registerNewArtist(fields, () => {
      this.props.reset('signupArtistWizard')
      this.props.history.push(routes.ARTIST_PROFILE)
    })

  }

  render() {
    const { page } = this.state
    let step_svg = step_one

    switch(page){
      case 1:
        step_svg = step_one
      break
      case 2:
        step_svg = step_two
      break
      case 3:
        step_svg = step_three
      break
      case 4:
        step_svg = step_four
      break
      case 5:
        step_svg = step_five
      break
      default:
      break
    }

    return (
      <div className='p-4'>
        <div className='d-flex justify-content-center my-2 my-md-4'>
          <img src={step_svg} alt={`Paso ${step_svg}`}/>
        </div>
        {page === 1 && <WizardFormFirstPage onSubmit={this.nextPage} />}
        {page === 2 && (
          <WizardFormSecondPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 3 && (
          <WizardFormThirdPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 4 && (
          <WizardFormFourthPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 5 && (
          <WizardFormFifthPage
            previousPage={this.previousPage}
            onSubmit={this.onSubmit.bind(this)}
          />
        )}
      </div>
    )
  }
}


export default connect(
  null, 
  { registerNewArtist, validateEmail, showSuccessMessage, reset }
)(withRouter(WizardForm))