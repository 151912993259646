import React, { Component } from 'react'
import { connect } from 'react-redux'

import { StyledSwitch } from './styled'
import { switchArtistAvailability } from 'actions/artists'

class ArtistAvailabilitySwitch extends Component{
    constructor(props){
        super(props)
        this.state = {
            availability : false
        }
        this.onSwitchChange = this.onSwitchChange.bind(this)
        this.toogleSwitch = this.toogleSwitch.bind(this)
    }

    onSwitchChange(){
        this.props.switchArtistAvailability(this.props.user_id, !this.props.active)
    }

    toogleSwitch(){
        this.setState({
            availability : !this.state.availability
        })
    }

    render(){
        return(
            <div className='d-flex justify-content-around align-items-center py-4 bg-gray-background'>
                <span className='text-primary'>Disponibilidad</span>
                <StyledSwitch
                    onClick={() => this.onSwitchChange()}
                    checked={this.props.active}
                    disabled={this.props.disabled}
                />
            </div>
        )
    }
}

function mapStateToProps({ auth }){
    const { user: { status } } = auth
    const user_id = auth.meta.user.uid
    let disabled = false
    let active = true

    switch(status){
        case 0:
            active = true
            break
        case 1:
            active = false
            disabled = true
            break
        default:
            active = false
            break
    }

    return { disabled, active, user_id }
}

export default connect(
    mapStateToProps,
    { switchArtistAvailability }
)(ArtistAvailabilitySwitch)