import React, { Component } from 'react'
import { Route,  } from 'react-router-dom'
import PrivateRoute from 'components/hocs/private_router'

import * as routes from 'constants/routes'

import NoAccess from 'components/ecosystems/statics/no_access'
import NoPayment from 'components/ecosystems/statics/no_payment'
import Login from 'components/ecosystems/login'
import ChooseRegistrationType from 'components/ecosystems/signup/choose_registration_type'
import IncompleteTypeDecision from 'components/ecosystems/signup/incomplete_type_decision'
import IncompleteTypeClient from 'components/ecosystems/signup/incomplete_type_client'
import IncompleteTypeArtist from 'components/ecosystems/signup/incomplete_type_artist'
import SignupTypeArtist from 'components/ecosystems/signup/type_artist'
import SignupTypeClient from 'components/ecosystems/signup/type_client'
import Home from 'components/ecosystems/home'
import SearchResults from 'components/ecosystems/search_results'
import CategoryList from 'components/ecosystems/category_list'
import CategoriesGrid from 'components/ecosystems/categories_grid'
import ShowService from 'components/ecosystems/show_service'
import BookingFilter from 'components/ecosystems/booking/filter'
import BookingConfirm from 'components/ecosystems/booking/confirm'
import BookingSelectCard from 'components/ecosystems/booking/cards_settings'
import ServiceAddHours from 'components/ecosystems/add_hours'
import ServiceAddHoursSelectCard from 'components/ecosystems/add_hours/card_settings'

import ClientMyServices from 'components/ecosystems/client/my_services'
import ClientShowMyService from 'components/ecosystems/client/show_my_service'
import ClientMyServiceChat from 'components/ecosystems/client/chat'
import ClientProfile from 'components/ecosystems/client/profile'
import ClientProfileEdit from 'components/ecosystems/client/profile/edit'
import ClientPaymentMethods from 'components/ecosystems/client/profile/payment_methods'
import ClientPaymentMethodsAdd from 'components/ecosystems/client/profile/payment_methods_add'
import ClientHistory from 'components/ecosystems/client/profile/history'
import ClientFavorites from 'components/ecosystems/client/profile/favorites'
import ClientSaved from 'components/ecosystems/client/profile/saved'
import ClientConvertToArtist from 'components/ecosystems/client/convert_to_artist'

import ArtistMyServices from 'components/ecosystems/artist/my_services'
import ArtistShowMyService from 'components/ecosystems/artist/show_my_service'
import ArtistMyServiceChat from 'components/ecosystems/artist/chat'
import ArtistProfile from 'components/ecosystems/artist/profile'
import ArtistProfileEdit from 'components/ecosystems/artist/profile/edit'
import ArtistPaymentMethods from 'components/ecosystems/artist/profile/payment_methods'
import ArtistPaymentMethodsAdd from 'components/ecosystems/artist/profile/payment_methods_add'
import ArtistHistory from 'components/ecosystems/artist/profile/history'
import ArtistFavorites from 'components/ecosystems/artist/profile/favorites'
import ArtistSaved from 'components/ecosystems/artist/profile/saved'
import ArtistPromoter from 'components/ecosystems/artist/profile/promoter'
import ArtistRevenue from 'components/ecosystems/artist/profile/revenue'

import ArtistJobServices from 'components/ecosystems/artist/profile/my_services'
import ArtistNewJobService from 'components/ecosystems/artist/profile/create_service'
import ArtistNewJobServiceName from 'components/ecosystems/artist/profile/create_service/name'
import ArtistNewJobServiceDescription from 'components/ecosystems/artist/profile/create_service/description'
import ArtistNewJobServiceCost from 'components/ecosystems/artist/profile/create_service/cost'
import ArtistNewJobServiceZones from 'components/ecosystems/artist/profile/create_service/zones'
import ArtistNewJobServiceAvailability from 'components/ecosystems/artist/profile/create_service/availability'
import ArtistNewJobServicePhotos from 'components/ecosystems/artist/profile/create_service/photos'
import ArtistNewJobServiceVideo from 'components/ecosystems/artist/profile/create_service/video'
import ArtistNewJobServiceTypes from 'components/ecosystems/artist/profile/create_service/types'
import ArtistNewJobServiceIncludes from 'components/ecosystems/artist/profile/create_service/includes'
import ArtistNewJobServiceCategory from 'components/ecosystems/artist/profile/create_service/category'

import ArtistEditJobService from 'components/ecosystems/artist/profile/edit_service'
import ArtistEditJobServiceName from 'components/ecosystems/artist/profile/edit_service/name'
import ArtistEditJobServiceDescription from 'components/ecosystems/artist/profile/edit_service/description'
import ArtistEditJobServiceCost from 'components/ecosystems/artist/profile/edit_service/cost'
import ArtistEditJobServiceZones from 'components/ecosystems/artist/profile/edit_service/zones'
import ArtistEditJobServiceAvailability from 'components/ecosystems/artist/profile/edit_service/availability'
import ArtistEditJobServicePhotos from 'components/ecosystems/artist/profile/edit_service/photos'
import ArtistEditJobServiceVideo from 'components/ecosystems/artist/profile/edit_service/video'
import ArtistEditJobServiceTypes from 'components/ecosystems/artist/profile/edit_service/types'
import ArtistEditJobServiceIncludes from 'components/ecosystems/artist/profile/edit_service/includes'
import ArtistEditJobServiceCategory from 'components/ecosystems/artist/profile/edit_service/category'
import ArtistPayments from 'components/ecosystems/artist/profile/my_payments'
import ArtistWeeklyPayments from 'components/ecosystems/artist/profile/my_payment_weekly_detail'

import CancellationPolicies from 'components/ecosystems/statics/cancellation'
import PrivacyPolicies from 'components/ecosystems/statics/privacy'

class MainEcosystem extends Component{
    render(){
        return(
            <>
                <Route
                    exact path={routes.LOGIN}
                    component={() => <Login />}
                />

                <Route
                    exact path={routes.NO_ACCESS}
                    component={() => <NoAccess />}
                />

                <Route
                    exact path={routes.NO_PAYMENT}
                    component={() => <NoPayment />}
                />

                <Route
                    exact path={routes.CHOOSE_REGISTRATION_TYPE}
                    component={() => <ChooseRegistrationType />}
                />

                <Route
                    exact path={routes.INCOMPLETE_USER_RECORD}
                    component={() => <IncompleteTypeDecision />}
                />

                <Route
                    exact path={routes.INCOMPLETE_USER_RECORD_AS_CLIENT}
                    component={() => <IncompleteTypeClient />}
                />

                <Route
                    exact path={routes.INCOMPLETE_USER_RECORD_AS_ARTIST}
                    component={() => <IncompleteTypeArtist />}
                />

                <Route
                    exact path={routes.USER_SIGNUP_AS_ARTIST}
                    component={() => <SignupTypeArtist />}
                />

                <Route
                    exact path={routes.USER_SIGNUP_AS_CLIENT}
                    component={() => <SignupTypeClient />}
                />

                <Route
                    exact path={routes.ROOT}
                    component={() => <Home/>}
                />

                <Route
                    exact path={routes.SEARCH_RESULTS}
                    component={() => <SearchResults/>}
                />

                <Route
                    exact path={routes.CATEGORY_LIST}
                    component={() => <CategoryList/>}
                />

                <Route
                    exact path={routes.CATEGORIES_GRID}
                    component={() => <CategoriesGrid/>}
                />

                <Route
                    exact path={routes.SHOW_SERVICE}
                    component={() => <ShowService/>}
                />

                <Route
                    exact path={routes.CANCELLATION_POLICIES}
                    component={() => <CancellationPolicies/>}
                />

                <Route
                    exact path={routes.PRIVACY_POLICIES}
                    component={() => <PrivacyPolicies/>}
                />
                
                <PrivateRoute 
                    exact path={routes.BOOKING_FILTER}
                    roles={{ 'artist':true, 'client':true }}>
                    <BookingFilter />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CONFIRM_BOOKING_REQUEST}
                    roles={{ 'artist':true, 'client':true }}>
                    <BookingConfirm />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.BOOKING_CARDS_SETTINGS}
                    roles={{ 'client':true, 'artist':true }}>
                    <BookingSelectCard />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ADD_HOURS_TO_SERVICE}
                    roles={{ 'client':true, 'artist':true }}>
                    <ServiceAddHours />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ADD_HOURS_CARDS_SETTINGS}
                    roles={{ 'client':true, 'artist':true }}>
                    <ServiceAddHoursSelectCard />
                </PrivateRoute>

                {/* CLIENT------------------------------- */}

                <PrivateRoute 
                    exact path={routes.CLIENT_MY_SERVICES}
                    roles={{ 'client':true }}>
                    <ClientMyServices />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_MY_SERVICE_DETAIL}
                    roles={{ 'client':true }}>
                    <ClientShowMyService />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_MY_SERVICE_CHAT}
                    roles={{ 'client':true }}>
                    <ClientMyServiceChat />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_PROFILE}
                    roles={{ 'client':true }}>
                    <ClientProfile />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_PROFILE_EDIT}
                    roles={{ 'client':true }}>
                    <ClientProfileEdit />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_PROFILE_PAYMENT_METHODS}
                    roles={{ 'client':true }}>
                    <ClientPaymentMethods />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_PROFILE_PAYMENT_METHODS_ADD}
                    roles={{ 'client':true }}>
                    <ClientPaymentMethodsAdd />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_PROFILE_HISTORY}
                    roles={{ 'client':true }}>
                    <ClientHistory />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_PROFILE_FAVORITES}
                    roles={{ 'client':true }}>
                    <ClientFavorites />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_PROFILE_SAVED}
                    roles={{ 'client':true }}>
                    <ClientSaved />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.CLIENT_CONVERT_TO_ARTIST}
                    roles={{ 'client':true }}>
                    <ClientConvertToArtist />
                </PrivateRoute>

                {/* ARTIST------------------------------- */}

                <PrivateRoute 
                    exact path={routes.ARTIST_MY_SERVICES}
                    roles={{ 'artist':true }}>
                    <ArtistMyServices />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_MY_SERVICE_DETAIL}
                    roles={{ 'artist':true }}>
                    <ArtistShowMyService />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_MY_SERVICE_CHAT}
                    roles={{ 'artist':true }}>
                    <ArtistMyServiceChat />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_PROFILE}
                    roles={{ 'artist':true }}>
                    <ArtistProfile />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_PROFILE_EDIT}
                    roles={{ 'artist':true }}>
                    <ArtistProfileEdit />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_PROFILE_PAYMENT_METHODS}
                    roles={{ 'artist':true }}>
                    <ArtistPaymentMethods />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_PROFILE_PAYMENT_METHODS_ADD}
                    roles={{ 'artist':true }}>
                    <ArtistPaymentMethodsAdd />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_PROFILE_HISTORY}
                    roles={{ 'artist':true }}>
                    <ArtistHistory />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_PROFILE_FAVORITES}
                    roles={{ 'artist':true }}>
                    <ArtistFavorites />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_PROFILE_SAVED}
                    roles={{ 'artist':true }}>
                    <ArtistSaved />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_PROMOTER}
                    roles={{ 'artist':true }}>
                    <ArtistPromoter />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_REVENUE}
                    roles={{ 'artist':true }}>
                    <ArtistRevenue />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES}
                    roles={{ 'artist':true }}>
                    <ArtistJobServices />
                </PrivateRoute>

                {/*------------------------CREACIÓN DE SERVICIO---------------------- */}

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobService />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_NAME}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServiceName />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_DESCRIPTION}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServiceDescription />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_COST}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServiceCost />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_ZONES}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServiceZones />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_AVAILABILITY}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServiceAvailability />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_PHOTOS}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServicePhotos />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_VIDEO}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServiceVideo />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_TYPES}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServiceTypes />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_INCLUDES}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServiceIncludes />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_NEW_CATEGORY}
                    roles={{ 'artist':true }}>
                    <ArtistNewJobServiceCategory />
                </PrivateRoute>

                {/*----------------------EDICIÓN DE SERVICIO-----------------------*/}

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobService />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_NAME}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServiceName />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_DESCRIPTION}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServiceDescription />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_COST}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServiceCost />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_ZONES}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServiceZones />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_AVAILABILITY}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServiceAvailability />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_PHOTOS}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServicePhotos />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_VIDEO}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServiceVideo />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_TYPES}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServiceTypes />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_INCLUDES}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServiceIncludes />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_JOB_SERVICES_EDIT_CATEGORY}
                    roles={{ 'artist':true }}>
                    <ArtistEditJobServiceCategory />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_MY_PAYMENTS}
                    roles={{ 'artist':true }}>
                    <ArtistPayments />
                </PrivateRoute>

                <PrivateRoute 
                    exact path={routes.ARTIST_MY_WEEK_HISTORY}
                    roles={{ 'artist':true }}>
                    <ArtistWeeklyPayments />
                </PrivateRoute>

            </>
        )
    }
}

export default MainEcosystem
