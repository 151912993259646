import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'

import { 
    completeNewServiceStep, 
    selectNewServiceStep, 
    uncheckNewServiceStep,
     } from 'actions/shared'
import { NEW_SERVICE_VIEWS, HOURS_FOR_MULTIPLE, WEEKDAYS_FOR_MULTIPLE, ARTISTS } from 'constants/index'
import MultipleSelect from 'components/atoms/inputs/multiple_select'
import Button from 'components/atoms/buttons/solid'

class NewServiceAvailability extends Component{
    constructor(props){
        super(props)
        this.state = { selectWeekdaysValue: this.props.initialWeekdays }
        this.updateValue = this.updateValue.bind(this)
        this.renderFieldsForHours = this.renderFieldsForHours.bind(this)
        this.minLength1 = this.minLength1.bind(this)
    }

    onSubmit(values){
        console.log(values)
        this.props.completeNewServiceStep(NEW_SERVICE_VIEWS.availability)
        this.props.selectNewServiceStep(NEW_SERVICE_VIEWS.photos)
    }
    
    minLength1(values = []){
        this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.availability)
        return values.length < 1 ? 'Tienes que elegir al menos una opción' : undefined
    }

    updateValue(newValue) {
        let difference = this.state.selectWeekdaysValue.filter(x => !newValue.includes(x))
        this.setState({ selectWeekdaysValue: newValue })
        if (difference.length !== 0)
            this.props.change(`hours-for-${difference[0].value}`, [])
    }

    renderFieldsForHours(){
        if (this.props.initialWeekdays.length > 0){
            
            return (
                _.map(this.props.initialWeekdays, weekday => {
                    return(
                        <div className='my-2' key={weekday.value}>
                            <p>Horarios disponibles para {weekday.label}</p>
                            <Field
                                name={`hours-for-${weekday.value}`}
                                type='select'
                                selectType={ARTISTS.SELECT_TYPE_WEEKDAYS_HOURS}
                                placeholder={`Selecciona al menos una hora para ${weekday.label}`}
                                options={HOURS_FOR_MULTIPLE}
                                validate={[this.minLength1]}
                                component={MultipleSelect}
                            />
                        </div>
                    )
                })
            )
        }
        else{
            console.log('No hay zonas')
        }
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>Disponibilidad</h2>
                <p>Agrega y edita fácilmente la disponibilidad de tu servicio.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="weekdays"
                        type='select'
                        selectType={ARTISTS.SELECT_TYPE_WEEKDAYS}
                        placeholder='Selecciona al menos un día de la semana'
                        options={WEEKDAYS_FOR_MULTIPLE}
                        component={MultipleSelect}
                        validate={[this.minLength1]}
                        updateValue={this.updateValue}
                    />

                    <div className='w-100 my-3'></div>
                    {this.renderFieldsForHours()}
            
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps({ form }){
    let initialWeekdays = []
    if(form.createNewServiceWizard.values)
        if(typeof form.createNewServiceWizard.values['weekdays'] !== 'undefined')
            initialWeekdays = form.createNewServiceWizard.values['weekdays']


    return { initialWeekdays }
}

NewServiceAvailability = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewServiceAvailability)
  
  NewServiceAvailability = connect(
    mapStateToProps,
    { 
        completeNewServiceStep, 
        selectNewServiceStep, 
        uncheckNewServiceStep, 
        change }
  )(NewServiceAvailability)
  
  export default NewServiceAvailability