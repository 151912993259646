import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'

import { 
    completeEditServiceStep, 
    selectEditServiceStep, 
    uncheckEditServiceStep,
     } from 'actions/shared'
import { updateService, fetchJobService } from 'actions/artists'
import { 
    EDIT_SERVICE_VIEWS, 
    HOURS_FOR_MULTIPLE, 
    WEEKDAYS_FOR_MULTIPLE, 
    ARTISTS,
    WEEKDAYS_LABELS_IN_SPANISH,
    HOURS_LABELS } from 'constants/index'
import MultipleSelect from 'components/atoms/inputs/multiple_select'
import Button from 'components/atoms/buttons/solid'
import { getHoursAvailability, containsObject } from 'helpers/index'

class EditServiceAvailability extends Component{
    constructor(props){
        super(props)
        this.state = { selectWeekdaysValue: this.props.initialWeekdays }
        this.updateValue = this.updateValue.bind(this)
        this.renderFieldsForHours = this.renderFieldsForHours.bind(this)
        this.minLength1 = this.minLength1.bind(this)
    }

    onSubmit(values){
        const change = { availability : getHoursAvailability(values) }
        this.props.updateService(change, this.props.job_service_selected.id, () => {
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.availability)
            const service_id = window.location.href.split('/').pop()
            this.props.fetchJobService(service_id)
        })
    }
    
    minLength1(values = []){
        if(values.length < 1){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.availability)
            return 'Tienes que elegir al menos una opción'
        }
        else
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.availability)
    }

    updateValue(newValue) {
        let difference = []
        this.props.initialWeekdays.forEach(weekday => {
            if(!containsObject(weekday, newValue))
                difference.push(weekday)
        })

        this.setState({ selectWeekdaysValue: newValue })

        if (difference.length !== 0)
            this.props.change(`hours-for-${difference[0].value}`, [])
    }

    renderFieldsForHours(){
        if (this.props.initialWeekdays.length > 0){
            
            return (
                _.map(this.props.initialWeekdays, weekday => {
                    return(
                        <div className='my-2' key={weekday.value}>
                            <p>Horarios disponibles para {weekday.label}</p>
                            <Field
                                name={`hours-for-${weekday.value}`}
                                type='select'
                                selectType={ARTISTS.SELECT_TYPE_WEEKDAYS_HOURS}
                                placeholder={`Selecciona al menos una hora para ${weekday.label}`}
                                options={HOURS_FOR_MULTIPLE}
                                validate={[this.minLength1]}
                                component={MultipleSelect}
                            />
                        </div>
                    )
                })
            )
        }
        else{
            console.log('No hay dias de la semana iniciales')
        }
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>Disponibilidad</h2>
                <p>Agrega y edita fácilmente la disponibilidad de tu servicio.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="weekdays"
                        type='select'
                        selectType={ARTISTS.SELECT_TYPE_WEEKDAYS}
                        placeholder='Selecciona al menos un día de la semana'
                        options={WEEKDAYS_FOR_MULTIPLE}
                        component={MultipleSelect}
                        validate={[this.minLength1]}
                        updateValue={this.updateValue}
                    />

                    <div className='w-100 my-3'></div>
                    {this.renderFieldsForHours()}
            
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps({ form, artist }){
    let initialWeekdays = []
    if(form.editServiceWizard && form.editServiceWizard.values)
        if(typeof form.editServiceWizard.values['weekdays'] !== 'undefined')
            initialWeekdays = form.editServiceWizard.values['weekdays']

    const { job_service_selected } = artist

    /*VALORES INICIALES*/
    let keys = []
    let weekdaysArrayOptions = []
    let hoursByEachDay = {}

    if (job_service_selected.availability){
        keys = Object.keys(job_service_selected.availability)
        weekdaysArrayOptions = _.map(keys, key => {
            hoursByEachDay[`hours-for-${key}`] = _.map(job_service_selected.availability[key], (value, hour) => {
                return { value: hour, label: HOURS_LABELS[hour] }
            })
            return { value: key, label: WEEKDAYS_LABELS_IN_SPANISH[key] }
        })
    }

    const initialValues = {
        'weekdays' : weekdaysArrayOptions,
    }

    Object.assign(initialValues, hoursByEachDay)

    return { initialWeekdays, job_service_selected, initialValues }
}

EditServiceAvailability = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true
  })(EditServiceAvailability)
  
EditServiceAvailability = connect(
    mapStateToProps,
    { 
        completeEditServiceStep, 
        selectEditServiceStep, 
        uncheckEditServiceStep, 
        change,
        updateService,
        fetchJobService
    }
  )(EditServiceAvailability)
  
  export default EditServiceAvailability