import React from 'react'
import ReactDom from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import reducers from 'reducers'
import { loadState, persistStore } from './localStorage'
import AppEnvironment from 'components/environment/App'
import './global.css'

const persistedState = loadState();
let initialLocalState = persistedState ? { auth: persistedState.auth } : {}
if(initialLocalState.auth)
    initialLocalState.auth.loading = false

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore)
const store = createStoreWithMiddleware(
  reducers,
  initialLocalState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
store.subscribe(() => { persistStore(store) })

ReactDom.render(
    <Provider store={store}>
        <BrowserRouter>
            <AppEnvironment/>
        </BrowserRouter>
    </Provider>
    , 
    document.getElementById('root')
)
