import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Logo from 'components/atoms/images/logo_a_color.png'
import Facebook from 'components/atoms/images/footer_facebook.svg'
import Instagram from 'components/atoms/images/footer_instagram.svg'
import { PointerP, BottomFooter, LogoImage, CopyrightP, SocialIcon } from './styled'
import { URLS } from 'constants/index'

class Footer extends Component{
    constructor(props){
        super(props)
        this.onCancellationClick = this.onCancellationClick.bind(this)
        this.onPrivacyClick = this.onPrivacyClick.bind(this)
        this.onInstagramClick = this.onInstagramClick.bind(this)
        this.onFacebookClick = this.onFacebookClick.bind(this)
    }

    onCancellationClick(){
        window.open(URLS.CONDITIONS, '_blank')
    }

    onPrivacyClick(){
        window.open(URLS.PRIVACY, '_blank')
    }

    onInstagramClick(){
        window.open(URLS.INSTAGRAM, '_blank')
    }

    onFacebookClick(){
        window.open(URLS.FACEBOOK, '_blank')
    }

    render(){
        return(
            <div className='d-block pt-5'>
                <BottomFooter className='w-100 d-flex flex-column flex-sm-row justify-content-sm-between py-4 px-5'>
                    <div className='d-flex align-items-center justify-content-center justify-content-sm-start order-2 order-sm-1 mt-5 my-sm-0'>
                        <div>   
                            <LogoImage src={Logo}/>
                            <CopyrightP className='text-light my-2 text-center text-sm-left'>Copyright {new Date().getFullYear()} ©</CopyrightP>
                        </div>
                    </div>
                    <div className='d-flex flex-column order-1 order-sm-2'>
                        <div className='d-flex justify-content-center justify-content-sm-start'>
                            <div className='text-center text-sm-left'>
                                <p className='text-white m-0'>Soporte:</p>
                                <p className='text-white m-0'>55 53 37 79 44</p>
                                <p className='text-white m-0'>soporte@themuzigzag.com</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-4 justify-content-sm-start'>
                            <div>
                                <SocialIcon onClick={() => this.onFacebookClick()} src={Facebook} className='mr-3'/>
                                <SocialIcon onClick={() => this.onInstagramClick()} src={Instagram} className='mr-3'/>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-4 justify-content-sm-start'>
                            <div className='text-center text-sm-left'>
                                <PointerP onClick={() => this.onCancellationClick()} className='text-white m-0'>Términos y Condiciones</PointerP>
                                <PointerP onClick={() => this.onPrivacyClick()} className='text-white m-0'>Aviso de Privacidad</PointerP>
                            </div>
                        </div>
                    </div>
                </BottomFooter>
            </div>
        )
    }
}

export default withRouter(Footer)