import _ from 'lodash'

const validate = values => {

    const errors = {}
    if (!values.name)
      errors.name = 'Ingresa tu nombre'
    
    if (!values.lastName)
      errors.lastName = 'Ingresa tus apellidos'
    
    if (!values.artistName)
      errors.artistName = 'Ingresa tu nombre artístico'

    if (!values.email)
      errors.email = 'Ingresa tu correo electrónico'
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Ingresa un correo electrónico válido'

    if (!values.password)
      errors.password = 'Necesitas definir una contraseña'
    else if (values.password.length < 6)
      errors.password = 'La contraseña debe tener un mínimo de 6 caracteres'
    
    if (!values.confirmPassword)
      errors.confirmPassword = 'Confirma tu contraseña'
    else if (values.password !== values.confirmPassword)
      errors.confirmPassword = 'Las contraseñas no coinciden'
    
    if (!values.tel)
      errors.tel = 'Ingresa tu número telefónico'
    else{
      const telRegex = /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{8}))/gi
      if(!telRegex.test(values.tel))
        errors.tel = 'Debes de ingresar un número telefónico válido'
    }

    if (!values.address)
      errors.address = 'Ingresa la dirección principal donde recibirás los servicios'
    
    if (!values.country)
      errors.country = 'Selecciona tu país de residencia'

    if(!values.idCard || _.isEmpty(values.idCard) )
      errors.idCard = 'Debes seleccionar un archivo para tu identificación oficial.'
    else if(values.idCard.file.size > 10485760)
      errors.idCard = 'El archivo no puede pasar de los 10 MB.'

    if (!values.clabe)
      errors.clabe = 'Ingresa tu CLABE interbancaria para recibir pagos.'
    else if (values.clabe.length !== 18)
      errors.clabe = 'La CLABE debe ser de 18 dígitos.'

    if(!values.artistType)
      errors.artistType = 'Debes seleccionar un tipo de artista.'

    return errors
  }
  
  export default validate