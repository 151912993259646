import React, { Component } from 'react'
import ArtistProfileEditForm from 'components/organisms/artist_profile_edit_form'

class EditProfile extends Component{
    render(){
        return (
            <>
                <h4 className='text-gray-muzig'>Información personal</h4>
                <ArtistProfileEditForm/>
            </>
        )
    }
}

export default EditProfile