import _ from 'lodash'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TabContent, TabPane, Nav, NavItem } from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'

import { MOMENT_IN_SPANISH, DEFAULT_PROFILE_IMAGE_URL, DEFAULT_PDF_PROFILE_IMG } from 'constants/index'
import { fetchRequestedServices, fetchBookedServices, selectMyService, deleteNewServices } from 'actions/user'
import { ServiceDiv, UserImage, IconImage, EmptyImg, PointerNavLink, MyServiceDiv } from './styled'
import IconDate from 'components/atoms/images/icono_dia.svg'
import IconHour from 'components/atoms/images/icono_hora.svg'
import IconAddress from 'components/atoms/images/icono_lugar.svg'
import IconNoRequests from 'components/atoms/images/vacios/sin_solicitudes.svg'
import IconNoReservations from 'components/atoms/images/vacios/sin_agendados.svg'
import Badge from 'components/atoms/noti_badge'

import * as routes from 'constants/routes'

class TabList extends React.Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.renderServices = this.renderServices.bind(this)
        this.onServiceClick = this.onServiceClick.bind(this)
        this.renderEmptyServices = this.renderEmptyServices.bind(this)
        this.renderRequestsBadge = this.renderRequestsBadge.bind(this)
        this.renderReservationsBadge = this.renderReservationsBadge.bind(this)
        this.deleteNewServices = this.deleteNewServices.bind(this)
        this.state = {
            activeTab: '1'
        }
    }

    deleteNewServices(tab){
        if(typeof(this.props.request_map) !== 'undefined' && tab === '1')
            this.props.deleteNewServices(this.props.user_info.uid, 'requests')
        if(typeof(this.props.reservation_map) !== 'undefined' && tab === '2')
            this.props.deleteNewServices(this.props.user_info.uid, 'reservations')
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
        this.deleteNewServices(tab)
    }

    componentDidMount(){
        this.props.fetchRequestedServices(this.props.user_info.uid, 'artist')
        this.props.fetchBookedServices(this.props.user_info.uid, 'artist')
        this.deleteNewServices(this.state.activeTab)
    }

    onServiceClick(service){
        this.props.selectMyService(service)
        if(service.accepted)
            this.props.history.push(routes.ARTIST_MY_SERVICE_CHAT.replace(':id',service.id))
        else
            this.props.history.push(routes.ARTIST_MY_SERVICE_DETAIL.replace(':id',service.id))
    }

    renderServices(services){
        moment.updateLocale('es', MOMENT_IN_SPANISH)
        return(
            _.map(services, service => {
                return (
                    <ServiceDiv 
                        key={service.id} 
                        className='row my-4 shadow-sm' 
                        onClick={() => this.onServiceClick(service)}
                    >
                        <div className='col-12 col-sm-3'>
                            {this.props.user_info.uid === service.client_id ? 
                                <UserImage src={
                                    service.artist_image && service.artist_image !== DEFAULT_PDF_PROFILE_IMG ?
                                        service.artist_image
                                        :
                                        DEFAULT_PROFILE_IMAGE_URL
                                } alt=''/>
                            :
                                <UserImage src={
                                    service.client_image && service.client_image !== DEFAULT_PDF_PROFILE_IMG ?
                                        service.client_image
                                        :
                                        DEFAULT_PROFILE_IMAGE_URL
                                } alt=''/>
                            }
                        </div>
                        <div className='col-11 col-sm-9 d-flex'>
                            <div className='w-100'>
                                <h4>{service.service_name}</h4>
                                <h6 className='text-gray-muzig'>{service.artist_name}</h6>
                                <div className='row py-2'>
                                    <div className='col-12 col-sm-6 d-flex align-items-center my-1'>
                                        <IconImage src={IconDate}/>
                                        <span className='mx-2'>{moment(service.when).format('dddd, DD MMM YYYY')}</span>
                                    </div>
                                    <div className='col-12 col-sm-6 d-flex align-items-center my-1'>
                                        <IconImage src={IconHour}/>
                                        <span className='mx-2'>{moment(service.when).format('HH:mm')}</span>
                                    </div>
                                    <div className='col-12 col-sm-6 d-flex align-items-center my-1'>
                                        <IconImage src={IconAddress}/>
                                        <span className='mx-2'>{service.where}</span>
                                    </div>
                                </div>
                            </div>
                            {this.props.user_info.uid === service.client_id &&
                                <MyServiceDiv className='flex-shrink-1 bg-success'/>
                            }
                        </div>
                    </ServiceDiv>
                )
            })
        )
    }

    renderEmptyServices(type){
        if(type === 'requests')
            return (
                <div className='text-center mt-5'>
                    <EmptyImg src={IconNoRequests} alt='' />
                    <h2 className='text-gray-muzig my-4'>Sin solicitudes</h2>
                </div>
            )
        else if(type === 'reservations')
                return (
                    <div className='text-center mt-5'>
                        <EmptyImg src={IconNoReservations} alt='' />
                        <h2 className='text-gray-muzig my-4'>Sin contrataciones</h2>
                    </div>
                )
    }

    renderRequestsBadge(){
        if(typeof(this.props.request_map) !== 'undefined')
            return <Badge class="bg-danger"/>
    }

    renderReservationsBadge(){
        if(typeof(this.props.reservation_map) !== 'undefined')
            return <Badge class="bg-danger"/>
    }

    render() {
        return(
        <div>
            <Nav tabs className='row border-0'>
                <NavItem className='col-6 p-0 text-center'>
                    <PointerNavLink
                    className={classnames(
                        { active: this.state.activeTab === '1' }, 
                        { 'bg-secondary': this.state.activeTab === '1' }, 
                        { 'text-white': this.state.activeTab === '1' }
                    )}
                    onClick={() => { this.toggle('1'); }}
                    >
                    Solicitudes {this.renderRequestsBadge()}
                    </PointerNavLink>
                </NavItem>
                <NavItem className='col-6 p-0 text-center'>
                    <PointerNavLink
                    className={classnames(
                        { active: this.state.activeTab === '2' }, 
                        { 'bg-secondary': this.state.activeTab === '2' }, 
                        { 'text-white': this.state.activeTab === '2' }
                    )}
                    onClick={() => { this.toggle('2'); }}
                    >
                    Contratados {this.renderReservationsBadge()}
                    </PointerNavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab} className='p-4'>
                <TabPane tabId="1">
                    {
                        this.props.requested_services.length === 0 ? 
                            this.renderEmptyServices('requests')
                            :
                            this.renderServices(this.props.requested_services)
                    }
                </TabPane>
                <TabPane tabId="2">
                    {
                        this.props.booked_services.length === 0 ?
                            this.renderEmptyServices('reservations')
                            :
                            this.renderServices(this.props.booked_services)
                    }
                </TabPane>
            </TabContent>
        </div>
        )
    }
}

function mapStateToProps({ auth, user }){
    const user_info = auth.meta.user
    const { requested_services, booked_services } = user
    const request_map = auth.user.request_map
    const reservation_map = auth.user.reservation_map
    
    return {  user_info, requested_services, booked_services, request_map, reservation_map }
}

export default withRouter(
    connect(
        mapStateToProps,
        { fetchRequestedServices, fetchBookedServices, selectMyService, deleteNewServices }
    )(TabList)
)