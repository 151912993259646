import _ from 'lodash'
import { BOOKINGS, LOCAL_WEEKDAYS } from 'constants/index'
import moment from 'moment'
import { getFormattedHour } from 'helpers'

const INITIAL_STATE = { 
            current_service_bookings: [],
            hours_options: [],
            blockedHoursByDay: {},
            hours_duration: [],
            weekDay: {},
            date_selected_from_calendar: '',
            filter_options: {},
            extra_duration : 0,
            payment_option : 'paypal'//se inicializa con paypal por default
        }

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case BOOKINGS.FETCH_BOOKINGS_OF_SERVICE:
            return { ...state, current_service_bookings: action.payload }

        case BOOKINGS.GENERATE_HOURS_FOR_DAY:
            //Primero seleccionamos las horas del día según día de la semana en availability
            //action.payload es el date (moment object) seleccionado en calendario
            //action.availability son los horarios de disponibilidad del servicio (array)
            const moment_date_selected = action.payload
            const date_selected_from_calendar = moment_date_selected.format('DD/MM/YYYY')
            const weekday = moment_date_selected.format('dddd').toLowerCase()
            console.log('weekday', LOCAL_WEEKDAYS[weekday])
            let available_hours_for_weekday = action.availability[LOCAL_WEEKDAYS[weekday]]
            console.log('available_hours_for_weekday', available_hours_for_weekday)

            Object.assign( state, { available_hours_for_weekday })
            Object.assign( state, { date_selected_from_calendar })

            let hours_options_for_select = _.map(state.available_hours_for_weekday, (value, hour) => {
                return { id: hour, name: getFormattedHour(hour) }
            })
            
            //Si el día seleccionado es hoy, quitamos de las opciones las horas que ya pasaron
            //considerando las horas previas mínimas para reservación
            if(moment(moment_date_selected).isSame(moment(), 'day')){
                const string_current_hour = moment().format('HH')
                const zero_minutes = '00'
                const current_hour = Number(`${string_current_hour}${zero_minutes}`)
                hours_options_for_select = _.filter(hours_options_for_select, hour_option => {
                    const minimum_hours_in_advance = Number(hour_option.id) - BOOKINGS.MINIMUM_HOURS_PREVIOUS_RESERVATION * 100
                    return current_hour <= minimum_hours_in_advance
                })
            }
            
            //Después le restamos las opciones de las horas ya reservadas, en caso de existir.
            //Si la fecha seleccionada se encuentra en el objeto de fechas reservadas:
            if(state.date_selected_from_calendar in state.blockedHoursByDay){

                let hoursToDelete = []
                let availableHoursWithoutBooked = {}
                //por cada opcion de hora disponible
                hours_options_for_select.forEach(availableHour => {
                    //y por cada hora ya reservada
                    state.blockedHoursByDay[state.date_selected_from_calendar].forEach(blockedHour => {
                        //las comparamos, si no son iguales, 
                        //la hora disponible seguirá siendo disponible
                        if(availableHour.id !== blockedHour)
                            availableHoursWithoutBooked[availableHour.id] =  true
                        else
                            //de lo contrario existe booking para esta hora, por lo que 
                            //hay que registrar N horas antes de ese booking para borrarlas
                            //se empieza en cero para que la hora reservada se borre también
                            for(let i = 0; i <= BOOKINGS.DELETE_PREVIOUS_HOURS_FROM_BOOKING; i++){
                                const auxHour = Number(availableHour.id)-(100*i)
                                if (auxHour > 0)
                                    hoursToDelete.push(auxHour.toString())
                            }
                    })
                })

                //Si se tienen que quitar horas(que se debería al menos una), 
                //las eliminamos del obj y asignamos opciones de select a hours_options_for_select
                if(hoursToDelete.length > 0){
                    hoursToDelete.forEach(hour => {
                        delete availableHoursWithoutBooked[hour]
                    })

                    hours_options_for_select = _.map(availableHoursWithoutBooked, (values, hour) => {
                        return { id: hour, name: getFormattedHour(hour) }
                    })
                }
            }
            else
                console.log('No hay reservaciones para esa fecha')

            //ordenamos horas de 000 a 23000
            hours_options_for_select = _.orderBy(hours_options_for_select, ['name'], ['asc'])

            return { ...state, hours_options: hours_options_for_select }

        
        case BOOKINGS.PUSH_TO_BLOCKED_HOURS:
            let obj = {}
            for (const booking of action.payload) {
                const date = moment(booking.when).format('DD/MM/YYYY')
                const hour = moment(booking.when).format('HH:mm')
                const hour_number = Number(hour.replace(':', ''))
                const duration = booking.hours + BOOKINGS.DELETE_NEXT_HOURS_FROM_BOOKING

                if(!obj[date])
                    obj[date] = []

                //Agregamos las horas que durará el servicio para bloquearlas en availability
                for(let i = 0; i < duration; i++){
                    const aux_number = hour_number + 100 * i
                    obj[date].push(aux_number.toString())
                }
            }
            
            return { ...state, blockedHoursByDay: obj }

        case BOOKINGS.PUSH_TO_BLOCKED_EXTRA_HOURS:
            let obj_extra = {}
            for (const booking of action.payload) {
                const date = moment(booking.when).format('DD/MM/YYYY')
                const hour = moment(booking.when).format('HH:mm')
                const hour_number = Number(hour.replace(':', ''))
                const duration = booking.hours

                //Si es la primera vez que leemos esta fecha, la inicializamos como array en el obj_extra
                if(!obj_extra[date])
                    obj_extra[date] = []

                //Agregamos las horas que durará el servicio para bloquearlas en availability
                for(let i = 0; i < duration; i++){
                    const aux_number = hour_number + 100 * i
                    obj_extra[date].push(aux_number.toString())
                }
            }
            
            return { ...state, blockedHoursByDay: obj_extra }

        case BOOKINGS.SET_DURATION_LIMIT_AT:
            const hour_selected = action.payload.replace(':', '')
            const last_hour = action.last_hour
            let hoursDuration = []
            let index = Number(hour_selected)
            let count = 0
            //Si existen horas reservadas en ese día
            if(state.date_selected_from_calendar in state.blockedHoursByDay){
                //Mientras la hora seleccionada vaya incrementando y sea menor a la última hora disponible
                while (index <= last_hour) {
                    count ++
                    //Si la hora actual no se encuentra reservada
                    if (state.blockedHoursByDay[state.date_selected_from_calendar].indexOf(index.toString()) < 0 ){
                        //Agregamos una hora más en las opciones del select de la duración
                        hoursDuration.push({ id: count, name: count })
                        index += 100
                    }
                    //Si la hora actual se encuentra reservada
                    else {
                        //Sacamos la última hora ya que se necesita una hora de anticipación
                        hoursDuration.pop()
                        break
                    }
                }
            }
            else{
                while (index <= last_hour) {
                    count ++
                    hoursDuration.push({ id: count, name: count })
                    index += 100
                }
            }

            return { ...state, hours_duration : hoursDuration }

        case BOOKINGS.SAVE_FILTER_OPTIONS_FOR_BOOKING:
                return { ...state, filter_options: action.payload }
        
        case BOOKINGS.SELECT_EXTRA_HOURS_DURATION:
            return { ...state, extra_duration : action.payload }

        case BOOKINGS.SELECT_PAYMENT_OPTION:
            return { ...state, payment_option: action.payload }

        default:
            return { ...state }
    }
}
