import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { reset } from 'redux-form'

import WizardFormFirstPage from './wizardFormFirstPage'
import { CLIENTS } from 'constants/index'
import * as routes from 'constants/routes'
import { registerIncompleteClient } from 'actions/authentication'

/*Lo dejo en Wizard por si después se extiende el formulario de registro para cliente.*/

class WizardForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1
    }
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    
  }
  
  nextPage() {
    this.setState({ page: this.state.page + 1 })
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 })
  }

  onSubmit(uid, values) {
    let fields = {}
    fields['country'] = values.country
    fields['email'] = values.email
    fields['last_name'] = values.lastName
    fields['name'] = values.name
    fields['phone'] = values.tel
    fields['profile_image'] = this.props.meta.user.photoURL
    fields['rfc'] = values.rfc || null
    fields['status'] = CLIENTS.STATUS.ACTIVE
    fields['user_type'] = CLIENTS.TYPE_ID
    
    this.props.registerIncompleteClient(fields, uid, () => {
      this.props.reset('completeClientWizard')
      this.props.history.push(routes.ROOT)
    })

  }

  render() {
    const { page } = this.state

    return (
      <div className='p-4'>
        {page === 1 && (
          <WizardFormFirstPage onSubmit={this.onSubmit.bind(this, this.props.meta.user.uid)} />
        )}
      </div>
    )
  }
}

function mapStateToProps({ auth }){
  const { meta } = auth
  
  return { meta }
}

export default connect(
  mapStateToProps, 
  { registerIncompleteClient, reset }
)(withRouter(WizardForm))