import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import MultipleFileInput from 'components/atoms/inputs/generic_multiple_files_input'
import { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep } from 'actions/shared'
import { NEW_SERVICE_VIEWS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'

class NewServicePhotos extends Component{
    constructor(props){
        super(props)
        this.validatePhotos = this.validatePhotos.bind(this)
    }

    validatePhotos(photos){
        if(!photos){
            this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.photos)
            return 'Debes subir al menos una imagen.'
        }
        if(photos && photos.files){

            if(photos.files.length > 10){
                this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.photos)
                return 'No puedes subir más de 10 imágenes'
            }

            const filesToBig = _.filter(photos.files, photo => {
                return photo.size/1000000 > 10 
            })

            if(filesToBig.length > 0){
                this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.photos)
                if (filesToBig.length === 1)
                    return `${filesToBig.length} archivo es mayor a 10 MB.`
                else
                    return `${filesToBig.length} archivos son mayores a 10 MB.`
            }

            const allowedExtensions = /(\.svg|\.ssvg|\.jpg|\.jpeg|\.png)$/i
            const filesWrongExt = _.filter(photos.files, photo => {
                return !allowedExtensions.exec(photo.name)
            })
            if(filesWrongExt.length > 0){
                this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.photos)
                if (filesWrongExt.length === 1)
                    return `1 archivo tiene una extensión que no se permite subir. Sólo se permiten JPG, JPEG, PNG y SVG`
                else
                    return `${filesWrongExt.length} archivos tienen extensiones que no se pueden subir. Sólo se permiten JPG, JPEG, PNG y SVG`
            }
        }

    }

    onSubmit(values){
        this.props.completeNewServiceStep(NEW_SERVICE_VIEWS.photos)
        this.props.selectNewServiceStep(NEW_SERVICE_VIEWS.video)
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>Fotos</h2>
                <p>La primera foto será la de la portada, te recomendamos que selecciones alguna en landscape (horizontal) para mostrar mejor tu servicio. 
                    Puedes agregar un máximo de 10 fotos con un límite de 10 MB por cada una.
                    Se admiten los siguientes formatos: JPG, JPEG, PNG y SVG.
                </p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="photos"
                        type="file"
                        label="Agrega una foto"
                        maxphotos={10}
                        validate={this.validatePhotos}
                        component={MultipleFileInput}
                    />
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

NewServicePhotos = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewServicePhotos)
  
  NewServicePhotos = connect(
    null,
    { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep }
  )(NewServicePhotos)
  
  export default NewServicePhotos