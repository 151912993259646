import { 
    ALERTS,
    SHARED,
    PROFILE_VIEWS,
    NEW_SERVICE_VIEWS,
    EDIT_SERVICE_VIEWS
} from 'constants/index';

const INITIAL_STATE = { 
            pageLoading: false,
            alert: {
                show: false,
                type: 'light',
                message: ''
            },
            countries: [],
            address: '',
            zonesByCountry: {},
            eventTypes: [],
            sidemenuPage: PROFILE_VIEWS.edit,
            errorMessage: '',
            newServiceStep: NEW_SERVICE_VIEWS.name,
            newServiceStepCompleted: {},
            editServiceStep: EDIT_SERVICE_VIEWS.name,
            editServiceStepCompleted: {
                [EDIT_SERVICE_VIEWS.name] : true,
                [EDIT_SERVICE_VIEWS.description] : true,
                [EDIT_SERVICE_VIEWS.cost] : true,
                [EDIT_SERVICE_VIEWS.zones] : true,
                [EDIT_SERVICE_VIEWS.availability] : true,
                [EDIT_SERVICE_VIEWS.types] : true,
                [EDIT_SERVICE_VIEWS.includes] : true,
                [EDIT_SERVICE_VIEWS.category] : true,
                [EDIT_SERVICE_VIEWS.video] : true,
                [EDIT_SERVICE_VIEWS.photos] : true,
            },
            includes: [],
            subcategories: []
        }

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SHARED.PAGE_IS_LOADING:
            return { ...state, pageLoading: true }

        case SHARED.PAGE_STOP_LOADING:
            return { ...state, pageLoading: false }

        case ALERTS.CLOSE_ALERT:
            return { ...state, alert: {show: action.payload, type:'light', message: ''} }
            
        case ALERTS.SHOW_ERROR_MESSAGE:
            return { ...state, alert: {show: true, type:'danger', message: action.payload} }

        case ALERTS.SHOW_SUCCESS_MESSAGE:
            return { ...state, alert: {show: true, type:'success', message: action.payload} }

        case SHARED.FETCH_COUNTRIES_SUCCESS:
            return { ...state, countries: action.payload }

        case SHARED.FETCH_ZONES_OF_COUNTRY:
            const { country, zones } = action.payload
            const immutable = Object.assign({} , state.zonesByCountry, { [country] : zones })
            return { ...state, zonesByCountry: immutable }

        case SHARED.UNSELECT_ZONES_OF_LIST:
            let aux = Object.assign({}, state.zonesByCountry)
            delete aux[action.payload]
            return { ...state, zonesByCountry : aux }

        case SHARED.FETCH_EVENT_TYPES:
            return { ...state, eventTypes: action.payload }

        case SHARED.SELECT_SIDEMENU_OPTION:
            return { ...state, sidemenuPage: action.payload }

        case SHARED.ON_PAYMENT_SELECT_ERROR:
            return { ...state, errorMessage: action.payload }

        case SHARED.SELECT_NEW_SERVICE_STEP:
            return { ...state, newServiceStep: action.payload }

        case SHARED.COMPLETE_SERVICE_STEP:
            const immutableStep = Object.assign({} , state.newServiceStepCompleted, { [action.payload] : true })
            return { ...state, newServiceStepCompleted: immutableStep }

        case SHARED.INCOMPLETE_SERVICE_STEP:
            const immutableNoStep = Object.assign({} , state.newServiceStepCompleted, { [action.payload] : false })
            return { ...state, newServiceStepCompleted: immutableNoStep }

        case SHARED.SELECT_EDIT_SERVICE_STEP:
            return { ...state, editServiceStep: action.payload }

        case SHARED.COMPLETE_EDIT_SERVICE_STEP:
            const immutableEditStep = Object.assign({} , state.editServiceStepCompleted, { [action.payload] : true })
            return { ...state, editServiceStepCompleted: immutableEditStep }

        case SHARED.INCOMPLETE_EDIT_SERVICE_STEP:
            const immutableEditNoStep = Object.assign({} , state.editServiceStepCompleted, { [action.payload] : false })
            return { ...state, editServiceStepCompleted: immutableEditNoStep }

        case SHARED.FETCH_INCLUDES:
            return { ...state, includes: action.payload }

        case SHARED.FETCH_SUBCATEGORIES:
            return { ...state, subcategories: action.payload }

        default:
            return { ...state }
    }
}
