import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import { 
    completeEditServiceStep, 
    selectEditServiceStep, 
    uncheckEditServiceStep, 
    getSubcategoriesList 
} from 'actions/shared'
import { updateService, fetchJobService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS, ARTISTS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'
import { getNormalizeString } from 'helpers/index'
import { StyledDiv, LabelTextDiv } from './styled'

class EditServiceCategory extends Component{
    constructor(props){
        super(props)
        this.validateCategory = this.validateCategory.bind(this)
        this.renderSubcategoriesOptions = this.renderSubcategoriesOptions.bind(this)
    }

    componentDidMount(){
        this.props.getSubcategoriesList(this.props.artist_type)
    }

    validateCategory(category){
        if(!category){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.category)
            return 'Debes definir una categoría para tu servicio'
        }
        else
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.category)
    }

    onSubmit(values){
        const change = { subCategory : values.serviceSubcategory }
        this.props.updateService(change, this.props.job_service_selected.id, () => {
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.category)
            const service_id = window.location.href.split('/').pop()
            this.props.fetchJobService(service_id)
        })
    }

    renderSubcategoriesOptions(subcategories){
        return _.map(subcategories, subcategory => {
            return (
                <div className='m-4' key={subcategory.id}>
                    <div className=''>
                        <Field 
                            id={`${getNormalizeString(subcategory.name)}Type`} 
                            name="serviceSubcategory" 
                            component='input' 
                            type="radio" 
                            value={subcategory.id} 
                            validate={this.validateCategory}/>
                        <label 
                            htmlFor={`${getNormalizeString(subcategory.name)}Type`} 
                            className={`${getNormalizeString(subcategory.name)} bg-secondary p-2`}></label>
                    </div>
                    <LabelTextDiv>
                        <h4 className='text-primary'>{subcategory.name}</h4>
                    </LabelTextDiv>
                </div>
            )
        })
        
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>Categoría</h2>
                <p>Selecciona la categoría que mejor le quede a tu servicio. Recuerda que puedes cambiar la categoría principal (musical, performance, producción) desde tu perfil para que te muestre aquí las categorías que estás buscando.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <StyledDiv className='d-flex flex-wrap justify-content-center justify-content-lg-start'>
                        {this.renderSubcategoriesOptions(this.props.subcategories)}
                    </StyledDiv>
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps({ auth, shared, artist }){
    const artist_type_id = auth.user.artist_type
    const artist_type = ARTISTS.TYPES[artist_type_id]
    const { subcategories } = shared
    const { job_service_selected } = artist

    const initialValues = {
        'serviceSubcategory' : job_service_selected.subCategory,
    }

    return { artist_type, subcategories, job_service_selected, initialValues }
}

EditServiceCategory = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true
  })(EditServiceCategory)
  
EditServiceCategory = connect(
    mapStateToProps,
    { 
        completeEditServiceStep, 
        selectEditServiceStep, 
        uncheckEditServiceStep, 
        getSubcategoriesList,
        updateService,
        fetchJobService
    }
  )(EditServiceCategory)
  
  export default EditServiceCategory