import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { selectEditServiceStep } from 'actions/shared'
import { updateService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS } from 'constants/index'
import { PointerDiv, OptionIconImg } from './styled'

import IncompleteIcon from 'components/atoms/images/icono_incompleto.svg'
import CompleteIcon from 'components/atoms/images/icono_completo.svg'


class BigSidemenu extends Component{
    constructor(props){
        super(props)
        this.onStepSelected = this.onStepSelected.bind(this)
    }

    onStepSelected(page){
        this.props.selectEditServiceStep(page)
    }

    render(){
        return(
            <section className='d-flex flex-column text-gray-muzig'>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.name}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.name)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.name] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Título</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.description}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.description)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.description] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Descripción</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.cost}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.cost)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.cost] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Costo por hora</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.zones}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.zones)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.zones] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Zonas de servicio</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.availability}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.availability)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.availability] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Disponibilidad</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.photos}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.photos)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.photos] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Fotos</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.video}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.video)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.video] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Video</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.types}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.types)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.types] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Tipo de evento</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.includes}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.includes)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.includes] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>¿Qué incluye?</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.editServiceStep === EDIT_SERVICE_VIEWS.category}
                    onClick={() => this.onStepSelected(EDIT_SERVICE_VIEWS.category)}
                    className='d-flex py-4' 
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.category] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Categoría</div>
                </PointerDiv>
                
            </section>
        )
    }
}

function mapStateToProps({ shared, auth }){
    const { editServiceStep, editServiceStepCompleted } = shared
    const { meta: { user }} = auth
    const user_info = auth.user
    return { editServiceStep, editServiceStepCompleted, user, user_info }
}
  
BigSidemenu = connect(
    mapStateToProps,
    { selectEditServiceStep, updateService }
  )(withRouter(BigSidemenu))

export default BigSidemenu