import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import Banner from 'components/molecules/show_my_service_banner'
import ArtistCard from 'components/molecules/my_service_artist_card'
import ModalCancelService from 'components/organisms/modal_cancel_service'
import { fetchMyService, cancelRequest, cancelReservation } from 'actions/user'
import { IconImage, StyledButton } from './styled'
import IconLike from 'components/atoms/images/icono_like_lleno.svg'
import IconDate from 'components/atoms/images/icono_dia.svg'
import IconHour from 'components/atoms/images/icono_hora.svg'
import IconAddress from 'components/atoms/images/icono_lugar.svg'
import * as routes from 'constants/routes'
import { CURRENCY_FORMATTER, PAYPAL_CURRENCIES } from 'constants/index'
import { 
    getClientCancelationAmountToReturn, 
    getArtistCancelationAmountToCharge,
    getClientCancelationAmountToCharge, 
    toFixed, 
    currencyFormatter 
} from 'helpers'
import ModalGenericAccept from 'components/organisms/modal_generic_accept'


class ShowService extends Component{
    constructor(props){
        super(props)
        this.state = {
            modalCancelService: false
        }
        this.toogleCancelServiceModal = this.toogleCancelServiceModal.bind(this)
        this.onAcceptCancelService = this.onAcceptCancelService.bind(this)
        this.onViewChat = this.onViewChat.bind(this)
        this.getCancelationAmount = this.getCancelationAmount.bind(this)
        this.renderCancelationModal = this.renderCancelationModal.bind(this)
        this.onAddHoursClick = this.onAddHoursClick.bind(this)
        this.getCost = this.getCost.bind(this)
    }

    componentDidMount(){
        if ( _.isEmpty(this.props.my_current_service) )
            this.props.history.push(routes.CLIENT_MY_SERVICES)
        else {
            window.scrollTo(0, 0)
            this.props.fetchMyService(this.props.my_current_service)
        }
    }

    toogleCancelServiceModal() {
        this.setState({
            modalCancelService: !this.state.modalCancelService
        })
    }

    onViewChat(service){
        this.props.history.push(routes.CLIENT_MY_SERVICE_CHAT.replace(':id',service.id))
    }

    getCancelationAmount(){
        if(this.props.role === 'client' ){
            return getClientCancelationAmountToReturn(
                this.props.my_current_service.created_at,
                this.props.my_current_service.when,
                this.props.total_cost,
                this.props.currency
            )
        }
        else if(this.props.role === 'artist'){
            return getArtistCancelationAmountToCharge(
                this.props.my_current_service.created_at,
                this.props.my_current_service.when,
                this.props.subtotal
            )
        }
    }

    getCancelationCharge(){
        if(this.props.role === 'client' || this.props.my_current_service.client_id === this.props.user_id){
            return getClientCancelationAmountToCharge(
                this.props.my_current_service.created_at,
                this.props.my_current_service.when,
                this.props.total_cost,
                this.props.currency
            )
        }
        else if(this.props.role === 'artist'){
            return getArtistCancelationAmountToCharge(
                this.props.my_current_service.created_at,
                this.props.my_current_service.when,
                this.props.subtotal
            )
        }
    }

    onAcceptCancelService(values){
        if(this.props.my_current_service.type === 'request') {
            let request_id = this.props.my_current_service.id
            let request = Object.assign(
                {}, 
                this.props.my_current_service, 
                values, 
                {   status: this.props.role === 'client' ? 0 : 1,
                    updated_at : new Date().getTime(),
                    origin: navigator.appVersion
                }
            )
            delete request.accepted
            delete request.end_hour
            delete request.image
            delete request.start_hour
            delete request.id
            
            this.props.cancelRequest(request, request_id, () => {
                this.toogleCancelServiceModal()
                this.props.history.push(routes.CLIENT_MY_SERVICES)
            })
        }
        else if(this.props.my_current_service.type === 'reservation'){
            this.props.cancelReservation({
                user_id : this.props.user_id,
                reservation_id : this.props.my_current_service.id,
                motive : values.reason,
                amount : toFixed(this.getCancelationAmount(), 2)},
                () => {
                    this.props.history.push(routes.CLIENT_MY_SERVICES)
                }
            )
        }
    }

    onAddHoursClick(){
        this.props.history.push(routes.ADD_HOURS_TO_SERVICE.replace(':id',this.props.my_current_service.id))
    }

    getCost(amount){
        if(this.props.my_current_service.currency_code)
            return currencyFormatter(this.props.my_current_service.currency_code, amount)
        else
            return CURRENCY_FORMATTER.format(amount)
    }

    renderCancelationModal(){
        if(this.props.my_current_service.type === 'reservation')
            return (
                <ModalCancelService
                    isOpen={this.state.modalCancelService}
                    toogle={this.toogleCancelServiceModal}
                    onClickAcceptButton={this.onAcceptCancelService}
                    modalHeaderText='¿Quieres cancelar el servicio?'
                    modalBodyText={`Se te cobrarán ${this.getCost(this.getCancelationCharge())} 
                    de penalización por cancelación y se te devolverán ${this.getCost(this.getCancelationAmount())}. Si contrataste horas extras, no olvides cancelarlas.`}
                />
            )
        else if(this.props.my_current_service.type === 'request')
            return (
                <ModalGenericAccept
                    isOpen={this.state.modalCancelService}
                    toogle={this.toogleCancelServiceModal}
                    onClickAcceptButton={this.onAcceptCancelService}
                    modalHeaderText='Cancelar'
                    modalBodyText='¿Quieres cancelar el servicio?'
                    acceptButtonColor='danger'
                    acceptButtonText='Si'
                    cancelButtonColor='secondary'
                    cancelButtonText='No'
                />
            )
    }

    render(){
        
        return(
            <div className='d-flex flex-column'>
                <Banner service={this.props.my_current_service} current_user={this.props.user_id}/>
                <div className='container'>
                    <ArtistCard service={this.props.my_current_service}/>
                    <div className='d-block d-md-none p-4 my-4 bg-gray-sidebar'>
                        <h6 className='text-gray-muzig'>Servicio</h6>
                        <h2 className='text-primary'>{this.props.my_current_service.service_name}</h2>
                        <div className='text-gray-muzig mt-auto d-flex align-items-center justify-content-end'>
                            <IconImage src={IconLike}/>
                            <span className='ml-2'>{this.props.my_current_service.rating}%</span>
                        </div>
                    </div>
                    <section className='px-4'>
                        <h4 className='text-primary'>Detalle del servicio</h4>
                        <div className='row py-2 px-4'>
                            <div className='col-12 col-sm-6 d-flex align-items-center my-3'>
                                <IconImage src={IconDate}/>
                                <span className='mx-2 text-gray-muzig'>{moment(this.props.my_current_service.when).format('dddd, DD MMM YYYY')}</span>
                            </div>
                            <div className='col-12 col-sm-6 d-flex align-items-center my-3'>
                                <IconImage src={IconHour}/>
                                <span className='mx-2 text-gray-muzig'>
                                    {this.props.my_current_service.start_hour} - {this.props.my_current_service.end_hour}
                                </span>
                            </div>
                            <div className='col-12 d-flex align-items-center my-3'>
                                <IconImage src={IconAddress}/>
                                <span className='mx-2 text-gray-muzig'>{this.props.my_current_service.where}</span>
                            </div>

                            <section className='d-flex my-4 w-100'>
                                <div className='flex-grow-1 text-right'>
                                    <h5 className='text-gray-muzig mx-2'>Subtotal: </h5>
                                    <h5 className='text-gray-muzig mx-2'>Comisión: </h5>
                                    <h4 className='text-gray-muzig mx-2'>Costo total ({PAYPAL_CURRENCIES[this.props.currency].id}): </h4>
                                </div>
                                <div className='text-left'>
                                    <h5 className='text-primary'>{this.getCost(this.props.subtotal)}</h5>
                                    <h5 className='text-primary'>{this.getCost(this.props.commissions)}</h5>
                                    <h4 className='text-primary'>
                                        {this.getCost(this.props.total_cost)}
                                    </h4>
                                </div>
                            </section>

                            
                            
                            <div className='container col-12 d-flex align-items-center mt-5 mb-2'>
                                {this.props.my_current_service.type === 'reservation' 
                                && <StyledButton 
                                    color='secondary' 
                                    block
                                    onClick={() => this.onViewChat(this.props.my_current_service)}
                                >
                                    Chat
                                </StyledButton>}
                            </div>

                            <div className='container col-12 d-flex align-items-center mt-2 mb-5'>
                                {this.props.my_current_service.type === 'reservation' 
                                && <StyledButton 
                                    color='primary' 
                                    block
                                    onClick={() => this.onAddHoursClick()}
                                >
                                    Agregar horas
                                </StyledButton>}
                            </div>

                            <div className='container col-12 d-flex align-items-center mb-5'>
                                <StyledButton 
                                    outline 
                                    color='danger' 
                                    block
                                    onClick={this.toogleCancelServiceModal}
                                >
                                    Cancelar
                                </StyledButton>
                            </div>
                        </div>
                    </section>
                </div>
                {this.renderCancelationModal()}
            </div>
        )
    }
}

function mapStateToProps({ user, auth }){
    const { my_current_service } = user
    const { role } = auth
    const user_id = auth.meta.user.uid
    //Comisiones
    // const subtotal = my_current_service.subtotal
    // const muzig_comision = subtotal*0.1
    // const openpay_constant = (subtotal + muzig_comision) * 0.0379
    // const openpay_comision = muzig_comision * .029 + 2.5 + (muzig_comision * .029 + 2.5) * .16
    // const comisions = muzig_comision + openpay_constant + openpay_comision
    // const total_cost = subtotal + comisions
    const subtotal = my_current_service.subtotal
    const provider = typeof my_current_service.gateway !== 'undefined' ? my_current_service.gateway_amount : my_current_service.openpay_amount
    const commissions = my_current_service.muzigzag_amount + provider
    const total_cost = my_current_service.cost
    const currency = my_current_service.currency_code ? my_current_service.currency_code : 'MXN'
    
    return { my_current_service, role, user_id, subtotal, commissions, total_cost, currency }
}

export default withRouter(
    connect(
        mapStateToProps, { fetchMyService, cancelRequest, cancelReservation }
    )(ShowService)
)