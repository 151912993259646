import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

export const StyledRow = styled(Row)`
    cursor: pointer;
    height: 20rem;
    &:hover {
        -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    }
    transition: 0.3s;

    @media screen and (max-width: 767px){
        height: 30rem;
    }
`
export const StyledCol = styled(Col)`
    background-image: url(${props => props.image});
    background-position: center;
    background-size: cover;
    @media screen and (max-width: 767px){
        height: 12rem;
    }
`

export const BottomDiv = styled.div`
    position: absolute;
    bottom: 0;
`

export const ImageLike = styled.img`
    width:1rem;
`

export const ImageMedal = styled.img`
    position: absolute;
    right: 1rem;
    top: 1;
    width:2.8rem;
    @media(max-width: 768px){
        width:2.6rem;
    }
    @media(max-width: 425px){
        width:2.4rem;
    }
`

export const SmallImageMedal = styled.img`
    position: absolute;
    right: 1rem;
    top: 0;
    width: 3rem;
`

export const BodyCol = styled(Col)`
    @media screen and (max-width: 767px){
        height: 18rem;
    }
`

export const StyledImg = styled.img`
    height: 10rem;
    object-fit: cover;
  border-radius: 10px 10px 0 0;
    @media(max-width: 768px){
        border-radius: 0.5rem 0.5rem 0 0 !important;
        height: 12rem;
    }
    @media(max-width: 425px){
        border-radius: 0.5rem 0.5rem 0 0 !important;
        height: 10rem;
    }
`

export const Card = styled.div`
    cursor: pointer;
    height: 18rem;
    @media(max-width: 768px){
        border-radius: 0.5rem!important;
        height: 20rem;
    }
    @media(max-width: 425px){
        border-radius: 0.5rem!important;
        height: 18rem;
    }
`