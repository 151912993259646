import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withRouter, Link } from 'react-router-dom'
import { Form } from 'reactstrap'

import EmailInput from 'components/atoms/inputs/login_email'
import PasswordInput from 'components/atoms/inputs/login_password'
import LoginButton from 'components/atoms/buttons/solid'
import RegisterButton from 'components/atoms/buttons/squared_solid'
import Loader from 'components/atoms/loader'
import ModalResetPassword from 'components/organisms/modal_reset_password'
import { ImageLogo, RightDiv, ClickableSpan, RoundedButton } from './styled'
import logo from 'components/atoms/images/logo.svg'

import { AUTH } from 'constants/index'
import * as routes from 'constants/routes'

import { loginWithEmail, loginWithFacebook, resetPassword, validateEmail } from 'actions/authentication'

class RightSide extends Component{
    constructor(props) {
        super(props)
        this.state = {
            modalResetPassword: false
        }
        this.toogleResetPassword = this.toogleResetPassword.bind(this)
        this.onAcceptResetPassword = this.onAcceptResetPassword.bind(this)
        this.onEmailLogin = this.onEmailLogin.bind(this)
        this.onFacebookLogin = this.onFacebookLogin.bind(this)
        this.onSignupAsArtist = this.onSignupAsArtist.bind(this)
        this.onSignupAsClient = this.onSignupAsClient.bind(this)
    }
    
    toogleResetPassword() {
        this.setState({
            modalResetPassword: !this.state.modalResetPassword
        })
    }

    onAcceptResetPassword(values){
        this.props.resetPassword(values.email)
        this.toogleResetPassword()
    }

    onEmailLogin(values){
        const { email, password } = values
        this.props.loginWithEmail({ email, password },  route => {
            this.props.history.push(route)
        })
    }

    onFacebookLogin(l){
        this.props.loginWithFacebook(route => {
            this.props.history.push(route)
        })
    }

    onSignupAsArtist(){
        this.props.history.push(routes.USER_SIGNUP_AS_ARTIST)
    }

    onSignupAsClient(){
        this.props.history.push(routes.USER_SIGNUP_AS_CLIENT)
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <div className=' ml-auto mr-auto'>
                <ModalResetPassword
                    isOpen={this.state.modalResetPassword}
                    toogle={this.toogleResetPassword}
                    onClickAcceptButton={this.onAcceptResetPassword}

                    modalHeaderText='Recuperar contraseña'
                    modalBodyText='Ingresa tu correo y te enviaremos una liga para que puedas recuperar tu contraseña.'
                />
                <RightDiv className="d-flex flex-column mb-4">
                    <div className=" text-center">
                        <ImageLogo src={logo} alt='logo muzigzag' className='mt-5'/>
                    </div>
                    <Form onSubmit={handleSubmit(this.onEmailLogin)}>
                        <div className="p-5 p-md-3 p-lg-2 text-center">
                            <Field
                                placeholder="Correo"
                                autocomplete="section-blue email"
                                name="email"
                                type="email"
                                component={EmailInput}
                            />
                            <Field
                                placeholder="Contraseña"
                                autocomplete="current-password"
                                name="password"
                                type="password"
                                component={PasswordInput}
                            />
                            <div className='d-flex justify-content-end'>
                                <ClickableSpan
                                    onClick={this.toogleResetPassword} 
                                    className='text-gray'>
                                    ¿Olvidaste tu contraseña?
                                </ClickableSpan>
                            </div>
                        </div>
                        <div className="text-center my-1">
                            {this.props.loading ? 
                                <div className='d-flex justify-content-center'>
                                    <Loader/>
                                </div> :
                                <div className={"d-flex align-items-center justify-content-between ml-auto mr-auto"}>
                                    <LoginButton color='warning' text='Entrar' type='submit'/>
                                    <RoundedButton
                                        color='primary'
                                        text='Entrar con Facebook'
                                        onClick={this.onFacebookLogin}>Entrar con facebook</RoundedButton>
                                </div>

                            }
                            
                        </div>
                    </Form>
                        <div className='p-2 text-center my-1'>
                            {this.props.loading ? 
                                <></>
                            :
                            <>
                                {/* Por alguna razon styled component está usando la misma referencia
                                de botón en el átomo, por lo que hay que crear uno diferente 'RoundedButton'
                                para que Redux Form no le haga caso en el onClick. */}

                                <div className="w-100 my-4"></div>
                                <Link to={routes.ROOT} className='text-gray-muzig'>Seguir como invitado</Link>
                            </>}
                        </div>
                    <div>
                        <div className='d-flex mt-3 flex-column flex-sm-row'>
                            <div className='flex-fill mx-2'>
                                <RegisterButton text='REGISTRO COMO CLIENTE' onClick={this.onSignupAsClient} />
                            </div>
                            <div className="w-100 my-2 d-block d-sm-none"/>
                            <div className='flex-fill mx-2'>
                                <RegisterButton text='REGISTRO COMO ARTISTA' onClick={this.onSignupAsArtist}/>
                            </div>
                        </div>
                    </div>
                </RightDiv>
            </div>
        )
    }
}

function validate(values){
    let errors = {}

    if (!values.email){
        errors.email = "Introduce tu correo electrónico"
    }
    else{
        if( !AUTH.EMAIL_REGEX.test(String(values.email).toLowerCase()) )
            errors.email = "Introduce un correo electrónico correcto."
    } 

    if (!values.password)
      errors.password = "Introduce tu contraseña"

    return errors
}

function mapStateToProps({ auth }){
    const { loading } = auth
    return { loading }
}

export default reduxForm({
    validate,
    form: 'access-form'
})(withRouter(
    connect(
        mapStateToProps, { loginWithEmail, loginWithFacebook, resetPassword, validateEmail }
        )(RightSide)
    )
)