import React, { Component } from 'react'
//import Media from 'react-media'
import { Col } from 'reactstrap'
import { 
    // StyledRow, StyledCol, 
    BottomDiv, ImageLike, ImageMedal, 
    // SmallImageMedal, BodyCol, 
    Card, StyledImg } from './styled'
import LikeIcon from 'components/atoms/images/icono_like.svg'
import MedalIcon from 'components/atoms/images/icono_medalla_fondo.svg'
import { CURRENCY_FORMATTER } from 'constants/index'
import { currencyFormatter } from 'helpers/index'

export default class SearchResultCard extends Component{
    constructor(props){
        super(props)
        this.renderMedal = this.renderMedal.bind(this)
        this.getCost = this.getCost.bind(this)
    }

    getCost(){
        if(this.props.service.currency_code)
            return currencyFormatter(this.props.service.currency_code, this.props.service.cost)
        else
            return CURRENCY_FORMATTER.format(this.props.service.cost)
    }

    renderMedal(){
        if(this.props.service.verified)
            return <ImageMedal src={MedalIcon} alt='servicio verificado' className='d-none d-md-block rounded'/>
    }

    render(){
        return(
            <Col onClick={() => this.props.onServiceClick(this.props.service)}
                xs='12' sm='4' lg='4'>
                <div className='p-2 '>
                    <Card className='d-flex flex-column shadow w-100 rounded'>
                        <StyledImg src={this.props.service.images[0]}/>
                        {this.renderMedal()}
                        <div className='container text-left'>
                            <h4 className='text-truncate text-capitalize'>{this.props.service.title}</h4>
                            <h6 className='text-truncate text-gray'>{this.props.service.artist_name}</h6>
                        </div>
                        <BottomDiv className="py-2 pl-2 pr-4 d-flex justify-content-between w-100 align-items-baseline">
                            <div className="text-left d-flex align-items-baseline">
                                <ImageLike src={LikeIcon}/>
                                <h6 className='mx-2'>{this.props.service.rating}%</h6>
                            </div>
                            <h5 className='text-secondary text-truncate'>{this.getCost()}</h5>
                        </BottomDiv>
                    </Card>
                </div>
            </Col>
        )
    }
}