import _ from 'lodash'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { reduxForm, Field } from 'redux-form'
import moment from 'moment'
import scriptjs from 'scriptjs'

import { Form, FormGroup } from 'reactstrap'
import FlashAlert from 'components/molecules/alert'
import * as routes from 'constants/routes'
import InputSelect from 'components/atoms/inputs/generic_option_select'
import InfoModal from 'components/organisms/modal_generic_ok'
import ContinueModal from 'components/organisms/modal_generic_continue'

import {
    ArtistImage,
    PaypalButton,
    CostInfo } from './styled'

import { 
    CURRENCY_FORMATTER,
    // OPENPAY_MERCHANT_ID,
    // OPENPAY_PUBLIC_API_KEY,
    PAYPAL_CLIENT_ID,
    PAYPAL_CURRENCIES,
    GATEWAY_CONSTANTS 
} from 'constants/index'
import { fetchUserCards } from 'actions/authentication'
import { saveService } from 'actions/user'
import { 
    payWithCard, 
    payWithCash, 
    generateHoursForDay, 
    fetchBookingDatesOfExtraService, 
    setDurationLimitAt,
    selectDurationExtraHours,
    payWithPaypal,
    saveHistoricPaypalOrder  } from 'actions/bookings'
import { fetchServiceAvailbility } from 'actions/services'
import { toFixed, currencyFormatter, getGatewayCommission, getEmailDate, getEmailHour } from 'helpers/index'
let PayPalButton

class BookingConfirm extends Component{
    constructor(props){
        super(props)
        let end_hour = Number('000'), end_date = moment()
        if(!_.isEmpty(this.props.my_current_service))
            end_hour = Number(this.props.my_current_service.end_hour.replace(':59','00')) + 100
            end_date = moment(this.props.my_current_service.when).format('DD/MM/YYYY')
        
        this.state = {
            // openpayLoaded: false,
            paypalLoaded: false,
            amount: this.props.total_cost.toString(),
            new_when : moment(`${end_date} ${end_hour.toString()}`, 'DD-MM-YYYY HH:mm').valueOf(),
            redirect : this.props.user_role === 'artist' ? routes.ARTIST_MY_SERVICES : routes.CLIENT_MY_SERVICES,
            isModalOpen: false,
            modalSuccesfullPayment: false
        }
        //this.onChangePaymentClick = this.onChangePaymentClick.bind(this)
        this.onSaveServiceClick = this.onSaveServiceClick.bind(this)
        // this.renderCardOption = this.renderCardOption.bind(this)
        this.onDurationChange = this.onDurationChange.bind(this)
        this.renderForm = this.renderForm.bind(this)
        this.setupPaypalPayment = this.setupPaypalPayment.bind(this)
        this.savePaypalOrder = this.savePaypalOrder.bind(this)
        this.renderPaypalButton = this.renderPaypalButton.bind(this)
        this.createOrder = this.createOrder.bind(this)
        this.onApprove = this.onApprove.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onError = this.onError.bind(this)
        this.getCost = this.getCost.bind(this)
        this.toogleInfoModal = this.toogleInfoModal.bind(this)
        this.toogleSuccesfullPayment = this.toogleSuccesfullPayment.bind(this)
        this.onAcceptSuccesfullPayment = this.onAcceptSuccesfullPayment.bind(this)
    }

    componentDidMount(){
        if(_.isEmpty(this.props.my_current_service))
            if(this.props.user_role === 'artist')
                this.props.history.push(routes.ARTIST_MY_SERVICES)
            else
                this.props.history.push(routes.CLIENT_MY_SERVICES)
        else {
            
            window.scrollTo(0, 0)
            this.props.fetchServiceAvailbility(this.props.my_current_service.service_id, () => {
                this.props.fetchBookingDatesOfExtraService(this.props.my_current_service.service_id, () => {
                    //ya se tienen las reservaciones
                    // moment.updateLocale('en', null)
                    // console.log('moment(this.props.my_current_service.when)', moment(this.props.my_current_service.when))
                    this.props.generateHoursForDay(
                        moment(this.props.my_current_service.when),
                        this.props.service_data.availability)
                    const end_hour = Number(this.props.my_current_service.end_hour.replace(':59','00')) + 100
                    //Filtramos horas a partir del fin del servicio en adelante
                    // console.log('this.props.hours_options', this.props.hours_options)
                    const availableExtraHours = _.filter(this.props.hours_options, hour => {
                        return Number(hour.id) >= end_hour
                    })

                    // console.log('availableExtraHours', availableExtraHours)
                    
                    if(availableExtraHours.length > 0){
                        //Si la hora final del servicio actual es igual o una hora antes de la siguiente hora disponible
                        if(Number(availableExtraHours[0].id) - 100 === end_hour || Number(availableExtraHours[0].id) === end_hour){
                            const last_hour = Number(this.props.hours_options[this.props.hours_options.length-1].id)
                            this.props.setDurationLimitAt(`${end_hour.toString().slice(0,-2)}:${end_hour.toString().slice(-2)}`, last_hour)
                        }
                    }
                    //this.props.fetchUserCards(this.props.user_id)
                    // scriptjs.order([
                    //     'https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js',
                    //     'https://openpay.s3.amazonaws.com/openpay.v1.min.js',
                    //     'https://openpay.s3.amazonaws.com/openpay-data.v1.min.js'], 'bundle')

                    // scriptjs.ready('bundle', () => {
                    //     this.setState({
                    //         openpayLoaded: true
                    //     })
                    //     window.OpenPay.setId(OPENPAY_MERCHANT_ID)
                    //     window.OpenPay.setApiKey(OPENPAY_PUBLIC_API_KEY)
                    //     window.OpenPay.setSandboxMode(true)
                    // })
                    scriptjs.order([`https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&intent=capture&currency=${this.props.currency}&disable-funding=card`], 'bundle')
                    scriptjs.ready('bundle', () => {
                        PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM })
                        this.setState({
                            paypalLoaded: true
                        })
                    }) 
                })
            })
        }
        
    }

    // onChangePaymentClick(){
    //     this.props.history.push(routes.ADD_HOURS_CARDS_SETTINGS.replace(':id', this.props.my_current_service.service_id))
    // }

    onDurationChange(event){
        const duration_selected = Number(event.target.value)
        this.props.selectDurationExtraHours(duration_selected)
    }

    onSubmit(values){
        if(values.payment_method === 'card' && _.isEmpty(this.props.default_card))
            this.props.history
            .push(routes.ADD_HOURS_CARDS_SETTINGS
                .replace(':id', this.props.my_current_service.service_id)
            )
        else{
            if(values.payment_method === 'card')
                this.props.payWithCard({
                    client_id : this.props.my_current_service.client_id,
                    artist_id : this.props.my_current_service.artist_id,
                    service_id : this.props.my_current_service.service_id,
                    client_name : this.props.my_current_service.client_name,
                    client_image : this.props.my_current_service.client_image,
                    artist_name : this.props.my_current_service.artist_name,
                    artist_image : this.props.my_current_service.artist_image,
                    service_name : this.props.my_current_service.service_name,
                    when : this.state.new_when,
                    where : this.props.my_current_service.where,
                    where_notes : this.props.my_current_service.where_notes,
                    hours : this.props.extra_duration,
                    subtotal : this.props.subtotal,
                    muzigzag_amount : this.props.muzig_commission,
                    gateway_amount : this.props.gateway_commission,
                    origin : navigator.appVersion,
                    device_id : window.OpenPay.deviceData.setup(),
                    card_id : this.props.user_info.default_card,
                    service_rating : this.props.my_current_service.service_rating
                }, () => {

                    this.props.history.push(this.state.redirect)
                })
            
            else
                this.props.payWithCash({
                    client_id : this.props.my_current_service.client_id,
                    artist_id : this.props.my_current_service.artist_id,
                    service_id : this.props.my_current_service.service_id,
                    client_name : this.props.my_current_service.client_name,
                    client_image : this.props.my_current_service.client_image,
                    artist_name : this.props.my_current_service.artist_name,
                    artist_image : this.props.my_current_service.artist_image,
                    service_name : this.props.my_current_service.service_name,
                    when : this.state.new_when,
                    where : this.props.my_current_service.where,
                    where_notes : this.props.my_current_service.where_notes,
                    hours : this.props.extra_duration,
                    subtotal : this.props.subtotal,
                    muzigzag_amount : this.props.muzig_commission,
                    gateway_amount : this.props.gateway_commission,
                    origin : navigator.appVersion,
                    service_rating : this.props.my_current_service.service_rating
                }, () => {
                    this.props.history.push(this.state.redirect)
                })
        }
    }

    onSaveServiceClick(){
        this.props.saveService(this.props.user_id, this.props.filter_options, () => {
            this.props.history.push(routes.ROOT)
        })
    }

    savePaypalOrder(data){
        this.props.saveHistoricPaypalOrder(data.orderID, {
            amount : this.props.total_cost,
            artist_name : this.props.my_current_service.artist_name,
            client_name : this.props.my_current_service.client_name,
            created_at : new Date().getTime(),
            currency_code : this.props.currency,
            requester_user : this.props.my_current_service.client_id,
            service_id : this.props.my_current_service.service_id,
            service_name : this.props.my_current_service.service_name,
            status : 0
        })
    }

    setupPaypalPayment(payment){
        this.props.payWithPaypal({
            gateway : 'paypal',
            order_id : payment.id,
            client_id : this.props.my_current_service.client_id,
            currency_code : this.props.currency,
            artist_id : this.props.my_current_service.artist_id,
            service_id : this.props.my_current_service.service_id,
            client_name : this.props.my_current_service.client_name,
            client_image : this.props.my_current_service.client_image,
            artist_name : this.props.my_current_service.artist_name,
            artist_image : this.props.my_current_service.artist_image,
            service_name : this.props.my_current_service.service_name,
            when : this.state.new_when,
            when_date : getEmailDate(this.state.new_when),
            when_hour: getEmailHour(this.state.new_when),
            where : this.props.my_current_service.where,
            where_notes : this.props.my_current_service.where_notes,
            hours : this.props.extra_duration,
            subtotal : this.props.subtotal,
            muzigzag_amount : this.props.muzig_commission,
            gateway_amount : this.props.gateway_commission + this.props.gateway_constant,
            origin : navigator.appVersion,
            service_rating : this.props.my_current_service.service_rating
        }, () => {
            this.toogleSuccesfullPayment()
        })
    }

    createOrder(data, actions) {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: this.props.total_cost.toString()
                }
            }]
        })
    }

    onApprove(data, actions) {
        console.log('lo has aprobado!!!', data, actions)
        this.savePaypalOrder(data)
        return actions.order.get().then(details => {
            console.log('Detalles: ', details)
            this.setupPaypalPayment(details)
        })
    }

    onCancel(data){
        console.log('El usuario ha cancelado la orden: ', data)
    }

    onError(error){
        console.log('Existió un error en la orden.', error)
    }

    getCost(amount){
        if(this.props.currency)
            return currencyFormatter(this.props.currency, amount)
        else
            return CURRENCY_FORMATTER.format(amount)
    }

    toogleInfoModal(){
        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
    }

    toogleSuccesfullPayment() {
        if(this.state.modalSuccesfullPayment){
            this.props.history.push(this.state.redirect)
        }
        this.setState({
            modalSuccesfullPayment: !this.state.modalSuccesfullPayment
        })
    }

    onAcceptSuccesfullPayment(){
        this.props.history.push(this.state.redirect)
    }

    componentWillUnmount(){
        window.location.reload()
    }

    // renderCardOption(){
    //     return _.isEmpty(this.props.default_card) ? 
    //         <span>Seleccionar de mis tarjetas</span>
    //         :
    //         <>
    //             <CardBrandLogo brand={this.props.default_card.brand}/>
    //             <span className='mx-2'>{this.props.default_card.last4}</span>
    //         </>
    // }

    renderPaypalButton(){
        return (
            <PayPalButton
                createOrder={ (data, actions) => this.createOrder(data, actions) }
                onApprove={ (data, actions) => this.onApprove(data, actions) }
                onCancel={ data => this.onCancel(data) }
                onError={ error => this.onError(error) }
                style={{ color: 'gold', shape: 'pill' }}
            />
        )
    }

    renderForm(){
        const { handleSubmit } = this.props
        if(this.props.hours_duration.length > 0 )
            return (
                <Form 
                        onSubmit={handleSubmit(this.onSubmit.bind(this))}
                        className='my-5'>

                        <section className='my-4'>
                            <h4 className='text-primary d-none d-sm-block'>Horas extra</h4>
                            <h4 className='text-gray-muzig d-block d-sm-none'>Horas extra</h4>
                            <FormGroup className='d-flex flex-column flex-sm-row'>
                                <div className='my-2 flex-fill'> 
                                    <Field
                                        name="duration"
                                        type="select"
                                        className="border-primary border-top-0 border-right-0 border-left-0"
                                        component={InputSelect}
                                        options={this.props.hours_duration}
                                        onChange={this.onDurationChange}
                                    />
                                </div>
                            </FormGroup>
                        </section>

                        {/* <section className='my-4'>
                            <h4 className='text-primary d-none d-sm-block'>Forma de pago</h4>
                            <h4 className='text-gray-muzig d-block d-sm-none'>Forma de pago</h4>
                            <div className='px-4 d-flex'>
                                <div className='flex-fill'>
                                    <div className='my-2'>
                                        <div className='d-flex align-items-center'>
                                            { !_.isEmpty(this.props.user_info.cards) ? 
                                                <FormGroup className='mb-0 mr-2 d-flex align-items-center'>
                                                    <Field id='card' name="payment_method" component='input' type="radio" value="card" />
                                                    <span className='text-gray-muzig mx-2 d-flex align-items-center'>{this.renderCardOption()}</span>
                                                </FormGroup>
                                                :
                                                <FormGroup className='mb-0 mr-2'>
                                                    <Field id='card' name="payment_method" component='input' type="radio" value="card" />
                                                    <span className='text-gray-muzig mx-2'>Tarjeta</span>
                                                </FormGroup>
                                            }
                                            <SpanChangePayment className='text-primary mx-2' onClick={this.onChangePaymentClick}>Cambiar</SpanChangePayment>
                                        </div>
                                            <TranslatedFormGroup className='mt-2 mr-2'>
                                                <Field 
                                                    id='store' 
                                                    name="payment_method" 
                                                    component={SimpliestInput} 
                                                    type="radio" 
                                                    value="store" 
                                                    text='Pagar en tienda' />
                                            </TranslatedFormGroup>
                                    </div>
                                </div>
                                
                            </div>
                        </section> */}

                        <section className='d-flex my-4'>
                            <div className='flex-grow-1 text-right'>
                                <h5 className='text-gray-muzig mx-2'>Subtotal: </h5>
                                <h5 className='text-gray-muzig mx-2'>
                                    <CostInfo 
                                        onClick={ () => this.toogleInfoModal() } 
                                        className='text-secondary'>
                                        ⓘ
                                    </CostInfo>
                                    {' '}Comisión:
                                </h5>
                                <h4 className='text-gray-muzig mx-2'>Costo total ({PAYPAL_CURRENCIES[this.props.currency].id}): </h4>
                            </div>
                            <div className='text-left'>
                                <h5 className='text-primary'>{this.getCost(this.props.subtotal)}</h5>
                                <h5 className='text-primary'>{this.getCost(this.props.commissions)}</h5>
                                <h4 className='text-primary'>
                                    {this.getCost(this.props.total_cost)}
                                </h4>
                            </div>
                        </section>

                        <PaypalButton payment={this.props.payment_option} className='my-2 text-center'>
                            {console.log('se renderiza el botón de paypal? ', this.state.paypalLoaded)}
                            {this.state.paypalLoaded ? this.renderPaypalButton() : ''}
                        </PaypalButton>

                        {/* <RoundedButton className='bg-secondary p-2' block size="lg">
                            Confirmar
                        </RoundedButton>  */}
                        
                    </Form>
            )
        else 
            return (
                <section className='my-4'>
                    <h4 className='text-primary d-none d-sm-block'>Lo sentimos, el servicio no está disponible para esta hora.</h4>
                </section>
            )
    }

    renderSuccesfullPaymentModal(){
        return(
            <ContinueModal
                isOpen={this.state.modalSuccesfullPayment}
                toogle={this.toogleSuccesfullPayment}
                onClickAcceptButton={this.onAcceptSuccesfullPayment}
                modalHeaderText='Pago exitoso'
                modalBodyText={`¡Felicidades! Has solicitado horas extra. Te invitamos a platicar con el artista para que finalices la contratación.`}
                acceptButtonText='OK'
                acceptButtonColor='primary'
            />
        )
    }

    render(){
        return(
            <>
            {this.renderSuccesfullPaymentModal()}
            <FlashAlert/>
            <div className='d-flex flex-column container mt-5 px-4'>
                <div className='d-flex justify-content-end'>
                    <h5 className='text-gray-muzig'>Contratación de horas extras</h5>
                </div>

                <section className='my-4 row'>
                    <div className='col-12 col-sm-3 col-md-2 p-2 text-center'>
                        <ArtistImage src={this.props.my_current_service.artist_image}/>
                    </div>
                    <div className='col-12 col-sm-9 col-md-10 p-2'>
                        <h1 className='text-primary d-none d-sm-block'>{this.props.my_current_service.service_name}</h1>
                        <h1 className='text-secondary d-block d-sm-none'>{this.props.my_current_service.service_name}</h1>
                        <h4 className='text-gray-muzig'>{this.props.my_current_service.artist_name}</h4>
                    </div>
                </section>

                
                {this.renderForm()}
            </div>
            <InfoModal
                toogle={this.toogleInfoModal}
                isOpen={this.state.isModalOpen}
                modalHeaderText='Comisión'
                modalBodyText='Cobramos una comisión una vez que el servicio es confirmado para contribuir al funcionamiento de nuestra plataforma.'
                acceptButtonColor='primary'
                acceptButtonText='Aceptar'
            />
            </>
        )
    }
}

function mapStateToProps({ auth, user, services, bookings }){
    const { my_current_service } = user
    const { hours_options, hours_duration, extra_duration, payment_option } = bookings
    const service_data = services.current_service
    
    const user_id = auth.meta.user.uid
    const user_info = auth.user
    const user_role = auth.role
    const default_card_id = user_info.default_card
    const default_card = default_card_id ? user_info.cards[default_card_id] : null
    //Comisiones
    const currency = my_current_service.currency_code ? my_current_service.currency_code : 'MXN'
    const subtotal = toFixed(service_data.cost_extra * extra_duration, 2)
    const muzig_commission = toFixed(subtotal * 0.1 * 1.16, 2)
    const gateway_constant = toFixed((subtotal + muzig_commission) * GATEWAY_CONSTANTS.PAYPAL.PLATFORM_CONSTANT, 2)
    const transaction_fix_commision = subtotal + muzig_commission + gateway_constant
    const gateway_commission = toFixed(getGatewayCommission(payment_option, transaction_fix_commision, currency), 2)
    const commissions = muzig_commission + gateway_constant + gateway_commission
    const total_cost = toFixed(subtotal + commissions, 2)
    
    
    return {  
        my_current_service,
        user_id,
        user,
        user_info,
        default_card, 
        subtotal,
        muzig_commission,
        gateway_commission,
        gateway_constant,
        commissions, 
        total_cost,
        service_data,
        hours_options,
        hours_duration,
        extra_duration,
        user_role,
        currency,
        payment_option
    }
}

function validate (values){
    let errors = {}
    if(!values.payment_method)
        errors.payment_method = 'Por favor selecciona tu método de pago'

    if (!values.duration)
        errors.duration = 'Selecciona las horas extra de tu servicio.'
    return errors
}

export default reduxForm({
    form:'AddHoursMethodForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(withRouter(
    connect(
        mapStateToProps,
        { 
            fetchUserCards, 
            saveService, 
            payWithCard, 
            payWithCash, 
            fetchServiceAvailbility, 
            generateHoursForDay, 
            fetchBookingDatesOfExtraService, 
            setDurationLimitAt,
            selectDurationExtraHours,
            payWithPaypal,
            saveHistoricPaypalOrder
        }
    )(BookingConfirm)
))