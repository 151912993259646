import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import Input from 'components/atoms/inputs/simpliest_input'
import { 
    completeEditServiceStep, 
    selectEditServiceStep, 
    uncheckEditServiceStep } from 'actions/shared'
import { updateService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'
import * as routes from 'constants/routes'
import { capitalizeString } from 'helpers/index'

class EditServiceName extends Component{
    constructor(props){
        super(props)
        this.validateName = this.validateName.bind(this)
    }

    componentDidMount(){
        if(isEmpty(this.props.job_service_selected))
            this.props.history.push(routes.ARTIST_JOB_SERVICES)
    }

    validateName(name){
        if(!name){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.name)
            return 'Debes definir un nombre para tu servicio'
        }
    }

    onSubmit(values){
        const change = { title : capitalizeString(values.name) }
        this.props.updateService(change, this.props.job_service_selected.id, () => {
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.name)
            this.props.selectEditServiceStep(EDIT_SERVICE_VIEWS.description)
            this.props.history.push(routes.ARTIST_JOB_SERVICES_EDIT.replace(':id', this.props.job_service_selected.id))
        })
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <div className='container mt-5'>
                <h4 className='text-secondary'>Título del servicio</h4>
                <p>Ingresa el título de tu servicio</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="name"
                        type="text"
                        placeholder="Agrega un título"
                        validate={this.validateName}
                        component={Input}
                    />
                    <div className='my-5 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps({ artist }){
    const { job_service_selected } = artist
    const initialValues = {
        'name' : job_service_selected.title
    }
    return { job_service_selected, initialValues }
}

EditServiceName = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true
  })(EditServiceName)
  
  EditServiceName = connect(
    mapStateToProps, 
    { 
        completeEditServiceStep, 
        selectEditServiceStep, 
        uncheckEditServiceStep,
        updateService 
    }
  )(EditServiceName)
  
  export default withRouter(EditServiceName)