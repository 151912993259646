import React, { Component } from 'react'
import ArtistProfileEditPromoter from 'components/organisms/artist_profile_edit_promoter'

class EditProfile extends Component{
    render(){
        return (
            <>
                <h4 className='text-gray-muzig'>Referidos</h4>
                <ArtistProfileEditPromoter/>
            </>
        )
    }
}

export default EditProfile