import React, { Component } from 'react'
import UserFavoritesList from 'components/organisms/user_favorites_list'

class Favorites extends Component{
    render(){
        return(
            <>
                <section className='p-4'>
                    <h4 className='text-secondary'>Favoritos</h4>
                    <UserFavoritesList/>
                </section>
            </>
        )
    }
}

export default Favorites