import React, { Component } from 'react'
import { connect } from 'react-redux'
import Media from 'react-media'
import { PageLoaderDiv } from './styled'

class PageLoader extends Component{

    render(){
        return(
            <Media query={{ maxWidth: 1024 }}>
                {matches =>
                    matches ? (
                        <PageLoaderDiv active={this.props.pageLoading} hd={false}>
                            <h3 className='text-primary'>Estamos procesando tu información.</h3>
                            <h5 className='text-secondary'>No cierres esta página.</h5>
                        </PageLoaderDiv>
                    ) : (
                        <PageLoaderDiv active={this.props.pageLoading} hd={true}>
                            <h3 className='text-primary'>Estamos procesando tu información.</h3>
                            <h5 className='text-secondary'>No cierres esta página.</h5>
                        </PageLoaderDiv>
                    )
                }
            </Media>
        )
    }
}

function mapStateToProps({ shared }){
    let { pageLoading } = shared
    return { pageLoading }
}

export default connect(mapStateToProps, {} )(PageLoader)