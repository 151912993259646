import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTimeout from 'react-timeout'

import { FixedAlert } from './styled'

import { closeAlert } from 'actions/shared'

class FlashAlert extends Component{
    componentDidUpdate(prevProps) {
        // if (this.props.alert !== prevProps.alert && this.props.alert.show)
        if(this.props.alert && this.props.alert.show)
            this.props.setTimeout(this.onDismiss.bind(this), 3000)
    }

    onDismiss() {
        this.props.closeAlert()
    }

    render(){

        let renderAlert = this.props.alert && this.props.alert.show ? 
        <FixedAlert color={this.props.alert.type} isOpen={this.props.alert.show} toggle={this.onDismiss.bind(this)}>
            {this.props.alert.message}
        </FixedAlert>
        :
        ''

        return(
            <>
                {renderAlert}
            </>
        
        );
    }
}

function mapStateToProps({ shared }){
  let { alert } = shared
  
  return { alert }
}

export default(
    connect(
      mapStateToProps, { closeAlert }
    )(ReactTimeout(FlashAlert))
)
