import React, { Component } from 'react'
import { ImageLike, DivCover, Card, ImageMedal } from './styled'
import LikeIcon from 'components/atoms/images/icono_like.svg'
import { CURRENCY_FORMATTER } from 'constants/index'
import MedalIcon from 'components/atoms/images/icono_medalla_fondo.svg'
import { currencyFormatter } from 'helpers/index'


export default class HomeServiceCard extends Component{
    constructor(props){
        super(props)
        this.renderMedal = this.renderMedal.bind(this)
        this.getCost = this.getCost.bind(this)
    }

    getCost(){
        if(this.props.service.currency_code)
            return currencyFormatter(this.props.service.currency_code, this.props.service.cost)
        else
            return CURRENCY_FORMATTER.format(this.props.service.cost)
    }

    renderMedal(){
        if(this.props.service.verified)
            return <ImageMedal src={MedalIcon} alt='servicio verificado'/>
    }

    render(){
        
        return(
            <div
                className='p-3 rounded '
                onClick={() => this.props.onServiceClick(this.props.service)}>
                <Card className='d-flex flex-column shadow w-100 rounded-lg'>
                    <DivCover src={this.props.service.images[0]} className='text-right rounded-lg'>
                        {this.renderMedal()}
                    </DivCover>
                    <div className='container text-left'>
                        <h5 className='text-truncate text-capitalize'>{this.props.service.title}</h5>
                        <h6 className='text-truncate text-gray'>{this.props.service.artist_name}</h6>
                    </div>
                    <div className="px-3 py-2 mt-auto d-flex justify-content-between w-100 align-items-baseline">
                        <div className="text-left d-flex align-items-baseline">
                            <ImageLike src={LikeIcon}/>
                            <h6 className='mx-2 my-0'>{this.props.service.rating}%</h6>
                        </div>
                        <h5 className='text-secondary text-truncate my-0'>{this.getCost()}</h5>
                    </div>
                </Card>
            </div>
        )
    }
}