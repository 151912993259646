import map from 'lodash/map'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TabContent, TabPane, Nav, NavItem } from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'

import { MOMENT_IN_SPANISH, CURRENCY_FORMATTER, RESERVATION_STATUS } from 'constants/index'
import { fetchUserHistory, fetchArtistHistory } from 'actions/artists'
import { EmptyImg, PointerNavLink } from './styled'

import IconNoHistory from 'components/atoms/images/vacios/sin_historial.svg'

class TabList extends React.Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.renderEmptyHistory = this.renderEmptyHistory.bind(this)
        this.renderAmount = this.renderAmount.bind(this)
        this.renderHistoryList = this.renderHistoryList.bind(this)
        this.state = {
            activeTab: '1'
        }
    }

    componentDidMount(){
        this.props.fetchUserHistory(this.props.user_id)
        this.props.fetchArtistHistory(this.props.user_id)
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    renderAmount(reservation){
        if(reservation.status >= RESERVATION_STATUS.client_canceled.id)
            if(reservation.status === RESERVATION_STATUS.client_canceled.id)
                return <h4>{CURRENCY_FORMATTER.format(reservation.fee)}</h4>
            else
                return <h4 className='text-gray-muzig'>$0.00</h4>
        
        else
            return <h4>{CURRENCY_FORMATTER.format(reservation.cost)}</h4>
    }

    renderHistoryList(services){
        moment.updateLocale('es', MOMENT_IN_SPANISH)
        return (
            map(services, reservation => {
                return (
                    <div className='row no-gutters border-bottom border-gray-muzig py-4' key={reservation.id}>
                        <div className='col-12 col-sm-9 mb-4 mb-sm-0'>
                            <h5>{reservation.service_name}</h5>
                            <span className='text-gray-muzig'>Reservación {RESERVATION_STATUS[reservation.status]}</span>
                        </div>
                        <div className='col-12 col-sm-3 d-flex flex-column align-items-sm-end'>
                            {this.renderAmount(reservation)}
                            <h6 className='text-gray-muzig'>
                                {moment(reservation.when).format('DD MMM YYYY')}
                            </h6>
                        </div>
                    </div>
                )
            })
        )
    }

    renderEmptyHistory(){
        return (
            <div className='text-center mt-5'>
                <EmptyImg src={IconNoHistory} alt='' />
                <h2 className='text-gray-muzig my-4'>Sin historial</h2>
            </div>
        )
    }

    render() {
        return(
        <div>
            <Nav tabs className='row border-0'>
                <NavItem className='col-6 p-0 text-center'>
                    <PointerNavLink
                        className={classnames(
                            { active: this.state.activeTab === '1' }, 
                            { 'bg-secondary': this.state.activeTab === '1' }, 
                            { 'text-white': this.state.activeTab === '1' }
                        )}
                        onClick={() => { this.toggle('1'); }}
                    >
                        Como Artista 
                    </PointerNavLink>
                </NavItem>
                <NavItem className='col-6 p-0 text-center'>
                    <PointerNavLink
                        className={classnames(
                            { active: this.state.activeTab === '2' }, 
                            { 'bg-secondary': this.state.activeTab === '2' }, 
                            { 'text-white': this.state.activeTab === '2' }
                        )}
                        onClick={() => { this.toggle('2'); }}
                    >
                        Como Cliente
                    </PointerNavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab} className='p-4'>
                <TabPane tabId="1">
                    {
                        this.props.artist_history.length === 0 ? 
                            this.renderEmptyHistory()
                            :
                            this.renderHistoryList(this.props.artist_history)
                    }
                </TabPane>
                <TabPane tabId="2">
                    {
                        this.props.client_history.length === 0 ?
                            this.renderEmptyHistory()
                            :
                            this.renderHistoryList(this.props.client_history)
                    }
                </TabPane>
            </TabContent>
        </div>
        )
    }
}

function mapStateToProps({ auth, artist }){
    const user_id = auth.meta.user.uid
    const { client_history, artist_history } = artist
    
    return {  user_id, client_history, artist_history }
}

export default withRouter(
    connect(
        mapStateToProps,
        { fetchUserHistory, fetchArtistHistory }
    )(TabList)
)