import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import validate from './validate'
import NextButton from 'components/atoms/buttons/solid'
import { BackButton, StyledDiv } from '../styled'

class WizardFormFifthPage extends Component {

  render(){
    const { handleSubmit, previousPage, pristine, submitting, invalid } = this.props

    return (
      <>
        <h2 className='text-primary mt-4'>Tipo de artista</h2>
        <p>Selecciona el ícono del tipo de artista al que perteneces.*</p>

        <form onSubmit={handleSubmit} className='p-2'>
        
          <StyledDiv className='d-flex flex-column'>
            <div className='row no-gutters my-4 my-sm-2 rounded'>
              <div className='col-xs-12 col-sm-3 rounded'>
                <Field id='musicalType' name="artistType" component='input' type="radio" value="musical" />
                <label htmlFor='musicalType' className='musical bg-warning p-2 rounded'></label>
              </div>
              <div className='col-xs-12 col-sm-9 px-xs-0 px-sm-5'>
                <h3 className='text-primary'>Musical</h3>
                <p>Agrupaciones musicales, mariachis, orquestas, conjuntos, solista, DJ, músicos.</p>
              </div>
            </div>
            <div className='row no-gutters my-4 my-sm-2 rounded'>
              <div className='col-xs-12 col-sm-3 rounded'>
                <Field id='performanceType' name="artistType" component='input' type="radio" value="performance" />
                <label htmlFor='performanceType' className='performance bg-warning p-2 rounded'></label>
              </div>
              <div className='col-xs-12 col-sm-9 px-xs-0 px-sm-5'>
                <h3 className='text-primary'>Performance</h3>
                <p>Mago, payaso, circense, comediante, animador, bailarín, imitador, actuación, puestas en escena.</p>
              </div>
            </div>
            <div className='row no-gutters my-4 my-sm-2 rounded'>
              <div className='col-xs-12 col-sm-3 rounded'>
                <Field id='productionType' name="artistType" component='input' type="radio" value="production" />
                <label htmlFor='productionType' className='production bg-warning p-2 rounded'></label>
              </div>
              <div className='col-xs-12 col-sm-9 px-xs-0 px-sm-5'>
                <h3 className='text-primary'>Producción</h3>
                <p>Producción audiovisual, producción discográfica, producción fotográfica, asesor de imagen, karaoke, locución, maquillaje, planner, talleres, otros.</p>
              </div>
            </div>
          </StyledDiv>

          <div className="py-1"/>
          <p className='text-gray'>*Campo obligatorio</p>
          <p className='text-gray'>Al registrarte y crear una cuenta confirmas que estás de acuerdo con nuestros Términos y Condiciones y nuestro Aviso de Privacidad.</p>

          <div className='d-flex flex-md-row flex-column justify-content-center mt-5'>
            <BackButton 
              className='mx-md-2 text-white' 
              type="button" 
              onClick={previousPage} 
              color='gray-muzig'>Anterior</BackButton>
            <div className='d-block d-md-none w-100 my-2'></div>
            <NextButton 
              disabled={ invalid || pristine || submitting }
              className='mx-md-2' 
              type="submit" 
              text='Registrar'/>
          </div>

        </form>
      </>
    )
  }
}

export default connect(
  null,
  { }
)(
  reduxForm({
    form: 'signupArtistWizard',
    destroyOnUnmount: false,
    validate
  })(WizardFormFifthPage)
)