import React from 'react'
import ReactLoading from 'react-loading'

export default () => {
    return(
        <ReactLoading 
            type={'bars'}
            delay={0}
            color={'#4E6395'}
            height={'10%'} 
            width={'10%'} 
        />
    )
}