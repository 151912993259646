import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import SmallProfile from './small_profile'
import BigProfile from './big_profile'
import * as routes from 'constants/routes'


class EditServiceJob extends Component{
    componentDidMount(){
        if(isEmpty(this.props.job_service_selected))
            this.props.history.push(routes.ARTIST_JOB_SERVICES)
    }

    render(){
        return(
            <>
                <Media query={{ maxWidth: 767}}>
                    {matches => matches ? 
                        <SmallProfile/> : <BigProfile/>
                    }
                </Media>
            </>
        )
    }
}

function mapStateToProps({ artist }){
    const { job_service_selected } = artist
    return { job_service_selected }
}

export default connect(
    mapStateToProps,
    {  }
)(withRouter(EditServiceJob))