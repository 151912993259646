import styled from 'styled-components'
import {
    Input,
    FormFeedback
} from 'reactstrap'
import AddFile from 'components/atoms/images/icono_historial.svg'
import EditImage from 'components/atoms/images/icono_mas.svg'

export const StyledInput = styled(Input)`
  border:0!important;
  &::-webkit-input-placeholder {
    color: #9B9B9B;
  }
`

export const StyledChatInput = styled(Input)`
  border:1px solid gray !important;
  border-radius:2rem!important;
  margin-right:1rem!important;
`

export const InputIcon = styled.img`
  margin-right: 0.25rem;
  width:1rem
`

export const StyledFormFeedback = styled(FormFeedback)`
  display: inline-block!important
`

export const FileInput = styled(Input)`
  cursor: pointer;  
  height: 10rem;  
  opacity: 0;
  transform: translateY(-3rem);
`

export const ImgInput = styled(Input)`
  background-image: url(${AddFile});
  background-position: center; 
  background-repeat: no-repeat;
  background-origin: content-box;
  opacity: 0;
  width: 1rem!important;
`

export const InputTitle = styled.h5`
  transform: translateY(-6.5rem)
`

export const FileDiv = styled.div`
  background-image: url(${AddFile});
  background-position: center; 
  background-repeat: no-repeat;
  background-origin: content-box;
  height: 10rem;
  padding: 3.5rem 0; 
`

export const FileThumb = styled.img`
  height: 10rem;
  object-fit: cover;
  width: 13rem;
  border-radius: 10px;
`

export const ActiveFormFeedback = styled(FormFeedback)`
  display: ${props => props.invalid === 'true' ? 'block!important' : 'none'}
`

export const ActiveImageFormFeedback = styled(FormFeedback)`
  display: ${props => props.invalid === 'true' ? 'block!important' : 'none'}
  transform: translateX(-3rem);
`

export const EditImg = styled.div`
  background-image: url(${EditImage});
  background-position: center; 
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 1rem 1rem;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  margin: 0 auto;
  width: 2rem;
`

export const EditIcon = styled.img`
  height: 1rem;
  transform: translateX(-1rem);
  width: 1rem;
`

export const InvalidFeedback = styled.span`
  font-size: 0.7rem;
`