import React, { Component } from 'react'
import { connect } from 'react-redux'

import EditServiceSideSteps from 'components/organisms/big_steps_edit_service'
import { EDIT_SERVICE_VIEWS } from 'constants/index'
import Name from './name'
import Description from './description'
import Cost from './cost'
import Zones from './zones'
import Availabiity from './availability'
import Photos from './photos'
import Video from './video'
import Types from './types'
import Includes from './includes'
import Category from './category'

class EditService extends Component{

    render(){
        return(
            <>
                <div className='p-4'>
                    <h2 className='text-primary'> Editar Servicio</h2>
                </div>
                <section className='row no-gutters'>
                    <div className='col-3 d-flex flex-column'>
                        <EditServiceSideSteps/>
                    </div>
                    <div className='col-9 px-4'>
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.name && <Name/>}
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.description && <Description/>}
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.cost && <Cost/>}
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.zones && <Zones/>}
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.availability && <Availabiity/>}
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.photos && <Photos/>}
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.video && <Video/>}
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.types && <Types/>}
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.includes && <Includes/>}
                        {this.props.editServiceStep ===  EDIT_SERVICE_VIEWS.category && <Category/>}
                    </div>
                </section>
            </>
        )
    }
}

function mapStateToProps({ shared }){
    const { editServiceStep } = shared
    return { editServiceStep }
}

export default connect(
    mapStateToProps,
    {}
)(EditService)