import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

import { GOOGLE_PLACES_API, ARTISTS } from 'constants/index'
import Input from 'components/atoms/inputs/generic_input'
import OptionSelect from 'components/atoms/inputs/generic_option_select'
import RoundedButton from 'components/atoms/buttons/solid'
import iconName from 'components/atoms/images/icono_nombre_apellido.svg'
import iconTel from 'components/atoms/images/icono_telefono_formulario.svg'
import iconEmail from 'components/atoms/images/icono_correo.svg'
import iconPlace from 'components/atoms/images/icono_direccion.svg'
import iconCountry from 'components/atoms/images/icono_pais_registro.svg'
import iconCLABE from 'components/atoms/images/icono_clabe.svg'
import iconRFC from 'components/atoms/images/icono_rfc.svg'

import { getCountriesList } from 'actions/shared'
import { updateUserData } from 'actions/user'
import { updateArtistServices } from 'actions/artists'
import validate from './validate'
import * as routes from 'constants/routes'
import { StyledDiv } from './styled'

class ArtistProfileEditForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            search: '',
            value: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectSuggest = this.handleSelectSuggest.bind(this)
    }
  
    handleInputChange(e) {
      this.setState({search: e.target.value, value: e.target.value})
    }
  
    handleSelectSuggest(suggest) {
      this.setState({search: "", value: suggest.formatted_address})
      this.props.change('address',this.state.value)
    }

    componentDidMount(){
        this.props.getCountriesList()
    }

    onSubmit(values){
        const old_artist_name = this.props.initialValues.artist_name
        this.props.updateUserData(values, this.props.user_id, () => {

            if(values.artist_name !== old_artist_name)
                this.props.updateArtistServices(this.props.user_id, { artist_name : values.artist_name }, () => {
                    this.props.history.push(routes.ARTIST_PROFILE)
                })
            else
                this.props.history.push(routes.ARTIST_PROFILE)
        })
    }

    render(){
        const { handleSubmit } = this.props
        const { search } = this.state

        return (
        <>
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                <Field
                    name="artist_name"
                    type="text"
                    placeholder="Nombre artístico"
                    autocomplete='name'
                    icon={iconName}
                    component={Input}
                />
                
                <Field
                    name="name"
                    type="text"
                    placeholder="Nombre"
                    autocomplete='name'
                    icon={iconName}
                    component={Input}
                    disabled
                />

                <Field
                    name="lastName"
                    type="text"
                    placeholder="Apellidos"
                    autocomplete='last-name'
                    icon={iconName}
                    component={Input}
                    disabled
                />

                <Field
                    name="email"
                    type="email"
                    placeholder="Correo electrónico"
                    autocomplete='email'
                    icon={iconEmail}
                    component={Input}
                    disabled
                />

                <Field
                    name="phone"
                    type='tel'
                    placeholder="Agrega tu teléfono"
                    autocomplete='main-tel'
                    icon={iconTel}
                    component={Input}
                />
                

                <Field
                    name="rfc"
                    type="text"
                    placeholder="RFC/ Número Fiscal o Tributario"
                    autocomplete='rfc'
                    icon={iconRFC}
                    component={Input}
                />

                <ReactGoogleMapLoader
                    params={{
                    key: GOOGLE_PLACES_API,
                    libraries: "places,geocode",
                    }}
                    render={googleMaps =>
                        googleMaps && (
                            <ReactGooglePlacesSuggest
                                autocompletionRequest={{input: search}}
                                googleMaps={googleMaps}
                                onSelectSuggest={this.handleSelectSuggest}
                            >
                                <Field
                                name="address"
                                type='text'
                                placeholder="Agrega tu dirección"
                                autocomplete='address'
                                icon={iconPlace}
                                component={Input}
                                onChange={this.handleInputChange}
                                />
                            </ReactGooglePlacesSuggest>
                        )
                    }
                />

                <Field
                    name="country"
                    type='select'
                    placeholder="País"
                    icon={iconCountry}
                    options={this.props.countries}
                    component={OptionSelect}
                />

                <div className="py-3"/>

                <h4 className='text-gray-muzig'>Datos bancarios</h4>
                <Field
                    name="paypal_email"
                    type='email'
                    placeholder="Correo Paypal"
                    icon={iconCLABE}
                    component={Input}
                />

                <div className="py-3"/>

                <h4 className='text-gray-muzig'>Tipo de artista</h4>
                <StyledDiv className='d-flex flex-column'>
                    <div className='row no-gutters my-4 my-sm-2'>
                        <div className='col-xs-12 col-sm-3'>
                            <Field id='musicalType' name="artistType" component='input' type="radio" value="musical" />
                            <label htmlFor='musicalType' className='musical bg-warning p-2 rounded-lg'></label>
                        </div>
                        <div className='col-xs-12 col-sm-9 px-xs-0 px-sm-5'>
                            <h3 className='text-primary'>Musical</h3>
                            <p>Grupo, banda, orquesta, conjunto, solista, DJ, karaoke.</p>
                        </div>
                    </div>
                    <div className='row no-gutters my-4 my-sm-2'>
                        <div className='col-xs-12 col-sm-3'>
                            <Field id='performanceType' name="artistType" component='input' type="radio" value="performance" />
                            <label htmlFor='performanceType' className='performance bg-warning p-2 rounded-lg'></label>
                        </div>
                        <div className='col-xs-12 col-sm-9 px-xs-0 px-sm-5'>
                            <h3 className='text-primary'>Performance</h3>
                            <p>Mimo, payaso, malabarista, mago, animador, contorsionista, comediante, imitador.</p>
                        </div>
                    </div>
                    <div className='row no-gutters my-4 my-sm-2'>
                        <div className='col-xs-12 col-sm-3'>
                            <Field id='productionType' name="artistType" component='input' type="radio" value="production" />
                            <label htmlFor='productionType' className='production bg-warning p-2 rounded-lg'></label>
                        </div>
                        <div className='col-xs-12 col-sm-9 px-xs-0 px-sm-5'>
                            <h3 className='text-primary'>Producción</h3>
                            <p>Video, audio, fotografía, escenografía.</p>
                        </div>
                    </div>
                </StyledDiv>

                <div className="py-3"/>
                
                <div className='d-flex justify-content-center mt-5'>
                    <RoundedButton type="submit" text='Guardar'/>
                </div>
            </form>
            <div className='mt-5 d-flex justify-content-end'>
                <a className='text-danger text-right' href='https://themuzigzag.com/eliminar-cuenta/' target='blank'>Eliminar cuenta</a>
            </div>
        </>
        )
    }
}

function mapStateToProps({ auth, shared }){
  const { user } = auth
  const { countries } = shared
  const user_id = auth.meta.user.uid
  
  const initialValues = {
    'artist_name' : user.artist_name,
    'name' : user.name,
    'lastName' : user.last_name,
    'phone' : user.phone,
    'email' : user.email,
    'address' : user.address,
    'country' : user.country,
    'paypal_email' : user.paypal_account_email,
    'rfc' : user.rfc,
    'artistType' : ARTISTS.TYPES[user.artist_type]
  }
  
  return { initialValues, countries, user_id }
}

ArtistProfileEditForm = reduxForm({
  form: 'ArtistProfileEditForm',
  validate,
})(ArtistProfileEditForm)

ArtistProfileEditForm = connect(
  mapStateToProps,
  { getCountriesList, updateUserData, updateArtistServices }
)(ArtistProfileEditForm)

export default withRouter(ArtistProfileEditForm)