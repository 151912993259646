import React, { Component } from 'react'

import AddCardForm from 'components/organisms/add_card_form'
import * as routes from 'constants/routes'

class PaymentMethodsAddCard extends Component{

    render(){
        return(
            <>
                <section className='p-4'>
                    <h4 className='text-secondary'>Agrega tu tarjeta</h4>
                    <p className='text-gray-muzig'>
                        Aceptamos Visa, Mastercard y American Express
                    </p>
                    <AddCardForm route={routes.CLIENT_PROFILE_PAYMENT_METHODS}/>
                    
                </section>
            </>
        )
    }
}

export default PaymentMethodsAddCard