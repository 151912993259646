import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import { 
    completeEditServiceStep, 
    selectEditServiceStep, 
    uncheckEditServiceStep,
    getEventTypesList } from 'actions/shared'
import { updateService, fetchJobService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS, SHARED } from 'constants/index'
import MultipleSelect from 'components/atoms/inputs/multiple_select'
import Button from 'components/atoms/buttons/solid'
import { getEventTypes } from 'helpers/index'

class EditServiceTypes extends Component{
    constructor(props){
        super(props)
        this.minLength1 = this.minLength1.bind(this)
    }

    componentDidMount(){
        this.props.getEventTypesList()
    }

    onSubmit(values){
        const change = { types : getEventTypes(values) }
        this.props.updateService(change, this.props.job_service_selected.id, () => {
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.types)
            const service_id = window.location.href.split('/').pop()
            this.props.fetchJobService(service_id)
        })
    }
    
    minLength1(values = []){
        if(values.length < 1){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.types)
            return 'Tienes que elegir al menos una opción'
        }
        else
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.types)
        
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>Tipo de evento</h2>
                <p>Selecciona los eventos que mejor se adapten a tu servicio.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="event-types"
                        type='select'
                        selectType={SHARED.SELECT_TYPE_EVENT_TYPES}
                        placeholder='Selecciona al menos un tipo de evento'
                        options={this.props.event_types}
                        component={MultipleSelect}
                        validate={[this.minLength1]}
                    />
            
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps({ shared, form, artist }){
    let initialEventTypes = []
    if(form.editServiceWizard && form.editServiceWizard.values)
        if(typeof form.editServiceWizard.values['event-types'] !== 'undefined')
            initialEventTypes = form.editServiceWizard.values['event-types']

    let eventTypesArray = []
    let { eventTypes } = shared
    eventTypes.forEach( type => eventTypesArray.push({ value:type.name, label:type.name }) )

    const { job_service_selected } = artist
    
    /*VALORES INICIALES*/
    let typesArrayOptions = []

    if (job_service_selected.types){
        typesArrayOptions = _.map(job_service_selected.types, type => {
            return { value: type, label: type }
        })
    }

    const initialValues = {
        'event-types' : typesArrayOptions,
    }
    
    return { event_types: eventTypesArray, initialEventTypes, job_service_selected, initialValues }
}

EditServiceTypes = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true
  })(EditServiceTypes)
  
  EditServiceTypes = connect(
    mapStateToProps,
    { 
        completeEditServiceStep, 
        selectEditServiceStep, 
        uncheckEditServiceStep,
        getEventTypesList,
        updateService,
        fetchJobService
    }
  )(EditServiceTypes)
  
  export default EditServiceTypes