import React, { Component } from 'react'
import { RoundedButton } from './styled'

export default class SolidButton extends Component {
    render(){
        return(
            <>
                <RoundedButton 
                    disabled={this.props.disabled}
                    color={"warning"}
                    className='shadow-md text-white'>
                    {this.props.text}
                </RoundedButton>
            </>
        )
    }
}