import styled from 'styled-components'
import { NavLink } from 'reactstrap'

export const HeadCollapsable = styled.div`
    cursor: pointer;
`

export const UserImage = styled.img`
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    object-fit: cover;
`

export const ServiceDiv = styled.div`
    cursor: pointer;
`

export const IconImage = styled.img`
    width: 1rem;
`

export const EmptyImg = styled.img`
    width: 45%;
`
export const PointerNavLink = styled(NavLink)`
    cursor: pointer;
`

export const MyServiceDiv = styled.div`
    transform: translateX(1rem);
    width: 1rem;
`

export const BottomDiv = styled.div`
    height: 2rem;
`