import styled from 'styled-components'

export const Banner = styled.div`
    background-image: url(${ props => props.image ? props.image : '' });
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: ${props => {
        switch(props.size){
            case 'xs':
                return '10vh'
            case 'sm':
                return '15vh'
            case 'md':
                return '20vh'
            case 'lg':
                return '25vh'
            case 'xl':
                return '35vh'
            default:
                break
        }
    }};
    width: 100%;
`

export const ArtistImage = styled.img`
    border-radius: 50%;
    height: 6rem;
    object-fit: cover;
    width: 6rem;
`