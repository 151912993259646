import styled from 'styled-components'
import cover1 from 'components/atoms/images/acceso/cover-login-access-1.jpg'
import cover2 from 'components/atoms/images/acceso/cover-login-access-2.jpg'
import cover3 from 'components/atoms/images/acceso/cover-login-access-3.jpg'
import cover4 from 'components/atoms/images/acceso/cover-login-access-4.jpg'
import cover5 from 'components/atoms/images/acceso/cover-login-access-5.jpg'

const covers = [cover1, cover2, cover3, cover4, cover5]

export const LeftCover = styled.div`
    background-image: url(${covers[Math.floor(Math.random() * 5) + 0 ]});    
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
    max-width: 40vw;
    min-width: 30vw;
    position: relative;
    border-radius: 0 0 20px 0;
    box-shadow: 0 0 30px rgba(0,0,0,0.5);
    z-index: 0;

    .opacity-layer {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

`