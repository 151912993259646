import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { EmptyIconImg } from './styled'
import * as routes from 'constants/routes'
import IconNoCards from 'components/atoms/images/vacios/sin_pagos.svg'

import UserCardList from 'components/organisms/user_card_list'
import { fetchUserCards } from 'actions/user'

class PaymentMethods extends Component{
    constructor(props){
        super(props)
        this.onAddCardClick = this.onAddCardClick.bind(this)
    }

    componentDidMount(){
        this.props.fetchUserCards(this.props.user_id)
    }

    onAddCardClick(){
        this.props.history.push(routes.ARTIST_PROFILE_PAYMENT_METHODS_ADD)
    }

    render(){
        return(
            <>
                <section className='p-4'>
                    <h4 className='text-secondary'>Formas de pago</h4>
                    { isEmpty(this.props.cards) ? 
                        <div className='text-center pt-5 pb-4'>
                            <EmptyIconImg alt='' src={IconNoCards}/>
                            <h4 className='text-gray-muzig py-2'>No hay tarjetas</h4>
                        </div>
                        :
                        <div className='pt-4 pb-4'>
                            <p className='text-gray-muzig my-4'>Selecciona tu tarjeta predeterminada</p>
                            <UserCardList/>
                        </div>
                    }
                    <div className='text-right' onClick={this.onAddCardClick}>
                        <span className='text-secondary'>+Agregar tarjeta</span>
                    </div>
                </section>
            </>
        )
    }
}

function mapStateToProps({ auth }){
    const { user: { cards } } = auth
    const user_id = auth.meta.user.uid
    
    return { cards, user_id }
}

export default withRouter(
    connect(
        mapStateToProps,
        { fetchUserCards }
    )(PaymentMethods)
)