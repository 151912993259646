import styled from 'styled-components'
import { MdClose, MdMenu, MdSearch } from 'react-icons/md'
import { InputGroupAddon } from 'reactstrap'
import { placeholder } from 'polished'

export const Navbar = styled.div`
    align-items: center; 
    display: flex;
    font-family: sans-serif;
    padding: 1rem; 
`

export const NavbarItemsLeft = styled.div`
    display: flex;
`

export const NavbarItemsRight = styled.div`
    display: flex;
    margin-left: auto;
`

export const NavbarLink = styled.div`
    padding-right: 0.5rem;
`

export const NavbarSearchBtn = styled(MdSearch)`
    cursor: pointer;
    display: ${props => props.issearchopen === 'true' ? 'none' : 'block'}
    font-size: 2rem;
`

export const NavbarCloseSearchBtn = styled(MdClose)`
    cursor: pointer;
    display: ${props => props.issearchopen === 'true' ? 'block' : 'none'}
    font-size: 2rem;
    margin-top: 0.2rem;
`

export const LogoImage = styled.img`
    width: ${props => props.isVisible ? '0' : '3rem' }
`

export const SideNav = styled.div`
    height: 100%; /* 100% Full-height */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    position: fixed; /* Stay in place */
    top: 0; /* Stay at the top */
    transition: 0.5s;
    width: ${props => props.isOpen ? '20rem' : '0' };
    z-index: 3; /* Stay on top */
    span {
        font-size: 1.4rem;
        overflow-wrap: normal;
    }
`

export const SideNavCloseBtn = styled(MdClose)`
    cursor: pointer;
    font-size: 2rem;
    margin-left: 3rem;
    position: absolute;
    right: 1.5rem;
    top: 1rem;
`

export const SideNavMenuBtn = styled(MdMenu)`
    cursor: pointer;
    display: ${props => props.issearchopen === 'true' ? 'none' : 'block'}
    font-size: 2rem;
    // transition: transform 0.5s linear;
    // transform: translateX(-4rem);
`

export const SideNavLink = styled.div`
    cursor: pointer;  
    margin: 1rem 0.5rem;
`

export const SearchForm = styled.div`
    display: ${props => props.isOpen ? 'block' : 'none'};
`
// export const SearchForm = styled(Collapse)`
//     width: ${props => props.isOpen ? '75vw' : '0vw'};
// `

export const InputSearchBtn = styled(InputGroupAddon)`
    border-bottom: solid 2px;
    cursor: pointer;
    font-size: 2rem;
`

export const StyledInput = styled.input`
    ${ placeholder({ color: 'white' }) }
`

export const SearchIcon = styled(MdSearch)`
    font-size: 2rem;
`

export const SidemenuLogo = styled.img`
    height: 4rem;
    width: auto;
`

export const CopyrightP = styled.p`
    opacity: 0.43;
`

export const SocialIcon = styled.img`
    cursor: pointer;
    width: 1.6rem;
`

export const PointerP = styled.p`
    cursor: pointer;
`