import { DATEPICKER, BOOKINGS } from 'constants/index'

export function onDatesChange({ startDate, endDate }){
    return (dispatch) => {
        dispatch({ 
            type: DATEPICKER.ON_DATES_CHANGE,
            payload: { startDate, endDate }
        })
    }
}

export function onFocusChange( focusedInput ){
    return (dispatch) => {
        dispatch({ 
            type: DATEPICKER.ON_FOCUS_CHANGE,
            payload: focusedInput
        })
    }
}

export function onDateChange(singleDate, availability = null){
    
    return (dispatch) => {
        dispatch({ 
            type: DATEPICKER.ON_DATE_CHANGE,
            payload: singleDate
        })

        if(availability && singleDate){
            dispatch({
                type: BOOKINGS.GENERATE_HOURS_FOR_DAY,
                payload: singleDate,
                availability
            })
        }
            
    }
}

export function onSingleFocusChange( focusedInput ){
    return (dispatch) => {
        dispatch({ 
            type: DATEPICKER.ON_SINGLE_FOCUS_CHANGE,
            payload: focusedInput.focused
        })
    }
}

export function pushToBlockedWeekDays(day) {
    return dispatch => {
        dispatch({
            type: DATEPICKER.PUSH_TO_BLOCKED_WEEK_DAYS,
            payload: day
        })
    }
}

export function resetBlockedWeekDays() {
    return dispatch => {
        dispatch({ type: DATEPICKER.RESET_BLOCKED_WEEK_DAYS })
    }
}

export function clearSingleDate() {
    return dispatch => {
        dispatch({ type: DATEPICKER.CLEAR_SINGLE_DATE })
    }
}