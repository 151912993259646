import styled from 'styled-components'

export const ClientImg = styled.img`
    border: white solid 2px;
    border-radius: 50%;
    height: 6rem;
    object-fit: cover;
    width: 6rem;
`

export const ImageForm = styled.form`
    transform: translate(2rem,-2rem);
`