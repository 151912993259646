import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import { changeProfileImage } from 'actions/user'
import { ArtistImg, ImageForm } from './styled'
import FileInput from 'components/atoms/inputs/change_image_input'
import { DEFAULT_PDF_PROFILE_IMG, DEFAULT_PROFILE_IMAGE_URL } from 'constants/index'

class SmallProfileHeader extends Component{

    onSubmit(values){
        if(!isEmpty(values.profile_image))
            this.props.changeProfileImage(this.props.user_id, values.profile_image)
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <>
                <section className='bg-primary d-flex justify-content-center align-items-center border-top border-grey pt-2'>
                    <div className='mt-4 mx-4'>
                        <div className='align-items-center'>
                            <ArtistImg 
                                alt=''
                                src={
                                    this.props.user.profile_image && this.props.user.profile_image !== DEFAULT_PDF_PROFILE_IMG ?
                                        this.props.user.profile_image
                                        :
                                        DEFAULT_PROFILE_IMAGE_URL
                                }/>
                            <ImageForm onChange={() => setTimeout(handleSubmit(params => this.onSubmit(params)))}>
                                <Field
                                    name="profile_image"
                                    type='file'
                                    label=""
                                    component={FileInput}
                                    
                                />
                            </ImageForm>
                        </div>
                    </div>
                    <div className='mx-4'>
                        <div className='text-white'>
                            <h5>{`${this.props.user.name} ${this.props.user.last_name}`}</h5>
                            <h6>El {this.props.user.rating}% te recomienda.</h6>
                        </div>
                        
                    </div>
                </section>
            </>
        )
    }
}

function mapStateToProps({ auth }){
    const { user } = auth
    const user_id = auth.meta.user.uid

    return { user, user_id }
}

function validate(values){
    let errors = {}
    
    if(values.profile_image && values.profile_image.file){
        const photo = values.profile_image.file
        if(photo.size/1000000 > 10 )
            errors.profile_image = 'Tu imagen es demasiado grande. Límite a 10 MB.'
    
        const allowedExtensions = /(\.svg|\.ssvg|\.jpg|\.jpeg|\.png)$/i
        if(!allowedExtensions.exec(photo.name))
            errors.profile_image = 'Sólo se aceptan archivos PNG, SVG, JPG o JPEG.'
    }
    return errors
}

export default reduxForm({
    form: 'changeImageProfile',
    validate
})(connect(
    mapStateToProps, { changeProfileImage }
)(SmallProfileHeader))