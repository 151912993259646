import { AUTH, USER } from 'constants/index'

const INITIAL_STATE = { 
    meta: null,
    user: null,
    error: '',
    loading: false,
    payment: true,
    role: null
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case AUTH.LOGIN_USER_LOADING:
            return { ...state, loading: true }

        case AUTH.LOGIN_USER_SUCCESS:
            return { 
                ...state, 
                role: action.user.user_type === 1 ? 'artist' : 'client', 
                user: action.user,
                meta: JSON.parse(JSON.stringify(action.meta)),
                loading: false
            }

        case AUTH.LOGOUT_USER:
            return { ...state, user: null, role: null, meta: null }

        case AUTH.LOGIN_USER_FAIL:
            return { ...state, loading: false }

        case AUTH.LOGIN_INCOMPLETE_USER:
            return { ...state, loading: false, meta: action.payload }

        case AUTH.FINISHED_SIGNUP_INCOMPLETE_ARTIST:
            Object.assign(action.payload, {role : 'artist'})
            return { 
                ...state, 
                role: action.payload.role,
                user: action.payload 
            }
        
        case AUTH.FINISHED_SIGNUP_INCOMPLETE_CLIENT:
            Object.assign(action.payload, {role : 'client'})
            return {
                ...state, 
                role: action.payload.role,
                user: action.payload 
            }

        case AUTH.FINISHED_SIGNUP_ARTIST:
            Object.assign(action.payload, {role : 'artist'})
            return {
                ...state,
                role: action.payload.role,
                user: action.payload,
                meta: {user: JSON.parse(JSON.stringify(action.meta))}
            }
        
        case AUTH.FINISHED_SIGNUP_CLIENT:
            Object.assign(action.payload, {role : 'client'})
            return {
                ...state, 
                role: action.payload.role,
                user: action.payload,
                meta: {user: JSON.parse(JSON.stringify(action.meta))}
            }

        case AUTH.FETCH_USER_CARDS:
            Object.assign(state.user, { cards: action.payload })
            return { ...state }

        case USER.FETCH_USER_DATA:
            return { ...state, user: action.payload }

        case AUTH.TEMPORARY_UPDATE:
            return { ...state, user: { ...state.user, ...action.payload }}

        case AUTH.FINISHED_CONVERT_CLIENT_TO_ARTIST:
            return { ...state, user: { ...state, ...action.payload }, role: 'artist'}

        default:
            return state
    }
}