import styled from 'styled-components'

export const MainContainer = styled.div`
    min-height: 100%;
    position: relative;
`

export const Divider = styled.div`
    height: 5rem;
    @media screen and (max-width: 576px){
        height: 10rem;
    }
`