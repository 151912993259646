import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

import validate from './validate'
import { GOOGLE_PLACES_API } from 'constants/index'
import Input from 'components/atoms/inputs/generic_input'
import RoundedButton from 'components/atoms/buttons/solid'
// import iconName from 'components/atoms/images/icono_nombre_apellido.svg'
import iconArtistName from 'components/atoms/images/icono_artista.svg'
// import iconEmail from 'components/atoms/images/icono_correo.svg'
import iconTel from 'components/atoms/images/icono_telefono_formulario.svg'
import iconRFC from 'components/atoms/images/icono_rfc.svg'
import iconPlace from 'components/atoms/images/icono_direccion.svg'

class WizardFormFirstPage extends Component {
  constructor(props){
    super(props)
    this.state = {
        search: '',
        value: '',
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSelectSuggest = this.handleSelectSuggest.bind(this)
  }

  handleInputChange(e) {
    this.setState({search: e.target.value, value: e.target.value})
  }

  handleSelectSuggest(suggest) {
    this.setState({search: "", value: suggest.formatted_address})
    this.props.change('address',this.state.value)
  }

  render(){
    const { handleSubmit } = this.props
    const { search } = this.state

    return (
      <>
        <h2 className='text-primary mt-4'>Quiero ser artista</h2>
        <p>Por favor ingresa tus datos personales.</p>
        <form onSubmit={handleSubmit} className='p-2'>
          {/* <Field
            name="name"
            type="text"
            placeholder="Nombre"
            autocomplete='name'
            icon={iconName}
            component={Input}
            disabled={true}
          />
          <Field
            name="lastName"
            type="text"
            placeholder="Apellidos"
            autocomplete='last-name'
            icon={iconName}
            component={Input}
            disabled={true}
          /> */}
          <Field
            name="artistName"
            type="text"
            placeholder="Nombre artístico*"
            autocomplete='artist-name'
            icon={iconArtistName}
            component={Input}
          />
          <Field
            name="rfc"
            type="text"
            placeholder="RFC/ Número Fiscal o Tributario"
            autocomplete='artist-rfc'
            icon={iconRFC}
            component={Input}
          />
          {/* <div className="py-3"/>
          <Field
            name="email"
            type="email"
            placeholder="Correo electrónico"
            autocomplete='email'
            icon={iconEmail}
            component={Input}
            disabled={true}
          /> */}
          <div className="py-3"/>
          <Field
            name="tel"
            type='number'
            placeholder="Teléfono principal*"
            autocomplete='main-tel'
            icon={iconTel}
            component={Input}
          />
          <Field
            name="telSecondary"
            type='number'
            placeholder="Teléfono secundario"
            autocomplete='secondary-tel'
            icon={iconTel}
            component={Input}
          />
          <div className="py-3"/>

          <ReactGoogleMapLoader
            params={{
              key: GOOGLE_PLACES_API,
              libraries: "places,geocode",
            }}
            render={googleMaps =>
              googleMaps && (
                <div>
                  <ReactGooglePlacesSuggest
                    autocompletionRequest={{input: search}}
                    googleMaps={googleMaps}
                    onSelectSuggest={this.handleSelectSuggest}
                  >
                    <Field
                      name="address"
                      type='text'
                      placeholder="Dirección*"
                      autocomplete='address'
                      icon={iconPlace}
                      component={Input}
                      onChange={this.handleInputChange}
                    />
                  </ReactGooglePlacesSuggest>
                </div>
              )
            }
          />

          <div className="py-1"/>
          <p className='text-gray'>*Campos obligatorios</p>
          <div className="py-3"/>
          
          <div className='d-flex justify-content-center mt-5'>
            <RoundedButton type="submit" text='Siguiente'/>
          </div>
        </form>
      </>
    )
  }
}

function mapStateToProps({ auth }){
  const { user } = auth
  
  const initialValues = {
    'name' : user.name,
    'artistName' : user.artist_name,
    'lastName' : user.last_name,
    'rfc' : user.rfc,
    'email' : user.email,
    'tel' : user.phone,
    'address' : user.address,
  }
  
  return { initialValues }
}

WizardFormFirstPage = reduxForm({
  form: 'completeArtistWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
  validate,
  // enableReinitialize: true
})(WizardFormFirstPage)

WizardFormFirstPage = connect(
  mapStateToProps,
  { change }
)(WizardFormFirstPage)

WizardFormFirstPage.propTypes = {
  googleMaps: PropTypes.object,
}

export default WizardFormFirstPage