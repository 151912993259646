import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm, reset } from 'redux-form'

import { DEFAULT_PROFILE_IMAGE_URL, DEFAULT_PDF_PROFILE_IMG, ENVIRONMENTS } from 'constants/index'
import Badge from 'components/atoms/noti_badge'
import logo from 'components/atoms/images/logo_navbar_fondo_blanco.svg'
import { 
    LogoImage,
    StyledLink,
    InputSearchBtn,
    StyledInput,
    SearchIcon,
    StyledDropdownToggle,
    StyledNavbarBrand
} from './styled'
import {
    Navbar,
    Nav,
    NavItem,
    Form,
    InputGroup,
    Button,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu
} from 'reactstrap'
import * as routes from 'constants/routes'
import { logoutUser } from 'actions/authentication'
import { findServicesWithFilter } from 'actions/services'
import { clearSingleDate } from 'actions/datepicker'
import { getCurrentCountry } from 'helpers/index'

class DesktopNavbarComponent extends Component {
    constructor(props) {
        super(props)
        this.onLogoutClick = this.onLogoutClick.bind(this)
        this.onHomeClick = this.onHomeClick.bind(this)
        this.onServicesClick = this.onServicesClick.bind(this)
        this.onProfileClick = this.onProfileClick.bind(this)
        this.renderBadge = this.renderBadge.bind(this)
        this.onConvertToArtist = this.onConvertToArtist.bind(this)
    }

    onSubmit(values){
        values.country = this.props.user_country
        this.props.findServicesWithFilter(values, () => {
            this.props.reset('SearchForm')
            this.props.clearSingleDate()
            this.props.history.push(routes.SEARCH_RESULTS)
        })
    }

    onHomeClick(){
        this.props.history.push(routes.ROOT)
    }

    onServicesClick(){
        if(this.props.role === 'client')
            this.props.history.push(routes.CLIENT_MY_SERVICES)
        else if(this.props.role === 'artist')
            this.props.history.push(routes.ARTIST_MY_SERVICES)
    }

    onProfileClick(){
        if(this.props.role === 'client')
            this.props.history.push(routes.CLIENT_PROFILE)
        else if(this.props.role === 'artist')
            this.props.history.push(routes.ARTIST_PROFILE)
    }

    renderSearchInput(field){
        return(
            <StyledInput 
                placeholder={field.placeholder}
                type={field.type}
                className='text-dark border-dark rounded'
                {...field.input}
                />
        )
    }

    onLogoutClick(){
        this.props.logoutUser( () => {
            this.props.history.push(routes.LOGIN)
        })
    }

    onConvertToArtist(){
        this.props.history.push(routes.CLIENT_CONVERT_TO_ARTIST)
    }

    renderBadge(){
        if(typeof(this.props.user.reservation_map) !== 'undefined' || typeof(this.props.user.request_map) !== 'undefined')
            return <Badge class="bg-danger"/>
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <div className='d-none d-md-block shadow-lg fixed'>
                <Navbar color={process.env.REACT_APP_STAGE === ENVIRONMENTS.QAS ? 'white' : 'white'} expand='md'>
                    <StyledNavbarBrand onClick={this.onHomeClick}>
                        <LogoImage src={logo} alt='logo muzigzag'/>
                    </StyledNavbarBrand>
                    <Nav className="ml-auto" navbar>
                        <NavItem className='mx-2'>
                            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                <InputGroup>
                                    <Field
                                        placeholder='Buscar servicio'
                                        name='search-query'
                                        type='text'
                                        component={this.renderSearchInput}
                                    />
                                    <InputSearchBtn 
                                        addonType="append">
                                        <Button type='submit' className='bg-warning border-0 p-0'>
                                            <SearchIcon className='align-self-center'/>
                                        </Button>
                                    </InputSearchBtn>
                                </InputGroup>
                            </Form>
                        </NavItem>
                        <NavItem className='mx-2 align-self-center'>
                            <StyledLink to="/categorias" className='text-dark'>Categorías</StyledLink>
                        </NavItem>
                        {this.props.user ? 
                            <>
                                <UncontrolledDropdown>
                                    <StyledDropdownToggle 
                                        className='text-white border-0 ml-4'
                                        image={
                                            this.props.user.profile_image && this.props.user.profile_image !== DEFAULT_PDF_PROFILE_IMG ?
                                                this.props.user.profile_image
                                                :
                                                DEFAULT_PROFILE_IMAGE_URL
                                        }/>
                                    <DropdownMenu right>
                                        <DropdownItem
                                            onClick={() => this.onProfileClick()}>
                                            Mi perfil
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => this.onServicesClick()}>
                                            Servicios {this.renderBadge()}
                                        </DropdownItem>
                                        {
                                            this.props.role === 'client' ?
                                                <DropdownItem
                                                    onClick={() => this.onConvertToArtist()}>
                                                    Quiero ser artista
                                                </DropdownItem>
                                            : ''
                                        }
                                        <DropdownItem
                                            onClick={() => this.onLogoutClick()}>
                                            Salir
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </> :
                            <>
                                <NavItem className='mx-2 align-self-center'>
                                    <StyledLink to="/acceso" className='text-dark'>Ingresar</StyledLink>
                                </NavItem>
                                <NavItem className='mx-2 align-self-center'>
                                    <StyledLink to="/elegir-tipo-registro" className='text-dark'>Crear cuenta</StyledLink>
                                </NavItem>
                            </>
                        }
                        
                    </Nav>
                </Navbar>
            </div>
        )
    }
}

function mapStateToProps({ auth }){
    const { role, user } = auth
    const user_country = getCurrentCountry(user ? user.country : 'MÉXICO')
    return { user, role, user_country }
}

function validate(values){
    let errors = {}
    return errors
}

export default reduxForm({
    validate,
    form: 'NavbarSearchForm',
    destroyOnUnmount: false
})(withRouter(
    connect(
        mapStateToProps,
        { logoutUser, findServicesWithFilter, reset, clearSingleDate }
    )(DesktopNavbarComponent)
))
