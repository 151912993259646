import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { firestoreFieldValue } from 'firebase/index'

import Input from 'components/atoms/inputs/simpliest_input'
import { completeEditServiceStep, selectEditServiceStep, uncheckEditServiceStep } from 'actions/shared'
import { updateService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'
import * as routes from 'constants/routes'

class EditServiceVideo extends Component{
    constructor(props){
        super(props)
        this.validateVideo = this.validateVideo.bind(this)
    }

    componentDidMount(){
        if(isEmpty(this.props.job_service_selected))
            this.props.history.push(routes.ARTIST_JOB_SERVICES)
    }

    validateVideo(video){
        const allowedPlatforms = /youtube\.com|youtu\.be/i
        if(video && !allowedPlatforms.exec(video)){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.video)
            return 'Tu video tiene que ser de Youtube.'
        }
    }

    onSubmit(values){
        let change = {}
        let fieldToDelete = {}

        if(values.video)
            change = { video : values.video }
        else
            fieldToDelete['video'] = firestoreFieldValue.delete()
        
        this.props.updateService({ ...change, ...fieldToDelete }, this.props.job_service_selected.id, () => {
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.video)
            this.props.selectEditServiceStep(EDIT_SERVICE_VIEWS.types)
            this.props.history.push(routes.ARTIST_JOB_SERVICES_EDIT.replace(':id', this.props.job_service_selected.id))
        })
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <div className='container mt-5'>
                <h4 className='text-secondary'>Video</h4>
                <p>Agrega uno de tus mejores videos para mostrarlo a tus clientes. 
                    Sólo puedes agregar enlaces de Youtube.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="video"
                        type="text"
                        placeholder="Agrega un video"
                        validate={this.validateVideo}
                        component={Input}
                    />
                    <div className='my-5 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps({ artist }){
    const { job_service_selected } = artist

    const initialValues = {
        'video' : job_service_selected.video,
    }

    return { job_service_selected, initialValues }
}

EditServiceVideo = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount,
    enableReinitialize: true
  })(EditServiceVideo)
  
  EditServiceVideo = connect(
    mapStateToProps,
    { completeEditServiceStep, selectEditServiceStep, uncheckEditServiceStep, updateService }
  )(EditServiceVideo)
  
  export default withRouter(EditServiceVideo)