import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MainEcosystem from 'components/ecosystems/main'
import './App.scss'

import { listenUserData, stopListenUserData } from 'actions/user'

import { MainContainer, Divider } from './styled'
import Navbar from 'components/organisms/navbar'
import DesktopNavbar from 'components/organisms/navbar_desktop'
import PageLoader from 'components/molecules/page_loader'
import FlashAlert from 'components/molecules/alert'
import Footer from 'components/molecules/footer'

class App extends Component {
    constructor(props){
        super(props)
        this.renderFooter = this.renderFooter.bind(this)
        this.state = {
            user_id : this.props.user ? this.props.user.uid : null
        }
    }

    componentDidMount(){
        if(this.state.user_id)
            this.props.listenUserData(this.state.user_id)
    }

    componentWillUnmount(){
        if(this.state.user_id)
            this.props.stopListenUserData(this.state.user_id)
    }

    static getDerivedStateFromProps(nextProps, prevState){
        //Si se hizo logout
        if(nextProps.user === null && prevState.user_id !== null){
            nextProps.stopListenUserData(prevState.user_id)
            return { user_id: null }
        }
        //Si se hizo login
        else if(nextProps.user && nextProps.user.uid !== prevState.user_id){
            //por si mágicamente se cambia de user sin hacer logout
            if(prevState.user_id !== null)
                nextProps.stopListenUserData(prevState.user_id)
            nextProps.listenUserData(nextProps.user.uid)
            return { user_id: nextProps.user.uid }
        }
        else return null
     }

    renderFooter(){
            const slug = window.location.href
            
        if(
            !slug.includes('servicios/detalle') && 
            !slug.includes('registro/artista') &&
            !slug.includes('registro/cliente') &&
            !slug.includes('cliente/quiero-ser-artista') &&
            !slug.includes('acceso') &&
            !slug.includes('chat'))
            return (
                <>
                    <Divider className='my-5'></Divider>
                    <Footer/>
                </>
            )
    }

    render(){
        return(
            <MainContainer>
                <FlashAlert/>
                <PageLoader/>
                <Navbar/>
                <DesktopNavbar/>
                <MainEcosystem/>
                {this.renderFooter()}
            </MainContainer>
        )
    }
}

function mapStateToProps({ auth }){
    const user = auth.meta ? auth.meta.user : null
    
    return ({ user })
}

//withRouter debe envolver a connect para que App reconozca las rutas y enrutamiento
export default withRouter(
    connect(mapStateToProps, { listenUserData, stopListenUserData } )(App)
)