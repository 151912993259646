import _ from 'lodash'
import axios from 'axios'
import { firestore, storage, auth } from 'firebase/index'
import { ARTISTS, SHARED, SERVICE_STATUS, ALERTS, USER, CLOUD_FUNCTIONS_URL } from 'constants/index'
import { setFirstCharToUpperCase, capitalizeString } from 'helpers/index'

export function fetchArtistJobServices(artist_id){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        
        let servicesArray = []
        firestore.collection("services")
        .where('artist_id', '==', artist_id)
        .get().then( querySnapshot => {
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                servicesArray.push({ ...sid, ...data })
            })
            dispatch({
                type: ARTISTS.FETCH_LIST_SERVICES,
                payload: servicesArray
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch( error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log(dispatch, error)
        })
    }
}

export function fetchArtistPayments(artist_id){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        let paymentsArray = []
        firestore.collection("transactions")
        .where('user_id', '==', artist_id)
        .where('type', '==', 2)
        .get().then( querySnapshot => {
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                paymentsArray.push({ ...sid, ...data })
            })
            dispatch({
                type: ARTISTS.FETCH_LIST_PAYMENTS,
                payload: paymentsArray
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch( error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log(dispatch, error)
        })
    }
}

export function fetchArtistWeekHistory(artist_id, end_date, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        let reservationsArray = []
        firestore.collection('reservations')
        .where('artist_id', '==', artist_id)
        .where('status', '>=', SERVICE_STATUS.rate_pending)
        .get().then(querySnapshot => {
            
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                
                if( data.when <= end_date && data.when >= end_date-604800000 )
                    reservationsArray.push({ ...sid, ...data })
            })
  
            dispatch({
                type: ARTISTS.FETCH_JOBS_DONE,
                payload: reservationsArray,
                end_date
            })
            callback()

            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch(error => {
            console.log('No se logró obtener historial del artista: ', error)
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

firestore.collection('services')
.constructor.prototype
.changeMultipleStatusOf = (servicesArray, active) => {
    return Promise.all(
        _.map(servicesArray, service => {
            return firestore
                    .collection(`services`)
                    .doc(service.id)
                    .update({
                        status: active ? 1 : 2
                    })
        })
    )    
}

export function switchArtistAvailability(artist_id, active){
    return dispatch => {
        firestore.collection('users').doc(artist_id)
        .update({ status: active ? 0 : 2 })
        .then( () => {
            let servicesArray = []
            firestore.collection('services')
            .where('artist_id', '==', artist_id)
            .get().then( querySnapshot => {
                querySnapshot.forEach( doc => {
                    let sid = { id:doc.id }
                    let data = doc.data()
                    servicesArray.push({ ...sid, ...data })
                })
                servicesArray = _.filter(servicesArray, service => {
                    return service.status === 1 || service.status === 2
                })
                firestore.collection('services').changeMultipleStatusOf(servicesArray, active)
                .then(() => {
                    console.log('Se hicieron los cambios en los servicios')
                    firestore.collection('users').doc(artist_id)
                    .get().then(doc => {
                        dispatch({
                            type: USER.FETCH_USER_DATA,
                            payload: doc.data()
                        })
                        dispatch({
                            type: ALERTS.SHOW_SUCCESS_MESSAGE,
                            payload: `Has ${active ? 'activado' : 'desactivado'} tus servicios.`
                        })
                    })
                    .catch(error => {
                        console.log('No se logro obtener actualización de artista')
                    })
                })
                .catch(error => {
                    console.log('Hubo un error al cambiar los estados de los servicios del artista: ', error)
                })
            })
            .catch(error => {
                console.log('No se lograron obtener los servicios del artista', error)
            })
        })
        .catch(error => {
            console.log('Hubo un error al cambiar estado del artista: ', error)
        })
    }
}

function getHoursByWeekday(values, weekday){
    let hours = {}
    values[`hours-for-${weekday}`].forEach(hour => {
        hours[hour.value] = true
    })
    return hours
}

function getAvailability(values){
    let availability = {}
    const weekdays = _.map(values.weekdays, weekday => {
        return weekday.value
    })

    weekdays.forEach(weekday => {
        availability[weekday] = getHoursByWeekday(values, weekday)
    })

    return availability
}

function getZonesPresence(values) {
    let zones = {}
    for (const country of values['countries-presence']) {
      zones[country.value] = []
      
      for (const zone of values[`presence-for-${country.value}`]) {
        zones[country.value].push(zone.value)
      }
    }
    return zones
}

function getCountries(values) {
    let countries = []
    for (const country of values['countries-presence'])
        countries.push(country.value)
    return countries
}

function getIncludes(values){
    return _.map(values.includes, include => {
        return include.value
    })
}

function getTypes(values){
    return _.map(values['event-types'], type => {
        return type.value
    })
}

storage.ref()
.constructor.prototype
.uploadFiles = (files, doc_id) => {
    return Promise.all(
        _.map(files, file => {
            return storage
                    .ref(`/services/${doc_id}/${file.name}-${new Date().getTime()}`)
                    .put(file)
        })
    )    
}

export function createService(values, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        const doc_id = firestore.collection('services').doc().id
        storage.ref().uploadFiles(values.photos.files, doc_id)
        .then(snapshot => {
            return Promise.all(
                _.map(snapshot, image => {
                    return image.ref.getDownloadURL()
                })
            )
            .then(urls => {
                let service = {
                    artist_id: values.artist_id,
                    artist_name: values.artist_name,
                    availability: getAvailability(values),
                    category: values.category,
                    contains: getIncludes(values),
                    countries: getCountries(values),
                    cost: Number(values.cost),
                    cost_extra: Number(values.cost_extra),
                    created_at: new Date().getTime(),
                    currency_code: values.currency_code,
                    description: setFirstCharToUpperCase(values.description),
                    images: urls,
                    rating: 0,
                    status: 0,
                    subCategory: values.serviceSubcategory,
                    title: capitalizeString(values.name),
                    types: getTypes(values),
                    ...( (values.video) && {
                        video: values.video
                    }),
                    zones: getZonesPresence(values),
                }
                firestore.collection('services').doc(doc_id)
                .set(service)
                .then(() => {
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: `Felicidades, has creado un nuevo servicio`
                    })
                    callback()
                })
                .catch(error => {
                    console.log('Existió un error al guardar el servicio en el servidor.', error)
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                })
            })
            .catch(error => {
                console.log('Existió un error al obtener URLs de las imagenes.', error)
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
            
        })
        .catch(error =>{
            console.log('Existió un error al subir las fotos al storage.', error)
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        
    }
}


export function updateService(change, service_id, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        change.updated_at = new Date().getTime()
        change.origin = navigator.appVersion
        change.status = 0
        
        firestore.collection('services').doc(service_id).update(change)
        .then(() => {
            dispatch({
                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                payload: `Cambio realizado con éxito.`
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            callback()
        })
        .catch(error => {
            console.log('Existió un error al actualizar este servicio.', error)
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

export function selectMyServiceJob(service, callback){
    return dispatch => {
        dispatch({
            type: ARTISTS.SELECT_MY_SERVICE_JOB,
            payload: service
        })
        callback()
    }
}

export function fetchJobService(service_id){
    return dispatch => {
        firestore.collection('services').doc(service_id)
        .onSnapshot(doc => {
            if (doc.exists){
                let sid = { id:doc.id }
                let data = doc.data()
                dispatch({
                    type: ARTISTS.SELECT_MY_SERVICE_JOB,
                    payload: { ...sid, ...data }
                })
            }
            else{
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'No existe este servicio, lamentamos los inconvenientes.'
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            }
        })
    }
}

export function deleteServiceImage(service, newPhotos, imageToDelete){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        storage.ref().child(`services/${service}/${imageToDelete}`)
        .delete().then(() => {
            firestore.collection('services').doc(service)
            .update({
                images : newPhotos
            })
            .then(() => {
                dispatch({
                    type: ALERTS.SHOW_SUCCESS_MESSAGE,
                    payload: `Imagen eliminada con éxito.`
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
            .catch(error => {
                console.log('Existió un error al actualizar este servicio.', error)
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
        })
        .catch(error => {
            console.log('Existió un error al actualizar las imágenes de este servicio.', error)
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

export function uploadServicePhotos(service_id, currentImages, newImages, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        storage.ref().uploadFiles(newImages, service_id)
        .then(snapshot => {
            return Promise.all(
                _.map(snapshot, image => {
                    return image.ref.getDownloadURL()
                })
            )
            .then(urls => {
                firestore.collection('services').doc(service_id)
                .update({
                    images : currentImages.concat(urls)
                })
                .then(() => {
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: `Imágenes guardadas con éxito.`
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    callback()
                })
                .catch(error => {
                    console.log('Existió un error al actualizar este servicio.', error)
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                })
            })
            .catch(error => {
                console.log('Existió un error al obtener URLs de las imagenes.', error)
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            }) 
        })
        .catch(error =>{
            console.log('Existió un error al subir las fotos al storage.', error)
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

export function getArtistPromoter(promoter_id, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        firestore.collection('promoters').doc(promoter_id)
        .onSnapshot(doc => {
            if (doc.exists){
                let pid = { id:doc.id }
                let data = doc.data()
                dispatch({
                    type: ARTISTS.FETCH_PROMOTER,
                    payload: { ...pid, ...data }
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                callback()
            }
            else{
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            }
        })
    }
}

export function updateArtistPromoter(change, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        auth.currentUser.getIdToken(true)
        .then(token => {
            const headers = { 
                headers: { 
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            }
            const data = JSON.stringify(change)
            const endpoint = `${CLOUD_FUNCTIONS_URL}/updateArtistPromoter`
            let axios_request = axios.create({
                validateStatus : status => { return status <= 500 }
            })
            axios_request.post(endpoint, data, headers)
            .then(response => {
                if(response.data.success){

                    dispatch({ type: SHARED.PAGE_STOP_LOADING })

                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: `Referido actualizado correctamente.`
                    })

                    callback()
                }
                else {
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: response.data.message
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    
                }

            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                })
                console.log('Error mayor a 500 desde servidor.', error)
            })
        })
        .catch( error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log('No se logró obtener token del usuario. ', error)
        })
    }
}

export function fetchUserHistory(user_id){
    let reservationsArray = []
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        firestore.collection('reservations')
        .where('client_id', '==', user_id)
        .where('status', '>=', SERVICE_STATUS.rate_pending)
        .get().then(querySnapshot => {
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                reservationsArray.push({ ...sid, ...data })
            })
            dispatch({
                type: ARTISTS.FETCH_USER_HISTORY,
                payload: _.orderBy(reservationsArray, ['created_at'], ['desc'])
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch(error => {
            console.log('No se logró obtener historial del usuario: ', error)
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

export function fetchArtistHistory(user_id){
    let reservationsArray = []
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        firestore.collection('reservations')
        .where('artist_id', '==', user_id)
        .where('status', '>=', SERVICE_STATUS.rate_pending)
        .get().then(querySnapshot => {
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                reservationsArray.push({ ...sid, ...data })
            })
            dispatch({
                type: ARTISTS.FETCH_ARTISTS_HISTORY,
                payload: _.orderBy(reservationsArray, ['created_at'], ['desc'])
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch(error => {
            console.log('No se logró obtener historial del usuario: ', error)
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

export function updateArtistServices(artist_id, change_on_services, callback){
    return dispatch => {
        let artistServicesArray = []
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        firestore.collection('services')
        .where('artist_id', '==', artist_id)
        .get().then(querySnapshot => {
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                artistServicesArray.push({ ...sid, ...data })
            })
            firestore.collection('services').changeMultipleServices(artistServicesArray, change_on_services)
            .then(()=>{
                console.log('Se hicieron los cambios en todos los servicios del artista.')
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                callback()
            })
            .catch(error => {
                console.log('No se lograron cambiar los servicios del artista.', error)
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
            
        })
        .catch(error => {
            console.log('No se logró obtener historial del usuario: ', error)
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

firestore.collection('services')
.constructor.prototype
.changeMultipleServices = (servicesArray, change) => {
    return Promise.all(
        _.map(servicesArray, service => {
            return firestore
                    .collection(`services`)
                    .doc(service.id)
                    .update(change)
        })
    )    
}