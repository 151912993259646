export const ROOT = '/'
export const NO_ACCESS = '/sin-permisos'
export const NO_PAYMENT = '/sin-servicio'
export const LOGIN = '/acceso'
export const CHOOSE_REGISTRATION_TYPE = '/elegir-tipo-registro'
export const INCOMPLETE_USER_RECORD = '/completar-registro'
export const INCOMPLETE_USER_RECORD_AS_ARTIST = '/completar-registro/artista'
export const INCOMPLETE_USER_RECORD_AS_CLIENT = '/completar-registro/cliente'
export const USER_SIGNUP_AS_ARTIST = '/registro/artista'
export const USER_SIGNUP_AS_CLIENT = '/registro/cliente'
export const SEARCH_RESULTS = '/resultados-busqueda'
export const CATEGORY_LIST = '/categoria/:id'
export const CATEGORIES_GRID = '/categorias'
export const SHOW_SERVICE = '/servicios/detalle/:slug/:id'
export const BOOKING_FILTER = '/servicios/reservacion/:id'
export const CONFIRM_BOOKING_REQUEST = '/servicios/confirmacion/:id'
export const BOOKING_CARDS_SETTINGS = '/servicios/confirmacion/:id/seleccionar-tarjeta'
export const ADD_HOURS_TO_SERVICE = '/servicios/:id/agregar-horas'
export const ADD_HOURS_CARDS_SETTINGS = '/servicios/:id/agregar-horas/seleccionar-tarjeta'

export const CLIENT_MY_SERVICES = '/cliente/mis-servicios'
export const CLIENT_MY_SERVICE_CHAT = '/cliente/mis-servicios/:id/chat'
export const CLIENT_MY_SERVICE_DETAIL = '/cliente/mis-servicios/:id'
export const CLIENT_PROFILE = '/cliente/perfil'
export const CLIENT_PROFILE_EDIT = '/cliente/perfil/editar'
export const CLIENT_PROFILE_PAYMENT_METHODS = '/cliente/perfil/formas-de-pago'
export const CLIENT_PROFILE_PAYMENT_METHODS_ADD = '/cliente/perfil/formas-de-pago/agregar'
export const CLIENT_PROFILE_HISTORY = '/cliente/perfil/historial'
export const CLIENT_PROFILE_FAVORITES = '/cliente/perfil/favoritos'
export const CLIENT_PROFILE_SAVED = '/cliente/perfil/reservaciones-guardadas'
export const CLIENT_PROFILE_SETTINGS = '/cliente/perfil/ajustes'
export const CLIENT_CONVERT_TO_ARTIST = '/cliente/quiero-ser-artista'

export const ARTIST_REVENUE = '/artista/mis-ganancias'
export const ARTIST_MY_SERVICES = '/artista/mis-servicios'
export const ARTIST_MY_SERVICE_CHAT = '/artista/mis-servicios/:id/chat'
export const ARTIST_MY_SERVICE_DETAIL = '/artista/mis-servicios/:id'
export const ARTIST_PROFILE = '/artista/perfil'
export const ARTIST_PROFILE_EDIT = '/artista/perfil/editar'
export const ARTIST_PROFILE_PAYMENT_METHODS = '/artista/perfil/formas-de-pago'
export const ARTIST_PROFILE_PAYMENT_METHODS_ADD = '/artista/perfil/formas-de-pago/agregar'
export const ARTIST_PROFILE_HISTORY = '/artista/perfil/historial'
export const ARTIST_PROFILE_FAVORITES = '/artista/perfil/favoritos'
export const ARTIST_PROFILE_SAVED = '/artista/perfil/reservaciones-guardadas'
export const ARTIST_PROFILE_SETTINGS = '/artista/perfil/ajustes'
export const ARTIST_PROMOTER = '/artista/perfil/referidos'
export const ARTIST_JOB_SERVICES = '/artista/perfil/mis-servicios'
export const ARTIST_JOB_SERVICES_NEW = '/artista/perfil/mis-servicios/nuevo'
export const ARTIST_JOB_SERVICES_NEW_NAME = '/artista/perfil/mis-servicios/nuevo/nombre'
export const ARTIST_JOB_SERVICES_NEW_DESCRIPTION = '/artista/perfil/mis-servicios/nuevo/descripcion'
export const ARTIST_JOB_SERVICES_NEW_COST = '/artista/perfil/mis-servicios/nuevo/costo'
export const ARTIST_JOB_SERVICES_NEW_ZONES = '/artista/perfil/mis-servicios/nuevo/zonas'
export const ARTIST_JOB_SERVICES_NEW_AVAILABILITY = '/artista/perfil/mis-servicios/nuevo/disponibilidad'
export const ARTIST_JOB_SERVICES_NEW_PHOTOS = '/artista/perfil/mis-servicios/nuevo/fotos'
export const ARTIST_JOB_SERVICES_NEW_VIDEO = '/artista/perfil/mis-servicios/nuevo/video'
export const ARTIST_JOB_SERVICES_NEW_TYPES = '/artista/perfil/mis-servicios/nuevo/tipos-de-evento'
export const ARTIST_JOB_SERVICES_NEW_INCLUDES = '/artista/perfil/mis-servicios/nuevo/que-incluye'
export const ARTIST_JOB_SERVICES_NEW_CATEGORY = '/artista/perfil/mis-servicios/nuevo/categoria'
export const ARTIST_JOB_SERVICES_EDIT = '/artista/perfil/mis-servicios/edicion/:id'
export const ARTIST_JOB_SERVICES_EDIT_NAME = '/artista/perfil/mis-servicios/editar/nombre'
export const ARTIST_JOB_SERVICES_EDIT_DESCRIPTION = '/artista/perfil/mis-servicios/editar/descripcion'
export const ARTIST_JOB_SERVICES_EDIT_COST = '/artista/perfil/mis-servicios/editar/costo'
export const ARTIST_JOB_SERVICES_EDIT_ZONES = '/artista/perfil/mis-servicios/editar/zonas'
export const ARTIST_JOB_SERVICES_EDIT_AVAILABILITY = '/artista/perfil/mis-servicios/editar/disponibilidad'
export const ARTIST_JOB_SERVICES_EDIT_PHOTOS = '/artista/perfil/mis-servicios/editar/fotos'
export const ARTIST_JOB_SERVICES_EDIT_VIDEO = '/artista/perfil/mis-servicios/editar/video'
export const ARTIST_JOB_SERVICES_EDIT_TYPES = '/artista/perfil/mis-servicios/editar/tipos-de-evento'
export const ARTIST_JOB_SERVICES_EDIT_INCLUDES = '/artista/perfil/mis-servicios/editar/que-incluye'
export const ARTIST_JOB_SERVICES_EDIT_CATEGORY = '/artista/perfil/mis-servicios/editar/categoria'
export const ARTIST_MY_PAYMENTS = '/artista/perfil/mis-pagos'
export const ARTIST_MY_WEEK_HISTORY = '/artista/perfil/mis-pagos/historial-semanal'

export const CANCELLATION_POLICIES = '/politicas-cancelacion'
export const PRIVACY_POLICIES = '/politicas-privacidad'