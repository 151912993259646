import _ from 'lodash'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { 
    reduxForm, 
    // Field
} from 'redux-form'
import moment from 'moment'
import scriptjs from 'scriptjs'

import { 
    Form, 
    // FormGroup
 } from 'reactstrap'
import FlashAlert from 'components/molecules/alert'
import InfoModal from 'components/organisms/modal_generic_ok'
import ContinueModal from 'components/organisms/modal_generic_continue'
import * as routes from 'constants/routes'
// import SimpliestInput from 'components/atoms/inputs/simpliest_input'

import IconDate from 'components/atoms/images/icono_dia.svg'
import IconHour from 'components/atoms/images/icono_hora.svg'
import IconAddress from 'components/atoms/images/icono_lugar.svg'
import { 
    ConfirmIconImage, 
    // SpanChangePayment, 
    ArtistImage, 
    RoundedButton, 
    CardBrandLogo,
    // TranslatedFormGroup,
    PaypalButton,
    // OpenpayButton,
    CostInfo
} from './styled'

import { 
    MOMENT_IN_SPANISH, 
    CURRENCY_FORMATTER, 
    // OPENPAY_MERCHANT_ID, 
    // OPENPAY_PUBLIC_API_KEY,
    PAYPAL_CLIENT_ID,
    GATEWAY_CONSTANTS } from 'constants/index'
import { fetchUserCards } from 'actions/authentication'
import { saveService } from 'actions/user'
import { 
    payWithCard, 
    payWithCash, 
    selectPaymentOption, 
    payWithPaypal,
    saveHistoricPaypalOrder } from 'actions/bookings'
import { toFixed, currencyFormatter, getGatewayCommission, getEmailDate, getEmailHour } from 'helpers/index'
let PayPalButton

moment.updateLocale('es', MOMENT_IN_SPANISH )

class BookingConfirm extends Component{
    constructor(props){
        super(props)
        this.state = {
            //openpayLoaded: false,
            paypalLoaded: false,
            amount: this.props.total_cost.toString(),
            isModalOpen: false,
            modalSuccesfullPayment: false
        }
        this.onChangePaymentClick = this.onChangePaymentClick.bind(this)
        this.onSaveServiceClick = this.onSaveServiceClick.bind(this)
        this.renderCardOption = this.renderCardOption.bind(this)
        this.onPaymentOptionSelect = this.onPaymentOptionSelect.bind(this)
        this.setupPaypalPayment = this.setupPaypalPayment.bind(this)
        this.savePaypalOrder = this.savePaypalOrder.bind(this)
        this.renderPaypalButton = this.renderPaypalButton.bind(this)
        this.createOrder = this.createOrder.bind(this)
        this.onApprove = this.onApprove.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onError = this.onError.bind(this)
        this.getCost = this.getCost.bind(this)
        this.toogleInfoModal = this.toogleInfoModal.bind(this)
        this.toogleSuccesfullPayment = this.toogleSuccesfullPayment.bind(this)
        this.onAcceptSuccesfullPayment = this.onAcceptSuccesfullPayment.bind(this)
    }

    componentDidMount(){
        console.log('componentDidMount')
        if(_.isEmpty(this.props.filter_options)){
            this.props.history.push(routes.ROOT)
        }
        else {
            window.scrollTo(0, 0)
            // this.props.fetchUserCards(this.props.user.uid)
            // scriptjs.order([
            //     'https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js',
            //     'https://openpay.s3.amazonaws.com/openpay.v1.min.js',
            //     'https://openpay.s3.amazonaws.com/openpay-data.v1.min.js'], 'bundle')

            // scriptjs.ready('bundle', () => {
            //     this.setState({
            //         openpayLoaded: true
            //     })
            //     window.OpenPay.setId(OPENPAY_MERCHANT_ID)
            //     window.OpenPay.setApiKey(OPENPAY_PUBLIC_API_KEY)
            //     window.OpenPay.setSandboxMode(true)
            // })
            // console.log('componentDidMount', toFixed(this.props.total_cost, 2).toString())
            // this.setState({
            //     transaction: {
            //         amount: {
            //             total: toFixed(this.props.total_cost, 2).toString(),
            //             currency: 'MXN'
            //         }
            //     }
            // })
            //console.log("State: ", this.state)

            scriptjs.order([`https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&intent=capture&currency=${this.props.filter_options.currency_code}&disable-funding=card`], 'bundle')
            scriptjs.ready('bundle', () => {
                
                PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM })
                this.setState({
                    paypalLoaded: true
                })
            })    
        }
    }

    componentWillUnmount(){
        window.location.reload()
    }

    savePaypalOrder(data){
        this.props.saveHistoricPaypalOrder(data.orderID, {
            amount : this.props.total_cost,
            artist_name : this.props.filter_options.artist_name,
            client_name : this.props.filter_options.client_name,
            created_at : new Date().getTime(),
            currency_code : this.props.currency,
            requester_user : this.props.filter_options.client_id,
            service_id : this.props.filter_options.service_id,
            service_name : this.props.filter_options.service_name,
            status : 0
        })
    }

    setupPaypalPayment(payment){
        this.props.payWithPaypal({
            gateway : 'paypal',
            order_id : payment.id,
            client_id : this.props.filter_options.client_id,
            currency_code : this.props.currency,
            artist_id : this.props.filter_options.artist_id,
            service_id : this.props.filter_options.service_id,
            client_name : this.props.filter_options.client_name,
            client_image : this.props.filter_options.client_image,
            artist_name : this.props.filter_options.artist_name,
            artist_image : this.props.filter_options.artist_image,
            service_name : this.props.filter_options.service_name,
            when : this.props.filter_options.when,
            when_date : getEmailDate(this.props.filter_options.when),
            when_hour: getEmailHour(this.props.filter_options.when),
            where : this.props.filter_options.where,
            where_notes : this.props.filter_options.where_notes,
            hours : this.props.filter_options.hours,
            subtotal : this.props.subtotal,
            muzigzag_amount : this.props.muzig_commission,
            gateway_amount : this.props.gateway_commission + this.props.gateway_constant,
            origin : navigator.appVersion,
            service_rating : this.props.filter_options.service_rating
        }, () => {
            
            this.toogleSuccesfullPayment()
            
        })
    }

    onChangePaymentClick(){
        this.props.history.push(routes.BOOKING_CARDS_SETTINGS.replace(':id', this.props.filter_options.service_id))
    }

    onSubmit(values){
        if(values.payment_method === 'card' && _.isEmpty(this.props.default_card))
            this.props.history
            .push(routes.BOOKING_CARDS_SETTINGS
                .replace(':id', this.props.filter_options.service_id)
            )
        else{
            if(values.payment_method === 'card')
                this.props.payWithCard({
                    gateway : 'openpay',
                    client_id : this.props.filter_options.client_id,
                    artist_id : this.props.filter_options.artist_id,
                    service_id : this.props.filter_options.service_id,
                    client_name : this.props.filter_options.client_name,
                    client_image : this.props.filter_options.client_image,
                    artist_name : this.props.filter_options.artist_name,
                    artist_image : this.props.filter_options.artist_image,
                    service_name : this.props.filter_options.service_name,
                    when : this.props.filter_options.when,
                    where : this.props.filter_options.where,
                    where_notes : this.props.filter_options.where_notes,
                    hours : this.props.filter_options.hours,
                    subtotal : this.props.subtotal,
                    muzigzag_amount : this.props.muzig_commission,
                    gateway_amount : this.props.gateway_commission,
                    origin : navigator.appVersion,
                    device_id : window.OpenPay.deviceData.setup(),
                    card_id : this.props.user_info.default_card,
                    service_rating : this.props.filter_options.service_rating
                }, () => {
                    this.props.history.push(routes.ROOT)
                })
            else
                this.props.payWithCash({
                    client_id : this.props.filter_options.client_id,
                    artist_id : this.props.filter_options.artist_id,
                    service_id : this.props.filter_options.service_id,
                    client_name : this.props.filter_options.client_name,
                    client_image : this.props.user_info.profile_image,
                    artist_name : this.props.filter_options.artist_name,
                    artist_image : this.props.filter_options.artist_image,
                    service_name : this.props.filter_options.service_name,
                    when : this.props.filter_options.when,
                    where : this.props.filter_options.where,
                    where_notes : this.props.filter_options.where_notes,
                    hours : this.props.filter_options.hours,
                    subtotal : this.props.subtotal,
                    muzigzag_amount : this.props.muzig_commission,
                    gateway_amount : this.props.gateway_commission,
                    origin : navigator.appVersion,
                    service_rating : this.props.filter_options.service_rating
                }, () => {
                    this.props.history.push(routes.ROOT)
                })
        }
    }

    onSaveServiceClick(){
        this.props.saveService(this.props.user.uid, this.props.filter_options, () => {
            this.props.history.push(routes.ROOT)
        })
    }

    onPaymentOptionSelect(type){
        this.props.selectPaymentOption(type)
    }

    createOrder(data, actions) {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: this.state.amount
                }
            }]
        })
    }

    onApprove(data, actions) {
        this.savePaypalOrder(data)
        return actions.order.get().then(details => {
            this.setupPaypalPayment(details)
        })
    }

    onCancel(data){
        console.log('El usuario ha cancelado la orden: ', data)
    }

    onError(error){
        console.log('Existió un error en la orden.', error)
    }

    getCost(amount){
        if(this.props.filter_options.currency_code)
            return currencyFormatter(this.props.filter_options.currency_code, amount)
        else
            return CURRENCY_FORMATTER.format(amount)
    }

    toogleInfoModal(){
        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
    }

    toogleSuccesfullPayment() {
        if(this.state.modalSuccesfullPayment){
            console.log('El modal de pago exitoso está abierto y debemos enviar al user a sus servicios')
            this.props.history.push(
                this.props.role === 'artist' ? routes.ARTIST_MY_SERVICES : routes.CLIENT_MY_SERVICES
            )
        }
        this.setState({
            modalSuccesfullPayment: !this.state.modalSuccesfullPayment
        })
    }

    onAcceptSuccesfullPayment(){
        this.props.history.push(
            this.props.role === 'artist' ? routes.ARTIST_MY_SERVICES : routes.CLIENT_MY_SERVICES
        )
    }

    renderCardOption(){
        return _.isEmpty(this.props.default_card) ? 
            <span>Seleccionar de mis tarjetas</span>
            :
            <>
                <CardBrandLogo brand={this.props.default_card.brand}/>
                <span className='mx-2'>{this.props.default_card.last4}</span>
            </>
    }

    renderPaypalButton(){
        return (
            <PayPalButton
                createOrder={ (data, actions) => this.createOrder(data, actions) }
                onApprove={ (data, actions) => this.onApprove(data, actions) }
                onCancel={ data => this.onCancel(data) }
                onError={ error => this.onError(error) }
                style={{ color: 'gold', shape: 'pill' }}
            />
        )
    }

    renderSuccesfullPaymentModal(){
        return(
            <ContinueModal
                isOpen={this.state.modalSuccesfullPayment}
                toogle={this.toogleSuccesfullPayment}
                onClickAcceptButton={this.onAcceptSuccesfullPayment}
                modalHeaderText='Pago exitoso'
                modalBodyText={`Se ha creado tu reservación, puedes revisarla en la sección de servicios. Te invitamos a platicar con el artista para que finalices la contratación. No olvides presionar el botón “CONTRATAR”`}
                acceptButtonText='OK'
                acceptButtonColor='primary'
            />
        )
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <>
            {this.renderSuccesfullPaymentModal()}
            <FlashAlert/>
            <div className='d-flex flex-column container mt-5 px-4'>
                <div className='d-flex justify-content-end'>
                    <h5 className='text-gray-muzig'>Confirmación de servicio</h5>
                </div>

                <section className='my-4 row'>
                    <div className='col-12 col-sm-3 col-md-2 p-2 text-center'>
                        <ArtistImage src={this.props.filter_options.artist_image}/>
                    </div>
                    <div className='col-12 col-sm-9 col-md-10 p-2'>
                        <h1 className='text-primary d-none d-sm-block'>{this.props.filter_options.service_name}</h1>
                        <h1 className='text-secondary d-block d-sm-none'>{this.props.filter_options.service_name}</h1>
                        <h4 className='text-gray-muzig'>{this.props.filter_options.artist_name}</h4>
                    </div>
                </section>

                <section className='my-4'>
                    <h4 className='text-primary d-none d-sm-block'>Detalles del servicio</h4>
                    <h4 className='text-gray-muzig d-block d-sm-none'>Detalles del servicio</h4>
                    <div className='row px-4'>
                        <div className='my-3 col-12 col-sm-6 d-flex align-items-center'>
                            <ConfirmIconImage src={IconDate} alt='icono fecha' className='mx-2'/>
                            <span className='text-gray-muzig'>{moment(this.props.filter_options.date, 'DD-MM-YYYY').format('dddd, DD MMM YYYY')}</span>
                        </div>
                        
                        <div className='my-3 col-12 col-sm-6 d-flex align-items-center'>
                            <ConfirmIconImage src={IconHour} alt='icono hora' className='mx-2'/>
                            <span className='text-gray-muzig'>{`${this.props.filter_options.startHour} - ${this.props.filter_options.endHour} hrs.`}</span>
                        </div>
                        <div className='my-3 col-12 d-flex align-items-center'>
                            <ConfirmIconImage src={IconAddress} alt='icono direccion' className='mx-2'/>
                            <span className='text-gray-muzig'>{this.props.filter_options.where}</span>
                        </div>
                    </div>
                </section>

                <Form 
                    onSubmit={handleSubmit(this.onSubmit.bind(this))}
                    className='my-5'>

                    <section className='my-4'>
                        <h4 className='text-primary d-none d-sm-block'>Desglose de pago</h4>
                        <h4 className='text-gray-muzig d-block d-sm-none'>Desglose de pago</h4>
                        <div className='px-4 d-flex'>
                            <div className='flex-fill'>
                                <div className='my-2'>
                                    {/* <div className='d-flex align-items-center'>
                                        { !_.isEmpty(this.props.user_info.cards) ? 
                                            <FormGroup className='mb-0 mr-2 d-flex align-items-center'>
                                                <Field 
                                                    id='card' 
                                                    name="payment_method" 
                                                    component='input' 
                                                    type="radio" 
                                                    value="card" 
                                                    onClick={() => this.onPaymentOptionSelect('card')}/>
                                                <span className='text-gray-muzig mx-2 d-flex align-items-center'>{this.renderCardOption()}</span>
                                            </FormGroup>
                                            :
                                            <FormGroup className='mb-0 mr-2'>
                                                <Field 
                                                    id='card' 
                                                    name="payment_method" 
                                                    component='input' 
                                                    type="radio" 
                                                    value="card" 
                                                    onClick={() => this.onPaymentOptionSelect('card')}/>
                                                <span className='text-gray-muzig mx-2'>Tarjeta</span>
                                            </FormGroup>
                                        }
                                        <SpanChangePayment className='text-primary mx-2' onClick={this.onChangePaymentClick}>Cambiar</SpanChangePayment>
                                    </div>
                                    <TranslatedFormGroup className='mt-2 mr-2'>
                                        <Field 
                                            id='store' 
                                            name="payment_method" 
                                            component={SimpliestInput} 
                                            type="radio" 
                                            value="store" 
                                            text='Pagar en tienda'
                                            onClick={() => this.onPaymentOptionSelect('store')} />
                                    </TranslatedFormGroup> */}
                                    {/* <FormGroup className='mb-0 mr-2'>
                                        <Field id='paypal' name="payment_method" component='input' type="radio" value="paypal" onClick={() => this.onPaymentOptionSelect('paypal')}/>
                                        <span className='text-gray-muzig mx-2'>Paypal</span>
                                    </FormGroup> */}
                                </div>
                            </div>
                            
                        </div>
                    </section>

                    <section className='d-flex my-4'>
                        <div className='flex-grow-1 text-right'>
                            <h5 className='text-gray-muzig mx-2'>Subtotal: </h5>
                            <h5 className='text-gray-muzig mx-2'>
                                <CostInfo 
                                    onClick={ () => this.toogleInfoModal() } 
                                    className='text-secondary'>
                                     ⓘ
                                </CostInfo>
                                {' '}Comisión:
                            </h5>
                            <h4 className='text-gray-muzig mx-2'>Costo total: </h4>
                        </div>
                        <div className='text-left'>
                            <h5 className='text-primary'>{this.getCost(this.props.subtotal)}</h5>
                            <h5 className='text-primary'>{this.getCost(this.props.commissions)}</h5>
                            <h4 className='text-primary'>
                                {this.getCost(this.props.total_cost)}
                            </h4>
                        </div>
                    </section>

                    <PaypalButton payment={this.props.payment_option} className='my-2 text-center'>
                        {/* <div id='paypal-button' className='my-3 text-center'></div> */}
                        {this.state.paypalLoaded ? this.renderPaypalButton() : ''}
                    </PaypalButton>
                    {/* <OpenpayButton payment={this.props.payment_option}>
                        <RoundedButton className='bg-secondary p-2 my-3' block size="lg">
                            Confirmar
                        </RoundedButton>
                    </OpenpayButton> */}

                    {this.props.filter_options.id ?
                        ''
                        :
                        <div className='d-flex justify-content-center'>
                            <RoundedButton 
                                type='button' 
                                color='primary' 
                                block 
                                size="lg" 
                                outline
                                onClick={() => this.onSaveServiceClick()}>
                                Guardar para después
                            </RoundedButton>
                        </div>
                    }
                </Form>
            </div>
            <InfoModal
                toogle={this.toogleInfoModal}
                isOpen={this.state.isModalOpen}
                modalHeaderText='Comisión'
                modalBodyText='Cobramos una comisión una vez que el servicio es confirmado para contribuir al funcionamiento de nuestra plataforma.'
                acceptButtonColor='primary'
                acceptButtonText='Aceptar'
            />
            </>
        )
    }
}

function mapStateToProps({ bookings, services, auth }){
    const { filter_options, payment_option } = bookings
    const { current_service } = services
    const { meta : { user } } = auth
    const user_info = auth.user
    const role = auth.role
    const default_card_id = user_info.default_card
    const default_card = default_card_id ? user_info.cards[default_card_id] : null
    //Comisiones
    const currency = current_service.currency_code ? current_service.currency_code : 'MXN'
    const subtotal = toFixed(filter_options.cost, 2)
    const muzig_commission = toFixed(subtotal * 0.1 * 1.16, 2)
    const gateway_constant = toFixed((subtotal + muzig_commission) * GATEWAY_CONSTANTS.PAYPAL.PLATFORM_CONSTANT, 2)
    const transaction_fix_commision = subtotal + muzig_commission + gateway_constant
    const gateway_commission = toFixed(getGatewayCommission(payment_option, transaction_fix_commision, currency), 2)
    const commissions = muzig_commission + gateway_constant + gateway_commission
    const total_cost = toFixed(subtotal + commissions, 2)
    
    return {
        filter_options, 
        current_service,
        user, 
        user_info, 
        role,
        default_card, 
        subtotal,
        muzig_commission,
        gateway_commission,
        gateway_constant,
        commissions,
        total_cost,
        payment_option,
        currency
    }
}

function validate (values){
    let errors = {}
    if(!values.payment_method)
        errors.payment_method = 'Por favor selecciona tu método de pago'
    return errors
}

export default reduxForm({
    form:'PaymentMethodForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(withRouter(
    connect(
        mapStateToProps,
        { 
            fetchUserCards, 
            saveService, 
            payWithCard, 
            payWithCash, 
            selectPaymentOption, 
            payWithPaypal,
            saveHistoricPaypalOrder
        }
    )(BookingConfirm)
))