import React, { Component } from 'react'

import FlashAlert from 'components/molecules/alert'
import ArtistProfileEditPromoter from 'components/organisms/artist_profile_edit_promoter'

class PromoterEdit extends Component{
    render(){
        return(
            <>
                <FlashAlert/>
                <section className='p-4'>
                    <h4 className='text-secondary'>Referidos</h4>
                    <ArtistProfileEditPromoter/>
                </section>
            </>
        )
    }
}

export default PromoterEdit