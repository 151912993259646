import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import ArtistServicesList from 'components/organisms/artist_job_services'
import { PointerSpan } from './styled'
import * as routes from 'constants/routes'

class ArtistJobServices extends Component{
    constructor(props){
        super(props)
        this.onAddServiceClick = this.onAddServiceClick.bind(this)
    }

    onAddServiceClick(){
        this.props.history.push(routes.ARTIST_JOB_SERVICES_NEW)
    }

    render(){
        return (
            <>
                <div className='d-flex justify-content-end'>
                    <PointerSpan 
                        className='text-primary mb-4'
                        onClick={() => this.onAddServiceClick()}>
                        Agregar servicio
                    </PointerSpan>
                </div>
                <ArtistServicesList/>
            </>
        )
    }
}

export default withRouter(ArtistJobServices)