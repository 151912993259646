import React, { Component } from 'react'
import UserRevenueList from 'components/organisms/user_revenue_list'

class UserRevenue extends Component{
    render(){
        return (
            <>
                <UserRevenueList/>
            </>
        )
    }
}

export default UserRevenue