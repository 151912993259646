import React, { Component } from 'react'
import ArtistPaymentsList from 'components/organisms/artist_payments_list'

class ArtistPayments extends Component{
    render(){
        return (
            <>
                <ArtistPaymentsList/>
            </>
        )
    }
}

export default ArtistPayments