import styled from 'styled-components'
import StagingIcon from 'components/atoms/images/categorias/icono_puesta_en_escena.svg'
import SoloistIcon from 'components/atoms/images/categorias/icono_solista.svg'
import AudioProductionIcon from 'components/atoms/images/categorias/icono_produccion_discografica.svg'
import VideoProductionIcon from 'components/atoms/images/categorias/icono_produccion_audiovisual.svg'
import ClownIcon from 'components/atoms/images/categorias/icono_payaso.svg'
import OrchestraIcon from 'components/atoms/images/categorias/icono_orquesta.svg'
import MusicianIcon from 'components/atoms/images/categorias/icono_musico.svg'
import KaraokeIcon from 'components/atoms/images/categorias/icono_karaoke.svg'
import CircusIcon from 'components/atoms/images/categorias/icono_circense.svg'
import MagicianIcon from 'components/atoms/images/categorias/icono_mago.svg'
import MariachiIcon from 'components/atoms/images/categorias/icono_mariachi.svg'
import ImitatorIcon from 'components/atoms/images/categorias/icono_imitador.svg'
import PhotographyIcon from 'components/atoms/images/categorias/icono_fotografia.svg'
import OthersIcon from 'components/atoms/images/categorias/icono_otros.svg'
import DJIcon from 'components/atoms/images/categorias/icono_dj.svg'
import WorkshopIcon from 'components/atoms/images/categorias/icono_talleres.svg'
import SetIcon from 'components/atoms/images/categorias/icono_conjunto.svg'
import ComedianIcon from 'components/atoms/images/categorias/icono_comediante.svg'
import MusicalGroupIcon from 'components/atoms/images/categorias/icono_banda.svg'
import DancerIcon from 'components/atoms/images/categorias/icono_bailarin.svg'
import EntertainerIcon from 'components/atoms/images/categorias/icono_animador.svg'
import ActorIcon from 'components/atoms/images/categorias/icono_actuacion.svg'
import MakeupIcon from 'components/atoms/images/categorias/icono_maquillaje.svg'
import ImageAdvisorIcon from 'components/atoms/images/categorias/icono_asesor_de_imagen.svg'
import LocutionIcon from 'components/atoms/images/categorias/icono_locucion.svg'
import PlannerIcon from 'components/atoms/images/categorias/icono_planner.svg'


export const StyledDiv = styled.div`
    
  input {
    margin:0;padding:0;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
  }

  input:active + label {opacity: .9;}

  input:checked + label {
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
  }

  label {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box
    width: 10rem;
    height: 10rem;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
       -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
            filter: brightness(1.8) grayscale(1) opacity(.7);
  }

  label:hover{
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
       -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
            filter: brightness(1.2) grayscale(.5) opacity(.9);
  }
  
  .actuacion { background-image: url(${ActorIcon})}
  .agrupaciones { background-image: url(${MusicalGroupIcon})}
  .animadores { background-image: url(${EntertainerIcon})}
  .asesor { background-image: url(${ImageAdvisorIcon})}
  .audiovisual { background-image: url(${VideoProductionIcon})}
  .bailarines { background-image: url(${DancerIcon})}
  .comediantes { background-image: url(${ComedianIcon})}
  .conjuntos { background-image: url(${SetIcon})}
  .talleres { background-image: url(${WorkshopIcon})}
  .djs { background-image: url(${DJIcon})}
  .discografica { background-image: url(${AudioProductionIcon})}
  .fotografica { background-image: url(${PhotographyIcon})}
  .imitadores { background-image: url(${ImitatorIcon})}
  .locucion { background-image: url(${LocutionIcon})}
  .magos { background-image: url(${MagicianIcon})}
  .circense { background-image: url(${CircusIcon})}
  .maquillaje { background-image: url(${MakeupIcon})}
  .mariachi { background-image: url(${MariachiIcon})}
  .karaoke { background-image: url(${KaraokeIcon})}
  .musicos { background-image: url(${MusicianIcon})}
  .orquestas { background-image: url(${OrchestraIcon})}
  .otros { background-image: url(${OthersIcon})}
  .payasos { background-image: url(${ClownIcon})}
  .planner { background-image: url(${PlannerIcon})}
  .puestas { background-image: url(${StagingIcon})}
  .solistas { background-image: url(${SoloistIcon})}
`


export const LabelTextDiv = styled.h4`
  width: 10rem;
`