import React, { Component } from 'react'
import { reduxForm, initialize } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import FlashAlert from 'components/molecules/alert'
import ArtistEditServiceSteps from 'components/organisms/artist_small_edit_service_steps'
import { fetchJobService } from 'actions/artists'

class SmallEditService extends Component{
    componentDidMount(){
        window.scrollTo(0,0)
        const service_id = window.location.href.split('/').pop()
        this.props.fetchJobService(service_id)
    }

    render(){
        return(
            <>
                <FlashAlert/>
                <ArtistEditServiceSteps/>
            </>
        )
    }
}

SmallEditService = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    //forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    //enableReinitialize: true
  })(SmallEditService)
  
SmallEditService = connect(
    null,
    { initialize, fetchJobService }
)(SmallEditService)

export default withRouter(SmallEditService)