import React, { Component } from 'react'
import { connect } from 'react-redux'

import ArtistSideMenuOptions from 'components/organisms/artist_big_sidemenu'
import ArtistProfileCard from 'components/molecules/artist_profile_card'
import { PROFILE_VIEWS } from 'constants/index'
import EditProfile from './edit'
import EditPaymentMethod from './payment_methods'
import UserHistory from './history'
import UserFavorites from './favorites'
import UserSaved from './saved'
import ArtistJobServices from './my_services'
import ArtistPayments from './my_payments'
import ArtistPromoter from './promoter'
import ArtistRevenue from './revenue'
import { BannerSection } from './styled'

class BigProfile extends Component{

    render(){
        return(
            <>
                <BannerSection className='bg-warning'>
                    <ArtistProfileCard/>
                </BannerSection>
                <section className='row no-gutters px-4'>
                    <div className='col-3 d-flex flex-column'>
                        <ArtistSideMenuOptions/>
                    </div>
                    <div className='col-9 p-4'>
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.edit && <EditProfile/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.payment && <EditPaymentMethod/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.history && <UserHistory/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.favorites && <UserFavorites/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.saved && <UserSaved/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.my_services && <ArtistJobServices/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.my_payments && <ArtistPayments/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.promoter && <ArtistPromoter/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.revenue && <ArtistRevenue/>}
                    </div>
                </section>
            </>
        )
    }
}

function mapStateToProps({ shared }){
    const { sidemenuPage } = shared
    return { sidemenuPage }
}

export default connect(
    mapStateToProps,
    {}
)(BigProfile)