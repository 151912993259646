import styled from 'styled-components'

export const CategorySquare = styled.div`
    background-color: rgba(0, 0, 0, 0);
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 15rem;
    position: relative;
    transition: 0.3s;
    width: 18rem;
    border-radius: 10px;
    &:before {
        background: rgba(0,0,0,0.2);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 10px;
    }
    &:hover {
        -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    }
    @media(max-width: 768px){
        height: 12rem;
        width: 12rem;
    }
`

export const Icon = styled.img`
    width:6rem;
    @media(max-width: 768px){
        width: 5rem;
    }
`

export const TitleDiv = styled.div`
    height: 15rem;
    position: relative;
`