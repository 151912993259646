import React from 'react'
import Select from 'react-select'
import { SIGNUP, ARTISTS } from 'constants/index'
import map from 'lodash/map'
import { capitalizeOnlyFirstLetter } from 'helpers/index'

import {
    InputGroup,
    FormGroup
} from 'reactstrap'
import { InvalidFeedback } from './styled'

export default ( field ) => {
    const { meta: {error, touched} } = field
    const colorClass = touched && error ? 'danger' : 'primary'
    const isInvalid = touched && error ? true : false

    let capitalizedOptions = map(field.options, option => {
        return { ...option, label: capitalizeOnlyFirstLetter(option.label) }
    })

    return(
        <FormGroup>
            <InputGroup className={`border-bottom border-${colorClass}`}>
                <Select 
                    className='w-100'
                    name={field.input.name}
                    options={capitalizedOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    placeholder={field.placeholder}
                    onFocus={field.input.onFocus}
                    value={field.input.value}
                    noOptionsMessage={() => 'Sin más opciones'}
                    onChange={(value) => {
                            field.input.onChange(value)
                            if (field.selectType === SIGNUP.SELECT_TYPE_COUNTRIES
                                || field.selectType === ARTISTS.SELECT_TYPE_WEEKDAYS)
                                field.updateValue(value)
                        }
                    }
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                />
            </InputGroup>
            <InvalidFeedback className='mx-3 text-danger'>{ isInvalid ? error : ''}</InvalidFeedback>
        </FormGroup>
    )
}