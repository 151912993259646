import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
    } from 'reactstrap'

class ModalGenericOk extends Component{
    
    render(){
        
        return(
            <Modal
                isOpen={this.props.isOpen} 
                toggle={ () => this.props.toogle() }
            >
                
                <ModalHeader toggle={ () => this.props.toogle() }>
                    {this.props.modalHeaderText}
                </ModalHeader>
                <ModalBody>
                    {this.props.modalBodyText}
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color={this.props.acceptButtonColor}
                        onClick={ () => this.props.toogle() }>
                        {this.props.acceptButtonText}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
  
export default connect(null, {  })(ModalGenericOk)