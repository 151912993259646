import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import validate from './validate'
import Input from 'components/atoms/inputs/generic_input'
import RoundedButton from 'components/atoms/buttons/solid'
import iconName from 'components/atoms/images/icono_nombre_apellido.svg'
import iconArtistName from 'components/atoms/images/icono_artista.svg'
import iconEmail from 'components/atoms/images/icono_correo.svg'
import iconTel from 'components/atoms/images/icono_telefono_formulario.svg'
import iconRFC from 'components/atoms/images/icono_rfc.svg'

class WizardFormFirstPage extends Component {

  render(){
    const { handleSubmit } = this.props

    return (
      <>
        <h2 className='text-primary mt-4'>Bienvenido a Muzigzag</h2>
        <p>Por favor ingresa tus datos personales.</p>
        <form onSubmit={handleSubmit} className='p-2'>
          <Field
            name="name"
            type="text"
            placeholder="Nombre*"
            autocomplete='name'
            icon={iconName}
            component={Input}
          />
          <Field
            name="lastName"
            type="text"
            placeholder="Apellidos*"
            autocomplete='last-name'
            icon={iconName}
            component={Input}
          />
          <Field
            name="artistName"
            type="text"
            placeholder="Nombre artístico*"
            autocomplete='artist-name'
            icon={iconArtistName}
            component={Input}
          />
          <Field
            name="rfc"
            type="text"
            placeholder="RFC/ Número Fiscal o Tributario"
            autocomplete='artist-rfc'
            icon={iconRFC}
            component={Input}
          />
          <div className="py-3"/>
          <Field
            name="email"
            type="email"
            placeholder="Correo electrónico*"
            autocomplete='email'
            icon={iconEmail}
            component={Input}
            disabled={this.props.initialValues.email ? true : false}
          />
          <div className="py-3"/>
          <Field
            name="tel"
            type='number'
            placeholder="Teléfono principal*"
            autocomplete='main-tel'
            icon={iconTel}
            component={Input}
          />
          <Field
            name="telSecondary"
            type='number'
            placeholder="Teléfono secundario"
            autocomplete='secondary-tel'
            icon={iconTel}
            component={Input}
          />

          <div className="py-1"/>
          <p className='text-gray'>*Campos obligatorios</p>
          <div className="py-3"/>
          
          <div className='d-flex justify-content-center mt-5'>
            <RoundedButton type="submit" text='Siguiente'/>
          </div>
        </form>
      </>
    )
  }
}

function mapStateToProps({ auth }){
  const { meta } = auth
  
  const initialValues = {
    'name' : meta.user.displayName,
    'email' : meta.user.email,
    'tel' : meta.user.phoneNumber || ''
  }
  
  return { initialValues }
}

WizardFormFirstPage = reduxForm({
  form: 'completeArtistWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(WizardFormFirstPage)

WizardFormFirstPage = connect(
  mapStateToProps,
  { }
)(WizardFormFirstPage)

WizardFormFirstPage.propTypes = {
  googleMaps: PropTypes.object,
}

export default WizardFormFirstPage