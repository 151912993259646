import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import { changeProfileImage } from 'actions/user'

import { ClientImg, ImageForm } from './styled'
import FileInput from 'components/atoms/inputs/change_image_input'
import { DEFAULT_PROFILE_IMAGE_URL, DEFAULT_PDF_PROFILE_IMG } from 'constants/index'

class SmallProfileHeader extends Component{
    onSubmit(values){
        if(!isEmpty(values.profile_image))
            this.props.changeProfileImage(this.props.user_id, values.profile_image)
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <section className='row bg-primary p-4 no-gutters p-2 d-flex align-items-center'>
                <div className='col-12 col-sm-4 text-center'>
                    <ClientImg 
                        alt=''
                        src={
                            this.props.user.profile_image && this.props.user.profile_image !== DEFAULT_PDF_PROFILE_IMG ?
                                this.props.user.profile_image
                                :
                                DEFAULT_PROFILE_IMAGE_URL
                        }/>
                    <ImageForm onChange={() => setTimeout(handleSubmit(params => this.onSubmit(params)))}>
                        <Field
                            name="profile_image"
                            type='file'
                            label=""
                            component={FileInput}
                            
                        />
                    </ImageForm>
                </div>
                <div className='col-12 col-sm-8 text-white text-center p-2'>
                    <h5>{`${this.props.user.name} ${this.props.user.last_name}`}</h5>
                    <h4>{`${this.props.user.rating}%`}</h4>
                    <h6>Calificación</h6>
                </div>
            </section>
        )
    }
}

function validate(values){
    let errors = {}
    
    if(values.profile_image && values.profile_image.file){
        const photo = values.profile_image.file
        if(photo.size/1000000 > 10 )
            errors.profile_image = 'Tu imagen es demasiado grande. Límite a 10 MB.'
    
        const allowedExtensions = /(\.svg|\.ssvg|\.jpg|\.jpeg|\.png)$/i
        if(!allowedExtensions.exec(photo.name))
            errors.profile_image = 'Sólo se aceptan archivos PNG, SVG, JPG o JPEG.'
    }
    return errors
}

function mapStateToProps({ auth }){
    const { user } = auth
    const user_id = auth.meta.user.uid

    return { user, user_id }
}

export default reduxForm({
    form: 'changeImageProfile',
    validate
})(connect(
    mapStateToProps, { changeProfileImage }
)(SmallProfileHeader))