import React from 'react'

import {
    InputGroup,
    InputGroupAddon,
    FormGroup
} from 'reactstrap'
import { MdLock } from 'react-icons/md'
import { StyledInput, InvalidFeedback } from './styled'

export default ( field ) => {
    const { meta: {error, touched} } = field
    const isInvalid = touched && error ? true : false
    const colorClass = touched && error ? 'gray' : 'gray'

    return(
        <FormGroup>
            <InputGroup className={`border p-1 border-${colorClass} rounded`}>
                <StyledInput
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    invalid={isInvalid}
                    autoComplete={field.autocomplete}
                    {...field.input}
                />
            </InputGroup>
            <InvalidFeedback className='mx-3 text-danger'>{ isInvalid ? error : ''}</InvalidFeedback>
        </FormGroup>
    )
}