import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import FlashAlert from 'components/molecules/alert'
import HomeBanner from 'components/molecules/home_banner'
import HomeSearch from 'components/organisms/home_search_field'
import HomeCategoryCard from 'components/molecules/home_category_card'
import HomeServiceCard from 'components/molecules/home_service_card'
import RateServiceModal from 'components/organisms/modal_rate_service'

import MusicalCover from 'components/atoms/images/home_musical_cover.jpg'
import PerformanceCover from 'components/atoms/images/home_performance_cover.jpg'
import ProductionCover from 'components/atoms/images/home_production_cover.jpg'
import MusicalIcon from 'components/atoms/images/icono_musical.svg'
import PerformanceIcon from 'components/atoms/images/icono_performance.svg'
import ProductionIcon from 'components/atoms/images/icono_produccion.svg'

import { fetchHomeServices, selectService } from 'actions/services'
import { rateService, rateClient, fetchPendingRates } from 'actions/user'
import * as routes from 'constants/routes'
import { CATEGORIES } from 'constants/index'
import { PointerSpan, HorizontalScroll } from './styled'
import { getCurrentCountry } from 'helpers'

class Home extends Component{
    constructor(props){
        super(props)
        this.state = {
            modalRateService: true
        }
        this.renderServices = this.renderServices.bind(this)
        this.onCategoryCardClick = this.onCategoryCardClick.bind(this)
        this.onServiceCardClick = this.onServiceCardClick.bind(this)
        this.onViewmoreClick = this.onViewmoreClick.bind(this)
        this.toogleRateServiceModal = this.toogleRateServiceModal.bind(this)
        this.onAcceptRateService = this.onAcceptRateService.bind(this)
        this.renderRateModal = this.renderRateModal.bind(this)
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        this.props.fetchHomeServices(this.props.user_country)
        if(this.props.current_user && this.props.current_user.pending_rates){
            this.props.fetchPendingRates(this.props.current_user.pending_rates, this.props.current_user_id)
        }
    }

    onCategoryCardClick(type){
        this.props.history.push(routes.CATEGORY_LIST.replace(':id', type.url))
    }

    onServiceCardClick(service){
        this.props.selectService(service, () => {
            const URL_TOKENS = { ':id':service.id, ':slug':service.slug }
            const formated_route = routes.SHOW_SERVICE.replace(/:id|:slug/gi, matched => URL_TOKENS[matched])
            this.props.history.push(formated_route)
        })
    }

    onViewmoreClick(type){
        this.props.history.push(routes.CATEGORY_LIST.replace(':id', type))
    }

    toogleRateServiceModal() {
        this.setState({
            modalRateService: !this.state.modalRateService
        })
    }

    onAcceptRateService(values){
        let rate = {}
        rate.comment = values.comment
        rate.created_at = new Date().getTime()
        rate.origin = navigator.appVersion
        rate.recommend = values.rateValue === 'like' ? true : false
        rate.user_id = this.props.current_user_id
        
        //Si yo soy el artista, califico al cliente
        if(this.props.my_pending_rates[this.props.my_pending_rates.length - 1].artist_id === this.props.current_user_id){
            const client_id = this.props.my_pending_rates[this.props.my_pending_rates.length - 1].client_id
            this.props.rateClient(rate, client_id, this.props.current_user.pending_rates, () => {
                this.props.history.push(routes.ROOT)
            })
        }
        //Si no, entonces calificamos el servicio
        else {
            const service_id = this.props.my_pending_rates[this.props.my_pending_rates.length - 1].service_id
            rate.client_name = this.props.my_pending_rates[this.props.my_pending_rates.length - 1].client_name
            this.props.rateService(rate, service_id, this.props.current_user.pending_rates, () => {
                this.props.history.push(routes.ROOT)
            })
        }
    }

    renderServices(services){
        return _.map( services, service => {
            return (
                <HomeServiceCard 
                    key={service.id}
                    service={service}
                    onServiceClick={this.onServiceCardClick}
                />
            )
        })
    }

    renderRateModal(){
        if(this.props.my_pending_rates.length > 0){
            const pending_reservation = this.props.my_pending_rates[this.props.my_pending_rates.length - 1]
            
            return (
                <RateServiceModal
                    isOpen={this.state.modalRateService}
                    toogle={this.toogleRateServiceModal}
                    onClickAcceptButton={this.onAcceptRateService}
                    modalHeaderText={this.props.my_pending_rates[this.props.my_pending_rates.length - 1].artist_id === this.props.current_user_id ? `¿Recomiendas al cliente '${pending_reservation.client_name}'?`: `¿Recomiendas el servicio '${pending_reservation.service_name}'?`}
                    modalBodyText='Agregar comentario'
                />
            )
        }
    }

    render(){
        return(
            <div className='d-flex flex-column'>
                <FlashAlert/>
                <HomeBanner/>

                <section className='text-center py-5 py-md-0'>
                    <HomeSearch />
                </section>

                <section className='container my-4'>
                    <h2 className='text-secondary mb-4'>¿Qué estás buscando{this.props.current_user ? `, ${this.props.current_user.name}` : ''}?</h2>
                    <HorizontalScroll className='d-flex w-100 justify-content-md-around'>
                        <div className='d-flex justify-content-center m-2'>
                            <HomeCategoryCard 
                                cover={MusicalCover}
                                icon={MusicalIcon}
                                category={CATEGORIES.musical}
                                onCategoryCardClick={this.onCategoryCardClick}
                            />
                        </div>
                        <div className='d-flex justify-content-center m-2'>
                            <HomeCategoryCard 
                                cover={PerformanceCover}
                                icon={PerformanceIcon}
                                category={CATEGORIES.performance}
                                onCategoryCardClick={this.onCategoryCardClick}
                            />
                        </div>
                        <div className='d-flex justify-content-center m-2'>
                            <HomeCategoryCard 
                                cover={ProductionCover}
                                icon={ProductionIcon}
                                category={CATEGORIES.produccion}
                                onCategoryCardClick={this.onCategoryCardClick}
                            />
                        </div>
                    </HorizontalScroll>
                </section>

                <section className='container my-4'>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                        <h2 className='text-secondary m-0'>Musical { this.props.current_user ? `en ${this.props.user_country}` : ''}</h2>
                        <PointerSpan 
                            className='text-gray-muzig'
                            onClick={ () => this.onViewmoreClick('musical')}>
                            Más
                        </PointerSpan>
                    </div>
                    <HorizontalScroll className='d-flex w-100 h-25'>
                        {this.renderServices(this.props.home_musical)}
                    </HorizontalScroll>
                </section>

                <section className='container my-4'>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h2 className='text-secondary m-0'>Performance { this.props.current_user ? `en ${this.props.user_country}` : ''}</h2>
                        <PointerSpan 
                            className='text-gray-muzig'
                            onClick={ () => this.onViewmoreClick('performance')}>
                            Más
                        </PointerSpan>
                    </div>
                    <HorizontalScroll className='d-flex w-100'>
                        {this.renderServices(this.props.home_performance)}
                    </HorizontalScroll>
                </section>

                <section className='container my-4'>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h2 className='text-secondary m-0'>Producción { this.props.current_user ? `en ${this.props.user_country}` : ''}</h2>
                        <PointerSpan 
                            className='text-gray-muzig'
                            onClick={ () => this.onViewmoreClick('produccion')}>
                            Más
                        </PointerSpan>
                    </div>
                    <HorizontalScroll className='d-flex w-100'>
                        {this.renderServices(this.props.home_production)}
                    </HorizontalScroll>
                </section>

                {this.renderRateModal()}

            </div>
        )
    }
}

function mapStateToProps({ auth, services, user }){
    const current_user = auth.user
    const current_user_id = auth.meta ? auth.meta.user.uid : null
    const { home_musical, home_performance, home_production } = services
    const { my_pending_rates } = user
    const user_country = getCurrentCountry(current_user ? current_user.country : 'MÉXICO')
    
    
    return { 
        current_user, 
        home_musical, 
        home_performance, 
        home_production, 
        my_pending_rates, 
        current_user_id,
        user_country
    }
}

export default withRouter(
    connect(
        mapStateToProps, { fetchHomeServices, selectService, fetchPendingRates, rateService, rateClient }
        )(Home)
    )