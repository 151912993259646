import React, { Component } from 'react'
import UserHistoryList from 'components/organisms/user_history_list'

class History extends Component{
    render(){
        return(
            <section className='p-4'>
                <h4 className='text-secondary'>Historial</h4>
                <UserHistoryList/>
            </section>
        )
    }
}

export default History