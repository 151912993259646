import React, { Component } from 'react'
import { Card, Icon } from './styled'


export default class HomeCategoryCard extends Component{

    render(){
        
        return(
            <Card 
                img={this.props.cover} 
                className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'
                onClick={() => this.props.onCategoryCardClick(this.props.category)}>
                <Icon src={this.props.icon}/>
                <div className='w-100'></div>
                <h3 className='text-white'>{this.props.category.text}</h3>
            </Card>
        )
    }
}