import _ from 'lodash'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Collapse } from 'reactstrap'
import moment from 'moment'

import { MOMENT_IN_SPANISH, DEFAULT_PDF_PROFILE_IMG, DEFAULT_PROFILE_IMAGE_URL } from 'constants/index'
import { fetchRequestedServices, fetchBookedServices, selectMyService, deleteNewServices } from 'actions/user'
import { HeadCollapsable, ServiceDiv, UserImage, IconImage, EmptyImg, BottomDiv } from './styled'
import IconDate from 'components/atoms/images/icono_dia.svg'
import IconHour from 'components/atoms/images/icono_hora.svg'
import IconAddress from 'components/atoms/images/icono_lugar.svg'
import IconNoRequests from 'components/atoms/images/vacios/sin_solicitudes.svg'
import IconNoReservations from 'components/atoms/images/vacios/sin_agendados.svg'
import Badge from 'components/atoms/noti_badge'

import * as routes from 'constants/routes'

class CollapseList extends Component {
    constructor(props) {
        super(props)
        this.toggleBooked = this.toggleBooked.bind(this)
        this.toggleRequests = this.toggleRequests.bind(this)
        this.renderServices = this.renderServices.bind(this)
        this.onServiceClick = this.onServiceClick.bind(this)
        this.renderRequestsBadge = this.renderRequestsBadge.bind(this)
        this.renderReservationsBadge = this.renderReservationsBadge.bind(this)
        this.state = { 
            collapseBooked: false,
            collapseRequests: false
        }
    }

    toggleBooked() {
        this.setState({ collapseBooked: !this.state.collapseBooked })
        if(typeof(this.props.reservation_map) !== 'undefined')
            this.props.deleteNewServices(this.props.user_info.uid, 'reservations')
    }

    toggleRequests() {
        this.setState({ collapseRequests: !this.state.collapseRequests })
        if(typeof(this.props.request_map) !== 'undefined')
            this.props.deleteNewServices(this.props.user_info.uid, 'requests')
    }

    componentDidMount(){
        this.props.fetchRequestedServices(this.props.user_info.uid, 'artist')
        this.props.fetchBookedServices(this.props.user_info.uid, 'artist')
    }

    onServiceClick(service){
        this.props.selectMyService(service)
        if(service.accepted)
            this.props.history.push(routes.ARTIST_MY_SERVICE_CHAT.replace(':id',service.id))
        else
            this.props.history.push(routes.ARTIST_MY_SERVICE_DETAIL.replace(':id',service.id))
    }

    renderServices(services){
        moment.updateLocale('es', MOMENT_IN_SPANISH)
        return(
            _.map(services, service => {
                return (
                    <ServiceDiv 
                        key={service.id}
                        className='row my-4 shadow no-gutters d-flex pt-2'
                        onClick={() => this.onServiceClick(service)}
                    >
                        <div className='col-12 col-sm-3 text-center'>
                            {this.props.user_info.uid === service.client_id ? 
                                <UserImage src={
                                    service.artist_image && service.artist_image !== DEFAULT_PDF_PROFILE_IMG ?
                                        service.artist_image
                                        :
                                        DEFAULT_PROFILE_IMAGE_URL
                                } alt=''/>
                            :
                                <UserImage src={
                                    service.client_image && service.client_image !== DEFAULT_PDF_PROFILE_IMG ?
                                        service.client_image
                                        :
                                        DEFAULT_PROFILE_IMAGE_URL
                                } alt=''/>
                            }
                        </div>
                        <div className='col-12 col-sm-9 p-2'>
                            <h4>{service.service_name}</h4>
                            <h6 className='text-gray-muzig'>{service.artist_name}</h6>
                            <div className='row py-2 no-gutters'>
                                <div className='col-12 col-sm-8 d-flex align-items-center my-1'>
                                    <IconImage src={IconDate}/>
                                    <span className='mx-2'>{moment(service.when).format('dddd, DD MMM YYYY')}</span>
                                </div>
                                <div className='col-12 col-sm-4 d-flex align-items-center my-1'>
                                    <IconImage src={IconHour}/>
                                    <span className='mx-2'>{moment(service.when).format('HH:mm')}</span>
                                </div>
                                <div className='col-12 d-flex align-items-center my-1'>
                                    <IconImage src={IconAddress}/>
                                    <span className='mx-2'>{service.where}</span>
                                </div>
                            </div>

                        </div>
                        {this.props.user_info.uid === service.client_id &&
                                <BottomDiv className='col-12 bg-success text-white d-flex align-items-center justify-content-end px-2'>
                                    Mi servicio {service.type === 'request' ? `solicitado` : `reservado`}
                                </BottomDiv>
                        } 
                    </ServiceDiv>
                )
            })
        )
    }

    renderEmptyServices(type){
        if(type === 'requests')
            return (
                <div className='text-center mt-5'>
                    <EmptyImg src={IconNoRequests} alt='' />
                    <h2 className='text-gray-muzig my-4'>Sin solicitudes</h2>
                </div>
            )
        else if(type === 'reservations')
                return (
                    <div className='text-center mt-5'>
                        <EmptyImg src={IconNoReservations} alt='' />
                        <h2 className='text-gray-muzig my-4'>Sin reservaciones</h2>
                    </div>
                )
    }

    renderRequestsBadge(){
        if(typeof(this.props.request_map) !== 'undefined')
            return <Badge class="bg-danger"/>
    }

    renderReservationsBadge(){
        if(typeof(this.props.reservation_map) !== 'undefined')
            return <Badge class="bg-danger"/>
    }

    render() {
        return (
            <div className='d-flex flex-column'>
                <div>
                    <HeadCollapsable onClick={() => this.toggleRequests()} className='my-2 py-2 px-4 bg-secondary w-100 text-white'>
                        Solicitudes {this.renderRequestsBadge()}
                    </HeadCollapsable>
                    <Collapse isOpen={this.state.collapseRequests}>
                        {
                            this.props.requested_services.length === 0 ? 
                                this.renderEmptyServices('requests')
                                :
                                this.renderServices(this.props.requested_services)
                        }
                    </Collapse>
                </div>

                <div>
                <div onClick={() => this.toggleBooked()} className='my-2 py-2 px-4 bg-secondary w-100 text-white'>
                    Reservados {this.renderReservationsBadge()}
                </div>
                    <Collapse isOpen={this.state.collapseBooked}>
                        {
                            this.props.booked_services.length === 0 ?
                                this.renderEmptyServices('reservations')
                                :
                                this.renderServices(this.props.booked_services)
                        }
                    </Collapse>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ auth, user }){
    const user_info = auth.meta.user
    const { requested_services, booked_services } = user
    const request_map = auth.user.request_map
    const reservation_map = auth.user.reservation_map
    
    return {  user_info, requested_services, booked_services, request_map, reservation_map }
}

export default withRouter(
    connect(
        mapStateToProps,
        { fetchRequestedServices, fetchBookedServices, selectMyService, deleteNewServices }
    )(CollapseList)
)