import styled from 'styled-components'

export const PointerP = styled.p`
    cursor: pointer;
`

export const BottomFooter = styled.div`
    background-color: #384A66;
    bottom: 0;
    transform: translateY(100px);
    position: absolute;
    z-index: 1;
`

export const LogoImage = styled.img`
    width: 15rem;
`

export const CopyrightP = styled.p`
    opacity: 0.43;
`

export const SocialIcon = styled.img`
    cursor: pointer;
    width: 2rem;
`