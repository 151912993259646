import React, { Component } from 'react'
import { connect } from 'react-redux'

import ClientSideMenuOptions from 'components/organisms/client_big_sidemenu'
import UserProfileCard from 'components/molecules/user_profile_card'
import { PROFILE_VIEWS } from 'constants/index'
import EditProfile from './edit'
import EditPaymentMethod from './payment_methods'
import UserHistory from './history'
import UserFavorites from './favorites'
import UserSaved from './saved'
import { BannerSection } from './styled'

class BigProfile extends Component{

    render(){
        return(
            <>
                <BannerSection className='bg-warning'>
                    <UserProfileCard/>
                </BannerSection>
                Hola
                <section className='row no-gutters p-4'>
                    <div className='col-3 d-flex flex-column'>
                        <ClientSideMenuOptions/>
                    </div>
                    <div className='col-9 p-4'>
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.edit && <EditProfile/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.payment && <EditPaymentMethod/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.history && <UserHistory/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.favorites && <UserFavorites/>}
                        {this.props.sidemenuPage ===  PROFILE_VIEWS.saved && <UserSaved/>}
                    </div>
                </section>
            </>
        )
    }
}

function mapStateToProps({ shared }){
    const { sidemenuPage } = shared
    return { sidemenuPage }
}

export default connect(
    mapStateToProps,
    {}
)(BigProfile)