import _ from 'lodash'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'

import * as routes from 'constants/routes'
import { NEW_SERVICE_VIEWS } from 'constants/index'
import { PointerDiv, OptionIconImg } from './styled'
import IncompleteIcon from 'components/atoms/images/icono_incompleto.svg'
import CompleteIcon from 'components/atoms/images/icono_completo.svg'
import { createService } from 'actions/artists'
import Button from 'components/atoms/buttons/squared_solid'

class SmallNewServiceSteps extends Component{
    constructor(props){
        super(props)
        this.onOptionClick = this.onOptionClick.bind(this)
        this.shouldFormBeInvalid = this.shouldFormBeInvalid.bind(this)
    }

    onOptionClick(route){
        this.props.history.push(route)
    }

    onSubmit(values){
        values.artist_id = this.props.user.uid
        values.artist_name = this.props.user_info.artist_name
        values.category = this.props.user_info.artist_type
        this.props.createService(values, () => {
            this.props.reset('createNewServiceWizard')
            this.props.history.push(routes.ARTIST_JOB_SERVICES)
        })
    }

    shouldFormBeInvalid(){
        const incompleteViews = _.filter(NEW_SERVICE_VIEWS, view => {
            return !this.props.newServiceStepCompleted[view]
        })

        if(incompleteViews.length > 0)
            return true
        else{
            return false
        }
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <section className='d-flex flex-column p-4 text-gray-muzig'>
                <h2 className='text-secondary'>Nuevo servicio</h2>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_NAME)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.name] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Título</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_DESCRIPTION)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.description] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Descripción</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_COST)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.cost] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Costo</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_ZONES)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.zones] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Zonas</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_AVAILABILITY)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.availability] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Disponibilidad</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_PHOTOS)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.photos] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Fotos</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_VIDEO)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.video] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Video</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_TYPES)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.types] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Tipos de evento</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_INCLUDES)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.includes] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Qué incluye</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_NEW_CATEGORY)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.newServiceStepCompleted[NEW_SERVICE_VIEWS.category] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Categoría</div>
                </PointerDiv>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='my-5'>
                    <Button
                        is_disabled={this.shouldFormBeInvalid()}
                        type='submit' 
                        color='secondary' 
                        text='Finalizar'/>
                </form>
            </section>
        )
    }
}

function mapStateToProps({ shared, auth }){
    const { newServiceStep, newServiceStepCompleted } = shared
    const { meta: { user }} = auth
    const user_info = auth.user
    return { newServiceStep, newServiceStepCompleted, user, user_info }
}

SmallNewServiceSteps = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(SmallNewServiceSteps)
  
  SmallNewServiceSteps = connect(
    mapStateToProps,
    { createService, reset }
  )(withRouter(SmallNewServiceSteps))

export default SmallNewServiceSteps