import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm, change, untouch, initialize } from 'redux-form'
import moment from 'moment'
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

import { Form, FormGroup } from 'reactstrap'
import { RoundedButton } from './styled'

import DatePicker from 'components/organisms/date_picker'
import FlashAlert from 'components/molecules/alert'
import InputSelect from 'components/atoms/inputs/generic_option_select'
import Input from 'components/atoms/inputs/generic_input'
import InputTextArea from 'components/atoms/inputs/generic_text_area'
import iconPlace from 'components/atoms/images/icono_direccion.svg'

import * as routes from 'constants/routes'
import { DATEPICKER, GOOGLE_PLACES_API, MOMENT_IN_SPANISH, DEFAULT_PDF_PROFILE_IMG } from 'constants/index'
import { pushToBlockedWeekDays, resetBlockedWeekDays } from 'actions/datepicker'
import { 
    fetchBookingDatesOfService, 
    setDurationLimitAt,
    saveFilterOptions,
    generateHoursForDay } from 'actions/bookings'
import { fetchArtistImage } from 'actions/services'
import { getFormattedHourForOptionSelect } from 'helpers/index'
moment.updateLocale('es', MOMENT_IN_SPANISH )

class BookingFilter extends Component{
    constructor(props){
        super(props)
        this.state = {
            search: '',
            value: '',
            first_time: true,
            service_id: window.location.href.split('/').pop()
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectSuggest = this.handleSelectSuggest.bind(this)
        this.onHourChange = this.onHourChange.bind(this)
        this.validateHour = this.validateHour.bind(this)
        this.validateDuration = this.validateDuration.bind(this)
        this.validateAddress = this.validateAddress.bind(this)
        this.onHourPreselected = this.onHourPreselected.bind(this)
    }

    componentDidMount(){
        if(_.isEmpty(this.props.current_service)){
            const URL_TOKENS = { ':id':this.state.service_id, ':slug':'info' }
            const formated_route = routes.SHOW_SERVICE.replace(/:id|:slug/gi, matched => URL_TOKENS[matched])
            this.props.history.push(formated_route)
        }
            
        else {
            window.scrollTo(0, 0)
            this.props.fetchArtistImage(this.props.current_service.artist_id)
            this.props.fetchBookingDatesOfService(this.props.current_service.id, () => {
                DATEPICKER.WEEK_DAYS.forEach(day => {
                    if(!(day in this.props.current_service.availability))//Hacemos capitalize al day
                        this.props.pushToBlockedWeekDays(day.charAt(0).toUpperCase() + day.slice(1))
                })
            })

            if(this.props.singleDate){
                console.log('this.props.singleDate', this.props.singleDate)
                this.props.generateHoursForDay(this.props.singleDate, this.props.current_service.availability, () => {
                    if(this.props.searchForm && this.props.searchForm.values.hour){
                        const initialHour = { 'hour' : getFormattedHourForOptionSelect(this.props.searchForm.values.hour) }
                        this.props.initialize({ ...initialHour, ...this.props.initialValues })
                    }
                })
            }
            
        }
    }

    componentWillUnmount(){
        this.props.resetBlockedWeekDays()
        this.setState()
    }

    handleInputChange(e) {
        this.setState({search: e.target.value, value: e.target.value})
    }

    handleSelectSuggest(suggest) {
        this.setState({search: "", value: suggest.formatted_address})
        this.props.change('address',this.state.value)
    }

    onHourChange(event){
        const last_hour = Number(this.props.hours_options[this.props.hours_options.length-1].id)
        this.props.setDurationLimitAt(event.target.value, last_hour)
        this.props.change('BookingFilterForm', 'duration', null)
        this.props.untouch('BookingFilterForm', 'duration')
        this.props.change('SearchForm', 'hour', null)
        this.props.untouch('SearchForm', 'hour')
    }

    onHourPreselected(){
        //Sólo si es la primera vez, generamos las horas a partir del dato del filtro del modal
        //De lo contrario no hacemos caso y dejamos el resultado de onHourChange
        if(this.state.first_time && this.props.searchForm && this.props.searchForm.values.hour){
            const last_hour = Number(this.props.hours_options[this.props.hours_options.length-1].id)
            this.props.setDurationLimitAt(this.props.searchForm.values.hour, last_hour)
            this.props.change('SearchForm', 'duration', null)
            this.props.untouch('SearchForm', 'duration')
            this.setState({ first_time: false })
        }
    }

    validateHour(hour){
        if(!hour)
            return 'Selecciona la hora de inicio del servicio.'
    }

    validateDuration(duration){
        if(!duration)
            return 'Selecciona la duración del servicio.'
    }

    validateAddress(address){
        if(!address){
            return 'Escribe la dirección donde será el evento.'
        }
    }

    onSubmit(singleDate, values){
        let fields = {}
        
        fields['accepted'] = false
        fields['artist_id'] = this.props.current_service.artist_id
        fields['artist_image'] = this.props.current_service.artist_image || DEFAULT_PDF_PROFILE_IMG
        fields['artist_name'] = this.props.current_service.artist_name
        fields['client_id'] = this.props.meta.user.uid
        fields['client_image'] = this.props.user.profile_image || DEFAULT_PDF_PROFILE_IMG
        fields['client_name'] = this.props.user.name
        fields['cost'] = Number(values.duration) * this.props.current_service.cost
        fields['created_at'] = fields['updated_at'] = new Date().getTime()
        fields['currency_code'] = this.props.current_service.currency_code
        fields['hours'] = Number(values.duration)
        fields['origin'] = navigator.appVersion
        fields['service_rating'] = this.props.current_service.rating
        fields['service_id'] = this.props.current_service.id
        fields['service_name'] = this.props.current_service.title
        fields['weekday'] = this.props.singleDate.format('dddd').toLowerCase()
        if (singleDate)
            fields['date'] = moment(singleDate).format('DD/MM/YYYY')
        fields['startHour'] = values['hour']
        fields['where'] = values.address
        fields['where_notes'] = values.indication || null

        this.props.saveFilterOptions(fields, () => {
            this.props.history.push(
                routes.CONFIRM_BOOKING_REQUEST
                .replace(':id',this.props.current_service.id)
            )
        })
        
    }

    render(){
        const { handleSubmit } = this.props
        const { search } = this.state
        return(
            <div className='d-flex flex-column container mt-5'>
                <FlashAlert/>
                
                <h4 className='text-secondary'>Datos de tu reservación</h4>
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this, this.props.singleDate))}>
                    
                    <FormGroup>
                        <div className='my-4'>
                            <h5 className='text-secondary'>¿Cuándo?</h5>
                            <DatePicker/>
                        </div>
                    </FormGroup>
                    <FormGroup className='d-flex flex-column flex-sm-row'>
                        <div className='my-4 flex-fill mr-sm-4'>
                            <h5 className='text-secondary'>Hora de inicio</h5>
                            <Field
                                name="hour"
                                type="select"
                                className="border border-gray-muzig border-2 rounded p-1 w-100"
                                component={InputSelect}
                                options={this.props.hours_options}
                                onChange={this.onHourChange}
                                validate={this.validateHour}
                            />
                        </div>
                        <div className='my-4 flex-fill'>
                            <h5 className='text-secondary'>Duración (horas)</h5>
                            {
                                !this.state.first_time &&
                                this.props.hours_options.length > 0 && 
                                this.props.BookingFilterForm.values &&
                                this.props.BookingFilterForm.values.hour &&
                                this.props.hours_duration.length === 0 ?
                                    <h6>No hay disponibilidad</h6>
                                :
                                    <Field
                                        name="duration"
                                        type="select"
                                        className="border-primary border-top-0 border-right-0 border-left-0"
                                        component={InputSelect}
                                        onFocus={ () => this.onHourPreselected()}
                                        options={this.props.hours_duration}
                                        validate={this.validateDuration}
                                    />
                            }
                            
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <ReactGoogleMapLoader
                            params={{
                                key: GOOGLE_PLACES_API,
                                libraries: 'places,geocode',
                            }}
                            render={googleMaps =>
                                googleMaps && (
                                    <div>
                                        <h5 className='text-secondary'>Dirección</h5>
                                        <ReactGooglePlacesSuggest
                                            autocompletionRequest={{input: search}}
                                            googleMaps={googleMaps}
                                            onSelectSuggest={this.handleSelectSuggest}
                                        >
                                            <Field
                                                name="address"
                                                type='text'
                                                placeholder="Calle Wallaby 42, Sidney"
                                                autocomplete='address'
                                                icon={iconPlace}
                                                component={Input}
                                                onChange={this.handleInputChange}
                                                validate={this.validateAddress}
                                            />
                                        </ReactGooglePlacesSuggest>
                                    </div>
                                )
                                
                            }
                        />
                    </FormGroup>
                    <div className='w-100 my-5'></div>
                    <FormGroup>
                        <div className='my-4'>
                            <h5 className='text-secondary'>Indicaciones</h5>
                            <Field
                                name="indication"
                                type="textarea"
                                className="border border-gray-muzig border-2 rounded p-1 w-100"
                                rows={3}
                                component={InputTextArea}
                            />
                        </div>
                    </FormGroup>
                    <div className='w-100 my-5'></div>
                    <FormGroup className='d-flex justify-content-center'>
                        <RoundedButton 
                            className='my-5 text-white'
                            type="submit" 
                            color="warning"
                            size="lg" block
                        >
                            Continuar
                        </RoundedButton>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

function mapStateToProps({ services, datepicker, bookings, auth, form }){
    const { current_service } = services
    const { blockedDays, blockedWeekDays, singleDate } = datepicker
    const { current_service_bookings, hours_options, blockedHoursByDay, hours_duration } = bookings
    const { meta, user } = auth
    const initialValues = { 'address' : user.address }
    const searchForm = form.SearchForm || null
    const BookingFilterForm = form.BookingFilterForm || null
    
    return { 
        current_service, 
        blockedDays, blockedWeekDays, singleDate,
        current_service_bookings, hours_options, blockedHoursByDay, hours_duration,
        meta, user,
        initialValues,
        searchForm,
        BookingFilterForm }
}

BookingFilter.propTypes = {
    googleMaps: PropTypes.object,
}

BookingFilter = reduxForm({
    form:'BookingFilterForm',
    destroyOnUnmount: false,
})(BookingFilter)

BookingFilter = connect(
    mapStateToProps, 
    { 
        pushToBlockedWeekDays, 
        resetBlockedWeekDays, 
        fetchBookingDatesOfService,
        setDurationLimitAt,
        saveFilterOptions,
        change, untouch,
        fetchArtistImage,
        generateHoursForDay,
        initialize
    }
)(withRouter(BookingFilter))

export default BookingFilter