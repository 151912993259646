import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import Authentication from './authentication'
import DatePicker from './datepicker'
import Shared from './shared'
import Services from './services'
import Bookings from './bookings'
import User from './user'
import Artists from './artists'

export default combineReducers({
    form: formReducer,
    auth: Authentication,
    datepicker: DatePicker,
    shared: Shared,
    services: Services,
    bookings: Bookings,
    user: User,
    artist: Artists
})