import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import Input from 'components/atoms/inputs/simpliest_text_area'
import { completeEditServiceStep, selectEditServiceStep, uncheckEditServiceStep } from 'actions/shared'
import { updateService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'
import * as routes from 'constants/routes'
import { setFirstCharToUpperCase } from 'helpers/index'

class EditServiceDescription extends Component{
    constructor(props){
        super(props)
        this.validateDescription = this.validateDescription.bind(this)
    }

    componentDidMount(){
        if(isEmpty(this.props.job_service_selected))
            this.props.history.push(routes.ARTIST_JOB_SERVICES)
    }

    validateDescription(description){
        if(!description){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.description)
            return 'Debes definir una descripción para tu servicio'
        }
    }

    onSubmit(values){
        const change = { description : setFirstCharToUpperCase(values.description) }
        this.props.updateService(change, this.props.job_service_selected.id, () => {
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.description)
            this.props.selectEditServiceStep(EDIT_SERVICE_VIEWS.cost)
            this.props.history.push(routes.ARTIST_JOB_SERVICES_EDIT.replace(':id', this.props.job_service_selected.id))
        })
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <div className='container mt-5'>
                <h4 className='text-secondary'>Descripción</h4>
                <p>Es este espacio describe a detalle tu servicio, trayectoria, lo que incluye, cuántos artistas son y duración del servicio. Recuerda que entre más específica sea la descripción de tu servicio más oportunidades de contratación tendrás.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="description"
                        type="textarea"
                        placeholder="Agrega una descripción"
                        rows={10}
                        validate={this.validateDescription}
                        component={Input}
                    />
                    <div className='my-5 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps({ artist }){
    const { job_service_selected } = artist
    const initialValues = {
        'description' : job_service_selected.description
    }
    return { job_service_selected, initialValues }
}

EditServiceDescription = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true
  })(EditServiceDescription)
  
  EditServiceDescription = connect(
    mapStateToProps,
    { completeEditServiceStep, selectEditServiceStep, uncheckEditServiceStep, updateService }
  )(EditServiceDescription)
  
  export default withRouter(EditServiceDescription)