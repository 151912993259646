import map from 'lodash/map'
import React, { Component } from 'react'
import  { connect } from 'react-redux'
import moment from 'moment'

import { fetchUserHistory } from 'actions/user'
import { EmptyImg } from './styled'
import IconNoHistory from 'components/atoms/images/vacios/sin_historial.svg'
import { MOMENT_IN_SPANISH, RESERVATION_STATUS, CURRENCY_FORMATTER } from 'constants/index'

class UserHistoryList extends Component{
    constructor(props){
        super(props)
        this.renderHistoryList = this.renderHistoryList.bind(this)
        this.renderEmptyHistory = this.renderEmptyHistory.bind(this)
        this.renderAmount = this.renderAmount.bind(this)
    }

    componentDidMount(){
        this.props.fetchUserHistory(this.props.user_id)
    }

    renderAmount(reservation){
        if(reservation.status >= RESERVATION_STATUS.client_canceled.id)
            if(reservation.status === RESERVATION_STATUS.client_canceled.id)
                return <h4>{CURRENCY_FORMATTER.format(reservation.fee)}</h4>
            else
                return <h4 className='text-gray-muzig'>$0.00</h4>
        
        else
            return <h4>{CURRENCY_FORMATTER.format(reservation.cost)}</h4>
    }

    renderHistoryList(){
        moment.updateLocale('es', MOMENT_IN_SPANISH)
        return (
            map(this.props.history, reservation => {
                return (
                    <div className='row no-gutters border-bottom border-gray-muzig py-4' key={reservation.id}>
                        <div className='col-12 col-sm-9 mb-2 mb-sm-0'>
                            <h5>{reservation.service_name}</h5>
                            <span className='text-gray-muzig'>Reservación {RESERVATION_STATUS[reservation.status]}</span>
                        </div>
                        <div className='col-12 col-sm-3 d-flex flex-column align-items-end'>
                            {this.renderAmount(reservation)}
                            <h6 className='text-gray-muzig'>
                                {moment(reservation.when).format('DD MMM YYYY')}
                            </h6>
                        </div>
                    </div>
                )
            })
        )
    }

    renderEmptyHistory(){
        return (
            <div className='text-center mt-5'>
                <EmptyImg src={IconNoHistory} alt='' />
                <h2 className='text-gray-muzig my-4'>Sin historial</h2>
            </div>
        )
    }

    render(){
        return(
            <>
                {
                    this.props.history.length > 0 ? 
                        this.renderHistoryList()
                        :
                        this.renderEmptyHistory()
                }
            </>
        )
    }
}

function mapStateToProps({ auth, user }){
    const user_id = auth.meta.user.uid
    const { history } = user
    
    return { user_id, history }
}

export default connect(
    mapStateToProps,
    { fetchUserHistory }
)(UserHistoryList)