/*
HELPERS
--loginEmailUserSuccess -> Logueado exitásamente con email

--loginUserFail -> Falló login con email

--loginFacebookUserSuccess -> Logueado exitósamente con facebook; el usuario existe en firestore

--completeClientRecord -> Se encontró auth de usuario, pero no se ha registrado en firestore como user.

ACTIONS
--loginWithEmail -> Se intenta login con email

--logoutUser -> Se desloguea al usuario

--resetPassword -> El usuario quiere recuperar su contraseña.

--loginWithFacebook -> Se intenta login con facebook. Si es la primera vez, es decir, 
si no hay registro en firestore, forzamos registro mándando al user a formulario de registro.

--registerIncompleteArtist -> Registramos a un artista
*/
import axios from 'axios'
import { auth, firestore, FacebookAuthProvider, storage } from 'firebase/index'
import { 
    AUTH, 
    ALERTS, 
    SHARED, 
    CLOUD_FUNCTIONS_URL, 
    USER, 
    FIREBASE_ERRORS
} from 'constants/index'
import * as routes from 'constants/routes'


/**********HELPERS**********/
const loginEmailUserSuccess = (dispatch, meta, user, callback) => {
    dispatch({
      type: AUTH.LOGIN_USER_SUCCESS,
      meta,
      user
    })
    callback(routes.ROOT)
}
  
const loginUserFail = (dispatch, error, alert_message) => {
    dispatch({ type: AUTH.LOGIN_USER_FAIL })
    dispatch({
        type: ALERTS.SHOW_ERROR_MESSAGE,
        payload: alert_message || error.message
    })
    console.log(error)
}

const loginFacebookUserSuccess = (dispatch, meta, user, callback) => {
    dispatch({
      type: AUTH.LOGIN_USER_SUCCESS,
      meta,
      user
    })
    callback(routes.ROOT)
}

const completeClientRecord = (dispatch, meta, callback) => {
    dispatch({
        type: AUTH.LOGIN_INCOMPLETE_USER,
        payload: meta
    })
    callback(routes.INCOMPLETE_USER_RECORD)
}

/**********ACTIONS**********/
export function loginWithEmail({ email, password }, callback){
    return (dispatch) => {
        dispatch({ type: AUTH.LOGIN_USER_LOADING })

        auth.signInWithEmailAndPassword(email,password)
        .then( result => {
            firestore.collection("users").doc(result.user.uid)
            .get().then( doc => {
                if (doc.exists)
                    loginEmailUserSuccess(dispatch, result, doc.data(), callback)
                else{
                    dispatch({ type: AUTH.LOGIN_USER_FAIL })
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Favor de completar tu registro.'
                    })
                    callback(routes.CHOOSE_REGISTRATION_TYPE)
                }
            })
            .catch( error => {
                dispatch({ type: AUTH.LOGIN_USER_FAIL })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                })
                console.log(error)
            })
        })
        .catch( error => {
            loginUserFail(dispatch, error, FIREBASE_ERRORS.USER_LOGIN[error.code])
        })
    }
}

export function logoutUser(callback){
    return (dispatch) => {
        auth.signOut()
        .then(function(){
            dispatch({ type: AUTH.LOGOUT_USER })
            dispatch({ type: USER.CLEAN_CURRENT_DATA })
            callback()
        })
    }
}


export function resetPassword(email){
    return dispatch => {
        auth.fetchSignInMethodsForEmail(email)
        .then(providers => {
            if(providers.includes("facebook.com")){
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Este correo corresponde a una cuenta de Facebook. Inicia sesión con Facebook.'
                })
            }
            else{
                auth.sendPasswordResetEmail(email)
                .then( () => {
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Hemos enviado un correo a tu cuenta.'
                    })
                })
                .catch(error => {
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: error.message
                    })
                })
            }
        })
        .catch(error => {
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: error.message
            })
        })
        
    }
}

export function loginWithFacebook(callback){
    return dispatch => {
        dispatch({ type: AUTH.LOGIN_USER_LOADING })

        auth.signInWithPopup(FacebookAuthProvider)
        .then( result => {
            firestore.collection("users").doc(result.user.uid)
            .get().then( doc => {
                dispatch({ type: AUTH.LOGIN_USER_FAIL })
                if (doc.exists)
                    loginFacebookUserSuccess(dispatch, result, doc.data(), callback)
                else
                    completeClientRecord(dispatch, result, callback)
            })
            .catch( error => {
                dispatch({ type: AUTH.LOGIN_USER_FAIL })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                })
                console.log(error)
            } )
        })
        .catch( error => {
            dispatch({ type: AUTH.LOGIN_USER_FAIL })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: FIREBASE_ERRORS.USER_LOGIN[error.code] || 'Ha ocurrido un error con tu autenticación, por favor inténtalo nuevamente.'
            })
            console.log(error)
        })
    }
}

export function registerIncompleteArtist(fields, uid, callback){
    return dispatch => {

        dispatch({ type: SHARED.PAGE_IS_LOADING })

        auth.currentUser.updateProfile({
            displayName: 'artista'
        })
        .then(() => {
            storage.ref(`/ids/${fields['card_id_file'].file.name}-${new Date().getTime()}`)
            .put(fields['card_id_file'].file)
            .then( snapshot => {
                snapshot.ref.getDownloadURL()
                .then(url => {
                    const idImage = { url, path: snapshot.metadata.fullPath }
                    const endpoint = `${CLOUD_FUNCTIONS_URL}/registerArtistNoGateway`
                    auth.currentUser.getIdToken(true)
                    .then(token => {
                        const headers = { 
                            headers: { 
                                'Authorization':`Bearer ${token}`,
                                'Content-Type':'application/json'
                            }
                        }
                        const data = JSON.stringify({
                            user_id: uid,
                            address : fields.address,
                            artist_name : fields.artist_name,
                            artist_type : fields.artist_type,
                            country : fields.country,
                            id_card : idImage,
                            zones : fields.zones,
                            email : fields.email,
                            last_name : fields.last_name,
                            name : fields.name,
                            paypal_account_email : fields.paypal_email,
                            phone : fields.phone,
                            phone2 : fields.phone2,
                            profile_image : fields.profile_image,
                            promoter : fields.promoter,
                            rfc : fields.rfc,
                            last_login: new Date().getTime(),
                            rating: 0
                        })
                        
                        let axios_request = axios.create({
                            validateStatus : status => { return status <= 500 }
                        })
                        axios_request.post(endpoint, data, headers)
                        .then(response => {
                            if(response.data.success){
                                Object.assign(fields, {card_id : idImage})
                                delete fields['card_id_file']
                                dispatch({ 
                                    type: AUTH.FINISHED_SIGNUP_INCOMPLETE_ARTIST,
                                    payload: fields
                                })

                                dispatch({ type: SHARED.PAGE_STOP_LOADING })

                                dispatch({
                                    type: ALERTS.SHOW_SUCCESS_MESSAGE,
                                    payload: `Bienvenido ${fields.artist_name}`
                                })

                                callback()
                            }
                            else {
                                dispatch({
                                    type: ALERTS.SHOW_ERROR_MESSAGE,
                                    payload: response.data.message
                                })
                                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                                
                            }

                        })
                        .catch(error => {
                            dispatch({ type: SHARED.PAGE_STOP_LOADING })
                            dispatch({
                                type: ALERTS.SHOW_ERROR_MESSAGE,
                                payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                            })
                            console.log('Error mayor a 500 desde servidor.', error)
                        })

                    })
                    .catch( error => {
                        dispatch({ type: SHARED.PAGE_STOP_LOADING })
                        console.log('No se logró obtener token del usuario. ', error)
                    })

                })
                .catch(error => {
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    console.log('No se ha logrado obtener la URL del archivo de la identificación oficial. ', error)
                })
            })    
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                console.log('Falló la subida del archivo de la identificación oficial.', error)
            })
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log('Falló la actualización de displayName.', error)
        })
    }
}

export function registerIncompleteClient(fields, uid, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        auth.currentUser.updateProfile({
            displayName: 'cliente'
        })
        .then(() => {
            firestore.collection('users').doc(uid)
            .set({
                country : fields.country,
                email : fields.email,
                last_name : fields.last_name,
                name : fields.name,
                phone : fields.phone,
                profile_image : fields.profile_image,
                rfc : fields.rfc,
                status : fields.status,
                user_type : fields.user_type,
                rating: 0
            })
            .then( () => {
                dispatch({ 
                    type: AUTH.FINISHED_SIGNUP_INCOMPLETE_CLIENT,
                    payload: fields
                })

                const endpoint = `${CLOUD_FUNCTIONS_URL}/send_welcome_user`
                auth.currentUser.getIdToken(true)
                .then(token => {
                    const headers = { 
                        headers: { 
                            'Authorization':`Bearer ${token}`,
                            'Content-Type':'application/json'
                        }
                    }
                    const data = JSON.stringify({ client_id: uid })
                    let axios_request = axios.create({
                        validateStatus : status => { return status <= 500 }
                    })
                    axios_request.post(endpoint, data, headers)
                    .then(response => {
                        if(response.data.success){
                            dispatch({ type: SHARED.PAGE_STOP_LOADING })
                            dispatch({
                                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                                payload: `Bienvenido ${fields.name}`
                            })
                            callback()
                        }
                        else {
                            dispatch({
                                type: ALERTS.SHOW_ERROR_MESSAGE,
                                payload: response.data.message
                            })
                            dispatch({ type: SHARED.PAGE_STOP_LOADING })
                        }

                    })
                    .catch( error => {
                        dispatch({ type: SHARED.PAGE_STOP_LOADING })
                        dispatch({
                            type: ALERTS.SHOW_ERROR_MESSAGE,
                            payload: 'ERROR 06: Ha ocurrido un error en el envío del correo de tu bienvenida.'
                        })
                        console.log('No se logró enviar el correo de bienvenida al nuevo cliente. ', error)
                    })
                })
            })
            .catch( error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'ERROR 01: Ha ocurrido un error con tu registro, por favor inténtalo nuevamente.'
                })
                console.log('No se logró registrar al nuevo usuario. ', error)
            })
        })
        .catch( error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'ERROR 02: Ha ocurrido un error con tu registro, por favor inténtalo nuevamente.'
            })
            console.log('No se logró definir displayName. ', error)
        })
    }
}

export function registerNewArtist(fields, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        
        auth.createUserWithEmailAndPassword(fields.email, fields.password)
        .then(result => {
            result.user.updateProfile({
                displayName: 'artista'
            })
            .then( () => {
                storage.ref(`/ids/${fields['card_id_file'].file.name}-${new Date().getTime()}`)
                .put(fields['card_id_file'].file)
                .then( snapshot => {
                    snapshot.ref.getDownloadURL()
                    .then(url => {
                        const idImage = { url, path: snapshot.metadata.fullPath }
                        const endpoint = `${CLOUD_FUNCTIONS_URL}/registerArtistNoGateway`
                        auth.currentUser.getIdToken(true)
                        .then(token => {
                            const headers = { 
                                headers: { 
                                    'Authorization':`Bearer ${token}`,
                                    'Content-Type':'application/json'
                                }
                            }
                            const data = JSON.stringify({
                                user_id: result.user.uid,
                                address : fields.address,
                                artist_name : fields.artist_name,
                                artist_type : fields.artist_type,
                                country : fields.country,
                                id_card : idImage,
                                zones : fields.zones,
                                email : fields.email,
                                last_name : fields.last_name,
                                name : fields.name,
                                paypal_account_email : fields.paypal_email,
                                phone : fields.phone,
                                phone2 : fields.phone2,
                                promoter : fields.promoter,
                                rfc : fields.rfc,
                                last_login: new Date().getTime(),
                                rating: 0
                            })
                            
                            let axios_request = axios.create({
                                validateStatus : status => { return status <= 500 }
                            })
                            axios_request.post(endpoint, data, headers)
                            .then(response => {
                                if(response.data.success){
                                    Object.assign(fields, {
                                        card_id : idImage, 
                                        paypal_account_email: fields.paypal_email
                                    })
                                    delete fields['card_id_file']
                                    delete fields['password']
                                    delete fields['paypal_email']
                                    dispatch({ 
                                        type: AUTH.FINISHED_SIGNUP_ARTIST,
                                        payload: fields,
                                        meta: result.user
                                    })
        
                                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
        
                                    dispatch({
                                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                                        payload: `Bienvenido ${fields.artist_name}`
                                    })
        
                                    callback()
                                }
                                else {
                                    dispatch({
                                        type: ALERTS.SHOW_ERROR_MESSAGE,
                                        payload: response.data.message
                                    })
                                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                                    
                                }

                            })
                            .catch(error => {
                                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                                dispatch({
                                    type: ALERTS.SHOW_ERROR_MESSAGE,
                                    payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                                })
                                console.log('Error mayor a 500 desde servidor.', error)
                            })
                        })
                        .catch( error => {
                            dispatch({ type: SHARED.PAGE_STOP_LOADING })
                            console.log('No se logró obtener token del usuario. ', error)
                        })
                    })
                    .catch(error => {
                        dispatch({ type: SHARED.PAGE_STOP_LOADING })
                        console.log('No se ha logrado obtener la URL del archivo de la identificación oficial. ', error)
                    })
                })
                .catch(error => {
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    console.log('Falló la subida del archivo de la identificación oficial.', error)
                })
            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                console.log('Falló la definición de displayName', error)
            })
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: FIREBASE_ERRORS.CREATE_USER_EMAIL[error.code]
            })
            console.log('No se ha podido registrar correo y contraseña.', error)
        })
    }
}

export function registerNewClient(fields, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        auth.createUserWithEmailAndPassword(fields.email, fields.password)
        .then(result => {
            result.user.updateProfile({
                displayName: 'cliente'
            })
            .then(() => {
                firestore.collection('users').doc(result.user.uid)
                .set({
                    country : fields.country,
                    email : fields.email,
                    last_name : fields.last_name,
                    name : fields.name,
                    phone : fields.phone,
                    rfc : fields.rfc,
                    status : fields.status,
                    user_type : fields.user_type,
                    rating: 0
                })
                .then( () => {
                    delete fields['password']
                    dispatch({ 
                        type: AUTH.FINISHED_SIGNUP_CLIENT,
                        payload: fields,
                        meta: result.user
                    })
                    const endpoint = `${CLOUD_FUNCTIONS_URL}/send_welcome_user`
                    auth.currentUser.getIdToken(true)
                    .then(token => {
                        const headers = { 
                            headers: { 
                                'Authorization':`Bearer ${token}`,
                                'Content-Type':'application/json'
                            }
                        }
                        const data = JSON.stringify({ client_id: result.user.uid })
                        let axios_request = axios.create({
                            validateStatus : status => { return status <= 500 }
                        })
                        axios_request.post(endpoint, data, headers)
                        .then(response => {
                            
                            if(response.data.success){
                                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                                dispatch({
                                    type: ALERTS.SHOW_SUCCESS_MESSAGE,
                                    payload: `Bienvenido ${fields.name}`
                                })
                                callback()
                            }
                            else {
                                dispatch({
                                    type: ALERTS.SHOW_ERROR_MESSAGE,
                                    payload: response.data.message
                                })
                                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                            }

                        })
                        .catch( error => {
                            dispatch({ type: SHARED.PAGE_STOP_LOADING })
                            dispatch({
                                type: ALERTS.SHOW_ERROR_MESSAGE,
                                payload: 'ERROR 06: Ha ocurrido un error en el envío del correo de tu bienvenida.'
                            })
                            console.log('No se logró enviar el correo de bienvenida al nuevo cliente. ', error)
                        })
                    })

                })
                .catch( error => {
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: 'ERROR 03: Ha ocurrido un error con tu registro, por favor inténtalo nuevamente.'
                    })
                    console.log('No se logró registrar al nuevo cliente. ', error)
                })
            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'ERROR 04: Ha ocurrido un error con tu registro, por favor inténtalo nuevamente.'
                })
                console.log('No se ha podido definir displayName.', error)
            })
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: FIREBASE_ERRORS.CREATE_USER_EMAIL[error.code]
            })
            console.log('No se ha podido registrar correo y contraseña.', error)
        })
    }
}

export function fetchUserCards(user_id){
    return dispatch => {
        firestore.collection('users').doc(user_id)
        .get().then( doc => {
            if (doc.exists){
                const cards = doc.data().cards || null
        
                dispatch({
                    type: AUTH.FETCH_USER_CARDS,
                    payload: cards
                })
            }
            else
              dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'No se logró conectar con el servidor para obtener tarjetas del usuario.'
              })
        })
        .catch( error => console.log(dispatch, error) )
        
    }
}

export function saveClientUserData(temporary_update){
    return dispatch => {
        dispatch({
            type: AUTH.TEMPORARY_UPDATE,
            payload: temporary_update
        })
    }
}



export function convertUserToArtist(fields, uid, callback){
    return dispatch => {

        dispatch({ type: SHARED.PAGE_IS_LOADING })
            storage.ref(`/ids/${fields['card_id_file'].file.name}-${new Date().getTime()}`)
            .put(fields['card_id_file'].file)
            .then( snapshot => {
                snapshot.ref.getDownloadURL()
                .then(url => {
                    const idImage = { url, path: snapshot.metadata.fullPath }
                    const endpoint = `${CLOUD_FUNCTIONS_URL}/convertUserToArtistNoGateway`
                    auth.currentUser.getIdToken(true)
                    .then(token => {
                        const headers = { 
                            headers: { 
                                'Authorization':`Bearer ${token}`,
                                'Content-Type':'application/json'
                            }
                        }
                        const data = JSON.stringify({
                            user_id: uid,
                            address : fields.address,
                            artist_name : fields.artist_name,
                            artist_type : fields.artist_type,
                            id_card : idImage,
                            zones : fields.zones,
                            paypal_account_email : fields.paypal_email,
                            phone : fields.phone,
                            phone2 : fields.phone2,
                            profile_image : fields.profile_image,
                            promoter : fields.promoter,
                            rfc : fields.rfc,
                            last_login: new Date().getTime()
                        })
                        
                        let axios_request = axios.create({
                            validateStatus : status => { return status <= 500 }
                        })
                        axios_request.post(endpoint, data, headers)
                        .then(response => {
                            if(response.data.success){
                                Object.assign(fields, {card_id : idImage})
                                delete fields['card_id_file']
                                dispatch({ 
                                    type: AUTH.FINISHED_CONVERT_CLIENT_TO_ARTIST,
                                    payload: fields
                                })

                                auth.currentUser.updateProfile({
                                    displayName: 'artista'
                                })
                                .then(() => {
                                    dispatch({
                                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                                        payload: `Bienvenido ${fields.artist_name}`
                                    })

                                    dispatch({ type: SHARED.PAGE_STOP_LOADING })

                                    callback()
                                })
                                .catch(error => {
                                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                                    console.log('Falló la actualización de displayName.', error)
                                })
                            }
                            else {
                                dispatch({
                                    type: ALERTS.SHOW_ERROR_MESSAGE,
                                    payload: response.data.message
                                })
                                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                                
                            }

                        })
                        .catch(error => {
                            dispatch({ type: SHARED.PAGE_STOP_LOADING })
                            dispatch({
                                type: ALERTS.SHOW_ERROR_MESSAGE,
                                payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
                            })
                            console.log('Error mayor a 500 desde servidor.', error)
                        })

                    })
                    .catch( error => {
                        dispatch({ type: SHARED.PAGE_STOP_LOADING })
                        console.log('No se logró obtener token del usuario. ', error)
                    })

                })
                .catch(error => {
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    console.log('No se ha logrado obtener la URL del archivo de la identificación oficial. ', error)
                })
            })    
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                console.log('Falló la subida del archivo de la identificación oficial.', error)
            })
        
    }
}

export function validateEmail(email, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        const endpoint = `${CLOUD_FUNCTIONS_URL}/check_email_available`
        
        const headers = { 
            headers: {
                'Content-Type':'application/json'
            }
        }
        const data = JSON.stringify({ email })
        
        let axios_request = axios.create({
            validateStatus : status => { return status <= 500 }
        })
        axios_request.post(endpoint, data, headers)
        .then(response => {
            if(response.data.success){
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                callback(response.data.code)
            }
            else {
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: response.data.message
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                
            }

        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error con el servidor, por favor inténtalo nuevamente.'
            })
            console.log('Error mayor a 500 desde servidor.', error)
        })
    }
}