import React, { Component } from 'react'
import ClientProfileEditForm from 'components/organisms/client_profile_edit_form'

class EditProfile extends Component{
    render(){
        return (
            <>
                <h4 className='text-gray-muzig'>Información personal</h4>
                <ClientProfileEditForm/>
            </>
        )
    }
}

export default EditProfile