import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { reset, initialize } from 'redux-form'
import HomeSearch from 'components/organisms/home_search_field'
import FlashAlert from 'components/molecules/alert'
import { findServicesWithFilter } from 'actions/services'
import { clearSingleDate } from 'actions/datepicker'
import * as routes from 'constants/routes'
import { getCurrentCountry } from 'helpers/index'

import { CategorySquare, Icon, TitleDiv } from './styled'
import AgrupacionesMusicales from 'components/atoms/images/categorias/fondo_agrupacion.jpg'
import AgrupacionesMusicalesIcon from 'components/atoms/images/categorias/icono_banda.svg'
import Orquesta from 'components/atoms/images/categorias/fondo_orquesta.jpg'
import OrquestaIcon from 'components/atoms/images/categorias/icono_orquesta.svg'
import Conjunto from 'components/atoms/images/categorias/fondo_conjunto.jpg'
import ConjuntoIcon from 'components/atoms/images/categorias/icono_conjunto.svg'
import Solista from 'components/atoms/images/categorias/fondo_solista.jpg'
import SolistaIcon from 'components/atoms/images/categorias/icono_solista.svg'
import DJ from 'components/atoms/images/categorias/fondo_dj.jpg'
import DJIcon from 'components/atoms/images/categorias/icono_dj.svg'
import Mariachi from 'components/atoms/images/categorias/fondo_mariachi.jpg'
import MariachiIcon from 'components/atoms/images/categorias/icono_mariachi.svg'
import Musico from 'components/atoms/images/categorias/fondo_musico.jpg'
import MusicoIcon from 'components/atoms/images/categorias/icono_musico.svg'
import Karaoke from 'components/atoms/images/categorias/fondo_karaoke.jpg'
import KaraokeIcon from 'components/atoms/images/categorias/icono_karaoke.svg'
import Payaso from 'components/atoms/images/categorias/fondo_payaso.jpg'
import PayasoIcon from 'components/atoms/images/categorias/icono_payaso.svg'
import Circense from 'components/atoms/images/categorias/fondo_circense.jpg'
import CircenseIcon from 'components/atoms/images/categorias/icono_circense.svg'
import Talleres from 'components/atoms/images/categorias/fondo_talleres.jpg'
import TalleresIcon from 'components/atoms/images/categorias/icono_talleres.svg'
import Mago from 'components/atoms/images/categorias/fondo_mago.jpg'
import MagoIcon from 'components/atoms/images/categorias/icono_mago.svg'
import Comediante from 'components/atoms/images/categorias/fondo_comediante.jpg'
import ComedianteIcon from 'components/atoms/images/categorias/icono_comediante.svg'
import Animador from 'components/atoms/images/categorias/fondo_animador.jpg'
import AnimadorIcon from 'components/atoms/images/categorias/icono_animador.svg'
import Bailarin from 'components/atoms/images/categorias/fondo_bailarin.jpg'
import BailarinIcon from 'components/atoms/images/categorias/icono_bailarin.svg'
import PuestasEscena from 'components/atoms/images/categorias/fondo_puesta_en_escena.jpg'
import PuestasEscenaIcon from 'components/atoms/images/categorias/icono_puesta_en_escena.svg'
import Imitador from 'components/atoms/images/categorias/fondo_imitador.jpg'
import ImitadorIcon from 'components/atoms/images/categorias/icono_imitador.svg'
import Actuacion from 'components/atoms/images/categorias/fondo_actuacion.jpg'
import ActuacionIcon from 'components/atoms/images/categorias/icono_actuacion.svg'
import Audiovisual from 'components/atoms/images/categorias/fondo_produccion_audiovisual.jpg'
import AudiovisualIcon from 'components/atoms/images/categorias/icono_produccion_audiovisual.svg'
import Discografica from 'components/atoms/images/categorias/fondo_produccion_discografica.jpg'
import DiscograficaIcon from 'components/atoms/images/categorias/icono_produccion_discografica.svg'
import Fotografica from 'components/atoms/images/categorias/fondo_fotografia.jpg'
import FotograficaIcon from 'components/atoms/images/categorias/icono_fotografia.svg'
import Otros from 'components/atoms/images/categorias/fondo_otros.jpg'
import OtrosIcon from 'components/atoms/images/categorias/icono_otros.svg'
import Makeup from 'components/atoms/images/categorias/fondo_maquillaje.jpg'
import MakeupIcon from 'components/atoms/images/categorias/icono_maquillaje.svg'
import Advisor from 'components/atoms/images/categorias/fondo_asesor_de_imagen.jpg'
import ImageAdvisorIcon from 'components/atoms/images/categorias/icono_asesor_de_imagen.svg'
import Locution from 'components/atoms/images/categorias/fondo_locucion.jpg'
import LocutionIcon from 'components/atoms/images/categorias/icono_locucion.svg'
import Planner from 'components/atoms/images/categorias/fondo_planner.jpg'
import PlannerIcon from 'components/atoms/images/categorias/icono_planner.svg'

class CategoriesGrid extends Component{
    constructor(props){
        super(props)
        this.onCategoryClick = this.onCategoryClick.bind(this)
    }

    onCategoryClick(term){
        let values = {}
        values.country = this.props.user_country
        values['search-query'] = term

        this.props.findServicesWithFilter(values, () => {
            this.props.reset('SearchForm')
            this.props.initialize('SearchForm', { 'search-query' : term })
            this.props.clearSingleDate()
            this.props.history.push(routes.SEARCH_RESULTS)
        })
    }

    render(){
        return(
            <>
                <div className='d-flex flex-column'>
                    <FlashAlert/>
                    <section className='text-center my-4 my-md-5'>
                        <HomeSearch />
                        <div className='row no-gutters my-4 my-md-2'>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare
                                    image={AgrupacionesMusicales} 
                                    onClick={() => this.onCategoryClick('Agrupaciones Musicales')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={AgrupacionesMusicalesIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Agrupaciones Musicales</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Mariachi} 
                                    onClick={() => this.onCategoryClick('Mariachi')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={MariachiIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Mariachi</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Orquesta} 
                                    onClick={() => this.onCategoryClick('Orquesta')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={OrquestaIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Orquesta</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Conjunto} 
                                    onClick={() => this.onCategoryClick('Conjunto')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={ConjuntoIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Conjunto</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Solista} 
                                    onClick={() => this.onCategoryClick('Solista')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={SolistaIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Solista</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={DJ} 
                                    onClick={() => this.onCategoryClick('DJ')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={DJIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>DJ</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Musico} 
                                    onClick={() => this.onCategoryClick('Músico')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={MusicoIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Músico</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Circense} 
                                        onClick={() => this.onCategoryClick('Circense')}>
                                            <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={CircenseIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Circense</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Payaso} 
                                    onClick={() => this.onCategoryClick('Payaso')}>
                                            <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={PayasoIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Payaso</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Mago} 
                                    onClick={() => this.onCategoryClick('Mago')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={MagoIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Mago</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Comediante} 
                                    onClick={() => this.onCategoryClick('Comediante')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={ComedianteIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Comediante</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Animador} 
                                    onClick={() => this.onCategoryClick('Animador')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={AnimadorIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Animador</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Bailarin} 
                                    onClick={() => this.onCategoryClick('Bailarín')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={BailarinIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Bailarín</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Imitador} 
                                    onClick={() => this.onCategoryClick('Imitador')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={ImitadorIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Imitador</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Actuacion} 
                                    onClick={() => this.onCategoryClick('Actuación')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={ActuacionIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Actuación</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={PuestasEscena} 
                                    onClick={() => this.onCategoryClick('Puestas en Escena')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={PuestasEscenaIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Puestas en Escena</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Audiovisual} 
                                    onClick={() => this.onCategoryClick('Producción Audiovisual')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={AudiovisualIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Producción Audiovisual</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Discografica} 
                                    onClick={() => this.onCategoryClick('Producción Discográfica')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={DiscograficaIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Producción Discográfica</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Fotografica} 
                                    onClick={() => this.onCategoryClick('Producción Fotográfica')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={FotograficaIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Producción Fotográfica</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Karaoke} 
                                    onClick={() => this.onCategoryClick('Karaoke')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={KaraokeIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Karaoke</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Talleres} 
                                    onClick={() => this.onCategoryClick('Talleres')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={TalleresIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Talleres</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Advisor} 
                                    onClick={() => this.onCategoryClick('Asesor de Imagen')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={ImageAdvisorIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Asesor de imagen</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Locution} 
                                    onClick={() => this.onCategoryClick('Locución')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={LocutionIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Locución</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Makeup} 
                                    onClick={() => this.onCategoryClick('Maquillaje')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={MakeupIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Maquillaje</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Planner} 
                                    onClick={() => this.onCategoryClick('Planner')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={PlannerIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Planner</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                            <div className='col-6 col-sm-4 col-lg-3 col-xl-3 d-flex justify-content-center p-2 my-3'>
                                <CategorySquare 
                                    image={Otros} 
                                    onClick={() => this.onCategoryClick('Otros')}>
                                        <TitleDiv className='d-flex flex-column align-content-end flex-wrap align-items-center justify-content-center p-3'>
                                            <Icon src={OtrosIcon}/>
                                            <div className='w-100'></div>
                                            <h4 className='text-white'>Otros</h4>
                                        </TitleDiv>
                                </CategorySquare>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

function mapStateToProps({ auth }){
    const { user } = auth
    const user_country = getCurrentCountry(user ? user.country:'MÉXICO')

    return { user_country }
}

CategoriesGrid = connect(
    mapStateToProps, 
    { findServicesWithFilter, reset, clearSingleDate, initialize }
)(CategoriesGrid)

export default withRouter(CategoriesGrid)