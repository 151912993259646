import map from 'lodash/map'
import React, { Component } from 'react'
import  { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchArtistJobServices, selectMyServiceJob } from 'actions/artists'
import { EmptyImg, StyledBadge, BottomDiv } from './styled'
import IconNoServices from 'components/atoms/images/vacios/sin_servicios.svg'
import { SERVICE_STATUS, CURRENCY_FORMATTER } from 'constants/index'
import * as routes from 'constants/routes'
import { currencyFormatter } from 'helpers/index'

class ArtistJobServicesList extends Component{
    constructor(props){
        super(props)
        this.renderServicesList = this.renderServicesList.bind(this)
        this.renderEmptyServices = this.renderEmptyServices.bind(this)
        this.onClickService = this.onClickService.bind(this)
        this.getCost = this.getCost.bind(this)
    }

    componentDidMount(){
        this.props.fetchArtistJobServices(this.props.user_id)
    }

    getCost(service){
        if(service.currency_code)
            return currencyFormatter(service.currency_code, service.cost)
        else
            return CURRENCY_FORMATTER.format(service.cost)
    }

    onClickService(service){
        this.props.selectMyServiceJob(service, () => {
            this.props.history.push(routes.ARTIST_JOB_SERVICES_EDIT.replace(':id', service.id))
        })
    }

    renderTypes(types){
        return map( types, type => {
            return (
                <StyledBadge 
                    key={type} 
                    className="badge badge-secondary px-2 py-1 m-1"
                >
                    {type}
                </StyledBadge>
            )
        })
    }

    renderServicesList(){
        return (
            map(this.props.job_services, service => {
                return (
                    <div 
                        className='row no-gutters py-4' 
                        key={service.id}
                        onClick={() => this.onClickService(service)}>
                        <div className='col-12 col-sm-9'>
                            <h5>{service.title}</h5>
                            <div className='d-flex flex-wrap'>
                                <span className='text-gray-muzig'>
                                    {this.renderTypes(service.types)}
                                </span>
                            </div>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <h4 className='text-secondary text-right'>{this.getCost(service)}</h4>
                        </div>
                        <BottomDiv 
                            className='col-12 text-white d-flex align-items-center justify-content-end px-2'
                            status={service.status}>
                            Servicio {SERVICE_STATUS[service.status]}
                        </BottomDiv>
                    </div>
                )
            })
        )
    }

    renderEmptyServices(){
        return (
            <div className='text-center mt-5'>
                <EmptyImg src={IconNoServices} alt='' />
                <h2 className='text-gray-muzig my-4'>Aún no has subido servicios</h2>
            </div>
        )
    }

    render(){
        return(
            <>
                {
                    this.props.job_services.length > 0 ? 
                        this.renderServicesList()
                        :
                        this.renderEmptyServices()
                }
            </>
        )
    }
}

function mapStateToProps({ auth, artist }){
    const user_id = auth.meta.user.uid
    const { job_services } = artist
    return { user_id, job_services }
}

export default connect(
    mapStateToProps,
    { fetchArtistJobServices, selectMyServiceJob }
)(withRouter(ArtistJobServicesList))