import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import Input from 'components/atoms/inputs/simpliest_input'
import OptionSelect from 'components/atoms/inputs/option_select_with_specific_label'
import { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep } from 'actions/shared'
import { NEW_SERVICE_VIEWS, CURRENCY_OPTIONS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'

class NewServiceCost extends Component{
    constructor(props){
        super(props)
        this.validateCost = this.validateCost.bind(this)
        this.validateCurrency = this.validateCurrency.bind(this)
        this.validateExtraCost = this.validateExtraCost.bind(this)
    }

    validateCost(cost){
        if(!cost){
            this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.cost)
            return 'Debes definir un costo para tu servicio'
        }
    }

    validateCurrency(currency_code){
        if(!currency_code){
            this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.cost)
            return 'Debes seleccionar un tipo de moneda'
        }
    }

    validateExtraCost(cost){
        if(!cost){
            this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.cost)
            return 'Debes definir un costo para la hora extra de tu servicio'
        }
    }

    onSubmit(values){
        this.props.completeNewServiceStep(NEW_SERVICE_VIEWS.cost)
        this.props.selectNewServiceStep(NEW_SERVICE_VIEWS.zones)
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <h2 className='text-secondary'>Costo</h2>
                    {/* <p>El precio de tu servicio debe incluir I.V.A.</p> */}
                    <Field
                        name="cost"
                        type="number"
                        placeholder="Agrega un costo"
                        validate={this.validateCost}
                        component={Input}
                    />

                    <h2 className='text-secondary mt-5'>Moneda</h2>
                    <p>Selecciona el tipo de moneda correspondiente.</p>
                    <Field
                        name="currency_code"
                        type='select'
                        placeholder="Moneda"
                        options={CURRENCY_OPTIONS}
                        validate={this.validateCurrency}
                        component={OptionSelect}
                    />

                    <h2 className='text-secondary mt-5'>Costo por hora extra</h2>
                    {/* <p>El precio de tu servicio debe incluir I.V.A.</p> */}
                    <Field
                        name="cost_extra"
                        type="number"
                        placeholder="Agrega un costo"
                        validate={this.validateExtraCost}
                        component={Input}
                    />

                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

NewServiceCost = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewServiceCost)
  
  NewServiceCost = connect(
    null,
    { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep }
  )(NewServiceCost)
  
  export default NewServiceCost