import styled from 'styled-components'

export const ClientCard = styled.div`
    border-radius: 10px;
`

export const ClientCardRow = styled.div`
    height: 8rem;
`

export const UserImage = styled.img`
    border-radius: 50%;
    height: 7rem;
    object-fit: cover;
    width: 7rem;
`

export const Divisor = styled.div`
    height:0.25rem;
    width:80%;
`

export const ImageForm = styled.form`
    transform: translate(-2rem,2rem);
`