import React from "react"
import { connect } from 'react-redux'
import Media from "react-media"
import { withRouter } from 'react-router-dom'

import LeftSide from 'components/molecules/access_left_side'
import OutlineButton from 'components/atoms/buttons/outline'
import { RightDiv, RoundedButton } from './styled'
import { loginWithFacebook } from 'actions/authentication'
import * as routes from 'constants/routes'
 
class ChooseRegistrationType extends React.Component {
    constructor(props){
        super(props)
        this.onFacebookLogin = this.onFacebookLogin.bind(this)
        this.onUserTypeClick = this.onUserTypeClick.bind(this)
        this.onArtistTypeClick = this.onArtistTypeClick.bind(this)
    }

    onFacebookLogin(l){
        this.props.loginWithFacebook(route => {
            this.props.history.push(route)
        })
    }

    onUserTypeClick(){
        this.props.history.push(routes.USER_SIGNUP_AS_CLIENT)
    }

    onArtistTypeClick(){
        this.props.history.push(routes.USER_SIGNUP_AS_ARTIST)
    }

    render() {
        return (
            <div className='d-flex'>
                <Media query={{ minWidth: 768 }}>
                    {matches =>
                        matches ? (
                        <LeftSide title='Selecciona tu tipo de registro'/>
                        ) : (
                        ''
                        )
                    }
                </Media>
                <RightDiv className='flex-fill d-flex flex-column justify-content-center align-items-center'>
                    <div className='p-2 text-center my-4'>
                        <h4 className=''>Regístrate con tu correo</h4>
                        <h6 className=''>Sólo selecciona qué tipo de usuario quieres ser:</h6>
                        <div className='my-4'>
                            <RoundedButton
                                color='warning'
                                text='Entrar con Facebook'
                                className={'text-white m-1'}
                                onClick={this.onUserTypeClick}>Cliente</RoundedButton>
                            <RoundedButton
                                color='warning'
                                text='Entrar con Facebook'
                                className={'text-white m-1'}
                                onClick={this.onArtistTypeClick}>Artista</RoundedButton>
                        </div>
                    </div>
                    
                    <div className='p-2 text-center my-4'>
                        {
                            this.props.loading ? 
                                ''
                            :
                                <>
                                    <h4 className=''>O regístrate con tu cuenta de Facebook</h4>
                                    <h6 className=''>Prellenaremos tus datos para el registro:</h6>
                                    <div className='my-4'>
                                        <RoundedButton 
                                            color='primary'
                                            text='Registrar'
                                            onClick={this.onFacebookLogin}>Facebook</RoundedButton>
                                    </div>
                                </>
                        }
                    </div>
                    
                    
                </RightDiv>
            </div>
        )
    }
}

function mapStateToProps({ auth }){
    const { loading } = auth
    return { loading }
}

export default connect(
    mapStateToProps,
    { loginWithFacebook }
)(withRouter(ChooseRegistrationType))