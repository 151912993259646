import styled from 'styled-components'

import VisaLogo from 'components/atoms/images/tarjeta_visa.svg'
import MasterCardLogo from 'components/atoms/images/tarjeta_master.svg'
import AmexLogo from 'components/atoms/images/tarjeta_american.svg'

import EmptyCard from 'components/atoms/images/icono_incompleto_tarjetas.svg'
import SelectedCard from 'components/atoms/images/icono_completo_tarjetas.svg'
import DeleteCard from 'components/atoms/images/icono_tache.svg'

export const CardBrandLogo = styled.div`
    background-image: url(
        ${props => {
            switch(props.brand){
                case 'visa':
                    return VisaLogo
                case 'mastercard':
                    return MasterCardLogo
                case 'american_express':
                    return AmexLogo
                default:
                    break
            }
        }}
    );
    background-repeat: no-repeat;
    background-size: cover;
    height: 2rem;
    width: 2rem;
`

export const CardCheck = styled.div`
    background-image: url(
        ${props => {
            return props.default ? SelectedCard : EmptyCard
        }}
    );
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 1rem;
    width: 1rem;
`

export const CardDelete = styled.div`
    background-image: url(${DeleteCard});
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 1rem;
    width: 1rem;
`