import _ from 'lodash'
import { 
    SERVICES, CATEGORIES
} from 'constants/index';

const INITIAL_STATE = { 
            home_musical : [],
            home_performance : [],
            home_production : [],
            list : [],
            current_category : {},
            current_service : {},
            current_includes : {},
            current_service_category : {},
            current_service_comments : {}
        }

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SERVICES.FETCH_LIST_SERVICES:
            return { ...state, list: action.payload }
        
        case SERVICES.FETCH_CATEGORY_LIST:
            return { ...state, list: action.payload, current_category: action.category }

        case SERVICES.FETCH_HOME_SERVICES:
            const arrayMusical = _.filter(action.payload, service => {
                return service.category === CATEGORIES.musical.id
            })
            const arrayPerformance = _.filter(action.payload, service => {
                return service.category === CATEGORIES.performance.id
            })
            const arrayProduction = _.filter(action.payload, service => {
                return service.category === CATEGORIES.produccion.id
            })
            
            return { 
                ...state, 
                home_musical: arrayMusical.slice(0,5),
                home_performance: arrayPerformance.slice(0,5),
                home_production: arrayProduction.slice(0,5) }

        case SERVICES.SELECT_SERVICE:
            return { ...state, current_service: action.payload }

        case SERVICES.FETCH_SERVICE:
            return { ...state, current_service: action.payload }

        case SERVICES.FETCH_SERVICE_INCLUDES:
            return { ...state, current_includes: action.payload }

        case SERVICES.FETCH_SERVICE_CATEGORY:
            return { ...state, current_service_category: action.payload }

        case SERVICES.FETCH_SERVICE_COMMENTS:
            return { ...state, current_service_comments: action.payload}
        
        case SERVICES.FETCH_ARTIST_IMAGE:
                const image = { artist_image : action.payload}
                const inmmutable_obj = Object.assign({}, state.current_service, image)
            return { ...state, current_service: inmmutable_obj }

        case SERVICES.SET_CURRENCY_CODE:
            return { ...state, current_service: { ...state.current_service, currency_code: action.payload }}

        default:
            return { ...state }
    }
}
