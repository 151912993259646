import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep, getSubcategoriesList } from 'actions/shared'
import { NEW_SERVICE_VIEWS, ARTISTS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'
import { getNormalizeString } from 'helpers/index'
import { StyledDiv, LabelTextDiv } from './styled'
import * as routes from 'constants/routes'

class NewServiceName extends Component{
    constructor(props){
        super(props)
        this.validateName = this.validateName.bind(this)
        this.renderSubcategoriesOptions = this.renderSubcategoriesOptions.bind(this)
    }

    componentDidMount(){
        this.props.getSubcategoriesList(this.props.artist_type)
    }

    validateName(name){
        if(!name){
            this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.category)
            return 'Debes definir un nombre para tu servicio'
        }
    }

    onSubmit(values){
        this.props.completeNewServiceStep(NEW_SERVICE_VIEWS.category)
        this.props.history.push(routes.ARTIST_JOB_SERVICES_NEW)
    }

    renderSubcategoriesOptions(subcategories){
        return _.map(subcategories, subcategory => {
            return (
                <div className='m-4' key={subcategory.id}>
                    <div className=''>
                        <Field id={`${getNormalizeString(subcategory.name)}Type`} name="serviceSubcategory" component='input' type="radio" value={subcategory.id} />
                        <label htmlFor={`${getNormalizeString(subcategory.name)}Type`} className={`${getNormalizeString(subcategory.name)} bg-secondary p-2`}></label>
                    </div>
                    <LabelTextDiv>
                        <h4 className='text-primary'>{subcategory.name}</h4>
                    </LabelTextDiv>
                </div>
            )
        })
        
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <div className='container mt-5'>
                <h4 className='text-secondary'>Categoría</h4>
                <p>Selecciona la categoría que mejor le quede a tu servicio. Recuerda que puedes cambiar la categoría principal (musical, performance, producción) desde tu perfil para que te muestre aquí las categorías que estás buscando.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <StyledDiv className='d-flex flex-wrap justify-content-center'>
                        {this.renderSubcategoriesOptions(this.props.subcategories)}
                    </StyledDiv>
                    <div className='my-5 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps({ auth, shared }){
    const artist_type_id = auth.user.artist_type
    const artist_type = ARTISTS.TYPES[artist_type_id]
    const { subcategories } = shared

    return { artist_type, subcategories }
}

NewServiceName = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewServiceName)
  
  NewServiceName = connect(
    mapStateToProps,
    { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep, getSubcategoriesList }
  )(NewServiceName)
  
  export default withRouter(NewServiceName)