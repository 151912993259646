import styled from 'styled-components'
import { placeholder } from 'polished'
import { InputGroupAddon, NavLink, DropdownToggle, NavbarBrand } from 'reactstrap'
import { Link } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'

export const LogoImage = styled.img`
    width: 3rem;
`
export const InputSearchBtn = styled(InputGroupAddon)`
    border-bottom: none;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 5px;
    outline: none;
`

export const StyledLink = styled(Link, NavLink)`
`

export const StyledInput = styled.input`
    ${ placeholder({ color: '#0a0b30', backgroundColor: "white", outline: "none", border: "#0a0b30"}) }
`

export const SearchIcon = styled(MdSearch)`
    font-size: 1.5rem;
`

export const StyledDropdownToggle = styled(DropdownToggle)`
    border-radius: 50%!important;
    background-image: url(${props => props.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 2rem;
    width: 2rem;
`

export const StyledNavbarBrand = styled(NavbarBrand)`
    cursor: pointer;
    padding: 0!important;
`