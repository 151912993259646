import React from "react"
import Media from "react-media"

import LeftSide from 'components/molecules/access_left_side'
import RightSide from './right_side'
 
export default class Login extends React.Component {
  render() {
    return (
        <div className='d-flex'>
            <Media query={{ minWidth: 768 }}>
                {matches =>
                    matches ? (
                    <LeftSide/>
                    ) : (
                    ''
                    )
                }
            </Media>
            <RightSide/>
        </div>
    )
  }
}