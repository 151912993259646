import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import * as routes from 'constants/routes'

const PrivateRouteComponent = (props) => (
  <Route {...props.routeProps} render={() => (
    
    !props.payment ? //ya pagaron?
      <Redirect to={{
        pathname: routes.NO_PAYMENT,
          state: { from: props.location }
        }}
      />
    
    : //else if no está logueado, que se loguee
    !props.user ?
    <Redirect to={{
        pathname: routes.LOGIN,
        state: { from: props.location }
      }}
    />
    
    : //else if está logueado y tiene permisos?
    props.user && (props.user_role in props.roles_needed) ?
      <div>{props.children}</div>
    : //else no tiene permisos
    <Redirect to={{
        pathname: routes.NO_ACCESS,
        state: { from: props.location }
      }}
    />

  )}/>
)

const mapStateToProps = (state, ownProps) => {
  
  return {
    children: ownProps.children,
    payment: state.auth.payment,
    user: state.auth.user,
    user_role: state.auth.role,
    roles_needed: ownProps.roles,
    location: ownProps.path,
    routeProps: {
      exact: ownProps.exact,
      path: ownProps.path
    }
  }
}

const PrivateRouterEcosystem = connect(mapStateToProps, null)(PrivateRouteComponent)
export default PrivateRouterEcosystem
