import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"

import { GOOGLE_PLACES_API } from 'constants/index'
import Input from 'components/atoms/inputs/generic_input'
import OptionSelect from 'components/atoms/inputs/generic_option_select'
import RoundedButton from 'components/atoms/buttons/solid'
import iconName from 'components/atoms/images/icono_nombre_apellido.svg'
import iconTel from 'components/atoms/images/icono_telefono_formulario.svg'
import iconEmail from 'components/atoms/images/icono_correo.svg'
import iconPlace from 'components/atoms/images/icono_direccion.svg'
import iconCountry from 'components/atoms/images/icono_pais_registro.svg'
import iconRFC from 'components/atoms/images/icono_rfc.svg'

import { getCountriesList } from 'actions/shared'
import { updateUserData } from 'actions/user'
import validate from './validate'
import * as routes from 'constants/routes'

class ClientProfileEditForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            search: '',
            value: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSelectSuggest = this.handleSelectSuggest.bind(this)
    }
  
    handleInputChange(e) {
      this.setState({search: e.target.value, value: e.target.value})
    }
  
    handleSelectSuggest(suggest) {
      this.setState({search: "", value: suggest.formatted_address})
      this.props.change('address',this.state.value)
    }

    componentDidMount(){
        this.props.getCountriesList()
    }

    onSubmit(values){
        this.props.updateUserData(values, this.props.user_id, () => {
            this.props.history.push(routes.CLIENT_PROFILE)
        })
    }

    render(){
        const { handleSubmit } = this.props
        const { search } = this.state

        return (
        <>
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                <Field
                    name="name"
                    type="text"
                    placeholder="Nombre"
                    autocomplete='name'
                    icon={iconName}
                    component={Input}
                    disabled
                />

                <Field
                    name="lastName"
                    type="text"
                    placeholder="Apellidos"
                    autocomplete='last-name'
                    icon={iconName}
                    component={Input}
                    disabled
                />

                <Field
                    name="email"
                    type="email"
                    placeholder="Correo electrónico"
                    autocomplete='email'
                    icon={iconEmail}
                    component={Input}
                    disabled
                />

                <Field
                    name="phone"
                    type='tel'
                    placeholder="Agrega tu teléfono"
                    autocomplete='main-tel'
                    icon={iconTel}
                    component={Input}
                />

                <Field
                    name="rfc"
                    type="text"
                    placeholder="RFC/ Número Fiscal o Tributario"
                    autocomplete='rfc'
                    icon={iconRFC}
                    component={Input}
                />

                <ReactGoogleMapLoader
                    params={{
                    key: GOOGLE_PLACES_API,
                    libraries: "places,geocode",
                    }}
                    render={googleMaps =>
                        googleMaps && (
                            <ReactGooglePlacesSuggest
                                autocompletionRequest={{input: search}}
                                googleMaps={googleMaps}
                                onSelectSuggest={this.handleSelectSuggest}
                            >
                                <Field
                                name="address"
                                type='text'
                                placeholder="Agrega tu dirección"
                                autocomplete='address'
                                icon={iconPlace}
                                component={Input}
                                onChange={this.handleInputChange}
                                />
                            </ReactGooglePlacesSuggest>
                        )
                    }
                />

                <Field
                    name="country"
                    type='select'
                    placeholder="País"
                    icon={iconCountry}
                    options={this.props.countries}
                    component={OptionSelect}
                />

                <div className="py-3"/>
                
                <div className='d-flex justify-content-center mt-5'>
                    <RoundedButton type="submit" text='Guardar'/>
                </div>
            </form>
            <div className='mt-5 d-flex justify-content-end'>
                <a className='text-danger text-right' href='https://themuzigzag.com/eliminar-cuenta/' target='blank'>Eliminar cuenta</a>
            </div>
        </>
        )
    }
}

function mapStateToProps({ auth, shared }){
  const { user } = auth
  const { countries } = shared
  const user_id = auth.meta.user.uid
  
  const initialValues = {
    'name' : user.name,
    'lastName' : user.last_name,
    'phone' : user.phone,
    'email' : user.email,
    'address' : user.address,
    'country' : user.country,
    'rfc' : user.rfc,
  }
  
  return { initialValues, countries, user_id }
}

ClientProfileEditForm = reduxForm({
  form: 'ClientProfileEditForm',
  validate,
})(ClientProfileEditForm)

ClientProfileEditForm = connect(
  mapStateToProps,
  { getCountriesList, updateUserData }
)(ClientProfileEditForm)

export default withRouter(ClientProfileEditForm)