import React, { Component } from 'react'
import Media from 'react-media'

import SmallProfile from './small_profile'
import BigProfile from './big_profile'


class Profile extends Component{

    render(){
        return(
            <>
                <Media query={{ maxWidth: 767}}>
                    {matches => matches ? 
                        <SmallProfile/> : <BigProfile/>
                    }
                </Media>
            </>
        )
    }
}

export default Profile