import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import scriptjs from 'scriptjs'
import { Field, reduxForm } from 'redux-form'
import { Form, FormGroup, Label } from 'reactstrap'

import GenericInput from 'components/atoms/inputs/generic_input'
import Button from 'components/atoms/buttons/solid'
import { OPENPAY_MERCHANT_ID, OPENPAY_PUBLIC_API_KEY } from 'constants/index'
import { createUserCard } from 'actions/user'

class AddCardForm extends Component{
    constructor(props){
        super(props)
        this.state = {
            openpayLoaded: false,
            blockButton: false
        }
        this.onSuccessTokenCreation = this.onSuccessTokenCreation.bind(this)
        this.onErrorTokenCreation = this.onErrorTokenCreation.bind(this)
        this.toogleButtonBlock = this.toogleButtonBlock.bind(this)
    }

    componentDidMount(){
        
        scriptjs.order([
            'https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js',
            'https://openpay.s3.amazonaws.com/openpay.v1.min.js',
            'https://openpay.s3.amazonaws.com/openpay-data.v1.min.js'], 'bundle')

        scriptjs.ready('bundle', () => {
            this.setState({
                openpayLoaded: true
            })
            window.OpenPay.setId(OPENPAY_MERCHANT_ID)
            window.OpenPay.setApiKey(OPENPAY_PUBLIC_API_KEY)
            window.OpenPay.setSandboxMode(true)
        })
    }

    onSubmit(values){
        this.toogleButtonBlock()
        window.OpenPay.token.create({
            "card_number": values.card_number,
            "holder_name": values.holder_name,
            "expiration_year": values.expiration_year,
            "expiration_month": values.expiration_month,
            "cvv2": values.cvv2,
            
      }, this.onSuccessTokenCreation, this.onErrorTokenCreation)
    }

    onSuccessTokenCreation(token){
        const deviceDataId = window.OpenPay.deviceData.setup()
        this.props.createUserCard(
            token.data.id, 
            deviceDataId, 
            this.props.user_id,
            (ok) => {
                if(ok)
                    this.props.history.push(this.props.route)

                this.toogleButtonBlock()
            }
        )
    }

    onErrorTokenCreation(error){
        this.toogleButtonBlock()
        console.log('Falló creación de token: ', error)
    }

    toogleButtonBlock(){
        this.setState({
            blockButton: !this.state.blockButton
        })
    }

    render(){

        const { handleSubmit } = this.props
        return(
            <Form
                className='my-4' 
                onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <FormGroup className='my-4'>
                    <Label for='holder_name' className='text-secondary'>Nombre del titular</Label>
                    <Field
                        placeholder="Como aparece en la tarjeta"
                        autocomplete="section-blue name"
                        name="holder_name"
                        type="text"
                        component={GenericInput}
                    />
                </FormGroup>
                <FormGroup className='my-4'>
                    <Label for='card_number' className='text-secondary'>Número de tarjeta</Label>
                    <Field
                        placeholder=""
                        autocomplete="section-blue card"
                        name="card_number"
                        type="text"
                        max={16}
                        component={GenericInput}
                    />
                </FormGroup>
                <FormGroup className='my-4 row no-gutters'>
                    <Label for='expiration_month' className='text-secondary col-12'>Fecha de expiración</Label>
                    <div className='col-4 col-sm-3'>
                        <Field
                            placeholder="Mes"
                            autocomplete="section-blue month"
                            name='expiration_month'
                            type="text"
                            max={2}
                            component={GenericInput}
                        />
                    </div>
                    <span className='text-center'>/</span>
                    <div className='col-4 col-sm-3'>
                        <Field
                            placeholder="Año"
                            autocomplete="section-blue year"
                            name='expiration_year'
                            type="text"
                            max={2}
                            component={GenericInput}
                        />
                    </div>
                </FormGroup>
                <FormGroup className='my-4'>
                    <Label for='cvv2' className='text-secondary'>Código de seguridad</Label>
                    <Field
                        placeholder="CVV"
                        autocomplete="section-blue month"
                        name='cvv2'
                        type="text"
                        max={4}
                        component={GenericInput}
                    />
                </FormGroup>
                <div className='my-5 text-center'>
                    <Button text='Agregar' disabled={this.state.blockButton}/>
                </div>
            </Form>
        )
    }
}

function validate(values){
    let errors = {}

    if(!values.holder_name)
        errors.holder_name = 'Ingresa el nombre del titular de la tarjeta'

    if(!values.card_number)
        errors.card_number = 'Ingresa el número de tu tarjeta.'
    else if(!window.OpenPay.card.validateCardNumber(values.card_number) )
        errors.card_number = 'Ingresa una tarjeta correcta'
    
    if(!values.expiration_month)
        errors.expiration_month = 'Mes inválido'
    else if(!values.expiration_year)
        errors.expiration_year = 'Año inválido'
    else if (!window.OpenPay.card.validateExpiry(values.expiration_month, values.expiration_year))
        errors.expiration_month = 'Fecha inválida'

    if(!values.cvv2)
        errors.cvv2 = 'Ingresa el código de tu tarjeta'
    else if (!window.OpenPay.card.validateCVC(values.cvv2, values.card_number))
        errors.cvv2 = 'Ingresa un código correcto'

    return errors
}

function mapStateToProps({ auth }){
    const user_id = auth.meta.user.uid

    return { user_id }
}

AddCardForm = reduxForm({
    validate,
    form: 'addUserCardForm'
  })(AddCardForm)
  
  AddCardForm = connect(
    mapStateToProps,
    { createUserCard }
  )(AddCardForm)

export default withRouter(AddCardForm)