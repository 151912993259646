import React, { Component } from 'react'
import { SquaredButton } from './styled'

export default class SolidButton extends Component {
    render(){
        return(
            <>
                <SquaredButton 
                    onClick={this.props.onClick}
                    color={this.props.color}
                    disabled={this.props.is_disabled}>
                    {this.props.text}
                </SquaredButton>
            </>
        )
    }
}