import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'

import validate from './validate'
import NextButton from 'components/atoms/buttons/solid'
import { BackButton } from '../styled'
import MultipleSelect from 'components/atoms/inputs/multiple_select'

import { getCountriesList, getZonesByCountry, unselectCountry } from 'actions/shared'
import { SIGNUP } from 'constants/index'
import { capitalizeOnlyFirstLetter, localeSortArrayBy } from 'helpers/index'

function maxLength3(values = []){
    return values.length > 3 ? 'No puedes elegir más de 3 opciones' : undefined
}

function minLength1(values = []){
    return values.length < 1 ? 'Tienes que elegit al menos una opción' : undefined
}

class WizardFormThirdPage extends Component {
    constructor(props){
        super(props)
        this.state = { selectCountriesValue: this.props.initialCountries }
        this.updateValue = this.updateValue.bind(this)
        this.renderFieldsForZones = this.renderFieldsForZones.bind(this)
    }

    componentDidMount(){
        this.props.getCountriesList()
    }

    updateValue(newValue) {
        let difference = this.state.selectCountriesValue.filter(x => !newValue.includes(x))
        this.setState({ selectCountriesValue: newValue })
        if (difference.length === 0)
            this.props.getZonesByCountry(newValue[newValue.length-1].value)
        else {
            this.props.unselectCountry(difference[0].value)
            this.props.change(`presence-for-${difference[0].value}`, [])
        }
    }

    renderFieldsForZones(zonesByCountry){
        if (Object.keys(zonesByCountry).length > 0){
            let zonesArrayOptions = []
            
            return (
                _.map(zonesByCountry, (zones, country) => {

                    zonesArrayOptions = _.map(zones, (zoneValue, zoneId) => ({ value:zoneId, label:capitalizeOnlyFirstLetter(zoneId) }) )
                    zonesArrayOptions = localeSortArrayBy(zonesArrayOptions, 'value')

                    return(
                        <div className='my-3' key={country}>
                            <p className='my-1'>Agrega aquí ciudad o municipio para {country}</p>
                            <Field
                                name={`presence-for-${country}`}
                                type='select'
                                selectType={SIGNUP.SELECT_TYPE_ZONES}
                                placeholder={`Selecciona hasta 3 zonas para ${country}*`}
                                options={zonesArrayOptions}
                                validate={[maxLength3,minLength1]}
                                component={MultipleSelect}
                            />
                        </div>
                    )
                })
            )
        }
        else{
            console.log('No hay zonas')
        }
    }

  render(){
    const { handleSubmit, previousPage } = this.props

    return (
      <>
        <h2 className='text-primary mt-4'>Zonas de servicio</h2>
        <p>Tus servicios se muestran en todo el mundo, indica las zonas en donde actualmente prestas tus servicios. Se requiere indicar por lo menos un país y una ciudad.</p>

        <form onSubmit={handleSubmit} className='p-2'>
            <Field
                name="countries-presence"
                type='select'
                selectType={SIGNUP.SELECT_TYPE_COUNTRIES}
                placeholder='Selecciona hasta 3 países*'
                options={this.props.countries}
                component={MultipleSelect}
                validate={[maxLength3,minLength1]}
                updateValue={this.updateValue}
            />

            <div className='w-100 my-3'></div>
            {this.renderFieldsForZones(this.props.zonesByCountry)}

            <div className="py-1"/>
            <p className='text-gray'>*Campos obligatorios</p>
            
            <div className='d-flex flex-md-row flex-column justify-content-center mt-5'>
                <BackButton 
                    className='mx-md-2 text-white' 
                    type="button" 
                    onClick={previousPage} 
                    color='gray-muzig'>
                    Anterior
                </BackButton>

                <div className='d-block d-md-none w-100 my-2'/>

                <NextButton
                    className='mx-md-2' 
                    type="submit" 
                    text='Siguiente'/>
            </div>
        </form>
      </>
    )
  }
}

function mapStateToProps({ shared, form }){
    const initialCountries = form.completeArtistWizard.values['countries-presence'] || []
    let countriesArray = []
    let { countries, zonesByCountry } = shared

    countries.forEach( country => countriesArray.push({ value:country.name, label:country.name }) )
    
    return { countries: countriesArray, zonesByCountry, initialCountries }
}

export default connect(
    mapStateToProps,
    { getCountriesList, getZonesByCountry, unselectCountry, change }
)(
  reduxForm({
    form: 'completeArtistWizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(WizardFormThirdPage)
)