import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import { 
    completeNewServiceStep, 
    selectNewServiceStep, 
    uncheckNewServiceStep,
    getIncludesList } from 'actions/shared'
import { NEW_SERVICE_VIEWS, SHARED } from 'constants/index'
import MultipleSelect from 'components/atoms/inputs/multiple_select'
import Button from 'components/atoms/buttons/solid'

class NewServiceIncludes extends Component{
    constructor(props){
        super(props)
        this.minLength1 = this.minLength1.bind(this)
    }

    componentDidMount(){
        this.props.getIncludesList()
    }

    onSubmit(values){
        console.log(values)
        this.props.completeNewServiceStep(NEW_SERVICE_VIEWS.includes)
        this.props.selectNewServiceStep(NEW_SERVICE_VIEWS.category)
    }
    
    minLength1(values = []){
        this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.includes)
        return values.length < 1 ? 'Tienes que elegir al menos una opción' : undefined
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>¿Qué incluye?</h2>
                <p>¿Utilizas artefactos o distracciones? No olvides agregar lo que ofreces en tu show.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="includes"
                        type='select'
                        selectType={SHARED.SELECT_TYPE_INCLUDES}
                        placeholder='Selecciona al menos una opción'
                        options={this.props.includes_options}
                        component={MultipleSelect}
                        validate={[this.minLength1]}
                    />
            
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps({ shared, form }){
    let initialIncludes = []
    if(form.createNewServiceWizard.values)
        if(typeof form.createNewServiceWizard.values['includes'] !== 'undefined')
            initialIncludes = form.createNewServiceWizard.values['includes']

    let includesArray = []
    let { includes } = shared
    includes.forEach( include => includesArray.push({ value:include.id, label:include.name }) )
    
    return { includes_options: includesArray, initialIncludes }
}

NewServiceIncludes = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewServiceIncludes)
  
  NewServiceIncludes = connect(
    mapStateToProps,
    { 
        completeNewServiceStep, 
        selectNewServiceStep, 
        uncheckNewServiceStep,
        getIncludesList}
  )(NewServiceIncludes)
  
  export default NewServiceIncludes