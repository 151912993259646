import React from 'react'
import Media from "react-media"

import { Banner, ArtistImage } from './styled'

export default (props) => {
    
    return(
        <>
            <Media query={{ maxWidth: 767 }}>
                {matches => matches ? 
                    <div className='bg-primary d-flex flex-column p-4'>
                        {props.current_user === props.service.client_id ?
                            <>
                                <div className='text-center my-2'><ArtistImage src={props.service.artist_image}/></div>
                                <h3 className='text-white text-center my-2'>{props.service.artist_name}</h3>
                            </>
                            :
                            <>
                                <div className='text-center my-2'><ArtistImage src={props.service.client_image}/></div>
                                <h3 className='text-white text-center my-2'>{props.service.client_name}</h3>
                            </>
                         }
                        
                    </div> : ''}
            </Media>
            <Media query={{ minWidth: 768, maxWidth: 991 }}>
                {matches => matches ? 
                    <Banner 
                        size='md' 
                        className='d-flex align-items-center p-4' 
                        image={props.service.image}
                    /> : ''}
            </Media>
            <Media query={{ minWidth: 992, maxWidth: 1199 }}>
                {matches => matches ? 
                    <Banner 
                        size='lg' 
                        className='d-flex align-items-center p-4' 
                        image={props.service.image}
                    /> : ''}
            </Media>
            <Media query={{ minWidth: 1200 }}>
                {matches => matches ? 
                    <Banner 
                        size='xl' 
                        className='d-flex align-items-center p-4' 
                        image={props.service.image}
                    /> : ''}
            </Media>
        </>
    )
}