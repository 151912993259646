import DefaultProfileImage from 'components/atoms/images/default_profile.png'

export const ENVIRONMENTS = {
    QAS: 'qas',
    PRD: 'prd'
}
let VAR_GOOGLE_PLACES_API
let VAR_OPENPAY_MERCHANT_ID
let VAR_OPENPAY_PUBLIC_API_KEY
let VAR_CLOUD_FUNCTIONS_URL
let VAR_PAYPAL_CLIENT_ID

if(process.env.REACT_APP_STAGE === ENVIRONMENTS.QAS){
    VAR_GOOGLE_PLACES_API = process.env.REACT_APP_QAS_GOOGLE_PLACES_API
    VAR_OPENPAY_MERCHANT_ID = process.env.REACT_APP_QAS_OPENPAY_MERCHANT_ID
    VAR_OPENPAY_PUBLIC_API_KEY = process.env.REACT_APP_QAS_OPENPAY_PUBLIC_API_KEY
    VAR_CLOUD_FUNCTIONS_URL = process.env.REACT_APP_QAS_CLOUD_FUNCTIONS_URL
    VAR_PAYPAL_CLIENT_ID = process.env.REACT_APP_QAS_PAYPAL_CLIENT_ID
}
else if(process.env.REACT_APP_STAGE === ENVIRONMENTS.PRD){
    VAR_GOOGLE_PLACES_API = process.env.REACT_APP_PRD_GOOGLE_PLACES_API
    VAR_OPENPAY_MERCHANT_ID = process.env.REACT_APP_PRD_OPENPAY_MERCHANT_ID
    VAR_OPENPAY_PUBLIC_API_KEY = process.env.REACT_APP_PRD_OPENPAY_PUBLIC_API_KEY
    VAR_CLOUD_FUNCTIONS_URL = process.env.REACT_APP_PRD_CLOUD_FUNCTIONS_URL
    VAR_PAYPAL_CLIENT_ID = process.env.REACT_APP_PRD_PAYPAL_CLIENT_ID
}

export const GOOGLE_PLACES_API = VAR_GOOGLE_PLACES_API
export const OPENPAY_MERCHANT_ID = VAR_OPENPAY_MERCHANT_ID
export const OPENPAY_PUBLIC_API_KEY = VAR_OPENPAY_PUBLIC_API_KEY
export const CLOUD_FUNCTIONS_URL = VAR_CLOUD_FUNCTIONS_URL
export const PAYPAL_CLIENT_ID = VAR_PAYPAL_CLIENT_ID

export const URLS = {
    PRIVACY: 'https://themuzigzag.com/aviso-privacidad/',
    CONDITIONS: 'https://themuzigzag.com/terminos-y-condiciones/',
    INSTAGRAM: 'https://instagram.com/themuzigzag.app?igshid=15rhpcu8p9zew',
    FACEBOOK: 'https://www.facebook.com/TheMuzigzag',
    PRD_ROOT: 'https://app.themuzigzag.com',
    QAS_ROOT: 'https://muzigzag-qas.web.app'
}
/*Globals*/
export const CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})

export const GATEWAY_CONSTANTS = {
    PAYPAL: {
        PLATFORM_CONSTANT: 0.0179,
        FIX_PORCENTAGE: .0445
    },
}

export const PAYPAL_CURRENCIES = {
    BRL : {
        id: 'BRL',
        symbol: 'R$',
        text: 'Real brasileiro',
        fixed_commission: 0.60,
        cancel_constant: 3.8
    },
    EUR : {
        id: 'EUR',
        symbol: '€',
        text: 'Euro',
        fixed_commission: 0.35,
        cancel_constant: 0.88
    },
    MXN : {
        id: 'MXN',
        symbol: '$',
        text: 'Peso mexicano',
        fixed_commission: 4.00,
        cancel_constant: 19.61
    },
    GBP : {
        id: 'GBP',
        symbol: '£',
        text: 'Pound sterling',
        fixed_commission: 0.20,
        cancel_constant: 0.78
    },
    USD : {
        id: 'USD',
        symbol: '$',
        text: 'United States dollar',
        fixed_commission: 0.30,
        cancel_constant: 1
    }
}

export const CURRENCY_OPTIONS = [
    {
        id: 'BRL',
        name_old: 'R$ Real brasileiro',
        name: 'BRL'
    },
    {
        id: 'EUR',
        name_old: '€ Euro',
        name: 'EUR'
    },
    {
        id: 'MXN',
        name_old: '$ Peso mexicano',
        name: 'MXN'
    },
    {
        id: 'GBP',
        name_old: '£ Pound sterling',
        name: 'GBP'
    },
    {
        id: 'USD',
        name_old: '$ United States dollar',
        name: 'USD'
    }
]

export const REACT_TABLE_DEFAULTS = {
    defaultPageSize: 10,
    previousText: 'Anterior',
    nextText: 'Siguiente',
    loadingText: 'Cargando...',
    noDataText: 'Cero entradas',
    pageText: 'Página',
    ofText: 'de',
    rowsText: 'entradas'
}

export const RESERVATION_STATUS = {
    accepted : { id:0, text:'aceptada' },
    store_pending : { id:1, text:'pendiente de pago' },
    rate_pending : { id:2, text:'pendiente de calificación' },
    finished: { id:3, text: 'finalizada' },
    client_canceled: { id:4, text: 'cancelada por el cliente' },
    artist_canceled: { id:5, text: 'cancelada por el artista' },
    0: 'aceptada',
    1: 'pendiente de pago',
    2: 'pendiente de calificación',
    3: 'finalizada',
    4: 'cancelada por el cliente',
    5: 'cancelada por el artista'
}

export const SERVICE_STATUS = {
    accepted : 0,
    store_pending : 1,
    rate_pending : 2,
    finished: 3,
    client_canceled: 4,
    artist_canceled: 5,
    1 : 'activo',
    0 : 'en revisión',
    2 : 'inactivo',
    3 : 'rechazado',
    4 : 'suspendido'
}

export const HOURS = [
    {id: '000', name: '0:00'},
    {id: '100', name: '1:00'},
    {id: '200', name: '2:00'},
    {id: '300', name: '3:00'},
    {id: '400', name: '4:00'},
    {id: '500', name: '5:00'},
    {id: '600', name: '6:00'},
    {id: '700', name: '7:00'},
    {id: '800', name: '8:00'},
    {id: '900', name: '9:00'},
    {id: '1000', name: '10:00'},
    {id: '1100', name: '11:00'},
    {id: '1200', name: '12:00'},
    {id: '1300', name: '13:00'},
    {id: '1400', name: '14:00'},
    {id: '1500', name: '15:00'},
    {id: '1600', name: '16:00'},
    {id: '1700', name: '17:00'},
    {id: '1800', name: '18:00'},
    {id: '1900', name: '19:00'},
    {id: '2000', name: '20:00'},
    {id: '2100', name: '21:00'},
    {id: '2200', name: '22:00'},
    {id: '2300', name: '23:00'},
]

export const HOURS_FOR_MULTIPLE = [
    {value: '000', label: '0:00 - 0:59'},
    {value: '100', label: '1:00 - 1:59'},
    {value: '200', label: '2:00 - 2:59'},
    {value: '300', label: '3:00 - 3:59'},
    {value: '400', label: '4:00 - 4:59'},
    {value: '500', label: '5:00 - 5:59'},
    {value: '600', label: '6:00 - 6:59'},
    {value: '700', label: '7:00 - 7:59'},
    {value: '800', label: '8:00 - 8:59'},
    {value: '900', label: '9:00 - 9:59'},
    {value: '1000', label: '10:00 - 10:59'},
    {value: '1100', label: '11:00 - 11:59'},
    {value: '1200', label: '12:00 - 12:59'},
    {value: '1300', label: '13:00 - 13:59'},
    {value: '1400', label: '14:00 - 14:59'},
    {value: '1500', label: '15:00 - 15:59'},
    {value: '1600', label: '16:00 - 16:59'},
    {value: '1700', label: '17:00 - 17:59'},
    {value: '1800', label: '18:00 - 18:59'},
    {value: '1900', label: '19:00 - 19:59'},
    {value: '2000', label: '20:00 - 20:59'},
    {value: '2100', label: '21:00 - 21:59'},
    {value: '2200', label: '22:00 - 22:59'},
    {value: '2300', label: '23:00 - 23:59'},
]

export const WEEKDAYS_FOR_MULTIPLE = [
    {value: 'monday', label: 'Lunes'},
    {value: 'tuesday', label: 'Martes'},
    {value: 'wednesday', label: 'Miércoles'},
    {value: 'thursday', label: 'Jueves'},
    {value: 'friday', label: 'Viernes'},
    {value: 'saturday', label: 'Sábado'},
    {value: 'sunday', label: 'Domingo'},
]

export const WEEKDAYS_LABELS_IN_SPANISH = {
    monday : 'Lunes',
    tuesday : 'Martes',
    wednesday : 'Miércoles',
    thursday : 'Jueves',
    friday : 'Viernes',
    saturday : 'Sábado',
    sunday : 'Domingo'
}

export const HOURS_LABELS = {
    '000' : '0:00 - 0:59',
    '100' : '1:00 - 1:59',
    '200' : '2:00 - 2:59',
    '300' : '3:00 - 3:59',
    '400' : '4:00 - 4:59',
    '500' : '5:00 - 5:59',
    '600' : '6:00 - 6:59',
    '700' : '7:00 - 7:59',
    '800' : '8:00 - 8:59',
    '900' : '9:00 - 9:59',
    '1000' : '10:00 - 10:59',
    '1100' : '11:00 - 11:59',
    '1200' : '12:00 - 12:59',
    '1300' : '13:00 - 13:59',
    '1400' : '14:00 - 14:59',
    '1500' : '15:00 - 15:59',
    '1600' : '16:00 - 16:59',
    '1700' : '17:00 - 17:59',
    '1800' : '18:00 - 18:59',
    '1900' : '19:00 - 19:59',
    '2000' : '20:00 - 20:59',
    '2100' : '21:00 - 21:59',
    '2200' : '22:00 - 22:59',
    '2300' : '23:00 - 23:59'
}

export const MOMENT_IN_SPANISH = {
    months : 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
    monthsShort : 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
    monthsParseExact : true,
    weekdays : 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
    weekdaysShort : 'dom_lun_mar_mie_jue_vie_sab'.split('_'),
    weekdaysMin : 'Do_Lu_Ma_Mi_Ju_Vi_Sa_Do'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'en %s',
        past : 'hace %s',
        s : 'unos segundos',
        m : 'un minuto',
        mm : '%d minutos',
        h : 'una hora',
        hh : '%d horas',
        d : 'un día',
        dd : '%d días',
        M : 'un mes',
        MM : '%d meses',
        y : 'un año',
        yy : '%d años'
    }
}

export const OPENPAY_ERRORS = {
    3001: 'La tarjeta fue rechazada',
    3002: 'La tarjeta ha expirado',
    3003: 'La tarjeta no tiene fondos suficientes',
    3004: 'La tarjeta ha sido identificada como una tarjeta robada',
    3005: 'La tarjeta ha sido rechazada por el sistema antifraudes',
}

export const CLOUD_FUNCTIONS_ERRORS = {
    ALREADY_RESERVED : 'Service is already reserved at selected time'
}

export const PROFILE_VIEWS = {
    edit: 'edit_profile',
    payment: 'payment_method',
    history: 'history',
    favorites: 'favorites',
    saved: 'saved',
    my_services: 'my_artistic_services',
    my_payments: 'my_payments_done',
    my_history: 'my_services_done',
    promoter: 'promoter',
    revenue: 'revenue'
}

export const NEW_SERVICE_VIEWS = {
    name: 'set_name_for_new_service',
    description: 'set_description_for_new_service',
    cost: 'set_cost_for_new_service',
    zones: 'set_zones_for_new_service',
    availability: 'set_availability_for_new_service',
    photos: 'set_photos_for_new_service',
    // video: 'set_video_for_new_service', ya no es obligatorio
    types: 'set_types_for_new_service',
    includes: 'set_includes_for_new_service',
    category: 'set_category_for_new_service'
}

export const EDIT_SERVICE_VIEWS = {
    name: 'set_name_for_edit_service',
    description: 'set_description_for_edit_service',
    cost: 'set_cost_for_edit_service',
    zones: 'set_zones_for_edit_service',
    availability: 'set_availability_for_edit_service',
    photos: 'set_photos_for_edit_service',
    video: 'set_video_for_edit_service',
    types: 'set_types_for_edit_service',
    includes: 'set_includes_for_edit_service',
    category: 'set_category_for_edit_service'
}

export const DEFAULT_PROFILE_IMAGE_URL = DefaultProfileImage//'https://firebasestorage.googleapis.com/v0/b/muzigzag-42a25.appspot.com/o/Sin%20foto%20perfil.pdf?alt=media&token=a2eb99ea-b1d6-4e1c-b3be-23c1248d3242'
export const DEFAULT_PDF_PROFILE_IMG = 'https://firebasestorage.googleapis.com/v0/b/muzigzag-42a25.appspot.com/o/Sin%20foto%20perfil.pdf?alt=media&token=a2eb99ea-b1d6-4e1c-b3be-23c1248d3242'

export const MIN_MESSAGES_TO_ACTIVATE_CHAT_OPTION = 2

export const FIREBASE_ERRORS = {
    CREATE_USER_EMAIL : {
        'auth/email-already-in-use' : 'Ya existe una cuenta con este correo, si estás registrado como usuario y deseas darlo de baja comunícate con nosotros.',
        'auth/invalid-email' : 'Este correo no es valido',
        'auth/operation-not-allowed' : 'Esta operación no está disponible.',
        'auth/weak-password' : 'La contraseña es demasiado débil.'
    },
    USER_LOGIN : {
        'auth/wrong-password' : 'El correo o la contraseña son incorrectos.',
        'auth/user-not-found' : 'El correo o la contraseña son incorrectos.',
        'auth/invalid-credential' : 'El proveedor no reconoce tus credenciales.',
        'auth/account-exists-with-different-credential' : 'Este correo de Facebook ya se encuentra registrado con contraseña. Intenta iniciar sesión con correo y contraseña.'
    }
}

/*Alerts*/
export const ALERTS = {
    SHOW_ERROR_MESSAGE : 'show_error_message',
    SHOW_SUCCESS_MESSAGE : 'show_success_message',
    CLOSE_ALERT : 'close_alert'
}

/*Shared*/
export const SHARED = {
    PAGE_IS_LOADING : 'page_is_loading',
    PAGE_STOP_LOADING : 'page_stop_loading',
    FETCH_COUNTRIES_SUCCESS : 'fetch_countries_success',
    CHANGE_ADDRESS_VALUE : 'change_address_value',
    FETCH_ZONES_OF_COUNTRY : 'fetch_zones_of_country',
    UNSELECT_ZONES_OF_LIST : 'unselect_zones_of_list',
    FETCH_EVENT_TYPES : 'fetch_event_types',
    SELECT_SIDEMENU_OPTION : 'select_sidemenu_option',
    ON_PAYMENT_SELECT_ERROR : 'on_payment_select_error',
    SELECT_NEW_SERVICE_STEP : 'select_new_service_step',
    COMPLETE_SERVICE_STEP : 'complete_service_step',
    INCOMPLETE_SERVICE_STEP : 'uncheck_service_step',
    SELECT_EDIT_SERVICE_STEP : 'select_edit_service_step',
    COMPLETE_EDIT_SERVICE_STEP : 'complete_edit_service_step',
    INCOMPLETE_EDIT_SERVICE_STEP : 'uncheck_edit_service_step',
    SELECT_TYPE_EVENT_TYPES : 'select_type_event_types',
    SELECT_TYPE_INCLUDES : 'select_type_includes',
    FETCH_INCLUDES : 'fetch_service_includes',
    FETCH_SUBCATEGORIES : 'fetch_services_subcategories'
}

/*Autenticacion*/
export const AUTH = {
    LOGIN_USER_LOADING : 'login_user_loading',
    LOGIN_USER_SUCCESS : 'login_user_success',
    LOGIN_USER_FAIL : 'login_user_fail',
    LOGOUT_USER : 'logout_user',
    EMAIL_REGEX : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    LOGIN_INCOMPLETE_USER : 'login_incomplete_user',
    FINISHED_SIGNUP_INCOMPLETE_ARTIST : 'finished_signup_incomplete_artist',
    FINISHED_SIGNUP_INCOMPLETE_CLIENT : 'finished_signup_incomplete_client',
    FINISHED_SIGNUP_ARTIST : 'finished_signup_artist',
    FINISHED_SIGNUP_CLIENT : 'finished_signup_client',
    FETCH_USER_CARDS : 'fetch_user_cards',
    FETCH_USER_NEW_SERVICES : 'fetch_user_new_services',
    TEMPORARY_UPDATE : 'auth_save_temporary_update',
    FINISHED_CONVERT_CLIENT_TO_ARTIST : 'auth_finished_convert_client_to_artist'
}

/*Datepicker*/
export const DATEPICKER = {
    ON_DATES_CHANGE : 'on_date_changes',
    ON_FOCUS_CHANGE : 'on_focus-change',
    ON_DATE_CHANGE : 'on_single_date_change',
    ON_SINGLE_FOCUS_CHANGE : 'on_single_focus_change',
    WEEK_DAYS : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    PUSH_TO_BLOCKED_WEEK_DAYS : 'push_to_blocked_week_days',
    RESET_BLOCKED_WEEK_DAYS : 'reset_blocked_week_days',
    CLEAR_SINGLE_DATE : 'clear_single_date'
}

/*Register*/
export const SIGNUP = {
    SELECT_TYPE_COUNTRIES : 'select_type_countries',
    SELECT_TYPE_ZONES : 'select_type_zones'
}

/*Artist*/
export const ARTISTS = {
    TYPE_ID : 1,
    TYPES : {
        MUSICAL : {
            name : 'musical',
            id : 0,
            text : 'Musical'
        },
        PERFORMANCE : {
            name : 'performance',
            id : 1,
            text : 'Performance'
        },
        PRODUCTION : {
            name : 'production',
            id : 2,
            text : 'Producción'
        },
        0 : 'musical',
        1 : 'performance',
        2 : 'production'
    },
    STATUS : {
        ACTIVE : 0,
        INACTIVE : 1
    },
    FETCH_LIST_SERVICES : 'fetch_artist_job_services_list',
    FETCH_LIST_PAYMENTS : 'fetch_artist_payments_list',
    FETCH_JOBS_DONE : 'fetch_artist_jobs_done_list',
    SELECT_TYPE_WEEKDAYS : 'artist_select_type_weekdays',
    SELECT_TYPE_WEEKDAYS_HOURS : 'artist_select_type_weekdays_hours',
    SELECT_MY_SERVICE_JOB : 'artist_select_my_service_job',
    FETCH_PROMOTER : 'artist_fetch_promoter',
    FETCH_USER_HISTORY : 'artist_fetch_user_history',
    FETCH_ARTISTS_HISTORY : 'artist_fetch_artist_history'
}

/*Clients*/
export const CLIENTS = {
    TYPE_ID : 0,
    STATUS : {
        ACTIVE : 0,
        INACTIVE : 1
    }
}

/*Services*/
export const SERVICES = {
    FETCH_LIST_SERVICES : 'fetch_list_services',
    FETCH_CATEGORY_LIST : 'fetch_category_list',
    FETCH_HOME_SERVICES : 'fetch_home_services',
    SELECT_SERVICE : 'select_service',
    FETCH_SERVICE : 'fetch_service',
    FETCH_SERVICE_INCLUDES : 'fetch_service_includes',
    FETCH_SERVICE_CATEGORY : 'fetch_service_subcategory',
    FETCH_SERVICE_COMMENTS : 'fetch_service_comments',
    FETCH_ARTIST_IMAGE : 'services_fetch_artist_image',
    SET_CURRENCY_CODE : 'services_set_currency_code',
    STATUS : {
        0 : 'en revisión',
        1 : 'activo',
        2 : 'inactivo',
        3 : 'rechazado',
        4 : 'suspendido',
        REVIEWING: 0,
        ACTIVE: 1,
        INACTIVE: 2,
        REJECTED: 3,
        SUSPENDED: 4
    },
    TYPE: {
        RESERVATION: "reservation",
        REQUEST: "request"
    }
}

/*Categories*/
export const CATEGORIES = {
    musical : {
        id: 0,
        text: 'Musical',
        url: 'musical'
    },
    performance : {
        id: 1,
        text: 'Performance',
        url: 'performance'
    },
    produccion: {
        id: 2,
        text: 'Producción',
        url: 'produccion'
    }
}

/*Bookings*/
export const BOOKINGS = {
    FETCH_BOOKINGS_OF_SERVICE : 'fetch_booking_of_service',
    GENERATE_HOURS_FOR_DAY : 'generate_hours_for_day',
    PUSH_TO_BLOCKED_HOURS : 'push_to_blocked_hours',
    DELETE_PREVIOUS_HOURS_FROM_BOOKING : 1,
    DELETE_NEXT_HOURS_FROM_BOOKING : 1,
    SET_DURATION_LIMIT_AT: 'set_duration_limit_at',
    SAVE_FILTER_OPTIONS_FOR_BOOKING: 'save_filter_options_for_booking',
    CANCEL_REQUEST_BY_USER: 'cancel_request_by_user',
    PUSH_TO_BLOCKED_EXTRA_HOURS : 'push_to_blocked_extra_hours',
    SELECT_EXTRA_HOURS_DURATION : 'select_extra_hours_duration',
    SELECT_PAYMENT_OPTION : 'select_payment_option',
    MINIMUM_HOURS_PREVIOUS_RESERVATION : 2 //sumar una hora a la cantidad minima que requiera el negocio
}

/*User*/
export const USER = {
    FETCH_MY_REQUESTED_SERVICES : 'fetch_my_requested_services',
    FETCH_MY_BOOKED_SERVICES : 'fetch_my_booked_services',
    SELECT_MY_SERVICE : 'select_my_service',
    FETCH_MY_SERVICE : 'fetch_my_current_service',
    FETCH_MY_CHAT : 'fetch_my_current_service_chat',
    FETCH_USER_DATA : 'fetch_user_data',
    FETCH_HISTORY : 'fetch_user_history',
    FETCH_FAVORITES : 'fetch_user_favorites',
    FETCH_SAVED : 'fetch_user_saved',
    FETCH_PENDING_RATES : 'fetch_pending_rates',
    CLEAN_CURRENT_DATA : 'clean_user_current_data'
}

export const SERVER_CODES = {
    VALIDATE_EMAIL: {
        AVAILABLE: 'CEA-001',
        PROVIDER_PASSWORD: 'CEA-002',
        PROVIDER_FACEBOOK: 'CEA-003',
        LIBERATED: 'CEA-004',
        PROVIDER_UNKNOWN: 'CEA-005',
        ADMIN: 'CEA-006'
    }
}

//Constante temporal, debe eliminarse al implementarse bien el cambio de moment.locale
export const LOCAL_WEEKDAYS = {
    monday : 'monday',
    tuesday : 'tuesday',
    wednesday : 'wednesday',
    thursday : 'thursday',
    friday : 'friday',
    saturday : 'saturday',
    sunday : 'sunday',
    lunes : 'monday',
    martes : 'tuesday',
    'miercoles' : 'wednesday',
    jueves : 'thursday',
    viernes : 'friday',
    'sábado' : 'saturday',
    domingo : 'sunday',
}