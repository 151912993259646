import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'

import MultipleFileInput from 'components/atoms/inputs/generic_multiple_files_input'
import { completeEditServiceStep, selectEditServiceStep, uncheckEditServiceStep } from 'actions/shared'
import { deleteServiceImage, uploadServicePhotos, fetchJobService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'
import { ServicePhoto, DeletePhotoOption } from './styled'
import DeletePhotoModal from 'components/organisms/modal_generic_accept'

class EditServicePhotos extends Component{
    constructor(props){
        super(props)
        this.state = {
            modalDeletePhoto: false,
            imageToDelete: null
        }
        this.validatePhotos = this.validatePhotos.bind(this)
        this.renderPhotos = this.renderPhotos.bind(this)
        this.renderPreviousPhotos = this.renderPreviousPhotos.bind(this)
        this.toogleDeletePhotoModal = this.toogleDeletePhotoModal.bind(this)
        this.onAcceptDeletePhoto = this.onAcceptDeletePhoto.bind(this)
    }

    validatePhotos(photos){
        if(!photos && this.props.job_service_selected.images.length === 0){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.photos)
            return 'Debes subir al menos una imagen.'
        }
        if(photos && photos.files){
            if(photos.files.length > 10){
                this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.photos)
                return 'No puedes subir más de 10 imágenes'
            }

            const filesToBig = _.filter(photos.files, photo => {
                return photo.size/1000000 > 10 
            })

            if(filesToBig.length > 0){
                this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.photos)
                if (filesToBig.length === 1)
                    return `${filesToBig.length} archivo es mayor a 10 MB.`
                else
                    return `${filesToBig.length} archivos son mayores a 10 MB.`
            }

            const allowedExtensions = /(\.svg|\.ssvg|\.jpg|\.jpeg|\.png)$/i
            const filesWrongExt = _.filter(photos.files, photo => {
                return !allowedExtensions.exec(photo.name)
            })
            if(filesWrongExt.length > 0){
                this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.photos)
                if (filesWrongExt.length === 1)
                    return `1 archivo tiene una extensión que no se permite subir. Sólo se permiten JPG, JPEG, PNG y SVG`
                else
                    return `${filesWrongExt.length} archivos tienen extensiones que no se pueden subir. Sólo se permiten JPG, JPEG, PNG y SVG`
            }
        }
        else if(photos && Object.keys(photos).length === 0){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.photos)
            return 'Debes seleccionar al menos una foto si quieres guardar los cambios.'
        }

        else if(typeof(photos) === 'undefined'){
            //this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.photos)
            return 'Debes seleccionar al menos una foto si quieres guardar los cambios.'
        }
        
    }

    renderPreviousPhotos(){
        if(this.props.job_service_selected.images && this.props.job_service_selected.images.length > 0)
        return(
            <div className='mt-5'>
                <h5 className='text-secondary'>Fotos previamente guardadas</h5>
                <div className='d-flex flex-wrap'>
                    {this.renderPhotos()}
                </div>
            </div>
        )
    }

    renderPhotos(){
        return _.map(this.props.job_service_selected.images, image => {
            return(
                <div className='d-flex flex-column' key={image}>
                    <ServicePhoto 
                        src={image} 
                        className='mx-2'
                    />
                    <DeletePhotoOption 
                        className='bg-danger text-center text-white mx-2 p-2'
                        onClick={ () => this.toogleDeletePhotoModal(image)}
                    >
                        Borrar
                    </DeletePhotoOption>
                </div>
            )
        })
    }

    toogleDeletePhotoModal(image){
        if(image)
            this.setState({
                imageToDelete : image,
                modalDeletePhoto: !this.state.modalDeletePhoto
            })
        else    
            this.setState({
                imageToDelete : null,
                modalDeletePhoto: !this.state.modalDeletePhoto
            })
    }

    onAcceptDeletePhoto(){
        const imageURL = this.state.imageToDelete
        const service = this.props.job_service_selected.id
        const imageName = imageURL.match(new RegExp(`${service}%2F(.*)\\?`))[1]

        let newImagesArray = _.filter(this.props.job_service_selected.images, image => {
            return image !== imageURL
        })

        this.toogleDeletePhotoModal()
        this.props.deleteServiceImage(service, newImagesArray, imageName)
        const service_id = window.location.href.split('/').pop()
        this.props.fetchJobService(service_id)
    }

    onSubmit(values){
        const newImages = values.photos.files
            const currentImages = this.props.job_service_selected.images
            const service_id = this.props.job_service_selected.id

            this.props.uploadServicePhotos(service_id, currentImages, newImages, () => {
                this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.photos)
                const service_id = window.location.href.split('/').pop()
                this.props.fetchJobService(service_id)
                this.props.change('photos', null)
            })
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>Fotos</h2>
                <p>La primera foto será la de la portada, te recomendamos que selecciones alguna en landscape (horizontal) para mostrar mejor tu servicio. 
                    Puedes agregar un máximo de 10 fotos con un límite de 10 MB por cada una.
                    Se admiten los siguientes formatos: JPG, JPEG, PNG y SVG.
                </p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="photos"
                        type="file"
                        label="Agrega una foto"
                        maxphotos={10}
                        validate={this.validatePhotos}
                        component={MultipleFileInput}
                    />
                    {this.renderPreviousPhotos()}
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
                <DeletePhotoModal
                    isOpen={this.state.modalDeletePhoto}
                    toogle={this.toogleDeletePhotoModal}
                    onClickAcceptButton={this.onAcceptDeletePhoto}
                    modalHeaderText='Eliminar imagen de mi servicio'
                    modalBodyText='¿Estás seguro de eliminar esta imagen?'
                    acceptButtonColor='danger'
                    acceptButtonText='Eliminar'
                    cancelButtonColor='secondary'
                    cancelButtonText='Cancelar'
                />
            </>
        )
    }
}

function mapStateToProps({ artist }){
    const { job_service_selected } = artist
    return { job_service_selected }
}

EditServicePhotos = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(EditServicePhotos)
  
EditServicePhotos = connect(
    mapStateToProps,
    { 
        completeEditServiceStep, 
        selectEditServiceStep, 
        uncheckEditServiceStep,
        deleteServiceImage,
        uploadServicePhotos,
        fetchJobService,
        change
    }
  )(EditServicePhotos)
  
  export default EditServicePhotos