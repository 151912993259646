import React from 'react'

import {
    InputGroup,
    InputGroupAddon,
    FormGroup
} from 'reactstrap'
import { StyledInput, InputIcon, InvalidFeedback } from './styled'

export default ( field ) => {
    const { meta: {error, touched} } = field
    const isInvalid = touched && error ? true : false
    const colorClass = touched && error ? 'danger' : 'gray'

    return(
        <FormGroup>
            <InputGroup className={`border border-${colorClass} border-2 rounded p-1 w-100`}>
                <InputGroupAddon addonType="prepend" className='d-flex align-items-center'>
                    <InputIcon src={field.icon}/>
                </InputGroupAddon>
                <StyledInput
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    invalid={isInvalid}
                    autoComplete={field.autocomplete}
                    rows={field.rows}
                    {...field.input}
                />
            </InputGroup>
            <InvalidFeedback className='mx-3 text-danger'>{ isInvalid ? error : ''}</InvalidFeedback>
        </FormGroup>
    )
}