import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import { changeProfileImage } from 'actions/user'

import { ClientCard, ClientCardRow, UserImage, Divisor, ImageForm } from './styled'
import FileInput from 'components/atoms/inputs/change_image_input'
import { DEFAULT_PROFILE_IMAGE_URL, DEFAULT_PDF_PROFILE_IMG } from 'constants/index'

class UserProfileCard extends Component{
    onSubmit(values){
        if(!isEmpty(values.profile_image))
            this.props.changeProfileImage(this.props.user_id, values.profile_image)
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <ClientCard className='bg-white shadow-sm'>
                <ClientCardRow className='row no-gutters rounded'>
                    <div className='col-4 d-flex align-items-center justify-content-center rounded'>
                        <UserImage alt='' src={
                            this.props.user.profile_image && this.props.user.profile_image !== DEFAULT_PDF_PROFILE_IMG ?
                                this.props.user.profile_image
                                :
                                DEFAULT_PROFILE_IMAGE_URL
                        }/>
                        <ImageForm onChange={() => setTimeout(handleSubmit(params => this.onSubmit(params)))}>
                            <Field
                                name="profile_image"
                                type='file'
                                label=""
                                component={FileInput}
                                
                            />
                        </ImageForm>
                    </div>
                    <div className='col-8 d-flex flex-column align-items-center justify-content-center'>
                        <h2 className='text-secondary'>{`${this.props.user.name} ${this.props.user.last_name}`}</h2>
                        <Divisor className='bg-secondary my-2'/>
                        <div className='row no-gutters d-flex align-items-baseline'>
                            <div className='col-4 d-flex align-items-end'>
                                <h2>{this.props.user.rating}%</h2>
                            </div>
                            <div className='col-8'>
                                <h5 className='text-secondary'>Calificación</h5>
                            </div>
                        </div>

                    </div>
                </ClientCardRow>
            </ClientCard>
        )
    }
}

function validate(values){
    let errors = {}
    
    if(values.profile_image && values.profile_image.file){
        const photo = values.profile_image.file
        if(photo.size/1000000 > 10 )
            errors.profile_image = 'Tu imagen es demasiado grande. Límite a 10 MB.'
    
        const allowedExtensions = /(\.svg|\.ssvg|\.jpg|\.jpeg|\.png)$/i
        if(!allowedExtensions.exec(photo.name))
            errors.profile_image = 'Sólo se aceptan archivos PNG, SVG, JPG o JPEG.'
    }
    return errors
}

function mapStateToProps({ auth }){
    const { user } = auth
    const user_id = auth.meta.user.uid

    return { user, user_id }
}

export default reduxForm({
    form: 'changeImageProfile',
    validate
})(connect(
    mapStateToProps,
    { changeProfileImage }
)(UserProfileCard))