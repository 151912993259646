import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import { 
    completeEditServiceStep, 
    selectEditServiceStep, 
    uncheckEditServiceStep,
    getIncludesList } from 'actions/shared'
import { updateService, fetchJobService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS, SHARED } from 'constants/index'
import MultipleSelect from 'components/atoms/inputs/multiple_select'
import Button from 'components/atoms/buttons/solid'
import { getIncludes } from 'helpers/index'

class EditServiceIncludes extends Component{
    constructor(props){
        super(props)
        this.minLength1 = this.minLength1.bind(this)
    }

    componentDidMount(){
        this.props.getIncludesList()
    }

    onSubmit(values){
        const change = { contains : getIncludes(values) }
        this.props.updateService(change, this.props.job_service_selected.id, () => {
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.includes)
            const service_id = window.location.href.split('/').pop()
            this.props.fetchJobService(service_id)
        })
    }
    
    minLength1(values = []){
        if(values.length < 1){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.includes)
            return 'Tienes que elegir al menos una opción'
        }
        else
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.includes)
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>¿Qué incluye?</h2>
                <p>¿Utilizas artefactos o distracciones? No olvides agregar lo que ofreces en tu show.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="includes"
                        type='select'
                        selectType={SHARED.SELECT_TYPE_INCLUDES}
                        placeholder='Selecciona al menos una opción'
                        options={this.props.includes_options}
                        component={MultipleSelect}
                        validate={[this.minLength1]}
                    />
            
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps({ shared, form, artist }){
    let initialIncludes = []
    let includesById = {}
    if(form.editServiceWizard && form.editServiceWizard.values)
        if(typeof form.editServiceWizard.values['includes'] !== 'undefined')
            initialIncludes = form.editServiceWizard.values['includes']

    let includesArray = []
    let { includes } = shared
    const { job_service_selected } = artist
    includes.forEach( include => {
        includesArray.push({ value:include.id, label:include.name })
        includesById[include.id] = include.name
    })

    /*VALORES INICIALES*/
    let includesArrayOptions = []

    if (job_service_selected.contains){
        includesArrayOptions = _.map(job_service_selected.contains, include => {
            return { value: include, label: includesById[include] }
        })
    }

    const initialValues = {
        'includes' : includesArrayOptions,
    }
    
    return { includes_options: includesArray, initialIncludes, job_service_selected, initialValues }
}

EditServiceIncludes = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true
  })(EditServiceIncludes)
  
  EditServiceIncludes = connect(
    mapStateToProps,
    { 
        completeEditServiceStep, 
        selectEditServiceStep, 
        uncheckEditServiceStep,
        getIncludesList,
        updateService,
        fetchJobService
    }
  )(EditServiceIncludes)
  
  export default EditServiceIncludes