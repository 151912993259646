import React, { Component } from 'react'
import UserSavedList from 'components/organisms/user_saved_list'

class Saved extends Component{
    render(){
        return(
            <>
                <section className='p-4'>
                    <h4 className='text-secondary'>Guardados</h4>
                    <UserSavedList/>
                </section>
            </>
        )
    }
}

export default Saved