import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form
} from 'reactstrap'

import DatePicker from 'components/organisms/date_picker'
import OptionSelect from 'components/atoms/inputs/generic_option_select'
import MultipleSelect from 'components/atoms/inputs/multiple_select'

import { getEventTypesList } from 'actions/shared'

import { HOURS, SHARED } from 'constants/index'

class SearchFilterModal extends Component{

    componentDidMount(){
        this.props.getEventTypesList()
    }
    
    render(){
        const { handleSubmit } = this.props
        
        return(
            <Modal
                isOpen={this.props.isOpen}
            >
                <Form onSubmit={handleSubmit(this.props.callSearchFilter)}>
                    <ModalHeader 
                        toggle={ () => this.props.toggleSearchFilter()}
                        className='text-secondary'>
                        {this.props.modalHeaderText}
                    </ModalHeader>
                    <ModalBody>
                        {this.props.modalBodyText}
                        <div className='my-4'>
                            <h5 className='text-secondary'>¿Cuándo?</h5>
                            <DatePicker/>
                        </div>
                        <div className='my-4'>
                            <h5 className='text-secondary'>Hora</h5>
                            <Field
                                name="hour"
                                type="select"
                                component={OptionSelect}
                                options={HOURS}
                            />
                        </div>
                        <div className='my-4'>
                            <h5 className='text-secondary'>Tipo de evento</h5>
                            <Field
                                name="event-type"
                                type='select'
                                selectType={SHARED.SELECT_TYPE_EVENT_TYPES}
                                placeholder='Selecciona una o varias opciones'
                                options={this.props.eventTypes}
                                component={MultipleSelect}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter className='text-center'>
                        <Button type="submit" color="secondary" size="lg" block className='rounded'>Filtrar</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

function mapStateToProps({ shared, form }){
    const { eventTypes } = shared
    let typesArray = []
    eventTypes.forEach( type => typesArray.push({ value:type.id, label:type.name }) )

    const initialValues = {
        'hour' : typeof form.SearchForm !== 'undefined' && typeof form.SearchForm.values !== 'undefined' ? form.SearchForm.values['hour'] : null
    }
    
    return { eventTypes: typesArray, initialValues }
}

SearchFilterModal = reduxForm({
    form: 'SearchForm',
    destroyOnUnmount: false,
})(SearchFilterModal)
  
SearchFilterModal = connect(
mapStateToProps,
{ getEventTypesList }
)(SearchFilterModal)
  
  
  export default SearchFilterModal
