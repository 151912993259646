import styled from 'styled-components'

export const BottomDiv = styled.div`
    bottom: 0;
    left: 0;
    position: absolute;
`

export const ImageLike = styled.img`
    width:1rem;
`

export const ImageMedal = styled.img`
    margin-right: 0.4rem;
    width: 4rem;
    @media(max-width: 768px){
        width:2.6rem;
    }
    @media(max-width: 425px){
        width:2.4rem;
    }
`

export const DivCover = styled.div`
    background-image: url('${props => props.src}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 8rem;
    @media(max-width: 768px){
        border-radius: 0.5rem 0.5rem 0 0 !important;
        min-height: 12rem;
    }
    @media(max-width: 425px){
        border-radius: 0.5rem 0.5rem 0 0 !important;
        min-height: 10rem;
    }
`

export const Card = styled.div`
    cursor: pointer;
    height: 16rem;
    max-width: 16rem;
    min-width: 16rem;
    @media(max-width: 768px){
        border-radius: 0.5rem!important;
        height: 20rem;
        max-width: 14rem;
        min-width: 14rem;
    }
    @media(max-width: 425px){
        border-radius: 0.5rem!important;
        height: 18rem;
        max-width: 12rem;
        min-width: 12rem;
    }
`