import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import UserCardsList from 'components/organisms/user_card_list'
import AddCardForm from 'components/organisms/add_card_form'
import * as routes from 'constants/routes'

class CardsSettings extends Component {

    render(){
        return(
            <>
                <section className='p-4'>
                    { isEmpty(this.props.cards) ? 
                        ''
                        :
                        <div className='mb-5'>
                            <h4 className='text-secondary'>Mis tarjetas</h4>
                            <UserCardsList 
                                redirectTo={routes.ADD_HOURS_TO_SERVICE.replace(':id', this.props.my_current_service.service_id)}/>
                        </div>
                    }
                    <h4 className='text-secondary'>Agregar tarjeta</h4>
                    <AddCardForm route={routes.ADD_HOURS_TO_SERVICE.replace(':id', this.props.my_current_service.service_id)}/>
                </section>
            </>
        )
    }

}

function mapStateToProps({ user, auth }){
    const { my_current_service } = user
    const { user: { cards } } = auth
    
    
    return { my_current_service, cards }
}

export default connect(mapStateToProps, {})(CardsSettings)