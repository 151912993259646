import { DATEPICKER } from 'constants/index'

const INITIAL_STATE = { 
    startDate: null,
    endDate: null,
    focusedInput: null,
    singleDate: null,
    singleFocusedInput: false,
    blockedDays: [],
    blockedWeekDays: []
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case DATEPICKER.ON_DATES_CHANGE:
            return { ...state, startDate: action.payload.startDate, endDate: action.payload.endDate }
        
        case DATEPICKER.ON_FOCUS_CHANGE:
            return { ...state, focusedInput: action.payload }
        
        case DATEPICKER.ON_DATE_CHANGE:
            return { ...state, singleDate: action.payload }

        case DATEPICKER.ON_SINGLE_FOCUS_CHANGE:
            return { ...state, singleFocusedInput: action.payload }

        case DATEPICKER.PUSH_TO_BLOCKED_WEEK_DAYS:
            return { ...state, blockedWeekDays: [ ...state.blockedWeekDays, action.payload] }
        
        case DATEPICKER.RESET_BLOCKED_WEEK_DAYS:
            return { ...state, blockedWeekDays: [] }
        
        case DATEPICKER.CLEAR_SINGLE_DATE:
            return { ...state, singleDate: null }
        
        default:
            return state
    }
}
