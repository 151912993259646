import styled from 'styled-components'
import { Button } from 'reactstrap'

export const StyledCarouselItem = styled.div`
    height: 35rem;
`

export const StyledCarouselImage = styled.img`
    height: 35rem;
    object-fit: contain;
`

export const StyledCarouselVideo = styled.iframe`
    height: 35rem;
    width: 100%;
`

export const HeartImage = styled.img`
    width: 3rem;
`

export const StyledBadge = styled.span`
    border-radius: 1rem!important;
    height: 4rem;
    @media(max-width: 768px){
        border-radius: 1rem!important;
        height: 2.6rem;
    }
    @media(max-width: 425px){
        border-radius: 1rem!important;
        height: 2rem;
    }
`

export const BookingFooter = styled.section`
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 2;
`

export const StyledButton = styled(Button)`
    border-radius: 2rem!important;
    padding: 0.2rem 5rem;
    min-width: 15rem;
`

export const IncludeImg = styled.img`
    width: 5rem;
`

export const SmallCategoryImage = styled.img`
    width: 2rem;
    @media(max-width: 768px){
        width: 1.4rem;
    }
    @media(max-width: 425px){
        width: 1.2rem;
    }
`

export const MiniText = styled.span`
    font-size: 0.8rem;
`

export const ImageMedal = styled.img`
    width:2rem;
`

export const CarouselSection = styled.section`
    .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
        opacity: 0.8!important;
        width: 4rem;
        @media(max-width: 425px){
            width: 2.5rem;
        }
    }
    .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
        border-top: 2rem solid transparent!important;
        border-bottom: 2rem solid transparent!important;
        @media(max-width: 425px){
            border-top: 1.2rem solid transparent!important;
            border-bottom: 1.2rem solid transparent!important;
        }
    }
    .carousel .control-next.control-arrow:before{
        border-left: 2rem solid #fff;
        @media(max-width: 425px){
            border-left: 1.2rem solid #fff;
        }
    }
    .carousel .control-prev.control-arrow:before{
        border-right: 2rem solid #fff;
        @media(max-width: 425px){
            border-right: 1.2rem solid #fff;
        }
    }
`

export const DescriptionP = styled.p`
    white-space: pre-wrap;
`