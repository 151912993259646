import _ from 'lodash'
import React from 'react'

import {
    Label,
    FormGroup,
} from 'reactstrap'

import { FileInput, FileDiv, FileThumb, ActiveFormFeedback, InputTitle } from './styled'
import { toFixed } from 'helpers/index'

export default ( field ) => {
    
    const { meta: {error, touched} } = field
    const isInvalid = touched && error ? true : false
    let errorMessage = touched ? error : null
    const { input: {onChange, onBlur, value: omitValue, ...inputProps} } = field
    const { ...props } = field
    let filesArray = []

    if(field.input.value && typeof(field.input.value.files) != 'undefined'  ) {
        filesArray = _.map(field.input.value.files, file =>  file )
    }

    
    const handleChange = handler => ({ target: {files} }) => {
                            
                          return handler(files.length > 0 ? {files : files} : {} )
                        }

    const renderFilesThumbs = () => {
        // if(files.length > field.maxphotos)
        //     files = files.slice(0,10)

        return _.map(filesArray, file => {
            return (
                <div
                className='d-flex flex-column my-4' 
                key={file.name}>
                    <FileThumb 
                        src={window.URL.createObjectURL(file)}
                        className='my-1'
                    />
                    <span className={`my-1 ${file.size/1000000 <= 10 ? 'text-secondary' : 'text-danger'}`}>
                        {file.name} - {toFixed(file.size/1000000,2)} MB. <br/>
                        {file.size/1000000 > 10 ? 'EL ARCHIVO SOBREPASA EL LÍMITE DE 10 MB' : ''}
                    </span>
                </div>
            )
        })
    }

    return(
        <FormGroup className="bg-white">
            <Label className='d-flex flex-column'>
                {filesArray.length > 0 ? renderFilesThumbs() : ''}
            </Label>
            <FileDiv className='bg-gray-sidebar'>
                <FileInput
                    onChange={handleChange(onChange)}
                    onBlur={handleChange(onBlur)}
                    multiple
                    { ...inputProps } { ...props }
                />
                <InputTitle className='text-secondary text-center'>Presiona para seleccionar tus archivos</InputTitle>
            </FileDiv>
            <ActiveFormFeedback invalid={isInvalid.toString()}>{errorMessage}</ActiveFormFeedback>
        </FormGroup>
    )
}