import React, { Component } from 'react'
import UserSavedList from 'components/organisms/user_saved_list'

class UserSaved extends Component{
    render(){
        return (
            <>
                <UserSavedList/>
            </>
        )
    }
}

export default UserSaved