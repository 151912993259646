import React, { Component } from 'react'
import { RoundedButton } from './styled'

export default class SolidButton extends Component {
    render(){
        return(
            <>
                <RoundedButton 
                    outline
                    color={"warning"}
                    className='shadow-sm m-2 text-white'
                    onClick={this.props.onClick}>
                    {this.props.text}
                </RoundedButton>
            </>
        )
    }
}