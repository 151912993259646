import _ from 'lodash'
import axios from 'axios'
import { SERVICES, ALERTS, SHARED, CLOUD_FUNCTIONS_URL } from 'constants/index'
import { firestore } from 'firebase/index'
import moment from 'moment'
import { MOMENT_IN_SPANISH } from 'constants/index'

export function findServicesWithFilter(values, callback){
  moment.updateLocale('es', MOMENT_IN_SPANISH)

  return dispatch => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })

    const url = `${CLOUD_FUNCTIONS_URL}/searchServices`
    const headers = { headers: { 'Content-Type':'application/json' } }
    let obj_data = {}
    
    if(values['search-query'])
      obj_data.term = values['search-query']
    if(values.date)
      obj_data.date = moment(values.date, 'DD-MM-YYYY').valueOf()
    if(values.hour_selected)
      obj_data.hour = values.hour_selected
    if(values['event-types'])
      obj_data.event_types = values['event-types']
    if(values.country)
      obj_data.country = values.country

    const data = JSON.stringify({ ...obj_data })
    
    let axios_request = axios.create({
        validateStatus : status => { return status <= 500 }
    })
    axios_request.post(url, data, headers)
    .then(response => {
        if(response.data.success){
          dispatch({
            type: SERVICES.FETCH_LIST_SERVICES,
            payload: response.data.services
          })
          callback()
          dispatch({ type: SHARED.PAGE_STOP_LOADING })
        }
        else {
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ocurrió un error con tu búsqueda, por favor inténtalo nuevamente.'
            })
        }
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
    })
    .catch(error => {
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
        dispatch({
            type: ALERTS.SHOW_ERROR_MESSAGE,
            payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
        })
        console.log('Error mayor a 500 desde servidor.', error)
    })
  }
}

export function fetchCategoryList(category, user_country = null){
  let servicesArray = []

  return dispatch => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })

    firestore.collection("services")
    .where('category', '==', category.id)
    .where('status', '==', 1)
    .get().then( querySnapshot => {
      querySnapshot.forEach( doc => {
          let sid = { id:doc.id }
          let data = doc.data()
          servicesArray.push({ ...sid, ...data })
      })

      let servicesWithCountryFound = []
      let servicesCountryNotFound = []
      if(user_country)
        for(let i=0; i<servicesArray.length; i++){
          const finder = _.find(servicesArray[i].countries, country => {
            return country === user_country
          })
          //le damos preferencia al país del usuario
          if(typeof finder !== 'undefined')
              servicesWithCountryFound.push(servicesArray[i])
          else
            servicesCountryNotFound.push(servicesArray[i]) 
        }
      else
        servicesWithCountryFound = servicesArray

      //const servicesOrderedByCountry = _.concat(servicesWithCountryFound, servicesCountryNotFound)
      
      
      dispatch({
        type: SERVICES.FETCH_CATEGORY_LIST,
        payload: servicesWithCountryFound,
        category: category
      })

      dispatch({ type: SHARED.PAGE_STOP_LOADING })

    })
    .catch( error => {
      dispatch({ type: SHARED.PAGE_STOP_LOADING })
      console.log(dispatch, error)
    })
  }
}

export function fetchHomeServices(user_country){
  let servicesArray = []

  return dispatch => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })

    firestore.collection("home")
    .get().then( querySnapshot => {
      querySnapshot.forEach( doc => {
          let sid = { id:doc.id }
          let data = doc.data()
          servicesArray.push({ ...sid, ...data })
      })

      
      let servicesWithCountryFound = []
      let servicesCountryNotFound = []

      for(let i=0; i<servicesArray.length; i++){
        const finder = _.find(servicesArray[i].countries, country => {
          return country === user_country
        })
        //le damos preferencia al país del usuario
        if(typeof finder !== 'undefined')
            servicesWithCountryFound.push(servicesArray[i])
        else
          servicesCountryNotFound.push(servicesArray[i]) 
      }

      // const servicesOrderedByCountry = _.concat(servicesWithCountryFound, servicesCountryNotFound)
      // Que ahora ya no se muestra nada...
      const servicesOrderedByCountry = servicesWithCountryFound
      
      dispatch({
        type: SERVICES.FETCH_HOME_SERVICES,
        payload: servicesOrderedByCountry
      })
      dispatch({ type: SHARED.PAGE_STOP_LOADING })

    })
    .catch( error => {
      dispatch({ type: SHARED.PAGE_STOP_LOADING })
      console.log(dispatch, error)
    })
  }
}

export function selectService(service, callback){
  return dispatch => {
    dispatch({
      type: SERVICES.SELECT_SERVICE,
      payload: service
    })
    callback()
  }
}

firestore.collection('includes').constructor.prototype.getIncludesOfService = function(includes) {
  return Promise.all(
    _.map(includes, include => {
      return firestore
            .collection(`includes`)
            .doc(include)
            .get()
    })
  )
}

export function fetchService(service_id, callback = ()=>{}){
  return dispatch => {
    let includesArray = []
    dispatch({ type: SHARED.PAGE_IS_LOADING })

    firestore.collection('services').doc(service_id)
    .get().then( doc => {
      if (doc.exists){
        let sid = { id:doc.id }
        let data = doc.data()
        Object.assign(data, sid)

        dispatch({
          type: SERVICES.FETCH_SERVICE,
          payload: data
        })

        firestore.collection('includes')
        .getIncludesOfService(data.contains)
        .then( includes => {
          includes.forEach( doc => {
            let sid = { id:doc.id }
            let data = doc.data()
            includesArray.push({ ...sid, ...data })
        })
          dispatch({
            type: SERVICES.FETCH_SERVICE_INCLUDES,
            payload: includesArray
          })
    
        })
        .catch(error => {
          console.log('No se logró conseguir la información de los qué incluye de este servicio', error)
        })

        firestore.collection('categories').doc(data.subCategory)
        .get().then( doc => {
          let cid = { id:doc.id }
          let category = doc.data()
          Object.assign(category, cid)

          dispatch({
            type: SERVICES.FETCH_SERVICE_CATEGORY,
            payload: category
          })

          dispatch({ type: SHARED.PAGE_STOP_LOADING })

          callback()
          
        })



      }
      else
        dispatch({
          type: ALERTS.SHOW_ERROR_MESSAGE,
          payload: 'No existe este servicio, lamentamos los inconvenientes.'
        })
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
    })
    .catch( error => {
      dispatch({ type: SHARED.PAGE_STOP_LOADING })
      console.log(dispatch, error)
    })
  }
}

export function fetchServiceComments(service_id){
  return dispatch => {
    let commentsArray = []

    firestore.collection('services').doc(service_id).collection('comments')
    .get().then( comments => {
      comments.forEach( doc => {
        let sid = { id:doc.id }
        let data = doc.data()
        commentsArray.push({ ...sid, ...data })
      })
      dispatch({
        type: SERVICES.FETCH_SERVICE_COMMENTS,
        payload: commentsArray
      })
    })
    .catch( error => {
      console.log(dispatch, error)
    })
  }
}

export function fetchServiceAvailbility(service_id, callback){
  return dispatch => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })

    firestore.collection('services').doc(service_id)
    .get().then( doc => {
      if (doc.exists){
        let sid = { id:doc.id }
        let data = doc.data()
        Object.assign(data, sid)

        dispatch({
          type: SERVICES.FETCH_SERVICE,
          payload: data
        })
        dispatch({ type: SHARED.PAGE_STOP_LOADING })

        callback()
      }
      else
        dispatch({
          type: ALERTS.SHOW_ERROR_MESSAGE,
          payload: 'No existe este servicio, lamentamos los inconvenientes.'
        })
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
    })
    .catch( error => {
      dispatch({ type: SHARED.PAGE_STOP_LOADING })
      console.log(dispatch, error)
    })
  }
}

export function fetchArtistImage(artist_id){
  return dispatch => {
      firestore.collection('users').doc(artist_id)
      .get().then( doc => {
          if (doc.exists){
              const artist_image = doc.data().profile_image
      
              dispatch({
                  type: SERVICES.FETCH_ARTIST_IMAGE,
                  payload: artist_image
              })
          }
      })
      .catch(error => {
          console.log('No se ha podido recuperar la imagen de perfil del artista.', error)
      })
  }
}

export function setCurrencyCode(currency_code, callback){
  return dispatch => {
    dispatch({
      type: SERVICES.SET_CURRENCY_CODE,
      payload: currency_code
    })
    callback()
  }
}