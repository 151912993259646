import { 
    ARTISTS
} from 'constants/index';

const INITIAL_STATE = { 
        job_services : [],
        payments : [],
        weekly_payments : [],
        end_of_week : '',
        job_service_selected : {},
        promoter : {},
        client_history : [],
        artist_history : []
    }

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case ARTISTS.FETCH_LIST_SERVICES:
            return { ...state, job_services: action.payload }
        case ARTISTS.FETCH_LIST_PAYMENTS:
            return { ...state, payments: action.payload }
        case ARTISTS.FETCH_JOBS_DONE:
            return { ...state, weekly_payments: action.payload, end_of_week: action.end_date }
        case ARTISTS.SELECT_MY_SERVICE_JOB:
            return { ...state, job_service_selected: action.payload }

        case ARTISTS.FETCH_PROMOTER:
            return { ...state, promoter: action.payload }

        case ARTISTS.FETCH_USER_HISTORY:
            return { ...state, client_history: action.payload }

        case ARTISTS.FETCH_ARTISTS_HISTORY:
            return { ...state, artist_history: action.payload }

        default:
        return { ...state }
    }
}