import React, { Component } from 'react'
import FlashAlert from 'components/molecules/alert'
import ClientSmallProfileHeader from 'components/organisms/client_small_profile_header'
import ClientProfileListOptions from 'components/organisms/client_small_profile_list'

class SmallProfile extends Component{
    render(){
        return(
            <>
                <FlashAlert/>
                <ClientSmallProfileHeader/>
                <ClientProfileListOptions/>
            </>
        )
    }
}

export default SmallProfile