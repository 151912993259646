import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm, reset } from 'redux-form'
import Media from "react-media"
import moment from 'moment'
import { showSuccessMessage } from 'actions/shared'

import { 
    InlineForm,
    SearchIcon,
    FilterIcon 
} from './styled'
import {
    Input,
    InputGroup,
    InputGroupAddon,
    Button
} from 'reactstrap'
import SearchFilterModal from 'components/organisms/modal_search_filter'
import { findServicesWithFilter } from 'actions/services'
import * as routes from 'constants/routes'
import { getCurrentCountry } from 'helpers/index'

class HomeSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalSearchFilter: false
        }
        this.toggleSearchFilter = this.toggleSearchFilter.bind(this)
    }
    
    toggleSearchFilter() {
        this.setState({
            modalSearchFilter: !this.state.modalSearchFilter
        })
    }

    onSubmit(singleDate, values){
        const hasAtLeastOneFilter = singleDate || values['hour'] || values['event-type'] || values['search-query'] ? true : false

        if(hasAtLeastOneFilter){
            if (singleDate)
                values['date'] = moment(singleDate).format('DD/MM/YYYY')
            else
                delete values.date
            
            if(values['hour'])
                values['hour_selected'] = values['hour'].replace(':', '')
            else
                delete values.hour_selected

            if(values['event-type']){
                let arrayEventType = []
                values['event-type'].forEach(type => {
                    arrayEventType.push(type.value)
                })
                values['event-types'] = arrayEventType
            }

            values.country = this.props.user_country

            this.props.findServicesWithFilter(values, () => {
                this.props.reset('NavbarSearchForm')
                this.props.history.push(routes.SEARCH_RESULTS)
            })

        }
        else{
            this.props.showSuccessMessage('Selecciona al menos un filtro para realizar tu búsqueda.')
        }

        if(this.state.modalSearchFilter)
            this.toggleSearchFilter()
    }

    renderSearchInput(field){
        return(
            <Input
                type={field.type}
                className='border-0 bg-transparent rounded'
                {...field.input}
            />
        )
    }

    render(){
        const { handleSubmit } = this.props
        return(
            <>
                <Media query={{ maxWidth: 768 }}>
                    {matches => { 
                        return (
                            <InlineForm
                                onSubmit={handleSubmit(this.onSubmit.bind(this, this.props.singleDate))}
                                className='shadow-sm py-2 px-4 rounded'
                                small={matches.toString()}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <SearchIcon className='text-primary mt-1'/>
                                    </InputGroupAddon>

                                    <Field
                                        name='search-query'
                                        type='text'
                                        component={this.renderSearchInput}
                                    />

                                    <InputGroupAddon addonType="append">
                                        <Button 
                                            type='button'
                                            className='bg-transparent border-0 p-0'
                                            onClick={this.toggleSearchFilter}>
                                            <FilterIcon className='text-primary'/>
                                        </Button>
                                    </InputGroupAddon>

                                </InputGroup>
                            </InlineForm>
                        )}
                    }
                </Media>
                <SearchFilterModal
                    isOpen={this.state.modalSearchFilter} 
                    modalHeaderText='Búsqueda por Disponibilidad'
                    modalBodyText='En este filtro podrás buscar los servicios que están disponibles para una fecha y horario en particular.'
                    callSearchFilter={this.onSubmit.bind(this, this.props.singleDate)}
                    toggleSearchFilter={this.toggleSearchFilter.bind(this)}
                />
            </>
        )
    }
}

function mapStateToProps({ datepicker, auth }){
    const { singleDate } = datepicker
    const { user } = auth
    const user_country = getCurrentCountry(user ? user.country:'MÉXICO')
    return { singleDate, user, user_country }
}

export default reduxForm({
    form:'SearchForm',
    destroyOnUnmount: false
})(withRouter(
    connect(
        mapStateToProps,
        { findServicesWithFilter, reset, showSuccessMessage }
    )(HomeSearch)
))