import { Button } from 'reactstrap'
import styled from 'styled-components'

export const RoundedButton = styled(Button)`
  border-radius: 2rem!important;
  padding: 0.2rem 5rem;
  min-width: 15rem;
`

export const SquaredButton = styled(Button)`
  padding: 0.2rem 5rem;
  min-width: 15rem;
  color: white;
`