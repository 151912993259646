import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, untouch, reset } from 'redux-form'

import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import { MOMENT_IN_SPANISH } from 'constants/index'

import { onDateChange, onSingleFocusChange } from 'actions/datepicker'

moment.updateLocale('es', MOMENT_IN_SPANISH)

class ClickyDatePicker extends Component{
    constructor(props){
        super(props)
        this.isDayBlocked = this.isDayBlocked.bind(this)
        this.isOutsideRange = this.isOutsideRange.bind(this)
    }

    isDayBlocked(day){
        day.locale('en') //porque los días están en inglés en la base de datos
        const result = this.props.blockedWeekDays.filter(
                blockedWeekDay => blockedWeekDay === day.format('dddd')
            ).length > 0
        return result
        
    }

    isOutsideRange(day){
        const maximumMonths = 2
        return day.isBefore(moment().subtract(1, 'days')) 
            || day.isAfter(moment().add(maximumMonths, 'months'))
    }

    render(){
        return(
            <div className='w-100'>
                <SingleDatePicker
                    date={this.props.datepicker.singleDate}
                    focused={this.props.datepicker.singleFocusedInput}
                    onDateChange={ singleDate => {
                            this.props.onDateChange(singleDate, this.props.current_service.availability)
                            this.props.change('SearchForm', 'hour', null)
                            this.props.untouch('SearchForm', 'hour')
                            this.props.change('SearchForm', 'duration', null)
                            this.props.untouch('SearchForm', 'duration')
                            // this.props.reset('BookingFilterForm')
                            this.props.change('BookingFilterForm', 'hour', null)
                            this.props.untouch('BookingFilterForm', 'hour')
                            this.props.change('BookingFilterForm', 'duration', null)
                            this.props.untouch('BookingFilterForm', 'duration')
                        }
                    }
                    onFocusChange={focusedInput => this.props.onSingleFocusChange(focusedInput)}
                    id='search-filter-single-date'
                    placeholder='Día'
                    isDayBlocked={this.isDayBlocked}
                    isOutsideRange={this.isOutsideRange}
                    numberOfMonths={1}
                    showClearDate={true}
                    hideKeyboardShortcutsPanel={true}
                />
            </div>
        )
    }
}

function mapStateToProps({ datepicker, services }){
    const { current_service } = services
    const { blockedDays, blockedWeekDays } = datepicker
    
    return { current_service, datepicker, blockedDays, blockedWeekDays }
}

export default connect(mapStateToProps, 
    { onDateChange, onSingleFocusChange, change, untouch, reset }
    )(ClickyDatePicker)
