import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import Input from 'components/atoms/inputs/simpliest_input'
import { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep } from 'actions/shared'
import { NEW_SERVICE_VIEWS } from 'constants/index'
import Button from 'components/atoms/buttons/solid'

class NewServiceName extends Component{
    constructor(props){
        super(props)
        this.validateName = this.validateName.bind(this)
    }

    validateName(name){
        if(!name){
            this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.name)
            return 'Debes definir un nombre para tu servicio'
        }
    }

    onSubmit(values){
        this.props.completeNewServiceStep(NEW_SERVICE_VIEWS.name)
        this.props.selectNewServiceStep(NEW_SERVICE_VIEWS.description)
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>Título del servicio</h2>
                <p>Ingresa el título de tu servicio</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="name"
                        type="text"
                        placeholder="Agrega un título"
                        validate={this.validateName}
                        component={Input}
                    />
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

NewServiceName = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewServiceName)
  
  NewServiceName = connect(
    null,
    { completeNewServiceStep, selectNewServiceStep, uncheckNewServiceStep }
  )(NewServiceName)
  
  export default NewServiceName