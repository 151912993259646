import styled from 'styled-components'

export const Card = styled.div`
    background-image: url('${props => props.img}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem; 
    cursor: pointer;
    height: 12rem;
    &:hover {
        -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
    }
    transition: 0.3s;
    width: 14rem;
`

export const Icon = styled.img`
    width:6rem;
`