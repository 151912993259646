import React from 'react'

import {
    InputGroup
} from 'reactstrap'
import { StyledChatInput } from './styled'

export default ( field ) => {
    const { meta: {error, touched} } = field
    const isInvalid = touched && error ? true : false

    return(
        
        <InputGroup>
            <StyledChatInput
                type={field.type}
                name={field.name}
                placeholder={field.placeholder}
                invalid={isInvalid}
                autoComplete={field.autocomplete}
                rows={field.rows}
                {...field.input}
            />
        </InputGroup>
    )
}
