import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

import FlashAlert from 'components/molecules/alert'
import { 
    StyledCarouselItem, 
    StyledCarouselImage, 
    HeartImage, 
    StyledBadge,
    BookingFooter,
    StyledButton,
    IncludeImg,
    SmallCategoryImage,
    StyledCarouselVideo,
    ImageMedal,
    CarouselSection,
    DescriptionP } from './styled'
import HeartIcon from 'components/atoms/images/icono_corazon_lleno.svg'
import EmptyHeartIcon from 'components/atoms/images/icono_corazon_detalle_vacio.svg'
import MedalIcon from 'components/atoms/images/icono_medalla_fondo.svg'
import { fetchService, fetchServiceComments } from 'actions/services'
import { fetchUserFavorites, likeService, unLikeService} from 'actions/user'
import { showErrorMessage } from 'actions/shared'
import * as routes from 'constants/routes'
import { CURRENCY_FORMATTER, MOMENT_IN_SPANISH, SERVICES, CLIENTS, ARTISTS } from 'constants/index'
import { embedFormat, currencyFormatter } from 'helpers/index'

moment.updateLocale('es', MOMENT_IN_SPANISH )

class ShowService extends Component{
    constructor(props){
        super(props)
        this.renderCarouselItems = this.renderCarouselItems.bind(this)
        this.renderMainCategory = this.renderMainCategory.bind(this)
        this.renderEventTypes = this.renderEventTypes.bind(this)
        this.renderContains = this.renderContains.bind(this)
        this.renderZones = this.renderZones.bind(this)
        this.onBookingClick = this.onBookingClick.bind(this)
        this.onLikeServiceClick = this.onLikeServiceClick.bind(this)
        this.getCost = this.getCost.bind(this)
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        const service_id = window.location.href.split('/').pop()
        this.props.fetchService(service_id)
        this.props.fetchServiceComments(service_id)
        if(this.props.user_id){
            this.props.fetchUserFavorites(this.props.user_id)
        }
    }

    renderCarouselItems(images, video){
        if (images && images.length > 0){
            let items = _.map( images, image => {
                return (
                    <StyledCarouselItem key={image}>
                        <StyledCarouselImage src={image} />
                    </StyledCarouselItem>
                )
            })

            if(video){
                items.push(
                    <StyledCarouselItem key={video}>
                            <StyledCarouselVideo src={embedFormat(video)} />
                    </StyledCarouselItem>
                )
}
            return items
        }
    }

    renderMainCategory(category){
        return (
            <StyledBadge
                className='badge badge-warning px-5 py-2 m-2 d-flex align-items-center'
            >
                <SmallCategoryImage src={ category.image ? category.image.url : ''}/>
                <span className='mx-2 text-white'>{category.name}</span>
            </StyledBadge>
        )
    }

    renderEventTypes(types){
        return <p>{types ? types.join(', ') : ''}</p>
    }

    renderContains(includes){
        console.log(includes)
        return _.map( includes, include => {
            return (
                <div key={include.id} className='text-center mx-4 my-2'>
                    {include.image ?
                        <>
                            {/*<IncludeImg src={include.image.url}/>*/}
                            <p className='text-gray-muzig'>{include.name}</p>
                        </>
                        :
                        <p className='text-gray-muzig'>{include.id}</p>
                    }
                </div>
            )
        })
    }

    renderZones(zones){
        return _.map(zones, (zone, id) => {
            return <p className='m-0'>{id}: {zone.join(', ')}</p>
        })
    }

    renderComments(comments){
        return _.map(comments, comment => {
            return (
                <div className='rounded-lg shadow-lg mx-2 p-3' key={comment.id}>
                    <h5>{comment.client_name}</h5>
                    <p className='text-gray-muzig'>
                        {moment(comment.created_at).format('DD MMM YYYY')}
                    </p>
                    <p>{comment.comment}</p>
                </div>
            )
        })
    }

    onBookingClick(){
        if(this.props.current_service.artist_id === this.props.user_id)
            this.props.showErrorMessage('Lo sentimos no puedes contratar tus mismos servicios.')
        else if(this.props.user_status === CLIENTS.STATUS.INACTIVE || this.props.user_status === ARTISTS.STATUS.INACTIVE)
            this.props.showErrorMessage(`Lo sentimos no puedes contratar porque estás suspendido.`)
        else if(this.props.current_service.status === SERVICES.STATUS.ACTIVE)
            this.props.history.push(routes.BOOKING_FILTER.replace(':id', this.props.current_service.id))
        else
            this.props.showErrorMessage(`Lo sentimos, los servicios de ${this.props.current_service.artist_name} no están disponibles en este momento.`)
    }

    onLikeServiceClick(){
        if(this.props.user_id){
            if (this.props.isFavorite)
                this.props.unLikeService(this.props.user_id, this.props.current_service.id)
            else
                this.props.likeService(
                    this.props.user_id, 
                    this.props.current_service.id,
                    this.props.current_service.artist_name,
                    this.props.current_service.title)
        }
        else{
            this.props.history.push(routes.LOGIN)
        }
    }

    getCost(){
        if(this.props.current_service.currency_code)
            return currencyFormatter(this.props.current_service.currency_code, this.props.current_service.cost)
        else
            return CURRENCY_FORMATTER.format(this.props.current_service.cost)
    }

    render(){
        return(
            <div className='d-flex flex-column'>
                <FlashAlert/>
                <CarouselSection className='text-center'>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        infiniteLoop={true}
                        autoPlay={false}
                        emulateTouch={false}
                        swipeable={false}
                        swipeScrollTolerance={1}
                    >
                        {this.renderCarouselItems(this.props.current_service.images, this.props.current_service.video)}
                    </Carousel>
                </CarouselSection>
                <section className='container my-4'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h1 className='text-dark text-capitalize'>{this.props.current_service.title}</h1>
                        <HeartImage src={this.props.isFavorite ? HeartIcon : EmptyHeartIcon} onClick={() => this.onLikeServiceClick() }/>
                    </div>

                    <div className='d-flex align-items-center'>
                        {
                            this.props.current_service.verified ? 
                                <ImageMedal src={MedalIcon} alt='servicio verificado' className='mr-2'/>
                            :
                                ''
                        }
                        <h2 style={{fontSize: "15px"}} className='text-gray-muzig'>{this.props.current_service.artist_name}</h2>
                    </div>

                    <div className='d-flex flex-wrap my-5'>
                        {this.renderMainCategory(this.props.current_service_category)}
                    </div>

                    <div className='my-5'>
                        <h2 className='text-primary'>Descripción</h2>
                        <DescriptionP>{this.props.current_service.description}</DescriptionP>
                    </div>

                    <div className='mt-5 mb-3'>
                        <h2 className='text-primary'>Incluye</h2>
                        <div className='d-flex flex-wrap mt-3'>
                            {this.renderContains(this.props.current_includes)}
                        </div>
                    </div>

                    <div className='mb-5'>
                        <h2 className='text-primary'>Disponible en:</h2>
                        {this.renderZones(this.props.current_service.zones)}
                        <p className='mt-3'>
                            OBSERVACIONES: Estos valores son para las ciudades seleccionadas, si se requiere para otro estado o ciudad es necesario solicitar otra cotización.
Para cualquier aclaración o asesoría al respecto podemos verlo por el chat que se abrirá al momento de reservar.
                        </p>
                    </div>

                    <div className='my-5'>
                        <h2 className='text-primary'>Servicio recomendado para:</h2>
                        {this.renderEventTypes(this.props.current_service.types)}
                    </div>

                    <div className='my-2'>
                        <h6 className='text-secondary my-5'>
                            {this.props.current_service.rating}% recomienda este servicio
                        </h6>
                        <div className='row no-gutters my-4 '>
                            {this.renderComments(this.props.current_service_comments)}
                        </div>

                    </div>

                </section>
                <div className='w-100 my-5'/>
                <BookingFooter className='d-flex flex-wrap justify-content-end bg-gray-background w-100 p-3 bg-white shadow-lg'>
                    <h2 className='text-dark mx-2'>
                        {this.getCost()}
                    </h2>
                    <StyledButton onClick={this.onBookingClick} className='mx-2 shadow-sm text-white' color='warning'>
                        Reservar
                    </StyledButton>
                </BookingFooter>
            </div>
        )
    }
}

function mapStateToProps({ services, auth, user }){
    const { 
        current_service, 
        current_includes, 
        current_service_category,
        current_service_comments } = services
    const user_id = auth.meta ? auth.meta.user.uid : null
    const user_status = auth.user ? auth.user.status : null
    const { favorites } = user
    let isFavorite = false
    const currency = current_service.currency_code ? current_service.currency_code : 'MXN'
    
    favorites.forEach(favorite => {
        if (favorite.id === current_service.id)
            isFavorite = true
    })
    
    return { 
        current_service, 
        user_id, 
        user_status,
        isFavorite, 
        current_includes, 
        current_service_category,
        current_service_comments,
        currency
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        { 
            fetchService, 
            fetchServiceComments, 
            fetchUserFavorites, 
            likeService, 
            unLikeService,
            showErrorMessage
        }
    )(ShowService)
)