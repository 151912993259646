import styled from 'styled-components'

export const ServicePhoto = styled.img`
    height: 10rem;
    object-fit: cover;
    width: 10rem;
`

export const DeletePhotoOption = styled.p`
    cursor: pointer;
`