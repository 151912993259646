import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import { 
    completeEditServiceStep, 
    selectEditServiceStep, 
    uncheckEditServiceStep,
    getEventTypesList } from 'actions/shared'
import { updateService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS, SHARED } from 'constants/index'
import MultipleSelect from 'components/atoms/inputs/multiple_select'
import Button from 'components/atoms/buttons/solid'
import * as routes from 'constants/routes'
import { getEventTypes } from 'helpers/index'

class EditServiceTypes extends Component{
    constructor(props){
        super(props)
        this.minLength1 = this.minLength1.bind(this)
    }

    componentDidMount(){
        if(isEmpty(this.props.job_service_selected))
            this.props.history.push(routes.ARTIST_JOB_SERVICES)

        this.props.getEventTypesList()
    }

    onSubmit(values){
        const change = { types : getEventTypes(values) }
        this.props.updateService(change, this.props.job_service_selected.id, () => {
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.types)
            this.props.selectEditServiceStep(EDIT_SERVICE_VIEWS.includes)
            this.props.history.push(routes.ARTIST_JOB_SERVICES_EDIT.replace(':id', this.props.job_service_selected.id))
        })
    }
    
    minLength1(values = []){
        this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.types)
        return values.length < 1 ? 'Tienes que elegir al menos una opción' : undefined
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <div className='container mt-5'>
                <h4 className='text-secondary'>Tipo de evento</h4>
                <p>Selecciona los eventos que mejor se adapten a tu servicio.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="event-types"
                        type='select'
                        selectType={SHARED.SELECT_TYPE_EVENT_TYPES}
                        placeholder='Selecciona al menos un tipo de evento'
                        options={this.props.event_types}
                        component={MultipleSelect}
                        validate={[this.minLength1]}
                    />
            
                    <div className='my-5 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps({ shared, form, artist }){
    let initialEventTypes = []
    if(form.editServiceWizard && form.editServiceWizard.values)
        if(typeof form.editServiceWizard.values['event-types'] !== 'undefined')
            initialEventTypes = form.editServiceWizard.values['event-types']

    let eventTypesArray = []
    let { eventTypes } = shared
    eventTypes.forEach( type => eventTypesArray.push({ value:type.name, label:type.name }) )
    const { job_service_selected } = artist
    
    /*VALORES INICIALES*/
    let typesArrayOptions = []

    if (job_service_selected.types){
        typesArrayOptions = _.map(job_service_selected.types, type => {
            return { value: type, label: type }
        })
    }

    const initialValues = {
        'event-types' : typesArrayOptions,
    }

    return { event_types: eventTypesArray, initialEventTypes, job_service_selected, initialValues }
}

EditServiceTypes = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true
  })(EditServiceTypes)
  
  EditServiceTypes = connect(
    mapStateToProps,
    { 
        completeEditServiceStep, 
        selectEditServiceStep, 
        uncheckEditServiceStep,
        getEventTypesList,
        updateService
    }
  )(EditServiceTypes)
  
  export default withRouter(EditServiceTypes)