import React from 'react'
import Media from "react-media"
import CollapseMyServices from './collapse_list'
import TabMyServices from './tab_list'

export default () => {
    return(
        <>
            <Media query={{ maxWidth: 767 }}>
                {matches => matches ? 
                    <div className='px-0 py-2'>
                        <CollapseMyServices/>
                    </div> : ''}
            </Media>
            <Media query={{ minWidth: 768 }}>
                {matches => matches ? 
                    <div className='px-4 py-2'>
                        <TabMyServices/>
                    </div> : ''}
            </Media>
        </>
    )
}