import React from 'react'
import Media from "react-media"

import { Banner } from './styled'

export default () => {
    return(
        <>
            <Media query={{ maxWidth: 767 }}>
                {matches => matches ? 
                        <div className='px-4 py-2'>
                            <h2 className='text-secondary'>Servicios</h2>
                            <h6>Consulta tus servicios agendados y tus solicitudes</h6>
                        </div> : ''}
            </Media>
            <Media query={{ minWidth: 768, maxWidth: 991 }}>
                {matches => matches ? 
                    <Banner size='md' className='d-flex align-items-center p-4'>
                        <div>
                            <h1 className='text-white'>Servicios</h1>
                            <h4 className='text-white'>Consulta tus servicios agendados y tus solicitudes</h4>
                        </div>
                    </Banner> : ''}
            </Media>
            <Media query={{ minWidth: 992, maxWidth: 1199 }}>
                {matches => matches ? 
                    <Banner size='lg' className='d-flex align-items-center p-4'>
                        <div>
                            <h1 className='text-white'>Servicios</h1>
                            <h4 className='text-white'>Consulta tus servicios agendados y tus solicitudes</h4>
                        </div>
                    </Banner> : ''}
            </Media>
            <Media query={{ minWidth: 1200 }}>
                {matches => matches ? 
                    <Banner size='xl' className='d-flex align-items-center p-4'>
                        <div>
                            <h1 className='text-white'>Servicios</h1>
                            <h4 className='text-white'>Consulta tus servicios agendados y tus solicitudes</h4>
                        </div>
                    </Banner> : ''}
            </Media>
        </>
    )
}