import map from 'lodash/map'
import React, { Component } from 'react'
import  { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { MOMENT_IN_SPANISH, RESERVATION_STATUS, CURRENCY_FORMATTER } from 'constants/index'

class ArtistWeeklyPaymentList extends Component{
    constructor(props){
        super(props)
        this.renderPaymentsList = this.renderPaymentsList.bind(this)
        this.renderAmount = this.renderAmount.bind(this)
    }

    renderAmount(reservation){
        if(reservation.status >= RESERVATION_STATUS.client_canceled.id)
            if(reservation.status === RESERVATION_STATUS.client_canceled.id)
                return <h4 className='text-gray-muzig'>$0.00</h4>
            else
                return <h4 className='text-danger'>{CURRENCY_FORMATTER.format(reservation.fee)}</h4>
        
        else
            return <h4>{CURRENCY_FORMATTER.format(reservation.cost)}</h4>
    }

    renderPaymentsList(){
        moment.updateLocale('es', MOMENT_IN_SPANISH)
        return (
            map(this.props.weekly_payments, reservation => {
                return (
                    <div className='row no-gutters border-bottom border-gray-muzig py-4' key={reservation.id}>
                        <div className='col-12 col-sm-9 mb-2 mb-sm-0'>
                            <h5>{reservation.service_name}</h5>
                            <span className='text-gray-muzig'>Reservación {RESERVATION_STATUS[reservation.status]}</span>
                        </div>
                        <div className='col-12 col-sm-3 d-flex flex-column align-items-end'>
                            {this.renderAmount(reservation)}
                            <h6 className='text-gray-muzig'>
                                {moment(reservation.when).format('DD MMM YYYY')}
                            </h6>
                        </div>
                    </div>
                )
            })
        )
    }

    render(){
        return(
            <>
                <h5 className='text-gray-muzig'>
                    {moment(this.props.end_of_week-604800000).format('DD MMM YYYY')} - {moment(this.props.end_of_week).format('DD MMM YYYY')}
                </h5>
                {this.renderPaymentsList()}
            </>
        )
    }
}

function mapStateToProps({ auth, artist }){
    const user_id = auth.meta.user.uid
    const { weekly_payments, end_of_week } = artist
    return { user_id, weekly_payments, end_of_week }
}

export default connect(
    mapStateToProps,
    {  }
)(withRouter(ArtistWeeklyPaymentList))