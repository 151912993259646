import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
//import { Link } from 'react-router-dom'
import { 
    // Field, 
    reduxForm, 
    reset } from 'redux-form'

import logo from 'components/atoms/images/logo_navbar_fondo_blanco.svg'
import Badge from 'components/atoms/noti_badge'
//import WhiteLogo from 'components/atoms/images/logo_horizontal.svg'
import WhiteLogo from 'components/atoms/images/logo_navbar_fondo_blanco.svg'
import Facebook from 'components/atoms/images/footer_facebook.svg'
import Instagram from 'components/atoms/images/footer_instagram.svg'
import { 
    Navbar,
    NavbarItemsLeft, 
    NavbarItemsRight, 
    NavbarLink,
    // NavbarSearchBtn,
    SideNav,
    SideNavMenuBtn,
    SideNavLink,
    LogoImage,
    SideNavCloseBtn,
    // NavbarCloseSearchBtn,
    // SearchForm,
    // InputSearchBtn,
    StyledInput,
    // SearchIcon 
    SidemenuLogo,
    CopyrightP,
    SocialIcon
} from './styled'
// import {
//     InputGroup,
//     Button,
//     Form
// } from 'reactstrap'
import * as routes from 'constants/routes'
import { logoutUser } from 'actions/authentication'
import { findServicesWithFilter } from 'actions/services'
import { clearSingleDate } from 'actions/datepicker'
import { getCurrentCountry } from 'helpers/index'
import { URLS } from 'constants/index'

class NavbarComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSideNavOpen: false,
            isSearchFormOpen: false
        }
        this.toggleSideNav = this.toggleSideNav.bind(this)
        this.toggleSearchForm = this.toggleSearchForm.bind(this)
        this.onHomeClick = this.onHomeClick.bind(this)
        this.onLogoutClick = this.onLogoutClick.bind(this)
        this.onServicesClick = this.onServicesClick.bind(this)
        this.onProfileClick = this.onProfileClick.bind(this)
        this.onLoginClick = this.onLoginClick.bind(this)
        this.onSignupClick = this.onSignupClick.bind(this)
        this.onArtistSignupClick = this.onArtistSignupClick.bind(this)
        this.renderBadge = this.renderBadge.bind(this)
        this.onConvertToArtist = this.onConvertToArtist.bind(this)
        this.onSideHomeClick = this.onSideHomeClick.bind(this)
        this.onCategoriesClick = this.onCategoriesClick.bind(this)
        this.onInstagramClick = this.onInstagramClick.bind(this)
        this.onFacebookClick = this.onFacebookClick.bind(this)
    }

    toggleSideNav() {
        this.setState({
            isSideNavOpen: !this.state.isSideNavOpen
        })
    }

    toggleSearchForm() {
        this.setState({
            isSearchFormOpen: !this.state.isSearchFormOpen
        })
    }   
    
    onSubmit(values){
        values.country = this.props.user_country
        this.props.findServicesWithFilter(values, () => {
            this.props.reset('SearchForm')
            this.props.clearSingleDate()
            this.props.history.push(routes.SEARCH_RESULTS)
        })
    }

    onHomeClick(){
        this.props.history.push(routes.ROOT)
    }

    onLogoutClick(){
        this.props.logoutUser( () => {
            this.props.history.push(routes.LOGIN)
        })
        this.toggleSideNav()
    }

    onLoginClick(){
        this.props.history.push(routes.LOGIN)
        this.toggleSideNav()
    }

    onSignupClick(){
        this.props.history.push(routes.CHOOSE_REGISTRATION_TYPE)
        this.toggleSideNav()
    }

    onArtistSignupClick(){
        this.props.history.push(routes.USER_SIGNUP_AS_ARTIST)
        this.toggleSideNav()
    }

    onServicesClick(){
        if(this.props.role === 'client')
            this.props.history.push(routes.CLIENT_MY_SERVICES)
        else if(this.props.role === 'artist')
            this.props.history.push(routes.ARTIST_MY_SERVICES)
        this.toggleSideNav()
    }

    onProfileClick(){
        if(this.props.role === 'client')
            this.props.history.push(routes.CLIENT_PROFILE)
        else if(this.props.role === 'artist')
            this.props.history.push(routes.ARTIST_PROFILE)
        this.toggleSideNav()
    }

    onCategoriesClick(){
        this.props.history.push(routes.CATEGORIES_GRID)
        this.toggleSideNav()
    }

    onConvertToArtist(){
        this.props.history.push(routes.CLIENT_CONVERT_TO_ARTIST)
        this.toggleSideNav()
    }

    onSideHomeClick(){
        this.props.history.push(routes.ROOT)
        this.toggleSideNav()
    }

    onInstagramClick(){
        window.open(URLS.INSTAGRAM, '_blank')
        this.toggleSideNav()
    }

    onFacebookClick(){
        window.open(URLS.FACEBOOK, '_blank')
        this.toggleSideNav()
    }

    renderSearchInput(field){
        return(
            <StyledInput 
                placeholder={field.placeholder}
                type={field.type}
                className='bg-primary border-top-0 border-left-0 border-right-0 text-white border-white'
                {...field.input}
                />
        )
    }

    renderBadge(){
        if(typeof(this.props.user.reservation_map) !== 'undefined' || typeof(this.props.user.request_map) !== 'undefined')
            return <Badge class="bg-danger"/>
    }

    render(){
        //const { handleSubmit } = this.props
        return(
            <>
                <SideNav isOpen={this.state.isSideNavOpen} className='bg-warning d-flex flex-column'>
                    <div className='p-2'>
                        <SideNavLink onClick={this.toggleSideNav}>
                            <SideNavCloseBtn className='text-dark'/>
                        </SideNavLink>
                        <SideNavLink onClick={this.onSideHomeClick}>
                            <span
                                className='text-dark'>
                                Página principal
                            </span>
                        </SideNavLink>
                        <SideNavLink onClick={this.onCategoriesClick}>
                            <span 
                                className='text-dark'>
                                Categorías
                            </span>
                        </SideNavLink>
                        {this.props.user ? 
                            <>
                                <SideNavLink onClick={this.onProfileClick}>
                                    <span 
                                        className='text-dark'>
                                        Mi perfil
                                    </span>
                                </SideNavLink>
                                <SideNavLink onClick={this.onServicesClick}>
                                    <span 
                                        className='text-dark'>
                                        Servicios {this.renderBadge()}
                                    </span>
                                </SideNavLink>
                                {
                                    this.props.role === 'client' ? 
                                        <SideNavLink onClick={this.onConvertToArtist}>
                                            <span 
                                                className='text-dark'>
                                                Quiero ser artista
                                            </span>
                                        </SideNavLink>
                                    : ''
                                }
                                <SideNavLink onClick={this.onLogoutClick}>
                                    <span 
                                        className='text-dark'>
                                        Cerrar sesión
                                    </span>
                                </SideNavLink>
                            </> :
                            <>
                                <SideNavLink onClick={this.onLoginClick}>
                                    <span 
                                        className='text-dark'>
                                        Ingresar
                                    </span>
                                </SideNavLink>
                                <SideNavLink onClick={this.onSignupClick}>
                                    <span 
                                        className='text-dark'>
                                        Crear cuenta
                                    </span>
                                </SideNavLink>
                                {/* <SideNavLink>
                                    <span 
                                        className='text-white'
                                        onClick={this.onArtistSignupClick}>
                                        Ser artista
                                    </span>
                                </SideNavLink> */}
                            </>
                        }
                    </div>
                    <div className='mt-auto py-2'>
                        <div className='d-flex justify-content-center justify-content-sm-start'>
                            <div className='text-center text-sm-left'>
                                <p className='text-white m-0'>Soporte:</p>
                                <p className='text-white m-0'>55 53 37 79 44</p>
                                <p className='text-white m-0'>soporte@themuzigzag.com</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-2 justify-content-sm-start'>
                            <div>
                                <SocialIcon onClick={() => this.onFacebookClick()} src={Facebook} className='mr-3'/>
                                <SocialIcon onClick={() => this.onInstagramClick()} src={Instagram} className='mr-3'/>
                            </div>
                        </div>
                        <div className='d-flex flex-column mt-2'>
                            <SidemenuLogo src={WhiteLogo}/>
                            <CopyrightP className='text-light my-1 text-center text-sm-left'>Copyright 2019 ©</CopyrightP>
                        </div>
                    </div>
                </SideNav>
                <Navbar className='bg-warning text-white d-md-none'>
                    <NavbarItemsLeft>
                        <NavbarLink onClick={this.toggleSideNav}>
                            <SideNavMenuBtn 
                                issearchopen={this.state.isSearchFormOpen.toString()}/>
                        </NavbarLink>
                    </NavbarItemsLeft>

                    <NavbarItemsRight issearchopen={this.state.isSearchFormOpen.toString()}>
                        {/* <NavbarLink className='flex-shrink-1 d-flex align-items-center'>
                            <NavbarSearchBtn 
                                onClick={this.toggleSearchForm}
                                issearchopen={this.state.isSearchFormOpen.toString()}/>
                            <NavbarCloseSearchBtn
                                className='align-self-center'
                                onClick={this.toggleSearchForm}
                                issearchopen={this.state.isSearchFormOpen.toString()}/>
                        </NavbarLink> */}
                        {/* <SearchForm isOpen={this.state.isSearchFormOpen} timeout={100} className='w-100'>
                            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                <InputGroup>
                                    <Field
                                        placeholder='Buscar servicio'
                                        name='search-query'
                                        type='text'
                                        component={this.renderSearchInput}
                                    />
                                    <InputSearchBtn addonType="append" className='bg-primary text-white'>
                                        <Button type='submit' className='bg-primary border-0 p-0'>
                                            <SearchIcon className='align-self-center'/>
                                        </Button>
                                    </InputSearchBtn>
                                </InputGroup>
                            </Form>
                        </SearchForm> */}
                        <NavbarLink onClick={this.onHomeClick}>
                            <LogoImage 
                                src={logo} 
                                alt='logo muzigzag' 
                                isVisible={this.state.isSearchFormOpen}/>
                        </NavbarLink>
                    </NavbarItemsRight>
                </Navbar>
            </>
        )
    }
}

function mapStateToProps({ auth }){
    const { user, role } = auth
    const user_country = getCurrentCountry(user ? user.country:'MÉXICO')
    return { user, role, user_country }
}

function validate(values){
    let errors = {}
    return errors
}

export default reduxForm({
    validate,
    form:'NavbarSearchForm',
    destroyOnUnmount: false
})(withRouter(
    connect(
        mapStateToProps,
        { logoutUser, findServicesWithFilter, reset, clearSingleDate }
    )(NavbarComponent)
))