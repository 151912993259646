import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import UserCardsList from 'components/organisms/user_card_list'
import AddCardForm from 'components/organisms/add_card_form'
import * as routes from 'constants/routes'

class CardsSettings extends Component {

    render(){
        return(
            <>
                <section className='p-4'>
                    { isEmpty(this.props.cards) ? 
                        ''
                        :
                        <div className='mb-5'>
                            <h4 className='text-secondary'>Mis tarjetas</h4>
                            <UserCardsList 
                                redirectTo={routes.CONFIRM_BOOKING_REQUEST.replace(':id', this.props.filter_options.service_id)}/>
                        </div>
                    }
                    <h4 className='text-secondary'>Agregar tarjeta</h4>
                    <AddCardForm  route={routes.CONFIRM_BOOKING_REQUEST.replace(':id', this.props.filter_options.service_id)}/>
                </section>
            </>
        )
    }

}

function mapStateToProps({ bookings, auth }){
    const { filter_options } = bookings
    const { user: { cards } } = auth
    
    
    return { filter_options, cards }
}

export default connect(mapStateToProps, {})(CardsSettings)