import React, { Component } from 'react'
import UserFavoritesList from 'components/organisms/user_favorites_list'

class UserFavorites extends Component{
    render(){
        return (
            <>
                <UserFavoritesList/>
            </>
        )
    }
}

export default UserFavorites