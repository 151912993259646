import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form
    } from 'reactstrap'

import TextAreaInput from 'components/atoms/inputs/generic_text_area'

class CancelServiceModal extends Component{
    
    render(){
        const { handleSubmit } = this.props
        
        return(
            <Modal 
                isOpen={this.props.isOpen} 
                toggle={() => this.props.toogle()}
            >
                <Form onSubmit={handleSubmit(this.props.onClickAcceptButton )}>
                    <ModalHeader toggle={ () => this.props.toogle()}>
                        {this.props.modalHeaderText}
                    </ModalHeader>
                    <ModalBody>
                        {this.props.modalBodyText}
                        <Field
                            name="reason"
                            type="textarea"
                            class="col-12 mt-4 border-0"
                            component={TextAreaInput}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="danger">Cancelar servicio</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

function validate(values){
    let errors = {}

    if(!values.reason)
        errors.reason = 'Debes proporcionar tu razón de cancelación'
    
    return errors
}

function mapStateToProps({ auth }){
    const { user } = auth
    return { user }
}
  
export default reduxForm({
    validate,
    form: 'CancelReservationForm'
  })(
    connect(mapStateToProps, {  })(CancelServiceModal)
  )