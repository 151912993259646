import moment from 'moment'
import { MOMENT_IN_SPANISH, PAYPAL_CURRENCIES, GATEWAY_CONSTANTS } from 'constants/index'

export function getClientCancelationAmountToReturn(created_at, when, total_cost, currency){
    const now = new Date().getTime()
    const diff_now_creation = now - created_at
    const diff_when_creation = when - created_at

    const diff_reservation = when - now

    const minutes_since_created = ((diff_now_creation / 1000) / 60)
    const hours_diff = (((diff_when_creation / 1000) / 60) / 60)
    const seconds = diff_reservation / 1000
    const minutes = seconds / 60
    const hours = minutes / 60

    if(minutes_since_created <= 30 && hours_diff >= 3)
        return total_cost - 0
    else{
        if(hours <= 3)
            return total_cost - (0.25 * total_cost + PAYPAL_CURRENCIES[currency].cancel_constant)
        else if(hours <= 24 && hours > 3)
            return total_cost - (0.2 * total_cost + PAYPAL_CURRENCIES[currency].cancel_constant)
        else
            return total_cost - (0.15 * total_cost + PAYPAL_CURRENCIES[currency].cancel_constant)
    }
}

export function getClientCancelationAmountToCharge(created_at, when, total_cost, currency){
    const now = new Date().getTime()
    const diff_now_creation = now - created_at
    const diff_when_creation = when - created_at

    const diff_reservation = when - now

    const minutes_since_created = ((diff_now_creation / 1000) / 60)
    const hours_diff = (((diff_when_creation / 1000) / 60) / 60)
    const seconds = diff_reservation / 1000
    const minutes = seconds / 60
    const hours = minutes / 60

    if(minutes_since_created <= 30 && hours_diff >= 3)
        return 0
    else{
        if(hours <= 3)
            return 0.25 * total_cost + PAYPAL_CURRENCIES[currency].cancel_constant
        else if(hours <= 24 && hours > 3)
            return 0.2 * total_cost + PAYPAL_CURRENCIES[currency].cancel_constant
        else
            return 0.15 * total_cost + PAYPAL_CURRENCIES[currency].cancel_constant
    }
}

export function getArtistCancelationAmountToCharge(created_at, when, subtotal){
    const now = new Date().getTime

    const diff_now_creation = now - created_at

    let seconds = diff_now_creation / 1000
    let minutes = seconds / 60
    let hours = minutes / 60

    if(hours <= 1)
        return 0
    else{
        const diff_reservation = when - now

        seconds = diff_reservation / 1000
        minutes = seconds / 60
        hours = minutes / 60
        const days = hours / 24

        if(hours < 18)
            return 0.055 * subtotal
        else if(hours < 24 && hours >= 18)
            return 0.05 * subtotal
        else if(hours < 72 && hours >= 24)
            return 0.03 * subtotal
        else if(days < 6 && days >= 3)
            return 0.02 * subtotal
        else if(days < 7 && days >= 6)
            return 0.013 * subtotal
        else
            return 0.01 * subtotal
    }
}

//Hacer conversión a string, cortar para dejar sólo dos decimales, y regresar Number
export function toFixed(val = 0, decimals = 0) {
    const string = val.toString()
    const array = string.split('.')

    if(array.length > 1){
        const new_decimals = array[1].substring(0, decimals)
        return Number(`${array[0]}.${new_decimals}`)
    }
    else
        return Number(`${array[0]}.00`)

}
// export function toFixed(val, decimals) {
//     const arr = ("" + val).split(".")
//     if(arr.length === 1)
//         return Number(val)
//     const int = arr[0],
//         dec = arr[1],
//         max = dec.length - 1
//     return decimals === 0 ? Number(int) :
//         Number([int,".",dec.substr(0, decimals > max ? max : decimals)].join(""))
// }

export function getWeekRange(){
    moment.updateLocale('es', MOMENT_IN_SPANISH )
    const today = moment()
    const weekday = today.weekday()
    if (weekday === 3)
        return `Del ${today.date()} al ${today.add('days',6).format('DD MMM YYYY')}`
    else if(weekday === 4 || weekday === 5 || weekday === 6){
        const last_wednesday = today.day(3)
        return `Del ${last_wednesday.date()} al ${last_wednesday.add('days',6).format('DD MMM YYYY')}`
    }
    else {
        const last_wednesday = today.day(0-4)
        return `Del ${last_wednesday.date()} al ${last_wednesday.add('days',6).format('DD MMM YYYY')}`
    }
}

export function getNormalizeString(string){
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()   
}

export function embedFormat(video){
    video = video.replace('/watch?v=','/embed/')
    video = video.replace('/youtu.be/', '/youtube.com/embed/')

    return video
}

export function getZonesPresence(values) {
    let zones = {}
    for (const country of values['countries-presence']) {
      zones[country.value] = []
      
      for (const zone of values[`presence-for-${country.value}`]) {
        zones[country.value].push(zone.value)
      }
    }
    return zones
}

export function getCountries(values) {
    let countries = []
    for (const country of values['countries-presence'])
        countries.push(country.value)
    return countries
}

export function containsObject(obj, list) {
    var x
    for (x in list) {
        if (list.hasOwnProperty(x) && list[x].value === obj.value)
            return true
    }
    return false
}

export function getHoursAvailability(values) {
    let weekdays = {}
    for (const weekday of values['weekdays']) {
        weekdays[weekday.value] = {}
      
      for (const hour of values[`hours-for-${weekday.value}`]) {
        Object.assign(weekdays[weekday.value], {[hour.value] : true})
      }
    }
    return weekdays
}

export function getEventTypes(values) {
    let types = []
    for (const type of values['event-types']) {
        types.push(type.value)
    }
    return types
}

export function getIncludes(values) {
    let includes = []
    for (const include of values['includes']){
        includes.push(include.value)
    }
    return includes
}

export function currencyFormatter(currency_code, amount){
    let currency_format
    if(currency_code === 'MXN' || currency_code === 'USD'){
        currency_format = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(amount)
    }
    else
        currency_format = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency_code,
            minimumFractionDigits: 2
        }).format(amount)
        
    return `${currency_format} ${currency_code}`
}

export function getGatewayCommission(type, transaction_fix_commission, currency){
    if(type === 'paypal'){
        let fija = PAYPAL_CURRENCIES[currency].fixed_commission
        return transaction_fix_commission * GATEWAY_CONSTANTS.PAYPAL.FIX_PORCENTAGE + fija + (transaction_fix_commission * GATEWAY_CONSTANTS.PAYPAL.FIX_PORCENTAGE + fija) * .16
    }
    else if(type ==='cash' || type === 'card') {
        return transaction_fix_commission * .029 + 2.5 + (transaction_fix_commission * .029 + 2.5) * .16
    }
    else
        return 0
}

export function getCurrentCountry(country){
    // switch(country){
    //     case 'ESPAÑA':
    //         return country
    //     case 'COLOMBIA':
    //         return country
    //     default:
    //         return 'MÉXICO'
    // }
    return country ? country : 'MÉXICO'


}

export function getFormattedHour(hour){
    if(hour.length === 3)
        return moment(hour, "Hmm").format("HH:mm")
    else
        return moment(hour, "HHmm").format("HH:mm")
}

export function getFormattedHourForOptionSelect(hour){
    if(hour.length === 4)
        return `0${hour}`
    else
        return hour
}

export function getEmailDate(when){
    return moment(when).format('DD/MM/YYYY')
}

export function getEmailHour(when){
    return moment(when).format('HH:mm')
}

export function capitalizeOnlyFirstLetter(string) {
    let lowercased = string.toLowerCase()
    return lowercased.replace(/(?:^|\s)\S/g, word => word.toUpperCase() )
}

export function localeSortArrayBy(array, param){
    return array.sort( (a, b) => {
        return a[param].localeCompare(b[param])
    })
}

export function setFirstCharToUpperCase(str){
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

export function capitalizeString(str){
    return str.replace(/\b\w/g, l => l.toUpperCase())
}