import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import WizardFormFirstPage from './wizardFormFirstPage'
// import WizardFormSecondPage from './wizardFormSecondPage'
import WizardFormThirdPage from './wizardFormThirdPage'
import WizardFormFourthPage from './wizardFormFourthPage'
import WizardFormFifthPage from './wizardFormFifthPage'

import step_one from 'components/atoms/images/convertir_artista_linea_1.svg'
import step_two from 'components/atoms/images/convertir_artista_linea_2.svg'
import step_three from 'components/atoms/images/convertir_artista_linea_3.svg'
import step_four from 'components/atoms/images/convertir_artista_linea_4.svg'

import { ARTISTS, URLS, ENVIRONMENTS } from 'constants/index'
import * as routes from 'constants/routes'
import { convertUserToArtist, saveClientUserData } from 'actions/authentication'

function getArtistTypeId(artist_name) {
  switch(artist_name){
    case ARTISTS.TYPES.MUSICAL.name:
      return ARTISTS.TYPES.MUSICAL.id

    case ARTISTS.TYPES.PERFORMANCE.name:
      return ARTISTS.TYPES.PERFORMANCE.id

    case ARTISTS.TYPES.PRODUCTION.name:
      return ARTISTS.TYPES.PRODUCTION.id

    default:
      return ARTISTS.TYPES.MUSICAL.id
  }
}

function getZonesPresence(values) {
  let zones = {}
  for (const country of values['countries-presence']) {
    zones[country.value] = []
    
    for (const zone of values[`presence-for-${country.value}`]) {
      zones[country.value].push(zone.value)
    }
  }
  return zones
}

class WizardForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1
    }
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.saveLocalDataOne = this.saveLocalDataOne.bind(this)
    this.saveLocalDataTwo = this.saveLocalDataTwo.bind(this)
  }
  
  nextPage() {
    this.setState({ page: this.state.page + 1 })
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 })
  }

  saveLocalDataOne(values){
    const temporary_update = {
      artist_name: values.artistName,
      email: values.email,
      last_name: values.lastName,
      name: values.name,
      phone: values.tel,
      ...(typeof values.telSecondary !== 'undefined' && { phone2: values.telSecondary }),
      ...(values.rfc && { rfc: values.rfc })
    }
    this.props.saveClientUserData(temporary_update)
    this.nextPage()
  }

  saveLocalDataTwo(values){
    const temporary_update = {
      address: values.address,
      country: values.country
    }
    this.props.saveClientUserData(temporary_update)
    this.nextPage()
  }

  onSubmit(uid, values) {
    let fields = {}
    fields['address'] = values.address
    fields['artist_name'] = values.artistName
    fields['artist_type'] = getArtistTypeId(values.artistType)
    fields['card_id_file'] = values.idCard
    fields['zones'] = getZonesPresence(values)
    fields['paypal_email'] = values.paypal_email
    fields['phone'] = values.tel
    fields['phone2'] = values.telSecondary || null
    fields['promoter'] = values.promoter_email || null
    fields['rfc'] = values.rfc || null
    
    this.props.convertUserToArtist(fields, uid, () => {
      if(process.env.REACT_APP_STAGE === ENVIRONMENTS.PRD)
        window.location.href = `${URLS.PRD_ROOT}${routes.ARTIST_PROFILE}`
      else
        window.location.href = `${URLS.QAS_ROOT}${routes.ARTIST_PROFILE}`
    })

  }

  render() {
    const { page } = this.state
    let step_svg = step_one

    switch(page){
      case 1:
        step_svg = step_one
      break
      case 2:
        step_svg = step_two
      break
      case 3:
        step_svg = step_three
      break
      case 4:
        step_svg = step_four
      break
      default:
      break
    }

    return (
      <div className='p-4'>
        <div className='d-flex justify-content-center my-2 my-md-4'>
          <img src={step_svg} alt={`Paso ${step_svg}`}/>
        </div>
        {page === 1 && 
          <WizardFormFirstPage 
            onSubmit={this.saveLocalDataOne} 
          />
        }
        
        {page === 2 && (
          <WizardFormThirdPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 3 && (
          <WizardFormFourthPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 4 && (
          <WizardFormFifthPage
            previousPage={this.previousPage}
            onSubmit={this.onSubmit.bind(this, this.props.meta.user.uid)}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps({ auth }){
  const { meta } = auth
  
  return { meta }
}

export default connect(
  mapStateToProps, 
  { convertUserToArtist, saveClientUserData }
)(withRouter(WizardForm))