import map from 'lodash/map'
import React, { Component } from 'react'
import  { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchUserSaved, deleteSaved } from 'actions/user'
import { selectFilter } from 'actions/bookings'
import { setCurrencyCode } from 'actions/services'
import { EmptyImg, DeleteImg, PointerDiv } from './styled'
import IconNoSaved from 'components/atoms/images/vacios/sin_guardados.svg'
import IconDelete from 'components/atoms/images/icono_tache.svg'
import * as routes from 'constants/routes'

class UserSavedList extends Component{
    constructor(props){
        super(props)
        this.renderSavedList = this.renderSavedList.bind(this)
        this.renderEmptySaved = this.renderEmptySaved.bind(this)
        this.onDeleteSaved = this.onDeleteSaved.bind(this)
        this.onClickSaved = this.onClickSaved.bind(this)
    }

    componentDidMount(){
        this.props.fetchUserSaved(this.props.user_id)
    }

    onDeleteSaved(service_id){
        this.props.deleteSaved(this.props.user_id, service_id)
    }

    onClickSaved(service){
        this.props.selectFilter(service, () => {
            this.props.setCurrencyCode(service.currency_code, () => {
                const formated_route = routes.CONFIRM_BOOKING_REQUEST.replace(':id', service.service_id)
                this.props.history.push(formated_route)
            })
        })
    }

    renderSavedList(){
        return (
            map(this.props.saved, service => {
                return (
                    <div className='row no-gutters py-4' key={service.id}>
                        <div
                            className='col-9'
                            onClick={() => this.onClickSaved(service)}>
                            <h5>{service.service_name}</h5>
                            <span className='text-gray-muzig'>{service.artist_name}</span>
                        </div>
                        <PointerDiv 
                            className='col-3 d-flex align-items-center justify-content-center'
                            onClick={() => this.onDeleteSaved(service.id)}>
                            <DeleteImg src={IconDelete} alt=''/>
                        </PointerDiv>
                    </div>
                )
            })
        )
    }

    renderEmptySaved(){
        return (
            <div className='text-center mt-5'>
                <EmptyImg src={IconNoSaved} alt='' />
                <h2 className='text-gray-muzig my-4'>Sin guardados</h2>
            </div>
        )
    }

    render(){
        return(
            <>
                {
                    this.props.saved.length > 0 ? 
                        this.renderSavedList()
                        :
                        this.renderEmptySaved()
                }
            </>
        )
    }
}

function mapStateToProps({ auth, user }){
    const user_id = auth.meta.user.uid
    const { saved } = user
    return { user_id, saved }
}

export default connect(
    mapStateToProps,
    { fetchUserSaved, deleteSaved, selectFilter, setCurrencyCode }
)(withRouter(UserSavedList))