import styled from 'styled-components'
import { Button } from 'reactstrap'
import MusicalIcon from 'components/atoms/images/icono_musical.svg'
import ProductionIcon from 'components/atoms/images/icono_produccion.svg'
import PerformanceIcon from 'components/atoms/images/icono_performance.svg'

export const BackButton = styled(Button)`
  border-radius: 2rem!important;
  padding: 0.2rem 5rem;
  min-width: 15rem;
`

export const StyledDiv = styled.div`
  input {
    margin:0;padding:0;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
  }

  input:active + label {opacity: .9;}

  input:checked + label {
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
  }

  label {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box
    width: 100%;
    height: 10rem;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
       -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
            filter: brightness(1.8) grayscale(1) opacity(.7);
  }

  label:hover{
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
       -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
            filter: brightness(1.2) grayscale(.5) opacity(.9);
  }
  
  .musical { background-image: url(${MusicalIcon})}
  .performance { background-image: url(${PerformanceIcon})}
  .production { background-image: url(${ProductionIcon})}
`

export const RightDiv = styled.div`
    height: 100vh;
`

export const RoundedButton = styled(Button)`
  border-radius: 2rem!important;
  padding: 0.2rem 5rem;
  min-width: 15rem;
`