import _ from 'lodash'
import React, { Component } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'

import { 
    completeEditServiceStep, 
    selectEditServiceStep, 
    uncheckEditServiceStep,
    getCountriesList, 
    getZonesByCountry, 
    unselectCountry } from 'actions/shared'
import { updateService, fetchJobService } from 'actions/artists'
import { EDIT_SERVICE_VIEWS, SIGNUP } from 'constants/index'
import MultipleSelect from 'components/atoms/inputs/multiple_select'
import Button from 'components/atoms/buttons/solid'
import { 
    getZonesPresence, 
    containsObject, 
    getCountries, 
    capitalizeOnlyFirstLetter, 
    localeSortArrayBy 
} from 'helpers/index'

class EditServiceZones extends Component{
    constructor(props){
        super(props)
        this.state = { 
            selectCountriesValue: this.props.initialCountries,
            firstTimeLoad : true
        }
        this.updateValue = this.updateValue.bind(this)
        this.renderFieldsForZones = this.renderFieldsForZones.bind(this)
        this.maxLength3 = this.maxLength3.bind(this)
        this.minLength1 = this.minLength1.bind(this)
    }

    componentDidMount(){
        this.setState({ firstTimeLoad: false })
        this.props.getCountriesList()
    }

    onSubmit(values){
        const change = { 
            zones : getZonesPresence(values),
            countries : getCountries(values)
        }
        
        this.props.updateService(change, this.props.job_service_selected.id, () => {
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.zones)
            const service_id = window.location.href.split('/').pop()
            this.props.fetchJobService(service_id)
        })
    }

    maxLength3(values = []){
        if(values.length > 3){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.zones)
            return 'No puedes elegir más de 3 opciones'
        }
        else
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.zones)
    }
    
    minLength1(values = []){
        if(values.length < 1){
            this.props.uncheckEditServiceStep(EDIT_SERVICE_VIEWS.zones)
            return 'Tienes que elegir al menos una opción'
        }
        else
            this.props.completeEditServiceStep(EDIT_SERVICE_VIEWS.zones)
    }

    updateValue(newValue) {
        let difference = []
        this.props.initialCountries.forEach(country => {
            if(!containsObject(country, newValue))
                difference.push(country)
        })
        
        this.setState({ selectCountriesValue: newValue })
        
        if (difference.length === 0){
            this.props.getZonesByCountry(newValue[newValue.length-1].value)
        }
        else {
            this.props.unselectCountry(difference[0].value)
            this.props.change(`presence-for-${difference[0].value}`, [])
        }
    }

    renderFieldsForZones(zonesByCountry){
        if (Object.keys(zonesByCountry).length > 0){
            let zonesArrayOptions = []
            
            return (
                _.map(zonesByCountry, (zones, country) => {

                    zonesArrayOptions = _.map(zones, (zoneValue, zoneId) => ({ value:zoneId, label:capitalizeOnlyFirstLetter(zoneId) }) )
                    zonesArrayOptions = localeSortArrayBy(zonesArrayOptions, 'value')
                    return(
                        <div className='my-2' key={country}>
                            <p>Zonas para {country}</p>
                            <Field
                                name={`presence-for-${country}`}
                                type='select'
                                selectType={SIGNUP.SELECT_TYPE_ZONES}
                                placeholder={`Selecciona 3 zonas para ${country}`}
                                options={zonesArrayOptions}
                                validate={[this.maxLength3,this.minLength1]}
                                component={MultipleSelect}
                            />
                        </div>
                    )
                })
            )
        }
        else if(Object.keys(this.props.initialValues).length > 0 && this.state.firstTimeLoad){
            _.map(this.props.initialValues['countries-presence'], country => {
                this.props.getZonesByCountry(country.value)
            })
        }
        else{
            console.log('No hay zonas')
        }
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <>
                <h2 className='text-secondary'>Zonas de servicio</h2>
                <p>Puedes delimitar las zonas de tu servicio.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="countries-presence"
                        type='select'
                        selectType={SIGNUP.SELECT_TYPE_COUNTRIES}
                        placeholder='Selecciona 3 países'
                        options={this.props.countries}
                        component={MultipleSelect}
                        validate={[this.maxLength3,this.minLength1]}
                        updateValue={this.updateValue}
                    />

                    <div className='w-100 my-3'></div>
                    {this.renderFieldsForZones(this.props.zonesByCountry)}
            
                    <div className='my-4 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps({ shared, form, artist }){
    let initialCountries = []
    if(form.editServiceWizard && form.editServiceWizard.values)
        if(typeof form.editServiceWizard.values['countries-presence'] !== 'undefined')
            initialCountries = form.editServiceWizard.values['countries-presence']

    let countriesArray = []
    let { countries, zonesByCountry } = shared
    const { job_service_selected } = artist

    countries.forEach( country => countriesArray.push({ value:country.name, label:country.name }) )

    /*VALORES INICIALES*/
    let keys = []
    let zonesArrayOptions = []
    let zonesByEachCountry = {}

    if (job_service_selected.zones){
        keys = Object.keys(job_service_selected.zones)
        zonesArrayOptions = _.map(keys, key => {
            zonesByEachCountry[`presence-for-${key}`] = _.map(job_service_selected.zones[key], zone => {
                return { value: zone, label: zone }
            })
            return { value: key, label: key }
        })
    }

    const initialValues = {
        'countries-presence' : zonesArrayOptions,
    }

    Object.assign(initialValues, zonesByEachCountry)
    
    return { countries: countriesArray, zonesByCountry, initialCountries, job_service_selected, initialValues }
}

EditServiceZones = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true
  })(EditServiceZones)
  
  EditServiceZones = connect(
    mapStateToProps,
    { 
        completeEditServiceStep, 
        selectEditServiceStep, 
        uncheckEditServiceStep,
        getCountriesList, 
        getZonesByCountry, 
        unselectCountry,
        change,
        updateService,
        fetchJobService
    }
  )(EditServiceZones)
  
  export default EditServiceZones