import React, { Component } from 'react'
import { UncontrolledCollapse } from 'reactstrap'
import UserCardList from 'components/organisms/user_card_list'
import AddCardForm from 'components/organisms/add_card_form'
import { PointerSpan } from './styled'

class EditPaymentMethod extends Component{
    render(){
        return (
            <>
                <h4 className='text-gray-muzig'>Formas de pago</h4>
                <div className='py-4'>
                    <UserCardList/>
                </div>
                <div className='w-100 text-right'>
                    <PointerSpan id='toggler' className='text-secondary'>+ Agregar tarjeta</PointerSpan>
                </div>
                <UncontrolledCollapse toggler="toggler">
                    <AddCardForm/>
                </UncontrolledCollapse>

            </>
        )
    }
}

export default EditPaymentMethod