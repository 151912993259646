export default function validate(values){
    const errors = {}
    if (!values.name)
      errors.name = 'Ingresa tu nombre'
    
    if (!values.lastName)
      errors.lastName = 'Ingresa tus apellidos'

    if (!values.phone)
      errors.phone = 'Escribe tu número de teléfono'

    if (!values.email)
      errors.email = 'Ingresa tu correo electrónico'
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Ingresa un correo electrónico válido'

    if(!values.address)
        errors.address = 'Escribe la dirección donde recibirás tus servicios'

    if (!values.country)
      errors.country = 'Selecciona tu país de residencia'

    // if (!values.clabe)
    //   errors.clabe = 'Ingresa tu CLABE interbancaria para recibir pagos.'
    // else if (values.clabe.length !== 18)
    //   errors.clabe = 'La CLABE debe ser de 18 dígitos.'

    if (!values.paypal_email)
      errors.paypal_email = 'Ingresa tu correo electrónico de Paypal'
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.paypal_email))
      errors.paypal_email = 'Ingresa un correo electrónico válido'

    return errors
}