import React, { Component } from 'react'
import UserRevenueList from 'components/organisms/user_revenue_list'

class Revenue extends Component{
    render(){
        return(
            <>
                <section className='p-4'>
                    <h4 className='text-secondary'>Ganancias</h4>
                    <UserRevenueList/>
                </section>
            </>
        )
    }
}

export default Revenue