import React, { Component } from 'react'
import FlashAlert from 'components/molecules/alert'
import ArtistNewServiceSteps from 'components/organisms/artist_small_new_service_steps'

class SmallNewService extends Component{
    render(){
        return(
            <>
                <FlashAlert/>
                <ArtistNewServiceSteps/>
            </>
        )
    }
}

export default SmallNewService