import { BOOKINGS, SHARED, ALERTS, MOMENT_IN_SPANISH, OPENPAY_ERRORS, CLOUD_FUNCTIONS_URL } from 'constants/index'
import { firestore, auth } from 'firebase/index'
import moment from 'moment'
import axios from 'axios'

export function fetchBookingDatesOfService(service_id, callback){
    console.log('fetchBookingDatesOfService')
    let bookingsArray = []
    let startDate = new Date()
    let endDate = new Date()
    
    //Limitamos búsqueda a máximo de días (2 meses aprox.)
    endDate.setDate(startDate.getDate() + 62)
    
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        
        firestore.collection('reservations')
        .where('service_id', '==', service_id)
        .where('when', '>', startDate.getTime() )
        .where('when', '<=', endDate.getTime() )
        .get().then( querySnapshot => {
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                bookingsArray.push({ ...sid, ...data })
            })
            dispatch({
                type: BOOKINGS.PUSH_TO_BLOCKED_HOURS,
                payload: bookingsArray
            })
            callback()
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch( error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log(dispatch, error)
        })
      }
}

export function setDurationLimitAt(hour, last_hour){
    return dispatch => {
        dispatch({
            type: BOOKINGS.SET_DURATION_LIMIT_AT,
            payload: hour,
            last_hour
        })
    }
}

export function saveFilterOptions(fields, callback){
    return dispatch => {
        // const h = moment.duration(`${fields.startHour}`).subtract(1, 'minutes')
        // const start_hour = Number(`${h._data.hours}${h._data.minutes}`)
        const start_hour = Number(fields.startHour.replace(':', ''))
        const duration = Number(fields.hours)
        let end_hour = (start_hour + 100 * duration).toString()
        fields['endHour'] = `${end_hour.slice(0,-2)}:${end_hour.slice(-2)}`
        fields['when'] = moment(`${fields.date} ${fields.startHour}`, 'DD-MM-YYYY HH:mm').valueOf()

        dispatch({
            type: BOOKINGS.SAVE_FILTER_OPTIONS_FOR_BOOKING,
            payload: fields
        })

        callback()
    }
}

export function selectFilter(service_filter, callback){
    return dispatch => {
        moment.updateLocale('es', MOMENT_IN_SPANISH )
        Object.assign(
            service_filter,
            {
                date: moment(service_filter.when).format('DD-MM-YYYY'),
                startHour: moment(service_filter.when).format('HH:mm')
            }
        )
        const start_hour = Number(service_filter.startHour.replace(':', ''))
        const duration = Number(service_filter.hours)
        let end_hour = (start_hour + 100 * duration).toString()
        service_filter['endHour'] = `${end_hour.slice(0,-2)}:${end_hour.slice(-2)}`
        dispatch({
            type: BOOKINGS.SAVE_FILTER_OPTIONS_FOR_BOOKING,
            payload: service_filter
        })
        callback()
    }
}

export function payWithCard(pago, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        const url = `${CLOUD_FUNCTIONS_URL}/payCard`
        auth.currentUser.getIdToken(true)
        .then(token => {
            const headers = { 
                headers: { 
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            }
            const data = JSON.stringify(pago)
            
            let axios_request = axios.create({
                validateStatus : status => { return status <= 500 }
            })

            axios_request.post(url, data, headers)
            .then(response => {
                if(response.data.success){
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Pago realizado exitósamente'
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    callback()
                }
                else {
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: OPENPAY_ERRORS[response.data.details.error_code]
                    })
                }

                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
                })
                console.log('Error mayor a 500 desde servidor.', error)
            })
            
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
            })
            console.log('No se logró actualizar token de usuario', error)
        })
    }
}

export function payWithCash(pago, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        const url = `${CLOUD_FUNCTIONS_URL}/payCash`
        auth.currentUser.getIdToken(true)
        .then(token => {
            const headers = { 
                headers: { 
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            }
            const data = JSON.stringify(pago)
            
            let axios_request = axios.create({
                validateStatus : status => { return status <= 500 }
            })

            axios_request.post(url, data, headers)
            .then(response => {
                if(response.data.success){
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Pago realizado exitósamente'
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    callback()
                }
                else {
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: OPENPAY_ERRORS[response.data.details.error_code]
                    })
                }

                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
                })
                console.log('Error mayor a 500 desde servidor.', error)
            })
            
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
            })
            console.log('No se logró actualizar token de usuario', error)
        })
    }
}

export function generateHoursForDay(singleDate, availability, callback = ()=>{}){
    return (dispatch) => {
        dispatch({
            type: BOOKINGS.GENERATE_HOURS_FOR_DAY,
            payload: singleDate,
            availability
        })
        callback()
    }
}

export function fetchBookingDatesOfExtraService(service_id, callback){
    let bookingsArray = []
    let startDate = new Date()
    let endDate = new Date()
    
    //Limitamos búsqueda a máximo de días (2 meses aprox.)
    endDate.setDate(startDate.getDate() + 62)
    
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        
        firestore.collection('reservations')
        .where('service_id', '==', service_id)
        .where('when', '>', startDate.getTime() )
        .where('when', '<=', endDate.getTime() )
        .get().then( querySnapshot => {
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                bookingsArray.push({ ...sid, ...data })
            })
            dispatch({
                type: BOOKINGS.PUSH_TO_BLOCKED_EXTRA_HOURS,
                payload: bookingsArray
            })
            callback()
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch( error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log(dispatch, error)
        })
      }
}

export function selectDurationExtraHours(duration){
    return dispatch => {
        dispatch({
            type: BOOKINGS.SELECT_EXTRA_HOURS_DURATION,
            payload: duration
        })
    }
}

export function selectPaymentOption(type){
    return dispatch => {
        dispatch({
            type: BOOKINGS.SELECT_PAYMENT_OPTION,
            payload: type
        })
    }
}

export function saveHistoricPaypalOrder(order_id, order){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        firestore.collection('paypal_orders').doc(order_id)
        .set(order)
        .then(() => {
            console.log('Registro de paypal_orders realizado correctamente.')
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch(error => {
            console.log('Error al registrar en paypal_orders', error)
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

export function payWithPaypal(pago, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        const url = `${CLOUD_FUNCTIONS_URL}/payNoGateway`
        auth.currentUser.getIdToken(true)
        .then(token => {
            const headers = { 
                headers: { 
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            }
            const data = JSON.stringify(pago)
            
            let axios_request = axios.create({
                validateStatus : status => { return status <= 500 }
            })

            axios_request.post(url, data, headers)
            .then(response => {
                if(response.data.success){
                    console.log('Se responde con éxito')
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Pago realizado exitósamente'
                    })
                    // dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    console.log('antes del callback')
                    callback()
                    console.log('después del callback')
                }
                else {
                    console.log('Se responde sin éxito')
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: OPENPAY_ERRORS[response.data.details.error_code]
                    })
                    
                }

            })
            .catch(error => {
                console.log('Se cachea error del axiso post')
                // dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
                })
                console.log('Error menor a 500 desde servidor.', error)
            })
        })
        .catch(error => {
            console.log('Se cachea error en la recuperación del token')
            // dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
            })
            console.log('No se logró actualizar token de usuario', error)
        })
    }
}