import styled from 'styled-components'
import DislikeIcon from 'components/atoms/images/icono_dislike_lleno.svg'
import LikeIcon from 'components/atoms/images/icono_like_lleno.svg'

export const StyledDiv = styled.div`
  input {
    margin:0;padding:0;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
  }

  input[type="radio"] {
    display: none;
  }

  input:active + label {opacity: .9;}

  input:checked + label {
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
  }

  label {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box
    width: 100%;
    height: 5rem;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
       -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
            filter: brightness(1.8) grayscale(1) opacity(.7);
  }

  label:hover{
    -webkit-filter: brightness(1.2) grayscale(.1) opacity(.9);
       -moz-filter: brightness(1.2) grayscale(.1) opacity(.9);
            filter: brightness(1.2) grayscale(.1) opacity(.9);
  }
  
  .dislike { 
    background-image: url(${DislikeIcon});
    max-height: 3rem;
    max-width: 3rem;
    min-height: 4rem;
    min-width: 4rem;
  }
  .like { 
    background-image: url(${LikeIcon});
    max-height: 3rem;
    max-width: 3rem;
    min-height: 4rem;
    min-width: 4rem;
  }
`