import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import FlashAlert from 'components/molecules/alert'
import HomeSearch from 'components/organisms/home_search_field'
import SearchResultCard from 'components/molecules/search_result_card'
import NoResults from 'components/atoms/images/imagen_sin_resultados.svg'
import { NoResultsImage } from './styled'

import { selectService, fetchCategoryList } from 'actions/services'
import * as routes from 'constants/routes'
import { CATEGORIES } from 'constants/index'
import { getCurrentCountry } from 'helpers/index'
import { Row } from 'reactstrap'

class CategoryList extends Component{
    constructor(props){
        super(props)
        this.onServiceClick = this.onServiceClick.bind(this)
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        const category = window.location.href.split('/').pop()

        if(CATEGORIES[category])
            this.props.fetchCategoryList(CATEGORIES[category], this.props.user_country)
            // this.props.fetchCategoryList(CATEGORIES[category])
        else
            this.props.history.push(routes.ROOT)
    }
    
    onServiceClick(service){
        this.props.selectService(service, () => {
            const URL_TOKENS = { ':id':service.id, ':slug':service.slug }
            const formated_route = routes.SHOW_SERVICE.replace(/:id|:slug/gi, matched => URL_TOKENS[matched])
            this.props.history.push(formated_route)
        })
        
    }

    renderListServices(services){
        if(services.length > 0)
            return _.map( services, service => {
                return (
                    <SearchResultCard 
                        key={service.id}
                        service={service}
                        onServiceClick={this.onServiceClick}/>
                )
            })
        else
            return (
                <div className='text-center'>
                    <NoResultsImage src={NoResults}/>
                    <h2 className='mt-5'>No se encontraron servicios de este tipo para el país actual.</h2>
                </div>
            )
    }

    render(){
        return(
            <div className='d-flex flex-column'>
                <FlashAlert/>
                <section className='text-center mt-3 mt-md-5'>
                    <HomeSearch />
                    <div className='container d-flex flex-column rounded'>
                        <h1 className='text-left mt-5 text-secondary'>
                            {this.props.current_category.text}
                            { this.props.user ? ` en ${this.props.user.country}` : '' }
                        </h1>
                        {/* <h1 className='text-left mt-5 text-secondary'>
                            {this.props.current_category.text}
                        </h1> */}
                        <Row noGutters className='p-2'>
                            {this.renderListServices(this.props.list)}
                        </Row>
                    </div>
                </section>
            </div>
        )
    }
}

function mapStateToProps({ services, auth }){
    const { list, current_category } = services
    const { user } = auth
    const user_country = getCurrentCountry(user ? user.country : 'MÉXICO')
    return { list, user, current_category, user_country }
}

export default withRouter(
    connect(
        mapStateToProps, { selectService, fetchCategoryList }
    )(CategoryList)
)