import styled from 'styled-components'

export const EmptyImg = styled.img`
    width: 45%;
`

export const DeleteImg = styled.img`
    width: 1.6rem;
`

export const PointerDiv = styled.div`
    cursor: pointer;
`