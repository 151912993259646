import styled from 'styled-components'

export const PointerDiv = styled.div`
    background-color: ${props => props.active ? '#4E6395' : 'white'}
    color: ${props => props.active ? 'white' : '#9b9b9b' }
    cursor: pointer;
`

export const OptionIconImg = styled.img`
    width: 1rem
`