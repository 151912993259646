import React, { Component } from 'react'
import FlashAlert from 'components/molecules/alert'
import ArtistSmallProfileHeader from 'components/organisms/artist_small_profile_header'
import ArtistProfileListOptions from 'components/organisms/artist_small_profile_list'
import ArtistAvailabilitySwitch from 'components/organisms/artist_availability_switch'

class SmallProfile extends Component{
    render(){
        return(
            <>
                <FlashAlert/>
                <ArtistSmallProfileHeader/>
                <ArtistAvailabilitySwitch/>
                <ArtistProfileListOptions/>
            </>
        )
    }
}

export default SmallProfile