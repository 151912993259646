import React, { Component } from 'react'
import UserHistoryList from 'components/organisms/user_history_list/tab'

class UserHistory extends Component{
    render(){
        return (
            <>
                <UserHistoryList/>
            </>
        )
    }
}

export default UserHistory