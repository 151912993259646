import map from 'lodash/map'
import filter from 'lodash/filter'
import React, { Component } from 'react'
import  { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { EmptyImg } from './styled'
import IconNoRevenue from 'components/atoms/images/vacios/sin_pagos.svg'
import { PAYPAL_CURRENCIES } from 'constants/index'
import { getWeekRange } from 'helpers/index'

class UserHistoryList extends Component{
    constructor(props){
        super(props)
        this.renderRevenueList = this.renderRevenueList.bind(this)
        this.renderEmptyRevenue = this.renderEmptyRevenue.bind(this)
        this.isThereRevenue = this.isThereRevenue.bind(this)
    }

    renderRevenueList(){
        return (
            <>
                <p className=''>El total mostrado corresponde al total de ganancias antes de restar la comisión de The Muzigzag®.</p>
                <div className='d-flex justify-content-end my-3 my-md-5'>
                    <span className='text-grey'>{getWeekRange()}</span>
                </div>
                {map(this.props.next_payment, (revenue, id) => {
                    if(revenue)
                        return (
                            <div 
                                className='d-flex justify-content-between align-items-center p-4 border-bottom border-grey' 
                                key={id}>
                                <h4>{PAYPAL_CURRENCIES[id].symbol}{revenue}</h4>
                                <h5 className='text-secondary'>{id}</h5>
                            </div>
                        )
                })}
            </>
        )
    }

    renderEmptyRevenue(){
        return (
            <>
                <div className='d-flex justify-content-end my-3 my-md-5'>
                    <span className='text-grey'>{getWeekRange()}</span>
                </div>
                <div className='text-center mt-5'>
                    <EmptyImg src={IconNoRevenue} alt='sin ganancias' />
                    <h2 className='text-gray-muzig my-4'>Sin ganancias</h2>
                </div>
            </>
        )
    }

    isThereRevenue(revenue){
        const array_revenue = filter(revenue, currency_revenue => {
            return currency_revenue !== 0
        })
        return array_revenue.length > 0
    }

    render(){
        return(
            <>
                {
                    this.isThereRevenue(this.props.next_payment) ? 
                        this.renderRevenueList()
                    :
                        this.renderEmptyRevenue()
                        
                }
            </>
        )
    }
}

function mapStateToProps({ auth }){
    const { next_payment } = auth.user

    return { next_payment }
}

export default connect(
    mapStateToProps,
    {}
)(withRouter(UserHistoryList))