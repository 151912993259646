import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { 
    completeNewServiceStep, 
    selectNewServiceStep, 
    uncheckNewServiceStep,
    getEventTypesList } from 'actions/shared'
import { NEW_SERVICE_VIEWS, SHARED } from 'constants/index'
import MultipleSelect from 'components/atoms/inputs/multiple_select'
import Button from 'components/atoms/buttons/solid'
import * as routes from 'constants/routes'

class NewServiceTypes extends Component{
    constructor(props){
        super(props)
        this.minLength1 = this.minLength1.bind(this)
    }

    componentDidMount(){
        this.props.getEventTypesList()
    }

    onSubmit(values){
        this.props.completeNewServiceStep(NEW_SERVICE_VIEWS.types)
        this.props.selectNewServiceStep(NEW_SERVICE_VIEWS.includes)
        this.props.history.push(routes.ARTIST_JOB_SERVICES_NEW)
    }
    
    minLength1(values = []){
        this.props.uncheckNewServiceStep(NEW_SERVICE_VIEWS.types)
        return values.length < 1 ? 'Tienes que elegir al menos una opción' : undefined
    }

    render(){
        const { handleSubmit } = this.props
        return (
            <div className='container mt-5'>
                <h4 className='text-secondary'>Tipo de evento</h4>
                <p>Selecciona los eventos que mejor se adapten a tu servicio.</p>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} className='p-2'>
                    <Field
                        name="event-types"
                        type='select'
                        selectType={SHARED.SELECT_TYPE_EVENT_TYPES}
                        placeholder='Selecciona al menos un tipo de evento'
                        options={this.props.event_types}
                        component={MultipleSelect}
                        validate={[this.minLength1]}
                    />
            
                    <div className='my-5 d-flex justify-content-center'>
                        <Button 
                            type='submit' 
                            color='primary' 
                            text='Guardar'
                        />
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps({ shared, form }){
    let initialEventTypes = []
    if(form.createNewServiceWizard && form.createNewServiceWizard.values)
        if(typeof form.createNewServiceWizard.values['event-types'] !== 'undefined')
            initialEventTypes = form.createNewServiceWizard.values['event-types']

    let eventTypesArray = []
    let { eventTypes } = shared
    eventTypes.forEach( type => eventTypesArray.push({ value:type.name, label:type.name }) )
    
    return { event_types: eventTypesArray, initialEventTypes }
}

NewServiceTypes = reduxForm({
    form: 'createNewServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewServiceTypes)
  
  NewServiceTypes = connect(
    mapStateToProps,
    { 
        completeNewServiceStep, 
        selectNewServiceStep, 
        uncheckNewServiceStep,
        getEventTypesList}
  )(NewServiceTypes)
  
  export default withRouter(NewServiceTypes)