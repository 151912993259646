import React from 'react'

import {
    InputGroup
} from 'reactstrap'
import { StyledInput, InvalidFeedback } from './styled'

export default ( field ) => {
    const { meta: {error, touched} } = field
    const isInvalid = touched && error ? true : false

    return(
        <>
            <InputGroup>
                <StyledInput
                    type={field.type}
                    name={field.name}
                    invalid={isInvalid}
                    placeholder={field.placeholder}
                    onClick={field.onClick}
                    rows={field.rows}
                    {...field.input}
                />
                <span className='text-gray-muzig mx-2'>{field.text}</span>
            </InputGroup>
            <InvalidFeedback className='mx-3 text-danger'>{ isInvalid ? error : ''}</InvalidFeedback>
        </>
    )
}