import React, { Component } from 'react'

import FlashAlert from 'components/molecules/alert'
import ArtistProfileEditForm from 'components/organisms/artist_profile_edit_form'

class ProfileEdit extends Component{
    render(){
        return(
            <>
                <FlashAlert/>
                <section className='p-4'>
                    <h4 className='text-secondary'>Mi perfil</h4>
                    <ArtistProfileEditForm/>
                </section>
            </>
        )
    }
}

export default ProfileEdit