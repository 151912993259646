import React from 'react'
import { EditImg, ImgInput, ActiveImageFormFeedback } from './styled'

export default ( field ) => {
    const { meta: {error, touched} } = field
    const isInvalid = touched && error ? true : false
    const { input: {onChange, onBlur, value: omitValue, ...inputProps} } = field
    const { ...props } = field

    const handleChange = (handler) => ({ target: {files} }) =>
                          handler(files.length ? { file: files[0] } : {} )

    return(
        <>
            <EditImg 
                className='d-flex justify-content-center align-items-center bg-secondary'>
                <ImgInput
                    onChange={handleChange(onChange)}
                    onBlur={handleChange(onBlur)}
                    { ...inputProps } { ...props }
                />            
            </EditImg>
            <ActiveImageFormFeedback 
                className='mx-3 bg-light'
                invalid={isInvalid.toString()}>
                    {error}
            </ActiveImageFormFeedback>
        </>
    )
}