import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { selectSidemenuOption } from 'actions/shared'
import { PROFILE_VIEWS } from 'constants/index'
import { PointerDiv, OptionIconImg } from './styled'

import EditIcon from 'components/atoms/images/icono_editar_perfil.svg'
import EditActiveIcon from 'components/atoms/images/icono_editar_perfil_blanco.svg'

// import PaymentMethodIcon from 'components/atoms/images/icono_pagos.svg'
// import PaymentMethodActiveIcon from 'components/atoms/images/icono_pagos_blanco.svg'

import HistoryIcon from 'components/atoms/images/icono_historial.svg'
import HistoryActiveIcon from 'components/atoms/images/icono_historial_blanco.svg'

import FavoritesIcon from 'components/atoms/images/icono_favoritos.svg'
import FavoritesActiveIcon from 'components/atoms/images/icono_favoritos_blanco.svg'

import SavedIcon from 'components/atoms/images/icono_guardados.svg'
import SavedActiveIcon from 'components/atoms/images/icono_guardados_blanco.svg'

import MyServicesIcon from 'components/atoms/images/icono_mis_servicios.svg'
import MyServicesActiveIcon from 'components/atoms/images/icono_misservicios_blanco.svg'

import PromoterIcon from 'components/atoms/images/icono_promotor.svg'
import PromoterActiveIcon from 'components/atoms/images/icono_promotor_inactivo.svg'

import RevenueIcon from 'components/atoms/images/icono_ganancias_activo.svg'
import RevenueActiveIcon from 'components/atoms/images/icono_ganancias.svg'

class BigSidemenu extends Component{
    constructor(props){
        super(props)
        this.onOptionSelected = this.onOptionSelected.bind(this)
    }

    onOptionSelected(page){
        this.props.selectSidemenuOption(page)
    }

    render(){
        return(
            <section className='d-flex flex-column text-gray-muzig'>
                <PointerDiv 
                    active={this.props.sidemenuPage === PROFILE_VIEWS.revenue}
                    onClick={() => this.onOptionSelected(PROFILE_VIEWS.revenue)}
                    className='d-flex py-4 rounded rounded'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.sidemenuPage === PROFILE_VIEWS.revenue ? 
                                RevenueActiveIcon
                                :
                                RevenueIcon
                            }/>
                    </div>
                    <div className='mx-2'>Ganancias</div>
                </PointerDiv>
                <PointerDiv 
                    active={this.props.sidemenuPage === PROFILE_VIEWS.my_services}
                    onClick={() => this.onOptionSelected(PROFILE_VIEWS.my_services)}
                    className='d-flex py-4 rounded'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.sidemenuPage === PROFILE_VIEWS.my_services ? 
                                MyServicesActiveIcon
                                :
                                MyServicesIcon
                            }/>
                    </div>
                    <div className='mx-2'>Mis servicios</div>
                </PointerDiv>

                {/* <PointerDiv 
                    active={this.props.sidemenuPage === PROFILE_VIEWS.my_payments}
                    onClick={() => this.onOptionSelected(PROFILE_VIEWS.my_payments)}
                    className='d-flex py-4 rounded'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.sidemenuPage === PROFILE_VIEWS.my_payments ? 
                                PaymentMethodActiveIcon
                                :
                                PaymentMethodIcon
                            }/>
                    </div>
                    <div className='mx-2'>Mis pagos</div>
                </PointerDiv> */}

                <div className='dropdown-divider'></div>

                <PointerDiv 
                    active={this.props.sidemenuPage === PROFILE_VIEWS.edit}
                    onClick={() => this.onOptionSelected(PROFILE_VIEWS.edit)}
                    className='d-flex py-4 rounded'
                >
                    <div className='mx-2'>
                        <OptionIconImg 
                            alt='' 
                            src={this.props.sidemenuPage === PROFILE_VIEWS.edit ? 
                                EditActiveIcon
                                :
                                EditIcon
                            }/>
                    </div>
                    <div className='mx-2'>Editar perfil</div>
                </PointerDiv>

                {/* <PointerDiv 
                    active={this.props.sidemenuPage === PROFILE_VIEWS.payment}
                    onClick={() => this.onOptionSelected(PROFILE_VIEWS.payment)}
                    className='d-flex py-4 rounded'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.sidemenuPage === PROFILE_VIEWS.payment ? 
                                PaymentMethodActiveIcon
                                :
                                PaymentMethodIcon
                            }/>
                    </div>
                    <div className='mx-2'>Formas de pago</div>
                </PointerDiv> */}

                <PointerDiv 
                    active={this.props.sidemenuPage === PROFILE_VIEWS.history}
                    onClick={() => this.onOptionSelected(PROFILE_VIEWS.history)}
                    className='d-flex py-4 rounded'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.sidemenuPage === PROFILE_VIEWS.history ? 
                                HistoryActiveIcon
                                :
                                HistoryIcon
                            }/>
                    </div>
                    <div className='mx-2'>Historial</div>
                </PointerDiv>

                <PointerDiv 
                    active={this.props.sidemenuPage === PROFILE_VIEWS.favorites}
                    onClick={() => this.onOptionSelected(PROFILE_VIEWS.favorites)}
                    className='d-flex py-4 rounded'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.sidemenuPage === PROFILE_VIEWS.favorites ? 
                                FavoritesActiveIcon
                                :
                                FavoritesIcon
                            }/>
                    </div>
                    <div className='mx-2'>Favoritos</div>
                </PointerDiv>

                <PointerDiv 
                    active={this.props.sidemenuPage === PROFILE_VIEWS.saved}
                    onClick={() => this.onOptionSelected(PROFILE_VIEWS.saved)}
                    className='d-flex py-4 rounded'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.sidemenuPage === PROFILE_VIEWS.saved ? 
                                SavedActiveIcon
                                :
                                SavedIcon
                            }/>
                    </div>
                    <div className='mx-2'>Guardados</div>
                </PointerDiv>

                <PointerDiv 
                    active={this.props.sidemenuPage === PROFILE_VIEWS.promoter}
                    onClick={() => this.onOptionSelected(PROFILE_VIEWS.promoter)}
                    className='d-flex py-4 rounded'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.sidemenuPage === PROFILE_VIEWS.promoter ? 
                                PromoterActiveIcon
                                :
                                PromoterIcon
                            }/>
                    </div>
                    <div className='mx-2'>Referidos</div>
                </PointerDiv>
                
            </section>
        )
    }
}

function mapStateToProps({ shared }){
    const { sidemenuPage } = shared
    return { sidemenuPage }
}

export default connect(
    mapStateToProps,
    { selectSidemenuOption }
)(withRouter(BigSidemenu))