import styled from 'styled-components'

export const SideMenuItem = styled.div`
    background-color: ${props => props.active ? 'blue' : 'gray'}
`

export const PointerSpan = styled.span`
    cursor: pointer;
`

export const BannerSection = styled.section`
    height: 8rem;
    margin-bottom: 8rem;
`