import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import * as routes from 'constants/routes'
import { EDIT_SERVICE_VIEWS } from 'constants/index'
import { PointerDiv, OptionIconImg } from './styled'
import IncompleteIcon from 'components/atoms/images/icono_incompleto.svg'
import CompleteIcon from 'components/atoms/images/icono_completo.svg'
import { updateService } from 'actions/artists'

class SmallEditServiceSteps extends Component{
    constructor(props){
        super(props)
        this.onOptionClick = this.onOptionClick.bind(this)
    }

    onOptionClick(route){
        this.props.history.push(route)
    }

    render(){
        return(
            <section className='d-flex flex-column p-4 text-gray-muzig'>
                <h2 className='text-secondary'>Editar servicio</h2>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_NAME)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.name] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Título</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_DESCRIPTION)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.description] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Descripción</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_COST)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.cost] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Costo</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_ZONES)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.zones] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Zonas</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_AVAILABILITY)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.availability] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Disponibilidad</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_PHOTOS)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.photos] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Fotos</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_VIDEO)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.video] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Video</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_TYPES)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.types] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Tipos de evento</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_INCLUDES)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.includes] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Qué incluye</div>
                </PointerDiv>
                <PointerDiv
                    onClick={() => this.onOptionClick(routes.ARTIST_JOB_SERVICES_EDIT_CATEGORY)}
                    className='d-flex py-4'
                >
                    <div className='mx-2'>
                    <OptionIconImg 
                            alt='' 
                            src={this.props.editServiceStepCompleted[EDIT_SERVICE_VIEWS.category] ? 
                                CompleteIcon
                                :
                                IncompleteIcon
                            }/>
                    </div>
                    <div className='mx-2'>Categoría</div>
                </PointerDiv>
            </section>
        )
    }
}

function mapStateToProps({ shared, auth }){
    const { editServiceStep, editServiceStepCompleted } = shared
    const { meta: { user }} = auth
    const user_info = auth.user
    return { editServiceStep, editServiceStepCompleted, user, user_info }
}

SmallEditServiceSteps = reduxForm({
    form: 'editServiceWizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(SmallEditServiceSteps)
  
  SmallEditServiceSteps = connect(
    mapStateToProps,
    { updateService }
  )(withRouter(SmallEditServiceSteps))

export default SmallEditServiceSteps