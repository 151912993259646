import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form
    } from 'reactstrap'
import { AUTH } from 'constants/index'
import EmailInput from 'components/atoms/inputs/email_reset_password'

class ResetPasswordModal extends Component{
    
    render(){
        const { handleSubmit } = this.props
        
        return(
            <Modal 
                isOpen={this.props.isOpen} 
                toggle={() => this.props.toogle()}
            >
                <Form onSubmit={handleSubmit(this.props.onClickAcceptButton )}>
                    <ModalHeader toggle={ () => this.props.toogle()}>
                        {this.props.modalHeaderText}
                    </ModalHeader>
                    <ModalBody>
                        {this.props.modalBodyText}
                        <Field
                            name="email"
                            type="email"
                            class="col-12 mt-4 border-0"
                            component={EmailInput}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary">Recuperar</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}

function validate(values){
    let errors = {}

    if (!values.email){
        errors.email = "Introduce tu correo electrónico"
    }
    else{
        if( !AUTH.EMAIL_REGEX.test(String(values.email).toLowerCase()) )
            errors.email = "Introduce un correo electrónico correcto."
    } 
    
    return errors
}

function mapStateToProps({ auth }){
    const { user } = auth
    return { user }
}
  
export default reduxForm({
    validate,
    form: 'ResetPasswordForm'
  })(
    connect(mapStateToProps, {  })(ResetPasswordModal)
  )