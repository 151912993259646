import _ from 'lodash'
import axios from 'axios'
import { USER, ALERTS, SHARED, AUTH, CLOUD_FUNCTIONS_URL, ARTISTS, CLOUD_FUNCTIONS_ERRORS } from 'constants/index'
import { database, firestore, auth, firestoreFieldValue, storage } from 'firebase/index'
import moment from 'moment'
import { MOMENT_IN_SPANISH, SERVICE_STATUS, OPENPAY_ERRORS } from 'constants/index'

export function fetchRequestedServices(user_id, user_role = 'client'){
    return (dispatch) => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        database.ref().child('/requests')
        .orderByChild('created')
        .on('value', snapshot => {
            let services = _.map(snapshot.val(), (service, id) => {
                Object.assign(service, { id, type: 'request' })
                return service
            })
            
            let requested_services = _.filter(services, service => {
                if(user_role === 'client')
                    return service.client_id === user_id
                if(user_role === 'artist')
                    return service.client_id === user_id || service.artist_id === user_id
            })

            const current_date = new Date().getTime()
            requested_services = _.filter(requested_services, service => {
                if(service.when >=  current_date)
                    return service
            })

            dispatch({
                type: USER.FETCH_MY_REQUESTED_SERVICES,
                payload: _.orderBy(requested_services, ['when'])
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })  
    }
}

export function fetchBookedServices(user_id, user_role = 'client'){
    let reservationsArray = []
    let reservationsArtistArray = []

    return (dispatch) => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        firestore.collection("reservations").where('client_id', '==', user_id)
        .where('status', '<', 4)
        .get().then( querySnapshot => {
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                reservationsArray.push({ ...sid, ...data, type: 'reservation' })
            })
            const current_date = new Date().getTime()
            reservationsArray = _.filter(reservationsArray, service => {
                if(service.when + (service.hours*3600000) >=  current_date)
                    return service
            })
            reservationsArray = _.orderBy(reservationsArray, ['when'])
            if(user_role === 'artist'){
                /*COMO ARTISTA*/
                firestore.collection("reservations").where('artist_id', '==', user_id)
                .where('status', '<', 4)
                .get().then( querySnapshot => {
                    querySnapshot.forEach( doc => {
                        let sid = { id:doc.id }
                        let data = doc.data()
                        reservationsArtistArray.push({ ...sid, ...data, type: 'reservation' })
                    })
                    const current_date = new Date().getTime()
                    reservationsArtistArray = _.filter(reservationsArtistArray, service => {
                        if(service.when + (service.hours*3600000) >=  current_date)
                            return service
                    })
                    reservationsArtistArray = _.orderBy(reservationsArtistArray.concat(reservationsArray), ['when'])
                    
                    dispatch({
                        type: USER.FETCH_MY_BOOKED_SERVICES,
                        payload: reservationsArtistArray
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                })
                .catch( error => {
                    console.log(dispatch, error)
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                })
            }
            else{
                dispatch({
                    type: USER.FETCH_MY_BOOKED_SERVICES,
                    payload: reservationsArray
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            }
        })
        .catch( error => {
            console.log(dispatch, error)
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

export function selectMyService(service){
    return dispatch => {
        dispatch({
            type: USER.SELECT_MY_SERVICE,
            payload: service
        })
    }
}

export function fetchMyService(service){
    moment.updateLocale('es', MOMENT_IN_SPANISH)
    
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        firestore.collection('services').doc(service.service_id)
        .get().then( doc => {
            if (doc.exists){
                const image = { image : doc.data().images[0] }
                const rating = { rating : doc.data().rating }
                const startHour = { start_hour : moment(service.when).format('HH:mm') }
                const start_hour = Number(moment(service.when).format('HH:mm').replace(':', ''))
                const duration = Number(service.hours)
                const end_hour = start_hour + (100 * (duration - 1) )
                const endHour = end_hour ? 
                    { end_hour : `${end_hour.toString().slice(0,-2)}:59` }
                    :
                    { end_hour : `00:59` }    
                
                Object.assign(service, image, rating, startHour, endHour)
        
                dispatch({
                    type: USER.SELECT_MY_SERVICE,
                    payload: service
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            }
            else{
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'No existe este servicio, lamentamos los inconvenientes.'
                })
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            }
        })
        .catch( error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log(dispatch, error)
        })
    }
}

export function cancelRequest(request, id, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        firestore.collection('rejected_requests').doc(id)
        .set(request)
        .then( () => {
            database.ref().child(`requests/${id}`).remove()
            .then( () => {
                dispatch({
                    type: ALERTS.SHOW_SUCCESS_MESSAGE,
                    payload: 'Se ha cancelado exitósamente esta solicitud.'
                })
                callback()
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
            .catch( error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                console.log('No se ha logrado borrar el request de firebase.', error)
            })
        })
        .catch( error => {
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'No se ha podido cancelar este servicio, por favor inténtalo nuevamente.'
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log(error)
        })

    }
}

export function fetchMessages(request_id, current_user_id){
    moment.updateLocale('es', MOMENT_IN_SPANISH)
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        database.ref().child(`/chats/${request_id}`)
        .on('value', snapshot => {
            const chat_info = Object.assign( {}, {
                artist_id: snapshot.val().artist_id,
                artist_name: snapshot.val().artist_name,
                client_id: snapshot.val().client_id,
                client_name: snapshot.val().client_name,
                created_at: snapshot.val().created_at
            })
            let messages = _.map(snapshot.val().messages, ( message, id ) => {
                const formated_message = Object.assign({}, { 
                    id,
                    position: message.user_id === current_user_id ? 'right' : 'left',
                    type: message.user_id === 'admin' ? 'system' : 'text',
                    text: message.message,
                    date: new Date(message.created_at),
                    dateString: moment(message.created_at).format('dddd, DD/MM/YYYY HH:mm'),
                    notch: false
                 })
                return formated_message
            })

            dispatch({
                type: USER.FETCH_MY_CHAT,
                payload: { messages, chat_info }
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })  
    }
}

export function sendChatMessage(fields){
    return dispatch => {
        const newMessageKey = database.ref().child(`chats/${fields.request_id}/messages`).push().key
        
        database.ref().child(`chats/${fields.request_id}/messages/${newMessageKey}`)
        .set({ 
            created_at: fields.created_at,
            message: fields.message,
            type: 0,
            user_id: fields.user_id
         })
    }
}

export function confirmRequest(request, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        const req_id = request.id
        const url = `${CLOUD_FUNCTIONS_URL}/confirmServiceNoGateway`
        auth.currentUser.getIdToken(true)
        .then(token => {
            const headers = { 
                headers: { 
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            }
            const data = JSON.stringify({ request_id: req_id })
            
            let axios_request = axios.create({
                validateStatus : status => { return status <= 500 }
            })

            axios_request.post(url, data, headers)
            .then(response => {
                if(response.data.success){
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Has confirmado la reservación para este servicio.'
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    callback()
                }
                else {
                    if(response.data.details === CLOUD_FUNCTIONS_ERRORS.ALREADY_RESERVED){
                        dispatch({
                            type: ALERTS.SHOW_ERROR_MESSAGE,
                            payload: 'Lo sentimos, este servicio ya fue contratado. No se realizará ningún cargo. El chat permanecerá abierto 12 horas por si gustas consultar con el artista su disponibilidad.'
                        })
                        dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    }
                    else{
                        dispatch({
                            type: ALERTS.SHOW_ERROR_MESSAGE,
                            payload: OPENPAY_ERRORS[response.data.details.error_code]
                        })
                        dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    }
                }
            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
                })
                console.log('Error mayor a 500 desde servidor.', error)
            })
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log('No se logró actualizar token de usuario', error)
        })

    }
}

export function cancelReservation(cancelation, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        const url = `${CLOUD_FUNCTIONS_URL}/cancelReservation`
        auth.currentUser.getIdToken(true)
        .then(token => {
            const headers = { 
                headers: { 
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            }
            const data = JSON.stringify(cancelation)
            let axios_request = axios.create({
                validateStatus : status => { return status <= 500 }
            })

            axios_request.post(url, data, headers)
            .then(response => {
                if(response.data.success){
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Has cancelado la reservación para este servicio.'
                    })
                    callback()
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                }
                else {
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
                    })
                }

                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
                })
                console.log('Error mayor a 500 desde servidor.', error)
            })
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log('No se logró actualizar token de usuario', error)
        })

        
    }
}

export function listenUserData(user_id){
    return dispatch => {
        firestore.collection('users').doc(user_id)
        .onSnapshot( user => {
            dispatch({
                type: USER.FETCH_USER_DATA,
                payload: user.data()
            })
        })
    }
}

export function stopListenUserData(user_id){
    return dispatch => {
        firestore.collection('users').doc(user_id)
        .onSnapshot( () => {} )
    }
}

function getArtistTypeId(artist_type_name) {
    switch(artist_type_name){
      case ARTISTS.TYPES.MUSICAL.name:
        return ARTISTS.TYPES.MUSICAL.id
  
      case ARTISTS.TYPES.PERFORMANCE.name:
        return ARTISTS.TYPES.PERFORMANCE.id
  
      case ARTISTS.TYPES.PRODUCTION.name:
        return ARTISTS.TYPES.PRODUCTION.id
  
      default:
        return ARTISTS.TYPES.MUSICAL.id
    }
  }

export function updateUserData(values, user_id, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        let user = {
            name: values.name,
            last_name: values.lastName,
            phone: values.phone,
            email: values.email,
            address: values.address,
            country: values.country,
        }

        if(typeof values.artist_name !== 'undefined')
            user.artist_name = values.artist_name

        if(typeof values.artistType !== 'undefined')
            user.artist_type = getArtistTypeId(values.artistType)

        if(typeof values.paypal_email !== 'undefined')
            user.paypal_account_email= values.paypal_email

        if(typeof values.rfc !== 'undefined')
            user.rfc= values.rfc

        firestore.collection('users').doc(user_id)
        .update(user)
        .then( () => {
            dispatch({
                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                payload: 'Tus cambios han sido guardados.'
            })
            callback()
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch(error => {
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'No se han podido guardar los cambios, por favor inténtalo nuevamente.'
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log('No se han podido guardar los cambios', error)
        })
    }
}

export function selectDefaultCard(user_id, card_id){
    return dispatch => {
        firestore.collection('users').doc(user_id)
        .update({
            default_card: card_id
        })
        .then( () => {
            dispatch({
                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                payload: 'Se ha cambiado tu tarjeta predeterminada.'
            })
        })
    }
}

export function createUserCard(card_token, device_id, user_id, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        const url = `${CLOUD_FUNCTIONS_URL}/addCard`
        auth.currentUser.getIdToken(true)
        .then(token => {
            const headers = { 
                headers: { 
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            }
            const data = JSON.stringify({ token: card_token, device_id, user_id })
            
            let axios_request = axios.create({
                validateStatus : status => { return status <= 500 }
            })

            axios_request.post(url, data, headers)
            .then(response => {
                if(response.data.success)
                    callback(true)
                else {
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: OPENPAY_ERRORS[response.data.details.error_code]
                    })
                    callback(false)
                }

                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
                })
                console.log('Error mayor a 500 desde servidor.', error)
            })
            
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log('No se logró actualizar token de usuario', error)
        })

        
    }
}

export function deleteCard(user_id, card_id){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        const url = `${CLOUD_FUNCTIONS_URL}/deleteCard`

        auth.currentUser.getIdToken(true)
        .then(token => {
            const headers = { 
                headers: { 
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            }
            const data = JSON.stringify({ user_id, card_id })

            axios.post(url, data, headers)
                .then(response => {
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Se ha eliminado tu tarjeta.'
                    })
                })
                .catch(error => {
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: 'No se ha podido eliminar la tarjeta, por favor inténtalo nuevamente.'
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    console.log('Hubo un error al intentar eliminar la tarjeta en el servidor. ', error)
                })
        })
        .catch(error => {
            console.log('No se logró actualizar token de usuario', error)
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'No se ha podido eliminar la tarjeta, por favor inténtalo nuevamente.'
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

export function fetchUserCards(user_id){
    return dispatch => {
        firestore.collection('users').doc(user_id)
        .get().then( doc => {
            if (doc.exists){
                const cards = doc.data().cards || null
                dispatch({
                    type: AUTH.FETCH_USER_CARDS,
                    payload: cards
                })
            }
            else
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'No se logró conectar con el servidor para obtener tarjetas del usuario.'
                })
        })
        .catch( error => console.log(dispatch, error) )
        
    }
}

export function fetchUserHistory(user_id){
    let reservationsArray = []
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        firestore.collection('reservations')
        .where('client_id', '==', user_id)
        .where('status', '>=', SERVICE_STATUS.rate_pending)
        .get().then(querySnapshot => {
            querySnapshot.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                reservationsArray.push({ ...sid, ...data })
            })
            dispatch({
                type: USER.FETCH_HISTORY,
                payload: _.orderBy(reservationsArray, ['created_at'], ['desc'])
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
        .catch(error => {
            console.log('No se logró obtener historial del usuario: ', error)
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        })
    }
}

export function fetchUserFavorites(user_id){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        database.ref().child(`/favorites/${user_id}`)
        .on('value', snapshot => {
            
            let favorites = _.map(snapshot.val(), ( favorite, id ) => {
                const formated_favorite = Object.assign({}, { 
                    id,
                    ...favorite
                 })
                return formated_favorite
            })

            dispatch({
                type: USER.FETCH_FAVORITES,
                payload: favorites
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        }) 
    }
}

export function unLikeService(user_id, service_id){
    return dispatch => {
        database.ref().child(`/favorites/${user_id}/${service_id}`).remove()
        .then( () => {
            dispatch({
                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                payload: 'Servicio eliminado de tu lista de favoritos'
            })
        })
        .catch(error => console.log('No se ha logrado quitar de favoritos', error))
    }
}

export function likeService(user_id, service_id, artist_name, service_name){
    return dispatch => {
        database.ref().child(`/favorites/${user_id}/${service_id}`)
        .set({artist_name, service_name})
        .then( () => {
            dispatch({
                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                payload: 'Servicio guardado en tu lista de favoritos'
            })
        })
        .catch(error => console.log('No se ha logrado agregar a favoritos', error))
    }
}

export function deleteSaved(user_id, service_id){
    return dispatch => {
        database.ref().child(`/saved/${user_id}/${service_id}`).remove()
        .then( () => {
            dispatch({
                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                payload: 'Servicio eliminado de tu lista de guardados'
            })
        })
        .catch(error => console.log('No se ha logrado quitar de la lista de guardados', error))
    }
}

export function fetchUserSaved(user_id){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        database.ref().child(`/saved/${user_id}`)
        .on('value', snapshot => {
            let saved = _.map(snapshot.val(), ( service, id ) => {
                let formated_service = {}
                formated_service = Object.assign({}, { 
                    id,
                    ...service
                 })
                return formated_service
            })
            dispatch({
                type: USER.FETCH_SAVED,
                payload: saved
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
        }) 
    }
}

export function saveService(user_id, service, callback){
    delete service.startHour
    delete service.endHour
    delete service.date
    service.hours = Number(service.hours)

    return dispatch => {
        const newPostKey = database.ref().child('saved').push().key
        database.ref().child(`/saved/${user_id}/${newPostKey}`)
        .set(service)
        .then( () => {
            dispatch({
                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                payload: 'Servicio añadido a tu lista de guardados.'
            })
            callback()
        })
        .catch( error => {
            console.log('No se ha podido guardar este servicio', error)
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'No se ha podido guardar el servicio, por favor inténtalo nuevamente.'
            })
        })
    }
}

export function preApproveRequest(request_id, callback){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        const url = `${CLOUD_FUNCTIONS_URL}/acceptService`
        auth.currentUser.getIdToken(true)
        .then(token => {
            const headers = { 
                headers: { 
                    'Authorization':`Bearer ${token}`,
                    'Content-Type':'application/json'
                }
            }
            const data = JSON.stringify({ request_id })
            
            let axios_request = axios.create({
                validateStatus : status => { return status <= 500 }
            })

            axios_request.post(url, data, headers)
            .then(response => {
                if(response.data.success){
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Has pre aprobado la reservación para este servicio.'
                    })
                    callback()
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                }
                else {
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: OPENPAY_ERRORS[response.data.details.error_code]
                    })
                }

                dispatch({ type: SHARED.PAGE_STOP_LOADING })
            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                dispatch({
                    type: ALERTS.SHOW_ERROR_MESSAGE,
                    payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
                })
                console.log('Error mayor a 500 desde servidor.', error)
            })
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
            })
            console.log('No se logró actualizar token de usuario', error)
        })
    }
}

firestore.collection('reservations').constructor.prototype.getPendingReservations = reservations => {
    
    return Promise.all(
      _.map(reservations, reservation => {
          console.log('reservation', reservation)
        return firestore
              .collection(`reservations`)
              .doc(reservation)
              .get()
      })
    )
  }

export function fetchPendingRates(pending_reservations, current_user_id){
    return dispatch => {
        let pendingsArray = []
        firestore.collection('reservations')
        .getPendingReservations(pending_reservations)
        .then(reservations => {
            reservations.forEach( doc => {
                let sid = { id:doc.id }
                let data = doc.data()
                pendingsArray.push({ ...sid, ...data })
            })

            dispatch({
                type: USER.FETCH_PENDING_RATES,
                payload: pendingsArray
            })
        })
        .catch(error => {
            console.log('No se lograron obtener los servicios con reseñas pendientes por el usuario', error)
        })
    }
}

export function rateService(rate, service_id, pending_rates, callback){
    return dispatch => {

        dispatch({ type: SHARED.PAGE_IS_LOADING })

        let batch = firestore.batch()

        batch.set(
            firestore.collection('services').doc(service_id).collection('comments').doc(),
            rate
        )

        const reservation_id = pending_rates.pop()

        batch.update( 
            firestore.collection('reservations').doc(reservation_id),
            { client_comment : rate }
        )

        batch.update(
            firestore.collection('users').doc(rate.user_id),
            { pending_rates }
        )

        batch.commit()
        .then(() => {
            dispatch({
                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                payload: 'Tu reseña ha sido guardada exitósamente.'
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            callback()
        })
        .catch( error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
            })
            console.log('No se logró guardar esta reseña.', error)
        })

    }
}

export function rateClient(rate, client_id, pending_rates, callback){
    return dispatch => {

        dispatch({ type: SHARED.PAGE_IS_LOADING })

        let batch = firestore.batch()

        batch.set(
            firestore.collection('users').doc(client_id).collection('comments').doc(),
            rate
        )

        const reservation_id = pending_rates.pop()

        batch.update(
            firestore.collection('reservations').doc(reservation_id),
            { artist_comment : rate }
        )

        batch.update(
            firestore.collection('users').doc(rate.user_id),
            { pending_rates }
        )

        batch.commit()
        .then(() => {
            dispatch({
                type: ALERTS.SHOW_SUCCESS_MESSAGE,
                payload: 'Tu reseña ha sido guardada exitósamente.'
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            callback()
        })
        .catch( error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'Ha ocurrido un error, por favor inténtalo nuevamente.'
            })
            console.log('No se logró guardar esta reseña.', error)
        })

    }
}

export function deleteNewServices(user_id, type_service){
    return dispatch => {
        let change = {}
        if(type_service === 'requests')
            change.request_map = firestoreFieldValue.delete()
        else if(type_service === 'reservations')
            change.reservation_map = firestoreFieldValue.delete()

        firestore.collection('users').doc(user_id)
        .update(change)
        .then(() => {
            console.log('Se cambio: ', change)
        })
    }
}

export function changeProfileImage(user_id, image){
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })

        storage.ref(`/users/${user_id}`)
        .put(image.file)
        .then( snapshot => {
            snapshot.ref.getDownloadURL()
            .then(url => {
                const change = { profile_image : url}
                firestore.collection('users').doc(user_id)
                .update(change)
                .then( () => {
                    dispatch({
                        type: ALERTS.SHOW_SUCCESS_MESSAGE,
                        payload: 'Tu imagen de perfil se ha actualizado.'
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                })
                .catch(error => {
                    dispatch({
                        type: ALERTS.SHOW_ERROR_MESSAGE,
                        payload: 'No se ha podido guardar tu imagen de perfil, por favor inténtalo nuevamente.'
                    })
                    dispatch({ type: SHARED.PAGE_STOP_LOADING })
                    console.log('No se han podido guardar la imagen de perfil del usuario', error)
                })
                
            })
            .catch(error => {
                dispatch({ type: SHARED.PAGE_STOP_LOADING })
                console.log('No se ha logrado obtener la URL de la imagen del perfil.', error)
            })
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            console.log('Falló la subida de la imagen de perfil.', error)
        })
    }
}